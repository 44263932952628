import React, { Component } from 'react';
import {
  DataTable, DataTableBody, DataTableCell, DataTableContent, DataTableHead, DataTableRow,
} from 'rmwc';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import { toast } from 'react-toastify';
import Input from '../form/Input';
import { deleteProductTagRequest, getProductAllTagsRequest } from '../../store/actions/product';
import Confirmation from '../modals/Confirmation';
import Utils from '../../helpers/Utils';
import Loader from '../Loader';

class AllTagsList extends Component {
  getTagsData = memoizeOne((search) => {
    const { match: { params: { productId } } } = this.props;
    this.props.getProductAllTagsRequest(productId, search);
  })

  static propTypes = {
    productAllTags: PropTypes.array.isRequired,
    getProductAllTagsRequest: PropTypes.func.isRequired,
    deleteProductTagRequest: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    productAllTagsStatus: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      confirmationModal: null,
      tag: null,
      loading: false,
    };
  }

  toggleConfirmationModal = (tag) => {
    const { confirmationModal } = this.state;
    this.setState({ confirmationModal: !confirmationModal, tag });
  }

  handleDelete = async () => {
    const { tag } = this.state;
    const { match: { params: { productId } } } = this.props;
    this.setState({ loading: true });
    await this.props.deleteProductTagRequest(tag);
    this.setState({ loading: false, confirmationModal: false });
    toast.success('Successfully deleted.');
    const query = Utils.queryParse();
    this.props.getProductAllTagsRequest(productId, query.search || '');
  }

  handleSearch = (ev) => {
    const query = Utils.queryParse();
    query.search = ev.target.value;
    this.props.history.replace(`?${Utils.queryStringify(query)}`);
  }

  render() {
    const { productAllTags, productAllTagsStatus } = this.props;
    const { confirmationModal, loading } = this.state;
    const query = Utils.queryParse();
    this.getTagsData(query.search || '');
    return (
      <div className="allTagsList">
        {productAllTagsStatus === 'ok' || query.search ? (
          <DataTable
            stickyRows="1"
          >
            <DataTableContent>
              <DataTableHead>
                <DataTableRow>
                  <DataTableCell>{`Tags (${productAllTags.length})`}</DataTableCell>
                  <DataTableCell />
                </DataTableRow>
              </DataTableHead>
              <DataTableBody>
                <DataTableRow>
                  <DataTableCell>
                    <Input placeholder="search" onChange={this.handleSearch} value={query.search} />
                  </DataTableCell>
                  <DataTableCell />
                </DataTableRow>
                {productAllTags?.map((tag) => {
                  const tags = tag.tag.split(',');
                  return (
                    <DataTableRow key={tag.group_id}>
                      <DataTableCell>
                        <div className="tags">
                          {tags.map((t) => <span className="tag" key={t}>{t}</span>)}
                        </div>
                      </DataTableCell>
                      <DataTableCell
                        className="blue clickable"
                        onClick={() => this.toggleConfirmationModal(tag)}
                      >
                        Delete
                      </DataTableCell>
                    </DataTableRow>
                  );
                })}
              </DataTableBody>
            </DataTableContent>
          </DataTable>
        ) : <Loader />}
        <Confirmation
          isOpen={confirmationModal}
          title="Are you sure, you want to delete this tag?"
          onCloseModal={() => this.toggleConfirmationModal(null)}
          onConfirm={this.handleDelete}
          loading={loading}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  productAllTags: state.product.productAllTags,
  productAllTagsStatus: state.product.productAllTagsStatus,
});

const mapDispatchToProps = {
  getProductAllTagsRequest,
  deleteProductTagRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AllTagsList);

export default withRouter(Container);
