import { EventEmitter } from 'events';
import firebase from './firebase';

class Account {
  static set(data) {
    localStorage.setItem('account', JSON.stringify(data));
  }

  static setFBToken(token) {
    localStorage.setItem('fbToken', token);
  }

  static setLoginTime() {
    localStorage.setItem('loginTime', new Date().getTime());
  }

  static getLoginTime() {
    return localStorage.getItem('loginTime') || -1;
  }

  static setPermissions(permissions) {
    localStorage.setItem('permissions', JSON.stringify(permissions));
  }

  static getPermissions() {
    try {
      const permissions = localStorage.getItem('permissions');
      return JSON.parse(permissions) || [];
    } catch (e) {
      return [];
    }
  }

  static getNewTokenPending = false;

  static getNewTokenEvent = new EventEmitter();

  static getNewToken = async (loginTime) => {
    if (this.getNewTokenPending) {
      return new Promise((resolve) => {
        this.getNewTokenEvent.on('getNewToken', resolve);
      });
    }
    this.getNewTokenPending = true;
    console.warn('token refresh ...', loginTime);
    const currentUser = await new Promise((resolve) => {
      const interval = setInterval(() => {
        if (firebase.auth().currentUser) {
          clearInterval(interval);
          resolve(firebase.auth().currentUser);
        }
      }, 100);
    });
    const token = await currentUser.getIdToken(true);
    this.setLoginTime();
    this.setFBToken(token);
    this.getNewTokenPending = false;
    console.warn('token refresh done');
    this.getNewTokenEvent.emit('getNewToken', token);
    return token;
  }

  static getFBToken = async () => {
    let token = localStorage.getItem('fbToken');
    if (!token) {
      return token;
    }
    const loginTime = Account.getLoginTime();
    if (new Date().getTime() - loginTime > 3 * 1000 * 1000) {
      console.warn('getNewToken');
      token = await this.getNewToken(loginTime);
    }
    return token;
  }

  static checkFBToken() {
    return localStorage.getItem('fbToken');
  }

  static get() {
    try {
      const account = localStorage.getItem('account');
      return JSON.parse(account) || {};
    } catch (e) {
      return {};
    }
  }

  static add(data) {
    this.set({ ...this.get(), ...data });
  }

  static delete() {
    localStorage.removeItem('fbToken');
    localStorage.removeItem('account');
    localStorage.removeItem('loginTime');
    localStorage.removeItem('permissions');
  }
}

export default Account;
