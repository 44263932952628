import React, { Component } from 'react';
import { List, SimpleListItem } from 'rmwc';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import config from '../../config';

const appVersion = config.APP_VERSION;

class LeftMenu extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    menus: PropTypes.array.isRequired,
  };

  render() {
    const { history: { location: { pathname } }, menus } = this.props;
    return (
      <div className="menuList">
        <List>
          {menus.map((menu) => (
            <SimpleListItem
              text={<Link to={menu.path}>{menu.title}</Link>}
              activated={pathname.includes(menu.path)}
              className="leftMenuLink"
              key={menu.path}
            />
          ))}

          {/* {tab === 'analytics' ? ( */}
          {/*  <SimpleListItem */}
          {/*    text="Intercom" */}
          {/*    onClick={() => this.handleRedirect('/analytics/intercom')} */}
          {/*    activated={pathname.includes('/analytics/intercom')} */}
          {/*  /> */}
          {/* ) : null} */}
        </List>
        <span className="version">{`Version ${appVersion}`}</span>
      </div>
    );
  }
}

export default withRouter(LeftMenu);
