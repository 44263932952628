import React, { Component } from 'react';
import Modal from 'react-modal';
import { Icon } from 'rmwc';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import memoizeOne from 'memoize-one';
import _ from 'lodash';
import Input from '../form/Input';
import Button from '../form/Button';
import { updateCodeForConsumersRequest } from '../../store/actions/inviteCodes';
import Utils from '../../helpers/Utils';

class EditInviteCode extends Component {
  initData = memoizeOne((code) => {
    this.setState({ formData: code });
  }, _.isEqual)

  static propTypes = {
    isOpen: PropTypes.bool,
    onCloseModal: PropTypes.func,
    updateCodeForConsumersRequest: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    code: PropTypes.object.isRequired,
  };

  static defaultProps = {
    isOpen: false,
    onCloseModal: undefined,
  }

  constructor(props) {
    super(props);
    this.state = {
      loading: '',
      formData: {},
    };
  }

  handleChange = (key, value) => {
    const { formData } = this.state;
    formData[key] = value;
    this.setState({ formData });
  }

  handleUpdate = async (type) => {
    const { formData } = this.state;
    this.setState({ loading: type });
    if (type === 'expire') {
      formData.state = 2;
    }
    const { payload: { data } } = await this.props.updateCodeForConsumersRequest(formData);
    if (data.success) {
      toast.success('Successfully updated.');
      const query = Utils.queryParse();
      query.updated = new Date();
      this.props.history.replace(`?${Utils.queryStringify(query)}`);
      this.props.onCloseModal();
    } else {
      toast.error('Something went wrong.');
    }

    this.setState({ loading: false });
  }

  render() {
    const {
      isOpen, code,
    } = this.props;
    const { loading, formData } = this.state;
    if (!isOpen) {
      return null;
    }
    this.initData(code);
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.props.onCloseModal}
        overlayClassName="modalOverlay"
        className="modalContent editInviteCode"
        parentSelector={() => document.querySelector('#root > div')}
      >
        <div className="top">
          <span>Edit Invite Code</span>
          <Icon icon="close" className="icon" onClick={this.props.onCloseModal} />
        </div>

        <Input
          textarea
          label="Notes"
          fieldType="Textarea"
          onChange={(ev) => this.handleChange('notes', ev.target.value)}
          value={formData.notes}
        />

        <div className="actions">
          <Button title="Cancel" outlined raised={false} onClick={this.props.onCloseModal} />
          <Button
            title="Save"
            loading={loading === 'update'}
            onClick={() => this.handleUpdate('update')}
          />
          {code.state !== 2 || loading === 'expire' ? (
            <Button
              title="Expire"
              loading={loading === 'expire'}
              onClick={() => this.handleUpdate('expire')}
            />
          ) : null}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  updateCodeForConsumersRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditInviteCode);

export default withRouter(Container);
