import React, { Component } from 'react';
import { Icon } from 'rmwc';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import Button from '../form/Button';
import Input from '../form/Input';

class ChangePassword extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onCloseModal: PropTypes.func,
  };

  static defaultProps = {
    isOpen: false,
    onCloseModal: undefined,
  }

  render() {
    const { isOpen } = this.props;
    if (!isOpen) {
      return null;
    }
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.props.onCloseModal}
        overlayClassName="modalOverlay"
        className="modalContent changePassword"
        parentSelector={() => document.querySelector('#root > div')}
      >
        <div className="top">
          <span>Change Robomart Password</span>
          <Icon icon="close" className="icon" onClick={this.props.onCloseModal} />
        </div>
        <Input label="New password" />
        <Input label="Confirm password" />
        <div className="actions">
          <Button title="Cancel" outlined raised={false} onClick={this.props.onCloseModal} />
          <Button title="Confirm" />
        </div>
      </Modal>
    );
  }
}

export default ChangePassword;
