import { GET_MERCHANTS, GET_MERCHANT_BY_ID } from '../actions/merchants';

const initialState = {
  merchants: [],
  merchantsStatus: '',
  merchant: {},
  merchantStatus: '',
  merchantsData: {},
  totalPages: 1,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_MERCHANTS.REQUEST: {
      return {
        ...state,
        merchantsStatus: 'request',
      };
    }
    case GET_MERCHANTS.SUCCESS: {
      const { data: { data: { Records, RecordsCount }, data } } = action.payload;
      return {
        ...state,
        merchants: Records,
        merchantsData: data,
        merchantsStatus: 'ok',
        totalPages: Math.ceil(RecordsCount / 10),
      };
    }
    case GET_MERCHANTS.FAIL: {
      return {
        ...state,
        merchantsStatus: 'fail',
      };
    }
    case GET_MERCHANT_BY_ID.REQUEST: {
      return {
        ...state,
        merchantStatus: 'request',
        merchant: {},
      };
    }
    case GET_MERCHANT_BY_ID.SUCCESS: {
      return {
        ...state,
        merchantStatus: 'ok',
      };
    }
    case GET_MERCHANT_BY_ID.FAIL: {
      return {
        ...state,
        merchantStatus: 'fail',
      };
    }
    default: {
      return state;
    }
  }
}
