import firebaseConfigsDev from './firebaseConfigsDev';
import firebaseConfigsProd from './firebaseConfigsProd';

const { REACT_APP_MODE } = process.env;
const config = ({
  dev: {
    API_URL: 'https://dev.api.robomart.ai',
    FIREBASE_CONFIGS: firebaseConfigsDev,
    URL: 'https://dev.control.robomart.ai',
    MAP_API_KEY: 'AIzaSyARNm0c7GjcdgbcBK3mQSoLlKycv829O50',
    APP_VERSION: '0.7.0-dev',
  },
  prod: {
    API_URL: 'https://api.robomart.ai',
    FIREBASE_CONFIGS: firebaseConfigsProd,
    MAP_API_KEY: 'AIzaSyD20zRpG2TjVjzCagN2Fr1jVC1FyAN0XHc',
    URL: 'https://control.robomart.ai',
    APP_VERSION: '0.6.0',
  },
})[REACT_APP_MODE || 'prod'];

export default config;
