import React, { Component } from 'react';
import { Checkbox, CircularProgress, Icon } from 'rmwc';
import memoizeOne from 'memoize-one';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Wrapper from '../../components/Wrapper';
import HeadersMeta from '../../components/HeadersMeta';
import DataTable from '../../components/DataTable';
import Pagination from '../../components/Pagination';
import { getOrdersOfConsumerRequest, getOrdersRequest } from '../../store/actions/orders';
import Utils from '../../helpers/Utils';
import Input from '../../components/form/Input';
import { setCurrentItemName } from '../../store/actions/app';
import Loader from '../../components/Loader';
import { getSingleCostumerRequest } from '../../store/actions/consumers';

class Orders extends Component {
  getData = memoizeOne(async (query, id, consumer) => {
    this.setState({ currentLoading: true });
    const {
      // eslint-disable-next-line camelcase
      name, email, phone, receipt_no, card_last4, status, request_time, charge_amount,
      total, vehicle, predicate, reverse, credit,
    } = query;
    const predicateObject = {
      fullname: name,
      email,
      phone,
      card_last4,
      receipt_no,
      status,
      total,
      vehicle_name: vehicle,
      request_time,
      stripe_charge_amount: charge_amount,
      credit_applied: credit,
    };
    const search = { predicateObject };
    const sort = { predicate: predicate || 'request_time', reverse: reverse || true };
    if (id) {
      this.props.setCurrentItemName('');
      const { payload: { data: { data } = {} } } = await this.props.getOrdersOfConsumerRequest(id);
      if (data && consumer) {
        this.props.setCurrentItemName(consumer.fullname);
      }
    } else {
      await this.props.getOrdersRequest(sort, search, (query.page - 1) || 0);
    }
    this.setState({ currentLoading: false });
  }, _.isEqual);

  static propTypes = {
    orders: PropTypes.array.isRequired,
    getOrdersRequest: PropTypes.func.isRequired,
    getOrdersOfConsumerRequest: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    setCurrentItemName: PropTypes.func.isRequired,
    ordersStatus: PropTypes.string.isRequired,
    totalPages: PropTypes.number,
    getSingleCostumerRequest: PropTypes.func.isRequired,
    consumer: PropTypes.object.isRequired,
  };

  static defaultProps = {
    totalPages: 1,
  }

  constructor(props) {
    super(props);
    this.state = {
      currentLoading: false,
    };
    this.columns = [
      { column: 'name', type: 'searchable' },
      { column: 'email', type: 'searchable' },
      { column: 'phone', type: 'searchable' },
      { column: 'receipt_no', type: 'searchable' },
      { column: 'card_last4', type: 'searchable' },
      { column: 'status', type: 'searchable' },
      { column: 'total', type: 'searchable', isNumber: true },
      { column: 'credit', type: 'searchable', isNumber: true },
      { column: 'charge_amount', type: 'searchable', isNumber: true },
      { column: 'vehicle', type: 'searchable' },
      { column: 'request_time', type: 'searchable', isDate: true },
      { column: 'rating', type: 'searchable' },
    ];
    this.keysToChange = {
      fullname: 'name',
      order_id: 'id',
      vehicle_name: 'vehicle',
      credit_applied: 'credit',
      stripe_charge_amount: 'charge_amount',
    };
  }

  componentDidMount() {
    const { match: { params: { consumerId } } } = this.props;
    this.props.getSingleCostumerRequest(consumerId);
  }

  handleRedirect = (id) => `/admin/orders/receipt/${id}`

  render() {
    const { currentLoading } = this.state;
    const {
      orders, totalPages, match: { params: { consumerId } }, ordersStatus, consumer,
    } = this.props;

    const data = Utils.renameKeys(this.keysToChange, orders);
    const query = Utils.queryParse(window.location.search, this.keysToChange);
    this.getData(query, consumerId, consumer);

    console.log(consumer);
    return (
      <Wrapper>
        <HeadersMeta page="Orders" />
        <div className="zones ordersList">
          <div className="top">
            <div className="loading">
              {currentLoading && !consumerId ? <CircularProgress size="small" /> : null}
            </div>
          </div>
          <div>
            {consumerId && ordersStatus === 'request' ? <Loader /> : null}
            {consumerId && !_.isEmpty(consumer) && ordersStatus !== 'request' ? (
              <div>
                <Input value={consumer.fullname} readOnly label="Name" />
                <div className="consumerData">
                  <Input value={consumer.email} readOnly label="Email" />
                  <Input value={consumer.phone} readOnly label="Phone" />
                </div>
                <div className="notificationContainer">
                  <div className="notification">
                    {consumer.delivery_sms
                      ? <Icon icon="check_box" className="active big" />
                      : <Icon icon="check_box_outline_blank" className="big blank" />}
                    <p>SMS Notification</p>
                  </div>
                  <div className="notification">
                    {consumer.delivery_notification
                      ? <Icon icon="check_box" className="active big" />
                      : <Icon icon="check_box_outline_blank" className="big blank" />}
                    <p>Push Notification</p>
                  </div>
                </div>
              </div>
            ) : null}
            {consumerId ? (
              <DataTable
                columns={this.columns}
                data={data}
                className="consumers"
                actions={['info']}
                onInfo={this.handleRedirect}
                nameKey="receipt_no"
              />
            ) : null}
            {!consumerId ? (
              <DataTable
                columns={this.columns}
                data={data}
                filters={!consumerId}
                className="consumers"
                actions={['info']}
                onInfo={this.handleRedirect}
                nameKey="receipt_no"
              />
            ) : null}
          </div>
          {!consumerId ? <Pagination currentPage={query.page} totalPages={totalPages} /> : null}
        </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  orders: state.orders.orders,
  totalPages: state.orders.totalPages,
  ordersStatus: state.orders.ordersStatus,
  consumer: state.consumers.consumer,
});

const mapDispatchToProps = {
  getOrdersRequest,
  getOrdersOfConsumerRequest,
  setCurrentItemName,
  getSingleCostumerRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Orders);

export default Container;
