import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import memoizeOne from 'memoize-one';
import Wrapper from '../components/Wrapper';
import LeftBlock from '../components/fleet/LeftBlock';
import RightBlock from '../components/fleet/RightBlock';
import firebase from '../helpers/firebase';
import HeadersMeta from '../components/HeadersMeta';
import { getCustomTokenRequest } from '../store/actions/app';
import Utils from '../helpers/Utils';
import { getRCsRequest } from '../store/actions/rcs';
import GoogleMapWrapper from '../components/GoogleMapWrapper';
import QueryStorage from '../helpers/QueryStorage';
import HasPermission from '../components/HasPermission';

// const logging = new Logging({ projectId: 'psychic-kite-342114' });
// console.log(logging, 'logging');

class Fleet extends Component {
  setPath = memoizeOne((search) => {
    QueryStorage.setPaths('fleet', search);
  })

  static propTypes = {
    getCustomTokenRequest: PropTypes.func.isRequired,
    getRCsRequest: PropTypes.func.isRequired,
    rcs: PropTypes.array.isRequired,
    zones: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      vehiclesData: {},
      activeCustomersData: {},
    };
  }

  async componentDidMount() {
    const { payload: { data: { data: customToken } = {} } } = await this.props.getCustomTokenRequest();
    this.props.getRCsRequest({ predicate: 'name', reverse: false }, {}, 0, 100);
    const { activeCustomersData } = this.state;

    const user = await firebase.auth().signInWithCustomToken(customToken);
    if (user) {
      const vehicles = firebase.database().ref().child('/Vehicles/');
      vehicles.on('value', (data) => {
        const obj = data.val();
        // axios.post('http://test.robomart.ai/firebase-data', obj);
        this.setState({ vehiclesData: obj });
      });
      const customers = firebase.database().ref().child('/ActiveCustomers/').orderByChild('date');
      customers.on('value', (data) => {
        const obj = data.val();
        if (_.isObject(obj)) {
          this.setState({ activeCustomersData: obj });
        }
      });
      customers.on('child_removed', (data) => {
        delete activeCustomersData[data.key];
        this.setState({ activeCustomersData });
      });
    }
  }

  handleRedirect = (type, markerId) => {
    this.props.history.push(`/admin/${type}/${markerId}`);
  }

  handleReplaceUrl = (params) => {
    const query = Utils.queryParse();
    _.forEach(params, (v, k) => {
      query[k] = v;
    });
    this.props.history.replace(`?${Utils.queryStringify(query)}`);
  }

  render() {
    const { vehiclesData, activeCustomersData } = this.state;
    const { rcs, zones, location: { search } } = this.props;
    const vehiclesMarkers = Utils.formatVehiclesMarkerData(vehiclesData);
    const storeMarkers = Utils.formatStoreMarkersData(rcs);
    let customersMarkers = Utils.formatCustomersMarkersData(activeCustomersData);
    const vehiclesDataArr = Utils.objectToArray(vehiclesData);
    let activeCustomersDataArr = Utils.objectToArray(activeCustomersData);
    const query = Utils.queryParse(window.location.search, {}, '', 'comma');
    const coordsData = _.compact(zones.map((z) => (query.zone?.includes(z.id) ? { coords: z.coords, id: z.id } : '')));
    if (query.f === 'true' || !query.f) {
      customersMarkers = customersMarkers.filter((f) => ((new Date().getTime() - f.date) / 1000 / 60 / 60 / 24) < 24);
      activeCustomersDataArr = activeCustomersDataArr.filter((f) => ((new Date().getTime() - f.date) / 1000 / 60 / 60 / 24) < 24);
    }
    this.setPath(search);
    return (
      <Wrapper>
        <HasPermission edit="fleet" redirect>
          <HeadersMeta page="Fleet" />
          <LeftBlock activeCustomers={activeCustomersDataArr} mapRef={this.mapRef} />
          <div
            className="centerBlock"
          >
            <GoogleMapWrapper
              storeMarkers={storeMarkers}
              vehiclesMarkers={vehiclesMarkers}
              customersMarkers={customersMarkers}
              vehicleId={query.vehicle_id}
              filters
              coords={coordsData}
              ref={(ref) => {
                this.mapRef = ref;
              }}
              onRedirect={this.handleRedirect}
              onReplaceUrl={this.handleReplaceUrl}
            />
          </div>
          <RightBlock vehicles={vehiclesDataArr} mapRef={this.mapRef} rcs={rcs} />
        </HasPermission>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  rcs: state.rcs.rcs,
  zones: state.zones.zones,
});

const mapDispatchToProps = {
  getCustomTokenRequest,
  getRCsRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Fleet);

export default Container;
