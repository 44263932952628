import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  DataTable, DataTableBody, DataTableCell, DataTableContent, DataTableHead, DataTableRow, Icon,
} from 'rmwc';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import Wrapper from '../../components/Wrapper';
import { getOrderReceiptRequest } from '../../store/actions/orders';
import Loader from '../../components/Loader';
import noImg from '../../assets/images/noImg.png';
import TagGroup from '../../components/tags/TagGroup';
import { setCurrentItemName } from '../../store/actions/app';

class OrderReceipt extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    getOrderReceiptRequest: PropTypes.func.isRequired,
    orderReceiptStatus: PropTypes.string.isRequired,
    orderReceipt: PropTypes.object.isRequired,
    setCurrentItemName: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      itemIds: [],
    };
  }

  async componentDidMount() {
    const { match: { params: { orderId } } } = this.props;
    this.props.setCurrentItemName('');
    const { payload: { data: { data } = {} } } = await this.props.getOrderReceiptRequest(orderId);
    this.props.setCurrentItemName(data?.receipt_no);
  }

  toggleTagsDataTable = (id) => {
    let { itemIds } = this.state;
    if (itemIds.includes(id)) {
      itemIds = itemIds.filter((i) => i !== id);
      this.setState({ itemIds });
    } else {
      this.setState({ itemIds: [...itemIds, id] });
    }
  }

  render() {
    const { orderReceipt, orderReceiptStatus } = this.props;
    const { itemIds } = this.state;

    return (
      <Wrapper>
        <div className="orderReceipt">
          {orderReceiptStatus !== 'ok' ? <Loader /> : null}
          {orderReceiptStatus === 'ok' && !_.isEmpty(orderReceipt) ? (
            <div className="robomartData">
              <div className="img">
                <img src={orderReceipt.type_image_url} alt="" />
              </div>
              <div className="title">
                <span>{orderReceipt.type_name}</span>
                <span className="vehicleName">{`Robomart ${orderReceipt.vehicle_name}`}</span>
                <span className="requestTime">{moment(+orderReceipt.request_time).format('D/MM/YYYY h:mm:ss A')}</span>
              </div>
            </div>
          ) : null}
          {orderReceiptStatus === 'ok' && !_.isEmpty(orderReceipt?.items) ? (
            <DataTable>
              <DataTableContent>
                <DataTableHead>
                  <DataTableRow>
                    <DataTableCell alignStart>Item</DataTableCell>
                    <DataTableCell alignEnd>QTY</DataTableCell>
                    <DataTableCell alignStart>Price</DataTableCell>
                  </DataTableRow>
                </DataTableHead>
                <DataTableBody>
                  {orderReceipt.items.map((item) => {
                    const tags = orderReceipt.itemsWithTags.map((t) => (t.product_id === item.product_id ? t : undefined));
                    return (
                      <Fragment key={item.id}>
                        <DataTableRow>
                          <DataTableCell className="item">
                            <div>
                              {!_.isEmpty(orderReceipt.itemsWithTags)
                                ? (
                                  <Icon
                                    icon={itemIds.includes(item.id) ? 'remove' : 'add'}
                                    className="icon"
                                    onClick={() => this.toggleTagsDataTable(item.id)}
                                  />
                                ) : null}
                              <img
                                src={item.img}
                                alt=""
                                onError={(ev) => ev.target.src = noImg}
                              />
                              <Link
                                to={`/admin/products/edit/${item.product_id}?n=${item.product_name}`}
                              >
                                {item.product_name || '-'}
                              </Link>
                            </div>
                          </DataTableCell>
                          <DataTableCell className="tag" alignEnd>
                            {`x${item.qty || '0'}`}
                          </DataTableCell>
                          <DataTableCell className="tag" alignEnd>
                            {`$${item.price?.toFixed(2) || '0'}`}
                          </DataTableCell>

                        </DataTableRow>
                        {itemIds.includes(item.id) ? (
                          <DataTableRow>
                            <DataTableCell colSpan={3} className="tagGroup">
                              <TagGroup tags={_.compact(tags)} />
                            </DataTableCell>
                          </DataTableRow>
                        ) : null}
                      </Fragment>
                    );
                  })}

                  <DataTableRow>
                    <DataTableCell />
                    <DataTableCell />
                    <DataTableCell className="tag">
                      <div className="subtotal">
                        <span>
                          Subtotal
                        </span>
                        <span>
                          {`$${orderReceipt.subtotal?.toFixed(2)}`}
                        </span>
                      </div>
                      <div className="subtotal">
                        <span>
                          Taxes
                        </span>
                        <span>
                          {`$${orderReceipt.tax?.toFixed(2)}`}
                        </span>
                      </div>
                      <div className="subtotal">
                        <span>
                          Credits
                        </span>
                        <span>
                          {`$${orderReceipt.credit_applied?.toFixed(2)}`}
                        </span>
                      </div>
                      <div className="subtotal">
                        <span>
                          Hailing Fee
                        </span>
                        <span>
                          {`$${orderReceipt.hailing_fee?.toFixed(2)}`}
                        </span>
                      </div>
                      <div className="subtotal total">
                        <span>
                          Total Incl.Tax
                        </span>
                        <span>
                          {`$${orderReceipt.stripe_charge_amount?.toFixed(2)}`}
                        </span>
                      </div>
                    </DataTableCell>

                  </DataTableRow>
                </DataTableBody>
              </DataTableContent>
            </DataTable>
          ) : null}
          {orderReceiptStatus === 'ok' && _.isEmpty(orderReceipt?.items)
            ? <div>{`This order is ${_.startCase(orderReceipt?.status).toLowerCase()}.`}</div> : null}
        </div>
        <div className="ratingContainer">
          <p>{`Rating: ${orderReceipt?.rating !== 0 ? `${orderReceipt?.rating}/5` : 'NA'}`}</p>
          <p>Review:</p>
          <p className="feedbackTxt">
            {orderReceipt?.feedback !== '' ? orderReceipt?.feedback : 'NA'}
          </p>
        </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  orderReceipt: state.orders.orderReceipt,
  orderReceiptStatus: state.orders.orderReceiptStatus,
});

const mapDispatchToProps = {
  getOrderReceiptRequest,
  setCurrentItemName,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderReceipt);

export default Container;
