import { define } from '../../helpers/redux-request';
import Api from '../../Api';

export const GET_USERS = define('GET_USERS');

export function getUsersRequest(sort, search, page, number) {
  return GET_USERS.request(() => Api.getUsers(sort, search, page, number)).takeLatest();
}

export const CREATE_USER = define('CREATE_USER');

export function createUserRequest(obj) {
  return CREATE_USER.request(() => Api.createUser(obj));
}

export const UPDATE_USER = define('UPDATE_USER');

export function updateUserRequest(obj) {
  return UPDATE_USER.request(() => Api.updateUser(obj));
}

export const GET_USER_BY_ID = define('GET_USER_BY_ID');

export function getUserByIdRequest(obj) {
  return GET_USER_BY_ID.request(() => Api.getUserById(obj));
}

export const DELETE_USER = define('DELETE_USER');

export function deleteUserRequest(id) {
  return DELETE_USER.request(() => Api.deleteUser(id));
}

export const GET_DRIVERS = define('GET_DRIVERS');

export function getDriversRequest(sort, search, page, number) {
  return GET_DRIVERS.request(() => Api.getDrivers(sort, search, page, number)).takeLatest();
}

export const GET_UNASSIGNED_VEHICLES = define('GET_UNASSIGNED_VEHICLES');

export function getUnassignedVehiclesRequest(id) {
  return GET_UNASSIGNED_VEHICLES.request(() => Api.getUnassignedVehicles(id));
}

export const ASSIGN_VEHICLE = define('ASSIGN_VEHICLE');

export function assignVehicleRequest(obj) {
  return ASSIGN_VEHICLE.request(() => Api.assignVehicle(obj));
}
