import React, { Component } from 'react';
import { CircularProgress } from 'rmwc';
import memoizeOne from 'memoize-one';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Wrapper from '../../components/Wrapper';
import HeadersMeta from '../../components/HeadersMeta';
import Button from '../../components/form/Button';
import DataTable from '../../components/DataTable';
import { deleteUserRequest, getUsersRequest } from '../../store/actions/users';
import Utils from '../../helpers/Utils';
import Confirmation from '../../components/modals/Confirmation';
import Account from '../../helpers/Account';
import Pagination from '../../components/Pagination';
import HasPermission from '../../components/HasPermission';

class Users extends Component {
  getData = memoizeOne(async (query) => {
    this.setState({ currentLoading: true });
    const {
      name, title, email, phone, predicate, reverse,
    } = query;
    const predicateObject = {
      display_name: name,
      title,
      email,
      phone,
    };
    const search = { predicateObject };
    const sort = { predicate: predicate || 'display_name', reverse: reverse || false };
    await this.props.getUsersRequest(sort, search, (query.page - 1) || 0);
    this.setState({ currentLoading: false });
  }, _.isEqual);

  static propTypes = {
    users: PropTypes.array.isRequired,
    getUsersRequest: PropTypes.func.isRequired,
    deleteUserRequest: PropTypes.func.isRequired,
    totalPages: PropTypes.number.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentLoading: false,
      confirmationModal: false,
      loading: false,
      userId: null,
    };
    this.columns = [
      { column: 'name', type: 'searchable' },
      { column: 'title', type: 'searchable' },
      { column: 'email', type: 'searchable' },
      { column: 'phone', type: 'searchable' },
      { column: 'driver', type: '' },
      { column: 'scanner', type: '' },
      { column: 'active', type: '' },
    ];
    this.keysToChange = {
      display_name: 'name',
      is_active: 'active',
      user_id: 'id',
      is_driver: 'driver',
      allow_scanner_app: 'scanner',
    };
  }

  handleAdd = () => {
    this.props.history.push('/admin/users/add');
  }

  handleEdit = (id) => `/admin/users/edit/${id}`

  toggleConfirmationModal = (userId, loggedUserId) => {
    const { confirmationModal } = this.state;
    if (userId === loggedUserId) {
      return null;
    }
    this.setState({ confirmationModal: !confirmationModal, userId });
    return null;
  }

  handleDelete = async () => {
    const { userId } = this.state;
    this.setState({ loading: true });
    await this.props.deleteUserRequest(userId);
    toast.success('Successfully Deleted');
    const query = Utils.queryParse(window.location.search, this.keysToChange);
    query.deleted = userId;
    this.getData(query);
    this.setState({ loading: false, confirmationModal: false });
  }

  render() {
    const { currentLoading, confirmationModal, loading } = this.state;
    const { users, totalPages } = this.props;
    const data = Utils.renameKeys(this.keysToChange, users);

    const query = Utils.queryParse(window.location.search, this.keysToChange);
    this.getData(query);
    const account = Account.get();
    const loggedUserId = data.find((d) => d.id === account.user_id)?.id;

    const actions = [];
    if (HasPermission.check('user_edit')) {
      actions.push('edit');
    }
    if (HasPermission.check('user_delete')) {
      actions.push('delete');
    }
    return (
      <Wrapper>
        <HasPermission edit="users" redirect>
          <HeadersMeta page="Users" />

          <div className="zones">
            <div className="top">
              <div className="loading">
                {currentLoading ? <CircularProgress size="small" /> : null}
              </div>
              <HasPermission edit="user_add">
                <Button title="Add User" onClick={this.handleAdd} />
              </HasPermission>
            </div>
            <div>
              <DataTable
                columns={this.columns}
                data={data}
                actions={actions}
                onEdit={this.handleEdit}
                onDelete={(userId) => this.toggleConfirmationModal(userId, loggedUserId)}
                disabled={loggedUserId}
              />
            </div>
            <Pagination currentPage={query.page} totalPages={totalPages} />
          </div>
          <Confirmation
            isOpen={confirmationModal}
            title="Are you sure, you want to delete this user?"
            onCloseModal={() => this.toggleConfirmationModal(null)}
            onConfirm={this.handleDelete}
            loading={loading}
          />
        </HasPermission>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users.users,
  totalPages: state.users.totalPages,
});

const mapDispatchToProps = {
  getUsersRequest,
  deleteUserRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Users);

export default Container;
