import { define } from '../../helpers/redux-request';
import Api from '../../Api';

export const GET_MERCHANTS = define('GET_MERCHANTS');

export function getMerchantsRequest(sort, search, page, number) {
  return GET_MERCHANTS.request(() => Api.getMerchants(sort, search, page, number)).takeLatest();
}

export const GET_MERCHANT_BY_ID = define('GET_MERCHANT_BY_ID');

export function getMerchantByIdRequest(id) {
  return GET_MERCHANT_BY_ID.request(() => Api.getMerchantById(id));
}

export const CREATE_MERCHANT = define('CREATE_MERCHANT');

export function createMerchantRequest(obj) {
  return CREATE_MERCHANT.request(() => Api.createMerchant(obj));
}

export const UPDATE_MERCHANT = define('UPDATE_MERCHANT');

export function updateMerchantRequest(obj) {
  return UPDATE_MERCHANT.request(() => Api.updateMerchant(obj));
}
