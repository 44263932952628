import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import memoizeOne from 'memoize-one';
import { toast } from 'react-toastify';
import _ from 'lodash';
import Input from '../../components/form/Input';
import Button from '../../components/form/Button';
import Select from '../../components/form/Select';
import Wrapper from '../../components/Wrapper';
import { createZoneRequest, getZoneByIdRequest, updateZoneRequest } from '../../store/actions/zones';
import HeadersMeta from '../../components/HeadersMeta';
import Validator from '../../helpers/Validator';
import Form from '../../components/form/Form';
import GoogleMapWrapper from '../../components/GoogleMapWrapper';
import { setCurrentItemName } from '../../store/actions/app';
import HasPermission from '../../components/HasPermission';

class ZoneForm extends Component {
  initZoneData = memoizeOne(async (id) => {
    if (!id) {
      return;
    }
    this.props.setCurrentItemName('');
    const { payload: { data: { data } = {} } } = await this.props.getZoneByIdRequest(id);
    if (data && !data.coords) {
      data.coords = [];
    }
    if (data) {
      this.props.setCurrentItemName(data.name);
      this.setState({ formData: data || {}, validCoords: true });
    }
  })

  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    createZoneRequest: PropTypes.func.isRequired,
    getZoneByIdRequest: PropTypes.func.isRequired,
    updateZoneRequest: PropTypes.func.isRequired,
    zoneStatus: PropTypes.string.isRequired,
    setCurrentItemName: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.status = [
      { value: true, label: 'Active' },
      { value: false, label: 'Inactive' }];
    this.state = {
      formData: {
        code: '',
        city: '',
        country: '',
        name: '',
        coords: '',
        is_active: false,
      },
      loading: false,
      validCoords: false,
      changed: false,
      emptyFields: [],
    };
  }

  handleAddZone = async (ev) => {
    ev.preventDefault();
    const { match: { params: { zoneId } } } = this.props;
    const { formData } = this.state;
    const emptyFields = Validator.getEmptyFields(formData, ['code', 'city', 'country', 'name', 'coords', 'state']);
    this.setState({ loading: true });
    if (!_.isEmpty(emptyFields)) {
      this.setState({ emptyFields, loading: false });
      return;
    }

    let data;
    if (zoneId) {
      formData.id = zoneId;
      const { payload } = await this.props.updateZoneRequest(formData);
      data = payload.data;
    } else {
      const { payload } = await this.props.createZoneRequest(formData);
      data = payload.data;
    }
    if (data.success) {
      toast.success(`Successfully ${zoneId ? 'updated' : 'created'}.`);
      this.props.history.push('/admin/zones');
    }
    if (data?.error) {
      toast.error('Something went wrong');
    }
    this.setState({ loading: false });
  }

  handleChange = (key, value) => {
    const { formData } = this.state;
    let { emptyFields } = this.state;
    formData[key] = value;
    if (emptyFields.includes(key)) {
      emptyFields = emptyFields.filter((f) => f !== key);
    }
    this.setState({ formData, emptyFields });
  }

  cancel = () => {
    this.props.history.push('/admin/zones');
  }

  handleGetPolygon = (polygon) => {
    const { formData, changed } = this.state;

    if (polygon !== formData.coords && this.form) {
      if (!changed) {
        this.setState({ changed: true });
      }
      this.form.forceChange();
    }
    this.handleChange('coords', polygon);
    // this.setState({ validCoords: Polygon.idValidPolygon(polygon) });
  }

  handleGetAddressData = (data) => {
    const { formData } = this.state;
    if (!data) {
      return;
    }
    data.forEach((d) => {
      formData[d.key] = d.value;
    });
    this.setState({ formData });
  }

  handleCoordinatesChange = (val) => {
    this.handleChange('coords', val);
  }

  handleFormChange = () => {
    const { changed } = this.state;
    if (!changed) {
      this.setState({ changed: true });
    }
  }

  render() {
    const {
      formData, validCoords, loading, changed, emptyFields,
    } = this.state;
    const { match: { params: { zoneId } }, zoneStatus } = this.props;
    this.initZoneData(zoneId);
    const blur = zoneStatus !== 'ok' && zoneId ? 'blur' : '';
    return (
      <Wrapper>
        <HasPermission edit={['zone_add', 'zone_edit']} redirect>
          <HeadersMeta page={` ${zoneId ? 'Edit' : 'Add'} Zone`} />
          <Form
            onSubmit={this.handleAddZone}
            className="addBlockForm"
            ref={(ref) => {
              this.form = ref;
            }}
            id={zoneId}
            onChange={this.handleFormChange}
          >
            <div className="addBlock">
              <div className="col">
                <Input
                  label="Code *"
                  value={formData.code || ''}
                  onChange={(ev) => this.handleChange('code', ev.target.value)}
                  className={blur}
                  invalid={emptyFields.includes('code')}
                />
                <Input
                  label="City *"
                  value={formData.city || ''}
                  onChange={(ev) => this.handleChange('city', ev.target.value)}
                  className={blur}
                  invalid={emptyFields.includes('city')}
                />
                <Input
                  label="Country *"
                  value={formData.country || ''}
                  onChange={(ev) => this.handleChange('country', ev.target.value)}
                  className={blur}
                  invalid={emptyFields.includes('country')}
                />
              </div>
              <div className="col">
                <Input
                  label="Name *"
                  value={formData.name || ''}
                  onChange={(ev) => this.handleChange('name', ev.target.value)}
                  className={blur}
                  invalid={emptyFields.includes('name')}
                />
                <Input
                  label="State *"
                  value={formData.state || ''}
                  onChange={(ev) => this.handleChange('state', ev.target.value)}
                  className={blur}
                  invalid={emptyFields.includes('state')}
                />
                <Select
                  options={this.status}
                  label="Active *"
                  value={this.status.find((s) => s.value === formData.is_active)}
                  onChange={(val) => this.handleChange('is_active', val.value)}
                  className={blur}
                />
              </div>
            </div>
            <Input
              textarea
              value={formData.coords || ''}
              rows={8}
              label="Coordinates (WKT format)"
              fieldType="Textarea"
              onChange={(ev) => this.handleCoordinatesChange(ev.target.value)}
              className={blur}
              invalid={emptyFields.includes('coords')}
            />
            <GoogleMapWrapper
              onGetPolygon={this.handleGetPolygon}
              onGetAddressData={this.handleGetAddressData}
              createPolygon={validCoords}
              coords={formData.coords}
              className={blur}
              drawingControl
              mode={formData.mode}
              createView={!zoneId}
            />
            <div className="actions">
              <Button title="Cancel" onClick={this.cancel} type="button" />
              <Button
                type="submit"
                title={zoneId ? 'Save' : 'Create'}
                disabled={(zoneStatus !== 'ok' && zoneId)
                  || (zoneId && !changed)}
                loading={loading}
              />
            </div>
          </Form>
        </HasPermission>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  zoneStatus: state.zones.zoneStatus,
});

const mapDispatchToProps = {
  createZoneRequest,
  getZoneByIdRequest,
  updateZoneRequest,
  setCurrentItemName,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ZoneForm);

export default Container;
