import React, { Component } from 'react';
import DataTable from '../../components/DataTable';
import HeadersMeta from '../../components/HeadersMeta';
import Wrapper from '../../components/Wrapper';
import Button from '../../components/form/Button';

class Coupons extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      { key: 'name', label: 'Product' },
      { key: 'price', label: 'Price' },
      { key: 'baseline_qty', label: 'Qty' },
      { key: 'current_qty', label: 'Last Qty' },
      { key: 'union_qty', label: 'Union Qty' },
    ];
  }

  handleGenerateCoupon = () => {

  }

  render() {
    return (
      <Wrapper>
        <HeadersMeta page="Coupons" />

        <div className="zones">
          <div className="top">
            <div className="loading">
              {/* {currentLoading ? <CircularProgress size="small" /> : null} */}
            </div>
            <Button title="Generate coupon" onClick={this.handleGenerateCoupon} />
          </div>
          <div>
            <DataTable
              columns={this.columns}
              data={[]}
              actions={['edit']}
              onEdit={this.toggleEditModal}
              customField="Gen. Code"
              stateType="list"
              onCustomFieldClick={this.handleGenCode}
              page="applicants"
            />
          </div>
          {/* <Pagination currentPage={query.page} totalPages={totalPages} /> */}
        </div>
        {/* <EditApplicants */}
        {/*  isOpen={editModal} */}
        {/*  onCloseModal={() => this.toggleEditModal(null)} */}
        {/*  onUpdate={this.handleUpdate} */}
        {/*  applicantId={applicantId} */}
        {/* /> */}
      </Wrapper>
    );
  }
}

export default Coupons;
