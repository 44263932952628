import {
  SET_GOOGLE, SET_CURRENT_ITEM_NAME, SET_LEFT_MENU_STATE, SET_USER_PERMISSIONS,
  SET_USER_PERMISSIONS_STATUS,
} from '../actions/app';
import Account from '../../helpers/Account';

const initialState = {
  google: null,
  currentItemName: '',
  leftMenuState: true,
  userPermissions: Account.getPermissions(),
  userPermissionsStatus: 'ok',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_GOOGLE: {
      return {
        ...state,
        google: action.payload.google,
      };
    }
    case SET_CURRENT_ITEM_NAME: {
      return {
        ...state,
        currentItemName: action.payload.currentItemName,
      };
    }
    case SET_LEFT_MENU_STATE: {
      return {
        ...state,
        leftMenuState: action.payload.state,
      };
    }
    case SET_USER_PERMISSIONS: {
      const { permissions } = action.payload;
      Account.setPermissions(permissions);
      return {
        ...state,
        userPermissions: permissions,
      };
    }
    case SET_USER_PERMISSIONS_STATUS: {
      const { status } = action.payload;
      return {
        ...state,
        userPermissionsStatus: status,
      };
    }
    default: {
      return state;
    }
  }
}
