import Api from '../../Api';
import { define } from '../../helpers/redux-request';

export const GET_CREDITS = define('GET_CREDITS');

export function getCreditsRequest(sort, search, page, number) {
  return GET_CREDITS.request(() => Api.getCredits(sort, search, page, number)).takeLatest();
}

export const GET_CREDITS_ALL_TAGS = define('GET_CREDITS_ALL_TAGS');

export function getCreditsAllTagsRequest() {
  return GET_CREDITS_ALL_TAGS.request(() => Api.getCreditsAllTags());
}

export const GET_CONTACTS_BY_TAG = define('GET_CONTACTS_BY_TAG');

export function getContactsByTagIdRequest(tagId) {
  return GET_CONTACTS_BY_TAG.request(() => Api.getContactsByTagId(tagId));
}

export const CREATE_CREDIT = define('CREATE_CREDIT');

export function createCreditRequest(data) {
  return CREATE_CREDIT.request(() => Api.createCredit(data));
}

export const DELETE_CREDIT = define('DELETE_CREDIT');

export function deleteCreditRequest(obj) {
  return DELETE_CREDIT.request(() => Api.deleteCredit(obj));
}
