import { GET_RCS, GET_RC_BY_ID } from '../actions/rcs';

const initialState = {
  rcs: [],
  rcsStatus: '',
  rc: {},
  rcStatus: '',
  totalPages: 1,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_RCS.REQUEST: {
      return {
        ...state,
        rcsStatus: 'request',
      };
    }
    case GET_RCS.SUCCESS: {
      const { data: { data: { Records, RecordsCount } } } = action.payload;
      return {
        ...state,
        rcsStatus: 'ok',
        rcs: Records,
        totalPages: Math.ceil(RecordsCount / 10),
      };
    }
    case GET_RCS.FAIL: {
      return {
        ...state,
        rcsStatus: 'fail',
      };
    }
    case GET_RC_BY_ID.REQUEST: {
      return {
        ...state,
        rcStatus: 'request',
      };
    }
    case GET_RC_BY_ID.SUCCESS: {
      return {
        ...state,
        rcStatus: 'ok',
      };
    }
    case GET_RC_BY_ID.FAIL: {
      return {
        ...state,
        rcStatus: 'fail',
      };
    }
    default: {
      return state;
    }
  }
}
