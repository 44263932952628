import { define } from '../../helpers/redux-request';
import Api from '../../Api';

export const GET_ORDERS = define('GET_ORDERS');

export function getOrdersRequest(sort, search, page, number) {
  return GET_ORDERS.request(() => Api.getOrders(sort, search, page, number)).takeLatest();
}

export const GET_ORDER_OF_CONSUMER = define('GET_ORDER_OF_CONSUMER');

export function getOrdersOfConsumerRequest(id, sort, search, page, number) {
  return GET_ORDER_OF_CONSUMER.request(() => Api.getOrderOfConsumer(id, sort, search, page, number)).takeLatest();
}

export const CANCEL_ORDER = define('CANCEL_ORDER');

export function cancelOrderRequest(id, orderId) {
  return CANCEL_ORDER.request(() => Api.cancelOrder(id, orderId));
}

export const GET_ORDER_RECEIPT = define('GET_ORDER_RECEIPT');

export function getOrderReceiptRequest(orderId) {
  return GET_ORDER_RECEIPT.request(() => Api.getOrderReceipt(orderId));
}
