import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const { REACT_APP_MODE } = process.env;
const dir = REACT_APP_MODE === 'dev' ? 'favicon-dev' : 'favicon';

class HeadersMeta extends Component {
    static propTypes = {
      page: PropTypes.string,
    };

    static defaultProps = {
      page: '',
    }

    render() {
      const { page } = this.props;
      return (
        <Helmet>
          <link rel="favicon" sizes="42x42" href={`/${dir}/favicon.ico`} />
          <link rel="android-chrome-192x192" sizes="192x192" href={`/${dir}/android-chrome-192x192.png`} />
          <link rel="android-chrome-256x256" sizes="256x256" href={`/${dir}/android-chrome-256x256.png`} />
          <link rel="apple-touch-icon" sizes="180x180" href={`/${dir}/apple-touch-icon.png`} />
          <link rel="icon" type="image/png" sizes="32x32" href={`/${dir}/favicon-32x32.png`} />
          <link rel="icon" type="image/png" sizes="16x16" href={`/${dir}/favicon-16x16.png`} />
          <link rel="mstile" type="image/png" sizes="150x150" href={`/${dir}/mstile-150x150.png`} />
          <link rel="safari" type="image/png" sizes="347x347" href={`/${dir}/safari-pinned-tab.svg`} />
          <link rel="manifest" href={`/${dir}/site.webmanifest`} />

          <title>{`Robomart - ${page}`}</title>
        </Helmet>
      );
    }
}

export default HeadersMeta;
