import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

class Outside extends Component {
  static propTypes = {
    children: PropTypes.any.isRequired,
    component: PropTypes.any,
    id: PropTypes.string,
    exclude: PropTypes.string,
    className: PropTypes.string,
  }

  static defaultProps = {
    component: 'span',
    id: 'outside',
    exclude: undefined,
    className: '',
  }

  componentDidMount() {
    const { children, ...events } = this.props;
    _.forEach(events, (cb, ev) => {
      const event = ev.replace(/^on/, '').toLowerCase();
      window.addEventListener(event, this.eventHandle, true);
    });
  }

  componentWillUnmount() {
    const { children, ...events } = this.props;
    _.forEach(events, (cb, ev) => {
      const event = ev.replace(/^on/, '').toLowerCase();
      window.removeEventListener(event, this.eventHandle, true);
    });
  }

  eventHandle = (ev) => {
    const { id, exclude } = this.props;
    if (!ev.target.closest(`[data-id="${id}"]`)) {
      if (!exclude || !ev.target.closest(exclude)) {
        const event = _.find(this.props, (fn, key) => key.toLowerCase() === `on${ev.type}`);
        // eslint-disable-next-line no-unused-expressions
        event && event(ev);
      }
    }
  }

  render() {
    const {
      component: C, children, id, className,
    } = this.props;
    return (
      <C data-id={id} className={className}>
        {children}
      </C>
    );
  }
}

export default Outside;
