import React, { Component } from 'react';
import { CircularProgress } from 'rmwc';
import memoizeOne from 'memoize-one';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Wrapper from '../../components/Wrapper';
import HeadersMeta from '../../components/HeadersMeta';
import Button from '../../components/form/Button';
import DataTable from '../../components/DataTable';
import { getGroupsRequest } from '../../store/actions/groups';
import Utils from '../../helpers/Utils';
import Pagination from '../../components/Pagination';
import HasPermission from '../../components/HasPermission';

class Groups extends Component {
  getData = memoizeOne(async (query) => {
    this.setState({ currentLoading: true });
    const {
      name,
      predicate, reverse,
    } = query;
    const predicateObject = {
      name,
    };
    const search = { predicateObject };
    const predicateVal = predicate === 'mode' ? 'is_active' : predicate;
    const sort = { predicate: predicateVal || 'name', reverse: reverse || false };
    await this.props.getGroupsRequest(sort, search, (query.page - 1) || 0);
    this.setState({ currentLoading: false });
  }, _.isEqual);

  static propTypes = {
    getGroupsRequest: PropTypes.func.isRequired,
    groups: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    totalPages: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentLoading: false,
    };
    this.columns = [
      { column: 'name', type: 'searchable' },
      { column: 'mode', type: '' },
    ];
  }

  handleAdd = () => {
    this.props.history.push('/admin/groups/add');
  }

  handleEdit = (id) => `/admin/groups/edit/${id}`

  render() {
    const { currentLoading } = this.state;
    const { groups, totalPages } = this.props;
    const query = Utils.queryParse();
    this.getData(query);
    const data = Utils.renameKeys({ is_active: 'mode' }, groups);
    const actions = HasPermission.check('group_edit') ? ['edit'] : undefined;

    return (
      <Wrapper>
        <HasPermission edit="groups" redirect>
          <HeadersMeta page="Groups" />

          <div className="zones">
            <div className="top">
              <div className="loading">
                {currentLoading ? <CircularProgress size="small" /> : null}
              </div>
              <HasPermission edit="group_add">
                <Button title="Add Group" onClick={this.handleAdd} />
              </HasPermission>
            </div>
            <div>
              <DataTable
                columns={this.columns}
                data={data}
                actions={actions}
                onEdit={this.handleEdit}
              />
            </div>
            <Pagination currentPage={query.page} totalPages={totalPages} />
          </div>
        </HasPermission>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  groups: state.groups.groups,
  totalPages: state.groups.totalPages,
});

const mapDispatchToProps = {
  getGroupsRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Groups);

export default Container;
