import { define } from '../../helpers/redux-request';
import Api from '../../Api';

export const GET_SETTINGS = define('GET_SETTINGS');

export function getSettingsRequest() {
  return GET_SETTINGS.request(() => Api.getSettings());
}

export const UPDATE_SETTINGS = define('UPDATE_SETTINGS');

export function updateSettingsRequest(obj) {
  return UPDATE_SETTINGS.request(() => Api.updateSettings(obj));
}
