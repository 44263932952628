import React, { Component } from 'react';
import { Icon } from 'rmwc';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import memoizeOne from 'memoize-one';
import { toast } from 'react-toastify';
import moment from 'moment';
import Input from '../form/Input';
import List from './List';
import { cancelOrderRequest } from '../../store/actions/orders';
import Confirmation from '../modals/Confirmation';
import Utils from '../../helpers/Utils';
import Select from '../form/Select';

class LeftBlock extends Component {
  setAnimationClass = memoizeOne((vId) => {
    if (vId) {
      document.body.classList.remove('blinkVehicle');
      setTimeout(() => {
        document.body.classList.add('blinkVehicle');
      }, 10);
      clearTimeout(this.animationTimeout);
      this.animationTimeout = setTimeout(() => {
        document.body.classList.remove('blinkVehicle');
        this.setState({ vId: null });
      }, 2100);
    }
  })

  static propTypes = {
    activeCustomers: PropTypes.array,
    cancelOrderRequest: PropTypes.func.isRequired,
    mapRef: PropTypes.object,
    history: PropTypes.object.isRequired,
  }

  static defaultProps = {
    activeCustomers: [],
    mapRef: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      search: '',
      confirmationModal: false,
      loading: false,
      orderId: null,
      id: null,
      customerName: '',
      dropdown: null,
      vId: '',
      filtered: true,
    };
    this.options = [{ label: 'All', value: false }, { label: 'Recent', value: true }];
  }

  componentDidMount() {
    window.addEventListener('click', this.closeDropdown, true);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.closeDropdown, true);
  }

  getColor = (status) => ({
    2: '#e3e600',
    3: '#4A97E0',
    4: '#1fb91c',
    5: '#1fb91c',
    6: '#1fb91c',
    7: '#1fb91c',
    8: '#1fb91c',
    9: '#bdbdbd',
  })[status] || '#333'

  handleSearch = (ev) => {
    this.setState({ search: ev.target.value.toLowerCase() });
  }

  toggleConfirmationModal = (id, orderId, customerName) => {
    const { confirmationModal } = this.state;
    this.setState({
      confirmationModal: !confirmationModal, id, orderId, customerName,
    });
  }

  handleDelete = async () => {
    this.setState({ loading: true });
    const { id, orderId } = this.state;
    const { payload: { data } } = await this.props.cancelOrderRequest(id, orderId);
    if (!data.success) {
      toast.error('Something went wrong.');
    }
    this.setState({ loading: false });
    this.toggleConfirmationModal('', '', '');
  }

  handleGetCustomerData = (customer) => {
    const { lat, lng } = customer?.location;
    this.props.mapRef.handleBoundsOnMarker(lat, lng);
    const query = Utils.queryParse(window.location.search, 'comma');
    query.customer_id = customer.id;
    query.v_id = customer.vehicle_id;
    this.props.mapRef.openCustomerInfoWindow(customer.id);
    this.setState({ vId: customer.vehicle_id });
    this.props.history.replace(`?${Utils.queryStringify(query)}`);
  }

  closeDropdown = (ev) => {
    const { dropdown } = this.state;
    if (dropdown && !(ev.target.closest('.dropdown') || ev.target.closest('.moreHoriz'))) {
      this.setState({ dropdown: null });
    }
  }

  toggleDropdown = (id) => {
    const { dropdown } = this.state;
    if (dropdown === id) {
      this.setState({ dropdown: null });
    } else {
      this.setState({ dropdown: id });
    }
  }

  handleFilter = (v) => {
    this.setState({ filtered: v.value });
    const query = Utils.queryParse(window.location.search, 'comma');
    query.f = v.value;
    this.props.history.replace(`?${Utils.queryStringify(query)}`);
  }

  render() {
    const { activeCustomers } = this.props;
    const {
      search, confirmationModal, loading, customerName, dropdown, vId, filtered,
    } = this.state;
    const query = Utils.queryParse(window.location.search, 'comma');
    let filteredArr = activeCustomers;
    if (filtered && !_.isEmpty(activeCustomers)) {
      filteredArr = filteredArr.filter((f) => !(moment().diff(moment(f.date * 1000), 'hour') > 24));
    }
    return (
      <div className="leftBlock">
        <div className="orders">
          <div className="search">
            <Input placeholder="Search" onChange={this.handleSearch} value={search} />
            <Select
              options={this.options}
              value={this.options.find((o) => o.value === filtered)}
              onChange={(v) => this.handleFilter(v)}
              isSearchable={false}
            />
          </div>
          <ul className="customers">
            {_.orderBy(filteredArr, (c) => c.date, 'desc').filter((c) => ![11, 1].includes(+c.status))
              .filter((c) => c.name?.toLowerCase().includes(search))
              .map((a) => (
                <li
                  key={a.id}
                  className={query.customer_id === a.id ? 'active' : ''}
                >
                  <div onClick={() => this.handleGetCustomerData(a)}>
                    {['5', '6', '8'].includes(a.status)
                      ? (
                        <span
                          className={`dot ${['5', '8'].includes(a.status) ? 'flash' : ''}`}
                          style={{ color: this.getColor(a.status) }}
                        >
                          .
                        </span>
                      ) : null}
                    <span style={{ color: this.getColor(a.status) }}>
                      {`${a.name} ${a.status !== '9' && a?.route?.duration?.text ? `(${a?.route?.duration?.text})` : ''}`}
                    </span>
                  </div>

                  <Icon icon="more_horiz" className="moreHoriz" onClick={() => this.toggleDropdown(a.id)} />
                  {dropdown === a.id ? (
                    <div className="dropdown">
                      <div onClick={() => this.toggleConfirmationModal(a.id, a.order_id, a.name)}>
                        <Icon icon="delete" />
                        <span> Delete</span>
                      </div>
                    </div>
                  ) : null}
                </li>
              ))}
          </ul>
        </div>
        <List title="Queue - " data={activeCustomers.filter((c) => +c.status === 11)} onGetData={this.handleGetCustomerData} />
        <Confirmation
          isOpen={confirmationModal}
          title={`Are you sure, you want to delete ${customerName}'s order?`}
          onCloseModal={() => this.toggleConfirmationModal(null, '')}
          onConfirm={this.handleDelete}
          loading={loading}
        />
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  cancelOrderRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LeftBlock);

export default withRouter(Container);
