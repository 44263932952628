import React, { Component } from 'react';
import Wrapper from '../components/Wrapper';
import Input from '../components/form/Input';
import Account from '../helpers/Account';

class Profile extends Component {
  render() {
    const account = Account.get();
    return (
      <Wrapper>
        <div className="profile">
          <div className="profileImg">
            {account.photo_url ? <img src={account.photo_url} alt="" />
              : <div className="firstLetter">{account.display_name.charAt(0)}</div>}
          </div>
          <div className="inputs">
            <Input label="Full Name" value={account.display_name} readOnly />
            <Input label="Email Address" value={account.email} readOnly />
          </div>
          <div className="inputs">
            <Input label="Title" value={account.title} readOnly />
            <Input label="Phone Number" value={account.phone} readOnly />
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default Profile;
