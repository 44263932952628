import React, { Component } from 'react';
import PropTypes from 'prop-types';
import List from './List';
import RobomartsList from './RobomartsList';

class RightBlock extends Component {
  static propTypes = {
    vehicles: PropTypes.array.isRequired,
  }

  render() {
    const { vehicles, mapRef, rcs } = this.props;
    return (
      <div className="rightBlock">
        <RobomartsList vehicles={vehicles.filter((v) => v.vehicle_state === 1)} mapRef={mapRef} rcs={rcs} />
        <List title="Logs - " />
      </div>
    );
  }
}

export default RightBlock;
