import { GET_TYPES } from '../actions/types';

const initialState = {
  types: [],
  typesStatus: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_TYPES.REQUEST: {
      return {
        ...state,
        typesStatus: 'request',
      };
    }
    case GET_TYPES.SUCCESS: {
      const { data: { data: { Records } } } = action.payload;
      return {
        ...state,
        typesStatus: 'ok',
        types: Records,
      };
    }
    case GET_TYPES.FAIL: {
      return {
        ...state,
        typesStatus: 'fail',
      };
    }
    default: {
      return state;
    }
  }
}
