import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import memoizeOne from 'memoize-one';
import _ from 'lodash';
import Wrapper from '../../components/Wrapper';
import Input from '../../components/form/Input';
import Select from '../../components/form/Select';
import Button from '../../components/form/Button';
import {
  createProductRequest,
  getProductByIdRequest,
  getProductCategoriesRequest,
  updateProductRequest,
} from '../../store/actions/product';
import { getMerchantsRequest } from '../../store/actions/merchants';
import HeadersMeta from '../../components/HeadersMeta';
import Validator from '../../helpers/Validator';
import Form from '../../components/form/Form';
import { setCurrentItemName } from '../../store/actions/app';
import { ReactComponent as CloseSvg } from '../../assets/images/close.svg';
import Api from '../../Api';
import Loader from '../../components/Loader';
import HasPermission from '../../components/HasPermission';
import { getActiveCategoriesRequest } from '../../store/actions/categories';

class ProductForm extends Component {
  initProductData = memoizeOne(async (id) => {
    if (!id) {
      return;
    }
    this.props.setCurrentItemName('');
    const { payload: { data: { data } = {} } } = await this.props.getProductByIdRequest(id);
    if (data) {
      if (data.min_qty === 0) {
        data.min_qty = '0';
      }
      this.props.setCurrentItemName(data.name);
      this.setState({ formData: data });
    }
  })

  static propTypes = {
    getProductCategoriesRequest: PropTypes.func.isRequired,
    getMerchantsRequest: PropTypes.func.isRequired,
    createProductRequest: PropTypes.func.isRequired,
    getProductByIdRequest: PropTypes.func.isRequired,
    getActiveCategoriesRequest: PropTypes.func.isRequired,
    updateProductRequest: PropTypes.func.isRequired,
    productCategories: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    categories: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    productStatus: PropTypes.string.isRequired,
    setCurrentItemName: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        code: '',
        category_id: null,
        expiry: '',
        price: '',
        img: null,
        instruction_img: null,
        tax: '0.00',
      },
      loading: false,
      changed: false,
      focused: null,
      errors: [],
      emptyFields: [],
      instructionLoading: false,
    };
  }

  componentDidMount() {
    this.props.getProductCategoriesRequest({}, {}, 0, 100);
    this.props.getMerchantsRequest({}, {}, 0, 100);
    this.props.getActiveCategoriesRequest({}, {}, 0, 100);
  }

  handleChange = async (key, value, type) => {
    const { formData, errors } = this.state;
    let { emptyFields } = this.state;
    formData[key] = value;
    if (emptyFields.includes(key)) {
      emptyFields = emptyFields.filter((f) => f !== key);
    }
    if (errors.includes(key)) {
      this.handleValidate(key, type);
    }
    if (key === 'instruction_img') {
      this.setState({ instructionLoading: true });
      const payload = await Api.fileUpload(value) || {};
      formData[key] = payload.data.data[0];
      formData.insImgName = value.name;
      this.setState({ instructionLoading: false });
    }
    if (key === 'img') {
      value.uri = URL.createObjectURL(value);
      const reader = new FileReader();
      reader.readAsDataURL(value);
      reader.onload = () => {
        value.base64 = reader.result;
        formData[key] = value;
        this.setState({ formData });
      };
    }
    this.setState({ formData, emptyFields });
  }

  handleRedirect = (url) => {
    this.props.history.push(url);
  }

  handleAddProduct = async (ev) => {
    ev.preventDefault();
    const { formData } = this.state;
    const { match: { params: { productId } } } = this.props;
    const emptyFields = Validator.getEmptyFields(formData, ['code', 'name', 'category_id', 'price', 'min_qty', 'max_qty', 'img']);
    this.setState({ loading: true });
    if (+formData.max_qty < +formData.min_qty) {
      emptyFields.push('max_qty');
    }
    if (!_.isEmpty(emptyFields)) {
      this.setState({ emptyFields, loading: false });
      return;
    }
    let data;
    const img = formData?.img?.base64 ? formData?.img?.base64 : formData?.img;
    formData.tax = +formData.tax;
    if (productId) {
      formData.product_id = productId;
      const { payload } = await this.props.updateProductRequest({ ...formData, img });
      data = payload.data;
    } else {
      const { payload } = await this.props.createProductRequest({ ...formData, img });
      data = payload.data;
    }
    if (data.success) {
      toast.success(`Successfully ${productId ? 'updated' : 'created'}.`);
      this.props.history.push('/admin/products');
    }
    if (data?.error.includes('duplicate key')) {
      toast.error('Product code must be unique.');
    }
    this.setState({ loading: false });
  }

  handleFocus = (focused) => {
    this.setState({ focused });
  }

  handleBlur = () => {
    this.setState({ focused: null });
  }

  handleValidate = (key, type) => {
    const { formData } = this.state;
    let { errors } = this.state;
    if (!_.isEmpty(formData[key]) && !Validator.validate(type, formData[key])) {
      errors = [...errors, key];
      this.setState({ errors });
    } else {
      this.setState({ errors: errors.filter((e) => e !== key) });
    }
  }

  handleFormChange = () => {
    const { changed } = this.state;
    if (!changed) {
      this.setState({ changed: true });
    }
  }

  handleRemoveImage = () => {
    const { formData } = this.state;
    formData.instruction_img = '';
    this.setState({ formData });
    this.form.forceChange();
    this.handleFormChange();
  }

  render() {
    const {
      formData, loading, focused, emptyFields, changed, instructionLoading,
    } = this.state;
    const {
      productCategories, match: { params: { productId } }, productStatus, categories,
    } = this.props;
    this.initProductData(productId);
    const blur = productStatus !== 'ok' && productId ? 'blur' : '';
    return (
      <Wrapper>
        <HasPermission edit={['product_add', 'product_edit']} redirect>
          <HeadersMeta page={` ${productId ? 'Edit' : 'Add'} Product`} />
          <Form
            onSubmit={this.handleAddProduct}
            className="addBlockForm"
            ref={(ref) => this.form = ref}
            id={productId}
            onChange={this.handleFormChange}
          >
            <div className="addBlock">
              <div className="col">
                <Input
                  label="Product Code *"
                  value={formData.code || ''}
                  onChange={(ev) => this.handleChange('code', ev.target.value)}
                  className={blur}
                  invalid={emptyFields.includes('code')}
                />
                <Select
                  options={categories}
                  label="Category *"
                  getOptionLabel={(o) => o.name}
                  getOptionValue={(o) => o.product_category_id}
                  value={productCategories.find((c) => c.product_category_id === formData.category_id)}
                  onChange={(val) => this.handleChange('category_id', val.product_category_id)}
                  className={blur}
                  invalid={emptyFields.includes('category_id')}
                />
                <Input
                  label="Price $ *"
                  value={formData.price || ''}
                  onChange={(ev) => this.handleChange('price', ev.target.value, 'float')}
                  // onBlur={() => this.handleValidate('price', 'float')}
                  // invalid={errors.includes('float') ? 'Not valid price format.' : undefined}
                  className={blur}
                  float
                  type="numeric"
                  invalid={emptyFields.includes('price')}
                  min="0"
                />
                <Input
                  label="Expiry (In Days)"
                  value={formData.expiry || ''}
                  onChange={(ev) => this.handleChange('expiry', ev.target.value)}
                  type="numeric"
                  className={blur}
                  min="1"
                />
                <Input
                  label="Shelf"
                  value={formData.shelf || ''}
                  onChange={(ev) => this.handleChange('shelf', ev.target.value)}
                  className={blur}
                />
                <Input
                  textarea
                  label="Description"
                  value={formData.desc || ''}
                  onChange={(ev) => this.handleChange('desc', ev.target.value)}
                  className={blur}
                  fieldType="Textarea"
                />

              </div>
              <div className="col">
                <Input
                  label="Product Name *"
                  value={formData.name || ''}
                  onChange={(ev) => this.handleChange('name', ev.target.value)}
                  className={blur}
                  invalid={emptyFields.includes('name')}
                />
                <Input
                  label="Minimum Quantity *"
                  value={formData.min_qty || ''}
                  onChange={(ev) => this.handleChange('min_qty', ev.target.value)}
                  type="numeric"
                  className={blur}
                  invalid={emptyFields.includes('min_qty')}
                  min="0"
                />
                <Input
                  label="Maximum Quantity *"
                  value={formData.max_qty || ''}
                  onChange={(ev) => this.handleChange('max_qty', ev.target.value)}
                  type="numeric"
                  className={blur}
                  invalid={emptyFields.includes('max_qty')}
                  min="1"
                />
                <Input
                  label="Cubby"
                  value={formData.cubby || ''}
                  onChange={(ev) => this.handleChange('cubby', ev.target.value)}
                  className={blur}
                />
                <Input
                  label="Tax % *"
                  value={formData.tax || '0.00'}
                  onChange={(ev) => this.handleChange('tax', ev.target.value)}
                  className={blur}
                  type="numeric"
                  max="99.99"
                  min="0"
                  invalid={emptyFields.includes('tax')}
                  float
                />
                <div className="fileInput">
                  {focused === 'img' ? <span className="fileName">{formData?.img?.name}</span> : null}
                  <Input
                    label="Product Image *"
                    onChange={(ev) => {
                      this.handleChange('img', ev.target.files[0]);
                      ev.target.value = '';
                    }}
                    type="file"
                    accept="image/png, image/jpeg, image/gif"
                    className={blur}
                    onFocus={() => this.handleFocus('img')}
                    onBlur={this.handleBlur}
                    invalid={emptyFields.includes('img')}
                  />

                </div>
                {formData?.img ? <img src={formData?.img?.uri || formData?.img} alt="" className="productImg" /> : null}
                <div className="fileInput">
                  {focused === 'instruction_img' ? <span className="fileName">{formData?.insImgName}</span> : null}
                  <Input
                    label="Instruction Image"
                    onChange={(ev) => {
                      this.handleChange('instruction_img', ev.target.files[0]);
                      ev.target.value = '';
                    }}
                    type="file"
                    accept="image/png, image/jpeg, image/gif"
                    className={blur}
                    onFocus={() => this.handleFocus('instruction_img')}
                    onBlur={this.handleBlur}
                  />
                  {formData?.instruction_img ? <CloseSvg className="xIcon" onClick={this.handleRemoveImage} /> : null}
                </div>
                {formData?.instruction_img
                  ? <img src={formData?.instruction_img} alt="" className="productImg" /> : null}
                {instructionLoading ? <Loader /> : null}
              </div>
            </div>

            <div className="actions">
              <Button title="Cancel" onClick={() => this.handleRedirect('/admin/products')} type="button" />
              <Button
                type="submit"
                title={productId ? 'Save' : 'Create'}
                loading={loading}
                disabled={productId && !changed}
              />
              {productId ? (
                <Button
                  title="Go to tags"
                  onClick={() => this.handleRedirect(`/admin/products/tags/${productId}?n=${formData.name}`)}
                  type="button"
                />
              ) : null}
            </div>
          </Form>
        </HasPermission>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  productCategories: state.product.productCategories,
  productStatus: state.product.productStatus,
  merchants: state.merchants.merchants,
  categories: state.categories.activeCategories,
});

const mapDispatchToProps = {
  getProductCategoriesRequest,
  getMerchantsRequest,
  createProductRequest,
  getProductByIdRequest,
  updateProductRequest,
  setCurrentItemName,
  getActiveCategoriesRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductForm);

export default Container;
