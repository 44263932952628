import React, { Component } from 'react';
import S from 'react-select';
import PropTypes from 'prop-types';
import _ from 'lodash';
import AsyncSelect from 'react-select/async';

class Select extends Component {
  static propTypes = {
    options: PropTypes.array,
    label: PropTypes.string,
    value: PropTypes.object,
    error: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    isAsync: PropTypes.bool,
    invalid: PropTypes.bool,
  };

  static defaultProps = {
    options: [],
    label: '',
    error: '',
    value: {},
    name: 'select',
    className: '',
    isAsync: false,
    invalid: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      focused: false,
    };
    this.changed = false;
  }

  handleFocus = () => {
    const { className } = this.props;
    if (className === 'filter') {
      return;
    }
    this.setState({ focused: true });
  }

  handleOnBlur = () => {
    const { value, className } = this.props;
    if (className === 'filter') {
      return;
    }
    if (_.isEmpty(value)) {
      this.setState({ focused: false });
    }
  }

  handleChange = (value, params) => {
    if (!this.changed) {
      this.changed = true;
      const input = this.ref.controlRef?.closest('.rmSelect').querySelector('input[type="hidden"]')
        || this.ref?.select?.controlRef?.closest('.rmSelect').querySelector('input[type="hidden"]');
      if (input) {
        input.dispatchEvent(new Event('change'));
      }
    }

    if (this.props.onChange) {
      this.props.onChange(value, params);
    }
  }

  render() {
    const {
      label, options, value, error, className, isAsync, invalid, ...props
    } = this.props;
    const { focused } = this.state;
    return (
      <div className={`rmSelect  ${className} ${error ? 'error' : ''} ${invalid ? 'invalid' : ''}`}>
        {isAsync ? (
          <AsyncSelect
            ref={(ref) => this.ref = ref}
            options={options}
            classNamePrefix="rm"
            onFocus={this.handleFocus}
            onBlur={this.handleOnBlur}
            value={value}
            {...props}
            onChange={this.handleChange}
          />
        ) : (
          <S
            ref={(ref) => this.ref = ref}
            options={options}
            classNamePrefix="rm"
            onFocus={this.handleFocus}
            onBlur={this.handleOnBlur}
            value={value}
            {...props}
            onChange={this.handleChange}
          />
        )}

        <span className={`label ${focused || !_.isEmpty(value) ? 'focused' : ''}`}>{label}</span>
      </div>
    );
  }
}

export default Select;
