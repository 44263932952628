import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Utils from '../../helpers/Utils';

class List extends Component {
  static propTypes = {
    title: PropTypes.string,
    data: PropTypes.array,
    onGetData: PropTypes.func,
  };

  static defaultProps = {
    title: '',
    data: [],
    onGetData: undefined,
  }

  render() {
    const { title, data } = this.props;
    const query = Utils.queryParse();
    return (
      <div className="list">
        <p>
          {title}
          {query.vehicle_name}
        </p>
        {query.vehicle_name ? data.map((d) => (
          query.vehicle_id === d.vehicle_id ? (
            <div className="" key={d.vehicle_id}>
              <p onClick={() => this.props.onGetData(d)} className="item">
                {`${d.name} ${d.status !== '9' && d?.route?.duration?.text ? `(${d?.route?.duration?.text})` : ''}`}
              </p>
            </div>
          ) : null
        )) : null}
      </div>
    );
  }
}

export default withRouter(List);
