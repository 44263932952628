import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Input from '../form/Input';
import Button from '../form/Button';
import { getProductAllTagsRequest, getProductByIdRequest, updateProductTagsRequest } from '../../store/actions/product';
import Utils from '../../helpers/Utils';
import Form from '../form/Form';
import CreatedItems from '../modals/CreatedItems';
import { setCurrentItemName } from '../../store/actions/app';
import HasPermission from '../HasPermission';

class AddTag extends Component {
  static propTypes = {
    updateProductTagsRequest: PropTypes.func.isRequired,
    getProductAllTagsRequest: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    getProductByIdRequest: PropTypes.func.isRequired,
    setCurrentItemName: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      tags: '',
      modal: false,
      tagsData: {},
      loading: false,
    };
  }

  async componentDidMount() {
    const { match: { params: { productId } } } = this.props;
    this.props.setCurrentItemName('');
    const { payload: { data: { data } = {} } } = await this.props.getProductByIdRequest(productId);
    this.props.setCurrentItemName(data.name);
  }

  handleAddTag = async (ev) => {
    ev.preventDefault();
    const { match: { params: { productId } } } = this.props;
    const { tags } = this.state;
    this.setState({ loading: true });
    const requestData = {
      product_id: productId,
      tags,
    };
    const { payload: { data: response, data: { data = {} } } } = await this.props.updateProductTagsRequest(requestData);
    if (response.success) {
      const query = Utils.queryParse();
      this.setState({ tags: '', modal: true, tagsData: data });
      this.props.getProductAllTagsRequest(productId, query.search || '');
    } else {
      toast.error('Something went wrong.');
    }
    this.setState({ loading: false });
  }

  handleTagChange = (ev) => {
    this.setState({ tags: ev.target.value });
  }

  handleRedirect = (url) => {
    const query = Utils.queryParse();
    this.props.history.push(`${url}?${Utils.queryStringify(query)}`);
  }

  handleCloseModal = () => {
    this.setState({ modal: false, tagsData: {} });
  }

  render() {
    const {
      tags, modal, tagsData, loading,
    } = this.state;
    const { match: { params: { productId } } } = this.props;

    return (
      <>
        <HasPermission edit="product_tags_add">
          <Form
            onSubmit={this.handleAddTag}
            className="addBlockForm addTag"
            ref={(ref) => this.form = ref}
          >
            <div className="addBlock">
              <Input
                textarea
                label="Tags"
                fieldType="Textarea"
                onChange={this.handleTagChange}
                value={tags}
              />
            </div>
            <div className="actions">
              <Button title="Cancel" type="button" onClick={() => this.handleRedirect('/admin/products')} />
              <Button title="Add tags" type="submit" loading={loading} disabled={loading} />
              <Button title="Go to edit" type="button" onClick={() => this.handleRedirect(`/admin/products/edit/${productId}`)} />
            </div>
          </Form>
        </HasPermission>
        <HasPermission edit="product_tags">
          <CreatedItems
            isOpen={modal}
            onCloseModal={this.handleCloseModal}
            data={tagsData}
            view="tags"
            addedText="Tags Added"
            notAddedText="Tags Already Exist"
          />
        </HasPermission>
      </>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  updateProductTagsRequest,
  getProductAllTagsRequest,
  setCurrentItemName,
  getProductByIdRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddTag);

export default withRouter(Container);
