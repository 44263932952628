import React, { Component } from 'react';
import {
  Icon,
  DataTable,
  DataTableContent,
  DataTableHead,
  DataTableRow,
  DataTableBody,
  DataTableCell,
} from 'rmwc';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import memoizeOne from 'memoize-one';
import robomartImg from '../../assets/images/robomart-pod.png';
import { getInventoryStockListRequest } from '../../store/actions/robomarts';
import Loader from '../Loader';

class InventoryStockList extends Component {
  getData = memoizeOne((id) => {
    if (!id) {
      return;
    }
    this.props.getInventoryStockListRequest(id);
  })

  static propTypes = {
    isOpen: PropTypes.bool,
    onCloseModal: PropTypes.func,
    robomartName: PropTypes.string,
    getInventoryStockListRequest: PropTypes.func.isRequired,
    inventoryStock: PropTypes.array.isRequired,
    robomartId: PropTypes.string,
    inventoryStockStatus: PropTypes.string.isRequired,
  };

  static defaultProps = {
    isOpen: false,
    onCloseModal: undefined,
    robomartName: '',
    robomartId: null,
  }

  render() {
    const {
      isOpen, robomartName, inventoryStock, robomartId, inventoryStockStatus,
    } = this.props;
    if (!isOpen) {
      return null;
    }
    this.getData(robomartId);
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.props.onCloseModal}
        overlayClassName="modalOverlay"
        className="modalContent inventoryStockList"
        parentSelector={() => document.querySelector('#root > div')}
      >
        <div className="top">
          <p className="title">
            <img src={robomartImg} alt="" className="robomartImg" />
            <span>{robomartName}</span>
          </p>
          <Icon icon="close" className="icon" onClick={this.props.onCloseModal} />
        </div>
        <hr />
        <div className="title">INVENTORY STOCK</div>
        <hr />

        {inventoryStockStatus === 'ok' ? (
          <DataTable stickyRows="1">
            <DataTableContent>
              <DataTableHead>
                <DataTableRow>
                  <DataTableCell>Pic</DataTableCell>
                  <DataTableCell alignStart> Product name </DataTableCell>
                  <DataTableCell alignEnd>Price</DataTableCell>
                  <DataTableCell alignEnd>Packs available</DataTableCell>
                </DataTableRow>
              </DataTableHead>
              <DataTableBody>
                {inventoryStock.map((stock) => (
                  <DataTableRow>
                    <DataTableCell><img src={stock.img} alt="" className="stockImg" /></DataTableCell>
                    <DataTableCell alignStart>{stock.name}</DataTableCell>
                    <DataTableCell alignEnd>{stock.price}</DataTableCell>
                    <DataTableCell alignEnd>{stock.qty}</DataTableCell>
                  </DataTableRow>
                ))}
              </DataTableBody>
            </DataTableContent>
          </DataTable>
        ) : <Loader />}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  inventoryStock: state.robomarts.inventoryStock,
  inventoryStockStatus: state.robomarts.inventoryStockStatus,
});

const mapDispatchToProps = {
  getInventoryStockListRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InventoryStockList);

export default Container;
