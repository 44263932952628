import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';

class Breadcrumb extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    currentItemName: PropTypes.string.isRequired,
  };

  handleFormatAction = (action, menuItem) => {
    const { currentItemName } = this.props;
    const { match: { params: { productId, typeId, robomartId } } } = this.props;
    if (action === 'add') {
      let menu = menuItem;
      if (menuItem === 'rcs') {
        menu = 'RCS';
      }
      if (menuItem === 'categories') {
        menu = 'categorys';
      }
      return `New ${_.upperFirst(_.startCase(menu.replace(/.$/, '')))}`;
    }
    if (action === 'tags') {
      return currentItemName !== 'undefined' && currentItemName && currentItemName !== 'null'
        ? (
          <>
            <Link
              to={`/admin/products/edit/${productId}`}
            >
              {currentItemName}
            </Link>
            <span> / Tags</span>
          </>
        ) : 'Tags';
    }
    if (action === 'menu') {
      return currentItemName !== 'undefined' && currentItemName
        ? (
          <>
            <Link
              to={`/admin/types/edit/${typeId}`}
            >
              {currentItemName}
            </Link>
            <span> / Menu</span>
          </>
        )
        : 'Products';
    }
    if (action === 'inventory') {
      return currentItemName !== 'undefined' && currentItemName
        ? (
          <>
            <Link
              to={`/admin/shops/edit/${robomartId}`}
            >
              {currentItemName}
            </Link>
            <span> / Inventory</span>
          </>
        )
        : 'Inventory';
    }
    if (action === 'receipt') {
      return currentItemName !== 'undefined' && currentItemName
        ? (
          <>
            <span>
              {currentItemName}
            </span>
            <span> / Receipt</span>
          </>
        )
        : 'Receipt';
    }
    if (action === 'edit' || action === 'orders') {
      return currentItemName !== 'undefined' ? (
        <span>
          {currentItemName}
        </span>
      ) : '';
    }

    return _.upperFirst(action);
  }

  getItem = (action, item) => {
    const { currentItemName } = this.props;
    // if (action !== 'edit' && item === 'rcs') {
    //   return <p className="mainMenu">Stations</p>;
    // }
    if (['menu', 'tags', 'edit', 'receipt', 'orders', 'inventory'].includes(action)) {
      return <p className={`mainMenu ${!currentItemName ? 'blur' : ''}`}>{currentItemName || _.upperFirst(_.startCase(item))}</p>;
    }
    return <p className="mainMenu">{_.upperFirst(_.startCase(item))}</p>;
  }

  getBlurStatus = (action) => {
    const { currentItemName } = this.props;
    return ['menu', 'tags', 'edit', 'receipt', 'orders', 'inventory'].includes(action) && !currentItemName;
  }

  render() {
    const { location: { pathname } } = this.props;
    const [, menu, item, action] = pathname.split('/');
    return (
      <div className="breadcrumb">
        {menu === 'profile' ? <div className="subMenus"><span>Profile</span></div> : (
          <div className="subMenus">
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <Link to={`/${menu}/zones`}>{_.upperFirst(menu)}</Link>
            {item ? <span className="slash">/</span> : null}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <Link
              to={`/${menu}/${item}`}
            >
              {_.upperFirst(_.startCase(item))}
            </Link>
            {action ? <span className="slash">/</span> : null}
            <span className={`action ${this.getBlurStatus(action) ? 'blur' : ''}`}>{this.handleFormatAction(action, item)}</span>
          </div>
        )}
        {this.getItem(action, item)}

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentItemName: state.app.currentItemName,
});

const mapDispatchToProps = {};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Breadcrumb);

export default withRouter(Container);
