import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Tooltip from 'rc-tooltip';
import { toast } from 'react-toastify';
import memoizeOne from 'memoize-one';
import Wrapper from '../../components/Wrapper';
import HeadersMeta from '../../components/HeadersMeta';
import Input from '../../components/form/Input';
import Button from '../../components/form/Button';
import Select from '../../components/form/Select';
import { getMerchantsRequest } from '../../store/actions/merchants';
import { getTypesRequest } from '../../store/actions/types';
import { getRCsRequest } from '../../store/actions/rcs';
import { createRobomartRequest, getRobomartByIdRequest, updateRobomartRequest } from '../../store/actions/robomarts';
import ChangePassword from '../../components/modals/ChangePassword';
import Validator from '../../helpers/Validator';
import Form from '../../components/form/Form';
import { setCurrentItemName } from '../../store/actions/app';
import HasPermission from '../../components/HasPermission';
import { getStorefrontsRequest } from '../../store/actions/storefronts';

class RobomartForm extends Component {
  initRobomartData = memoizeOne(async (id) => {
    if (!id) {
      return;
    }
    this.props.setCurrentItemName('');
    const { payload: { data: { data } } } = await this.props.getRobomartByIdRequest(id);
    if (data) {
      this.props.setCurrentItemName(data.name);
      this.setState({ formData: data });
    }
  })

  static propTypes = {
    getMerchantsRequest: PropTypes.func.isRequired,
    getTypesRequest: PropTypes.func.isRequired,
    merchants: PropTypes.array.isRequired,
    getRCsRequest: PropTypes.func.isRequired,
    types: PropTypes.array.isRequired,
    rcs: PropTypes.array.isRequired,
    createRobomartRequest: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    storefronts: PropTypes.array.isRequired,
    robomartStatus: PropTypes.string.isRequired,
    getRobomartByIdRequest: PropTypes.func.isRequired,
    updateRobomartRequest: PropTypes.func.isRequired,
    getStorefrontsRequest: PropTypes.func.isRequired,
    setCurrentItemName: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.status = [
      { value: true, label: 'Active' },
      { value: false, label: 'Inactive' }];
    this.state = {
      formData: {
        name: '',
        registration_no: '',
        is_active: false,
        org_id: '',
        gen: '',
        type_id: '',
        store_id: '',
        storefront_id: '',
      },
      loading: false,
      changed: false,
      changePasswordModal: false,
      emptyFields: [],
    };
  }

  componentDidMount() {
    this.props.getMerchantsRequest({ predicate: 'name', reverse: false }, {});
    this.props.getStorefrontsRequest({ predicate: 'name', reverse: false }, {});
    this.props.getRCsRequest({ predicate: 'name', reverse: false });
    this.props.getTypesRequest();
  }

  toggleChangePasswordModal = () => {
    const { changePasswordModal } = this.state;
    this.setState({ changePasswordModal: !changePasswordModal });
  }

  handleAddRobomart = async (ev) => {
    ev.preventDefault();
    const { formData } = this.state;
    const { match: { params: { robomartId } } } = this.props;
    const emptyFields = Validator.getEmptyFields(formData,
      ['name', 'gen', 'registration_no', 'engine_no']);
    this.setState({ loading: true });
    if (!_.isEmpty(emptyFields)) {
      this.setState({ emptyFields, loading: false });
      return;
    }
    let data;
    if (formData.is_active) {
      formData.mode = '1';
    } else {
      formData.mode = '2';
    }
    if (robomartId) {
      formData.vid = robomartId;

      const { payload } = await this.props.updateRobomartRequest(formData);
      data = payload.data;
    } else {
      const { payload } = await this.props.createRobomartRequest(formData);
      data = payload.data;
    }
    if (data.success) {
      toast.success(`Successfully ${robomartId ? 'updated' : 'created'}.`);
      this.props.history.push('/admin/shops');
    }
    if (data?.error) {
      toast.error('Something went wrong');
    }

    this.setState({ loading: false });
  }

  handleChange = (key, value) => {
    const { formData } = this.state;
    let { emptyFields } = this.state;
    this.handleFormChange();
    formData[key] = value;
    if (emptyFields.includes(key)) {
      emptyFields = emptyFields.filter((f) => f !== key);
    }
    this.setState({ formData, emptyFields });
  }

  handleRedirect = () => {
    const { match: { params: { robomartId } } } = this.props;
    this.props.history.push(`/admin/shops/inventory/${robomartId}`);
  }

  cancel = () => {
    this.props.history.push('/admin/shops');
  }

  handleFormChange = () => {
    const { changed } = this.state;
    if (!changed) {
      this.setState({ changed: true });
    }
  }

  render() {
    const {
      formData, loading, changePasswordModal, changed, emptyFields,
    } = this.state;
    const {
      rcs, match: { params: { robomartId } }, robomartStatus, storefronts,
    } = this.props;
    this.initRobomartData(robomartId);
    const blur = robomartStatus !== 'ok' && robomartId ? 'blur' : '';

    return (
      <Wrapper>
        <HasPermission edit={['robomart_add', 'robomart_edit']} redirect>
          <HeadersMeta page={` ${robomartId ? 'Edit' : 'Add'} Robomart`} />
          <Form
            onSubmit={this.handleAddRobomart}
            className="addBlockForm"
            ref={(ref) => this.form = ref}
            id={robomartId}
            onChange={this.handleFormChange}
          >
            {robomartId ? (
              <div className="addBlock">
                <div className="col">
                  <Input
                    label="Vehicle ID"
                    value={formData.vid || ''}
                    className={blur}
                    readOnly
                  />
                </div>
                <div className="col">
                  {robomartId ? (
                    <Input
                      label="Auth Email"
                      value={formData.auth_email || ''}
                      className={blur}
                      readOnly
                    />
                  ) : null}
                </div>
              </div>
            ) : null}
            <div className="addBlock">
              <div className="col">
                <Input
                  label="Name *"
                  value={formData.name || ''}
                  onChange={(ev) => this.handleChange('name', ev.target.value)}
                  className={blur}
                  invalid={emptyFields.includes('name')}
                />
                <Input
                  label="Registration No *"
                  value={formData.registration_no || ''}
                  onChange={(ev) => this.handleChange('registration_no', ev.target.value)}
                  className={blur}
                  invalid={emptyFields.includes('registration_no')}
                />

                <Select
                  options={this.status}
                  label="Vehicle State *"
                  value={this.status.find((s) => formData.is_active === s.value)}
                  onChange={(val) => this.handleChange('is_active', val.value)}
                  className={blur}
                />
                {/* <Select */}
                {/*  options={merchants} */}
                {/*  label="Merchant *" */}
                {/*  getOptionValue={(o) => o.org_id} */}
                {/*  getOptionLabel={(o) => o.name} */}
                {/*  value={merchants.find((s) => formData.org_id === s.org_id)} */}
                {/*  onChange={(val) => { */}
                {/*    this.handleChange('org_id', val.org_id); */}
                {/*    this.handleGetRC(val.org_id); */}
                {/*  }} */}
                {/*  className={blur} */}
                {/*  invalid={emptyFields.includes('org_id')} */}
                {/* /> */}
                {!robomartId ? (
                  <Input
                    label="Password *"
                    value={formData.password || ''}
                    onChange={(ev) => this.handleChange('password', ev.target.value)}
                    className={blur}
                    invalid={emptyFields.includes('password')}
                  />
                ) : null}

              </div>
              <div className="col">
                <Input
                  label="Generation *"
                  value={formData.gen || ''}
                  onChange={(ev) => this.handleChange('gen', ev.target.value)}
                  className={blur}
                  invalid={emptyFields.includes('gen')}
                />

                <Input
                  label="VIN *"
                  value={formData.engine_no || ''}
                  onChange={(ev) => this.handleChange('engine_no', ev.target.value)}
                  className={blur}
                  invalid={emptyFields.includes('engine_no')}
                />
                {/* <Select */}
                {/*  options={types} */}
                {/*  label="Type *" */}
                {/*  getOptionValue={(o) => o.id} */}
                {/*  getOptionLabel={(o) => o.name} */}
                {/*  value={types.find((s) => formData.type_id === s.id)} */}
                {/*  onChange={(val) => this.handleChange('type_id', val.id)} */}
                {/*  className={blur} */}
                {/*  invalid={emptyFields.includes('type_id')} */}
                {/* /> */}

                <Select
                  options={[{ store_id: '', name: '(empty)' }, ...rcs]}
                  label="Station"
                  getOptionValue={(o) => o.store_id}
                  getOptionLabel={(o) => o.name}
                  value={rcs.find((s) => formData.store_id === s.store_id)}
                  onChange={(val) => this.handleChange('store_id', val.store_id)}
                  className={blur}
                />
                <Select
                  options={storefronts}
                  label="Storefront"
                  getOptionValue={(o) => o.id}
                  getOptionLabel={(o) => o.name}
                  value={storefronts.find((s) => formData.storefront_id === s.id)}
                  onChange={(val) => this.handleChange('storefront_id', val.id)}
                  className={blur}
                />
              </div>
            </div>
            <div className="actions">
              <Button title="Cancel" onClick={this.cancel} type="button" />
              <Button
                type="submit"
                title={robomartId ? 'Save' : 'Create'}
                loading={loading}
                disabled={(robomartStatus !== 'ok' && robomartId)
                  || (robomartId && !changed)}
              />
              {robomartId ? (
                <Button
                  type="button"
                  title="Change password"
                  onClick={this.toggleChangePasswordModal}
                />
              ) : null}
              {robomartId ? (
                <Button
                  type="button"
                  title="Go to inventory"
                  onClick={this.handleRedirect}
                />
              ) : null}
            </div>
          </Form>
          <ChangePassword isOpen={changePasswordModal} onCloseModal={this.toggleChangePasswordModal} />
        </HasPermission>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  merchants: state.merchants.merchants,
  types: state.types.types,
  rcs: state.rcs.rcs,
  robomartStatus: state.robomarts.robomartStatus,
  storefronts: state.storefronts.storefronts,
});

const mapDispatchToProps = {
  getMerchantsRequest,
  getRCsRequest,
  getTypesRequest,
  createRobomartRequest,
  getRobomartByIdRequest,
  updateRobomartRequest,
  setCurrentItemName,
  getStorefrontsRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RobomartForm);

export default Container;
