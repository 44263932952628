import React, { Component } from 'react';
import Utils from '../helpers/Utils';
import GoogleMaps from './GoogleMaps';
import config from '../config';

const mapApiKey = config.MAP_API_KEY;

class GoogleMapWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    if (!window.handleMapInit) {
      this.loadScript();
    } else {
      this.handleMapInit();
    }
  }

  loadScript = () => {
    window.handleMapInit = this.handleMapInit;
    const script = document.createElement('script');
    const configs = {
      key: mapApiKey,
      libraries: 'drawing,places,geometry',
      callback: 'handleMapInit',
      language: 'en',
    };
    script.src = `https://maps.googleapis.com/maps/api/js?${Utils.queryStringify(configs)}`;
    document.body.appendChild(script);
  }

  handleMapInit = () => {
    this.setState({ loaded: true });
  }

  render() {
    const { loaded } = this.state;
    const { innerRef, ...props } = this.props;
    if (!loaded) {
      return null;
    }
    return (
      <GoogleMaps {...props} ref={innerRef} />
    );
  }
}

export default React.forwardRef((props, ref) => (
  <GoogleMapWrapper
    innerRef={ref}
    {...props}
  />
));
