import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from 'rmwc';

class Button extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    title: PropTypes.string,
  };

  static defaultProps = {
    loading: false,
    title: '',
  }

  render() {
    const { title, loading, ...props } = this.props;
    return (
      <div className="buttonNew">
        <button {...props}>
          {loading ? <CircularProgress size="xsmall" /> : title}
        </button>
      </div>
    );
  }
}

export default Button;
