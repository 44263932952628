import { GET_CONSUMERS, GET_SINGLE_CONSUMER, GET_STATE_DYNAMIC_LINK } from '../actions/consumers';
import Utils from '../../helpers/Utils';

const initialState = {
  consumers: [],
  consumersStatus: '',
  totalPages: 1,
  consumer: {},
  consumerStatus: '',
  stateDynamicLink: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_CONSUMERS.REQUEST: {
      return {
        ...state,
        consumersStatus: 'request',
      };
    }
    case GET_CONSUMERS.SUCCESS: {
      const { data: { data: { Records, RecordsCount } } } = action.payload;
      const consumers = Records.map((c) => {
        c.credit = Utils.formatPriceAmounts(c.credit);
        c.balance = Utils.formatPriceAmounts(c.balance);
        return c;
      });
      return {
        ...state,
        consumers,
        consumersStatus: 'ok',
        totalPages: Math.ceil(RecordsCount / 10),
      };
    }
    case GET_CONSUMERS.FAIL: {
      return {
        ...state,
        consumersStatus: 'fail',
      };
    }
    case GET_SINGLE_CONSUMER.REQUEST: {
      return {
        ...state,
        consumerStatus: 'request',
      };
    }
    case GET_SINGLE_CONSUMER.SUCCESS: {
      return {
        ...state,
        consumerStatus: 'ok',
        consumer: action.payload.data.data,
      };
    }
    case GET_SINGLE_CONSUMER.FAIL: {
      return {
        ...state,
        consumerStatus: 'fail',
        consumer: {},

      };
    }
    case GET_STATE_DYNAMIC_LINK.REQUEST: {
      return {
        ...state,
        consumerStatus: 'request',
      };
    }
    case GET_STATE_DYNAMIC_LINK.SUCCESS: {
      return {
        ...state,
        consumerStatus: 'ok',
        stateDynamicLink: action.payload.data.data?.linkEventStats,
      };
    }
    case GET_STATE_DYNAMIC_LINK.FAIL: {
      return {
        ...state,
        consumerStatus: 'fail',
        stateDynamicLink: {},

      };
    }
    default: {
      return state;
    }
  }
}
