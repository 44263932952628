import React, { Component } from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import logo from '../assets/images/logo.svg';
import Button from '../components/formNew/Button';
import Account from '../helpers/Account';
import HeadersMeta from '../components/HeadersMeta';
import { getUserByIdRequest } from '../store/actions/users';
import firebase from '../helpers/firebase';
import Input from '../components/formNew/Input';
import Utils from '../helpers/Utils';
import { getGroupAllRightsRequest, getGroupByIdRequest } from '../store/actions/groups';
import { setUserPermissionsRequest, setUserPermissionsStatusRequest } from '../store/actions/app';

class Login extends Component {
  static propTypes = {
    getUserByIdRequest: PropTypes.func.isRequired,
    getGroupByIdRequest: PropTypes.func.isRequired,
    getGroupAllRightsRequest: PropTypes.func.isRequired,
    setUserPermissionsRequest: PropTypes.func.isRequired,
    groupAllRights: PropTypes.array.isRequired,
    setUserPermissionsStatusRequest: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      loading: false,
    };
  }

  // get firebase token
  handleSubmit = async (ev) => {
    ev.preventDefault();
    const { email, password } = this.state;
    if (_.isEmpty(email)) {
      toast.error('Email field is required!...');
      return;
    }
    if (_.isEmpty(password)) {
      toast.error('Password field is required!');
      return;
    }
    this.setState({ loading: true });
    try {
      const { user: { uid } } = await firebase.auth().signInWithEmailAndPassword(email, password);
      const token = await firebase.auth().currentUser.getIdToken(true);
      Account.setFBToken(token);
      Account.setLoginTime();
      const { payload: { data: { data } } } = await this.props.getUserByIdRequest(uid);

      // set permissions data
      this.props.setUserPermissionsStatusRequest('request');
      const permissions = data.groups.split(',');
      await this.props.getGroupAllRightsRequest();
      const permissionsData = await Promise.all(permissions.map((p) => this.props.getGroupByIdRequest(p)));

      const { groupAllRights } = this.props;
      const rights = _.uniq(permissionsData.map((p) => p.payload.data.data.rights.split(',')).flat(1));
      let rightsData = groupAllRights.filter((g) => rights.includes((g.id).toString()));
      rightsData = rightsData.map((r) => _.snakeCase(r.name));
      this.props.setUserPermissionsRequest(rightsData);
      this.props.setUserPermissionsStatusRequest('ok');
      Account.set(data);
    } catch (err) {
      toast.error('Invalid email or password.');
    }
    this.setState({ loading: false });
  }

  handleChange = (key, val) => {
    this.setState({ [key]: val });
  }

  render() {
    const { email, password, loading } = this.state;
    const token = Account.checkFBToken();
    const query = Utils.queryParse();
    if (token) {
      if (query.redirect) {
        return <Redirect to={query.redirect} />;
      }
      return <Redirect to="/" />;
    }
    return (
      <>
        <HeadersMeta page="Login" />
        <div className="loginContainer">
          <form action="" onSubmit={this.handleSubmit} className="loginForm" autoComplete="off">
            <div className="logo">
              <img src={logo} alt="" />
            </div>
            <div className="formFields">
              <Input
                placeholder="Email address"
                value={email}
                onChange={(ev) => this.handleChange('email', ev.target.value)}
                autoComplete="off"
                name="email"
              />
              <Input
                placeholder="Password"
                type="password"
                value={password}
                onChange={(ev) => this.handleChange('password', ev.target.value)}
                autoComplete="off"
                name="password"
              />
              <Button title="Login" loading={loading} type="submit" />
              <Link to="/forgot-password" className="forgotPassword">Forgot password?</Link>
            </div>
          </form>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  groupAllRights: state.groups.groupAllRights,
});

const mapDispatchToProps = {
  getUserByIdRequest,
  getGroupByIdRequest,
  getGroupAllRightsRequest,
  setUserPermissionsRequest,
  setUserPermissionsStatusRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);

export default Container;
