import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import _ from 'lodash';
import { CircularProgress } from 'rmwc';
import Wrapper from '../../components/Wrapper';
import Button from '../../components/form/Button';
import { getMerchantsRequest } from '../../store/actions/merchants';
import DataTable from '../../components/DataTable';
import Utils from '../../helpers/Utils';
import HeadersMeta from '../../components/HeadersMeta';
import Pagination from '../../components/Pagination';
import HasPermission from '../../components/HasPermission';

class Merchants extends Component {
  getData = memoizeOne(async (query) => {
    this.setState({ currentLoading: true });
    const {
      // eslint-disable-next-line camelcase
      owner_name, name, phone, owner_email, address, admin_name, admin_email, web,
      predicate, reverse,
    } = query;
    const predicateObject = {
      owner_name,
      name,
      owner_email,
      address,
      admin_name,
      admin_email,
      phone,
      www_address: web,
    };
    const search = { predicateObject };
    const predicateVal = predicate === 'web' ? 'www_address' : predicate;
    const sort = { predicate: predicateVal || 'name', reverse: reverse || false };
    await this.props.getMerchantsRequest(sort, search);
    this.setState({ currentLoading: false });
  }, _.isEqual);

  static propTypes = {
    merchants: PropTypes.array.isRequired,
    getMerchantsRequest: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    merchantsData: PropTypes.object.isRequired,
    merchantsStatus: PropTypes.string.isRequired,
    totalPages: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentLoading: false,
    };
    this.columns = [
      { column: 'name', type: 'searchable' },
      { column: 'address', type: 'searchable' },
      { column: 'owner_name', type: 'searchable' },
      { column: 'owner_email', type: 'searchable' },
      { column: 'admin_name', type: 'searchable' },
      { column: 'admin_email', type: 'searchable' },
      { column: 'phone', type: 'searchable' },
      { column: 'web', type: 'searchable' }];
  }

  handleAdd = () => {
    this.props.history.push('/admin/merchants/add');
  }

  handleEdit = (id) => `/admin/merchants/edit/${id}`

  render() {
    const {
      merchants, merchantsStatus, merchantsData, totalPages,
    } = this.props;
    const { currentLoading } = this.state;

    const data = Utils.renameKeys({ www_address: 'web', org_id: 'id' }, merchants);

    const query = Utils.queryParse();
    this.getData(query);

    const loading = merchantsStatus !== 'ok' && _.isEmpty(merchantsData);

    const actions = HasPermission.check('merchant_edit') ? ['edit'] : undefined;

    return (
      <Wrapper>
        <HasPermission edit="merchants" redirect>
          <HeadersMeta page="Merchants" />

          <div className="merchants">
            <div className="top">
              <div className="loading">
                {loading || currentLoading ? <CircularProgress size="small" /> : null}
              </div>
              <HasPermission edit="merchant_add">
                <Button title="Add Merchant" onClick={this.handleAdd} />
              </HasPermission>
            </div>
            <div>
              <DataTable
                columns={this.columns}
                data={data}
                actions={actions}
                onEdit={this.handleEdit}
                onSearch={this.handleSearch}
                onSort={this.handleSort}
                loading={loading}
              />
            </div>
            <Pagination currentPage={query.page} totalPages={totalPages} />

          </div>
        </HasPermission>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  merchants: state.merchants.merchants,
  merchantsData: state.merchants.merchantsData,
  merchantsStatus: state.merchants.merchantsStatus,
  totalPages: state.merchants.totalPages,
});

const mapDispatchToProps = {
  getMerchantsRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Merchants);

export default Container;
