import React, { Component } from 'react';
import {
  BrowserRouter, Route, Switch, Redirect,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Login from './pages/Login';
import Logout from './pages/Logout';
import ForgotPassword from './pages/ForgotPassword';
import Merchants from './pages/merchants/Merchants';
import MerchantForm from './pages/merchants/MerchantForm';
import Zones from './pages/zones/Zones';
import ZoneForm from './pages/zones/ZoneForm';
import RCS from './pages/rcs/RCS';
import RCSForm from './pages/rcs/RCSForm';
import Robomarts from './pages/robomarts/Robomarts';
import RobomartForm from './pages/robomarts/RobomartForm';
import Inventory from './pages/robomarts/Inventory';
import Products from './pages/products/Products';
import ProductsTags from './pages/products/ProductsTags';
import ProductForm from './pages/products/ProductForm';
import Groups from './pages/groups/Groups';
import GroupForm from './pages/groups/GroupForm';
import Users from './pages/users/Users';
import UserForm from './pages/users/UserForm';
import Drivers from './pages/drivers/Drivers';
import Consumers from './pages/consumers/Consumers';
import Orders from './pages/orders/Orders';
import RobomartTypes from './pages/robomartTypes/RobomartTypes';
import RobomartTypeForm from './pages/robomartTypes/RobomartTypeForm';
import Menu from './pages/storefronts/Menu';
import Applicants from './pages/applicants/Applicants';
import InviteCodes from './pages/inviteCodes/InviteCodes';
import Settings from './pages/settings/Settings';
import Profile from './pages/Profile';
import Fleet from './pages/Fleet';
import Utils from './helpers/Utils';
import OrderReceipt from './pages/orders/OrderReceipt';
import Coupons from './pages/coupons/Coupons';
import Storefronts from './pages/storefronts/Storefronts';
import StorefrontForm from './pages/storefronts/StorefrontForm';
import Categories from './pages/categories/Categories';
import CategoryForm from './pages/categories/CategoryForm';
import Credits from './pages/credits/Credits';
import ConsumerLink from './pages/consumers/ConsumerLink';

export default class App extends Component {
  render() {
    return (
      <>
        <BrowserRouter>
          <Switch>
            <Redirect from="/" to={`${Utils.isMobile() ? '/admin/zones' : '/fleet'}`} exact />
            <Redirect from="/admin" to="/admin/zones" exact />

            <Route path="/login" exact component={Login} />
            <Route path="/logout" exact component={Logout} />
            <Route path="/forgot-password" exact component={ForgotPassword} />

            <Route path="/admin/merchants" exact component={Merchants} />
            <Route path="/admin/merchants/add" exact component={MerchantForm} />
            <Route path="/admin/merchants/edit/:merchantId" exact component={MerchantForm} />

            <Route path="/admin/zones" exact component={Zones} />
            <Route path="/admin/zones/add" exact component={ZoneForm} />
            <Route path="/admin/zones/edit/:zoneId" exact component={ZoneForm} />

            <Route path="/admin/stations" exact component={RCS} />
            <Route path="/admin/stations/add" exact component={RCSForm} />
            <Route path="/admin/stations/edit/:rcId" exact component={RCSForm} />

            <Route path="/admin/shops" exact component={Robomarts} />
            <Route path="/admin/shops/add" exact component={RobomartForm} />
            <Route path="/admin/shops/edit/:robomartId" exact component={RobomartForm} />
            <Route path="/admin/shops/inventory/:robomartId" exact component={Inventory} />

            <Route path="/admin/storefronts" exact component={Storefronts} />
            <Route path="/admin/storefronts/add" exact component={StorefrontForm} />
            <Route path="/admin/storefronts/edit/:storefrontId" exact component={StorefrontForm} />
            <Route path="/admin/storefronts/menu/:storefrontId" exact component={Menu} />

            <Route path="/admin/products" exact component={Products} />
            <Route path="/admin/products/tags/:productId" exact component={ProductsTags} />
            <Route path="/admin/products/edit/:productId" exact component={ProductForm} />
            <Route path="/admin/products/add" exact component={ProductForm} />

            <Route path="/admin/groups" exact component={Groups} />
            <Route path="/admin/groups/add" exact component={GroupForm} />
            <Route path="/admin/groups/edit/:groupId" exact component={GroupForm} />

            <Route path="/admin/users" exact component={Users} />
            <Route path="/admin/users/add" exact component={UserForm} />
            <Route path="/admin/users/edit/:userId" exact component={UserForm} />

            <Route path="/admin/drivers" exact component={Drivers} />

            <Route path="/admin/consumers" exact component={Consumers} />
            <Route path="/admin/consumers/invite-link/:consumerId" exact component={ConsumerLink} />
            <Route path="/admin/consumers/orders/:consumerId" exact component={Orders} />

            <Route path="/admin/orders" exact component={Orders} />
            <Route path="/admin/orders/receipt/:orderId" exact component={OrderReceipt} />

            <Route path="/admin/coupons" exact component={Coupons} />
            {/* <Route path="/admin/coupons/create/:couponId" exact component={OrderReceipt} /> */}

            <Route path="/admin/categories" exact component={Categories} />
            <Route path="/admin/categories/add" exact component={CategoryForm} />
            <Route path="/admin/categories/edit/:categoryId" exact component={CategoryForm} />

            <Route path="/admin/types" exact component={RobomartTypes} />
            <Route path="/admin/types/add" exact component={RobomartTypeForm} />
            <Route path="/admin/types/edit/:typeId" exact component={RobomartTypeForm} />

            <Route path="/admin/credits" exact component={Credits} />

            <Route path="/admin/applicants" exact component={Applicants} />

            <Route path="/admin/invite-codes" exact component={InviteCodes} />

            <Route path="/admin/settings" exact component={Settings} />

            <Route path="/profile" exact component={Profile} />

            <Route path="/fleet" exact component={Fleet} />

            {/* <Route path="/platform" exact component={Platform} /> */}

            {/* <Redirect from="/analytics" to="/analytics/intercom" exact /> */}
            {/* <Route path="/analytics/intercom" exact component={Analytics} /> */}

          </Switch>
        </BrowserRouter>
        <ToastContainer />
      </>
    );
  }
}
