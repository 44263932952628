import { GET_CATEGORIES, GET_CATEGORY_BY_ID, GET_ACTIVE_CATEGORIES } from '../actions/categories';

const initialState = {
  categories: [],
  categoriesStatus: '',
  activeCategories: [],
  activeCategoriesStatus: '',
  totalPages: 1,
  categoryStatus: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_CATEGORIES.REQUEST: {
      return {
        ...state,
        categoriesStatus: 'request',
      };
    }
    case GET_CATEGORIES.SUCCESS: {
      const { data: { data: { Records, RecordsCount } } } = action.payload;
      return {
        ...state,
        categories: Records,
        categoriesStatus: 'ok',
        totalPages: Math.ceil(RecordsCount / 10),
      };
    }
    case GET_CATEGORIES.FAIL: {
      return {
        ...state,
        categoriesStatus: 'fail',
      };
    }
    case GET_ACTIVE_CATEGORIES.REQUEST: {
      return {
        ...state,
        activeCategoriesStatus: 'request',
      };
    }
    case GET_ACTIVE_CATEGORIES.SUCCESS: {
      const { data: { data: { Records } } } = action.payload;
      return {
        ...state,
        activeCategories: Records,
        activeCategoriesStatus: 'ok',
      };
    }
    case GET_ACTIVE_CATEGORIES.FAIL: {
      return {
        ...state,
        activeCategoriesStatus: 'fail',
      };
    }
    case GET_CATEGORY_BY_ID.REQUEST: {
      return {
        ...state,
        categoryStatus: 'request',
      };
    }
    case GET_CATEGORY_BY_ID.SUCCESS: {
      return {
        ...state,
        categoryStatus: 'ok',
      };
    }
    case GET_CATEGORY_BY_ID.FAIL: {
      return {
        ...state,
        categoryStatus: 'fail',
      };
    }
    default: {
      return state;
    }
  }
}
