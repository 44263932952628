import { GET_APPLICANTS, GET_APPLICANT_BY_ID } from '../actions/applicants';

const initialState = {
  applicants: [],
  applicantsStatus: '',
  applicant: {},
  applicantStatus: '',
  totalPages: 1,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_APPLICANTS.REQUEST: {
      return {
        ...state,
        applicantsStatus: 'request',
      };
    }
    case GET_APPLICANTS.SUCCESS: {
      const { data: { data: { Records, RecordsCount } } } = action.payload;
      return {
        ...state,
        applicants: Records,
        applicantsStatus: 'ok',
        totalPages: Math.ceil(RecordsCount / 10),
      };
    }
    case GET_APPLICANTS.FAIL: {
      return {
        ...state,
        applicantsStatus: 'fail',
      };
    }
    case GET_APPLICANT_BY_ID.REQUEST: {
      return {
        ...state,
        applicantStatus: 'request',
      };
    }
    case GET_APPLICANT_BY_ID.SUCCESS: {
      const { data: { data } } = action.payload;
      return {
        ...state,
        applicantStatus: 'ok',
        applicant: data,
      };
    }
    case GET_APPLICANT_BY_ID.FAIL: {
      return {
        ...state,
        applicantStatus: 'fail',
      };
    }
    default: {
      return state;
    }
  }
}
