import { define } from '../../helpers/redux-request';
import Api from '../../Api';

export const SET_GOOGLE = 'SET_GOOGLE';

export function setGoogle(google) {
  return {
    type: SET_GOOGLE,
    payload: { google },
  };
}

export const GET_CUSTOM_TOKEN = define('GET_CUSTOM_TOKEN');

export function getCustomTokenRequest() {
  return GET_CUSTOM_TOKEN.request(() => Api.getCustomToken());
}

export const SET_CURRENT_ITEM_NAME = define('SET_CURRENT_ITEM_NAME');

export function setCurrentItemName(currentItemName) {
  return {
    type: SET_CURRENT_ITEM_NAME,
    payload: { currentItemName },
  };
}

export const SET_LEFT_MENU_STATE = define('SET_LEFT_MENU_STATE');

export function setLeftMenuState(state) {
  return {
    type: SET_LEFT_MENU_STATE,
    payload: { state },
  };
}

export const SET_USER_PERMISSIONS = define('SET_USER_PERMISSIONS');

export function setUserPermissionsRequest(permissions) {
  return {
    type: SET_USER_PERMISSIONS,
    payload: { permissions },
  };
}

export const SET_USER_PERMISSIONS_STATUS = define('SET_USER_PERMISSIONS_STATUS');

export function setUserPermissionsStatusRequest(status) {
  return {
    type: SET_USER_PERMISSIONS_STATUS,
    payload: { status },
  };
}
