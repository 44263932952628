import React, { Component } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Icon, TopAppBar, TopAppBarFixedAdjust, TopAppBarRow,
} from 'rmwc';
import { CSSTransition } from 'react-transition-group';
import { connect } from 'react-redux';
import _ from 'lodash';
import Account from '../helpers/Account';
import logo from '../assets/images/logo.svg';
import logoDev from '../assets/images/logo-dev.svg';
import LeftMenu from './container/LeftMenu';
import Breadcrumb from './container/Breadcrumb';
import UserSettings from './container/UserSettings';
import adminTabMenus from '../adminTabMenus';
import QueryStorage from '../helpers/QueryStorage';
import Utils from '../helpers/Utils';
import { setLeftMenuState, setUserPermissionsRequest } from '../store/actions/app';
import OfflineIndicator from './OfflineIndicator';
import HasPermission from './HasPermission';
import { getGroupAllRightsRequest, getGroupByIdRequest } from '../store/actions/groups';
import Loader from './Loader';
import Logout from '../pages/Logout';

const { REACT_APP_MODE } = process.env;

class Wrapper extends Component {
    static propTypes = {
      children: PropTypes.node.isRequired,
      history: PropTypes.object.isRequired,
      location: PropTypes.object.isRequired,
      setLeftMenuState: PropTypes.func.isRequired,
      getGroupAllRightsRequest: PropTypes.func.isRequired,
      getGroupByIdRequest: PropTypes.func.isRequired,
      groupAllRights: PropTypes.array.isRequired,
      setUserPermissionsRequest: PropTypes.func.isRequired,
      leftMenuState: PropTypes.bool.isRequired,
      userPermissions: PropTypes.array.isRequired,
    };

    async componentDidMount() {
      if (Utils.isMobile()) {
        this.props.setLeftMenuState(false);
      }
      // const account = Account.get();
      // const permissions = account.groups.split(',');
      // await this.props.getGroupAllRightsRequest();
      // const permissionsData = await Promise.all(permissions.map((p) => this.props.getGroupByIdRequest(p)));
      //
      // const { groupAllRights } = this.props;
      // const rights = _.uniq(permissionsData.map((p) => p.payload.data.data.rights.split(',')).flat(1));
      // let rightsData = groupAllRights.filter((g) => rights.includes((g.id).toString()));
      // rightsData = rightsData.map((r) => _.snakeCase(r.name));
      // this.props.setUserPermissionsRequest(rightsData);
    }

    toggleLeftMenuState = () => {
      const { leftMenuState } = this.props;
      this.props.setLeftMenuState(!leftMenuState);
    }

    render() {
      const token = Account.checkFBToken();
      const {
        children, location: { pathname }, leftMenuState, userPermissions, userPermissionsStatus,
      } = this.props;

      if (!token) {
        return <Redirect to={`/login?redirect=${pathname}`} />;
      }
      if (token && _.isEmpty(userPermissions) && userPermissionsStatus !== 'request') {
        return <Logout />;
      }

      if (token && _.isEmpty(userPermissions)) {
        return <Loader />;
      }

      const [, tab] = pathname.split('/');
      const fleetPath = QueryStorage.getPaths()?.fleet ? `/fleet${QueryStorage.getPaths()?.fleet}` : '/fleet';
      return (
        <>

          <div className="wrapper">
            <TopAppBar fixed theme="background">
              <TopAppBarRow>
                <div>
                  <img
                    src={REACT_APP_MODE === 'dev' ? logoDev : logo}
                    alt=""
                    className="logo"
                    onClick={() => this.props.history.push('/')}
                  />
                  <div className="tabs">
                    {tab === 'admin' || tab === 'profile' ? (
                      <div className="menu" onClick={this.toggleLeftMenuState}>
                        <Icon icon="menu" className="tab" />
                      </div>
                    ) : null}
                    <Link to={fleetPath} className={`tab ${tab === 'fleet' ? 'active' : ''}`}>FLEET</Link>
                    <Link
                      to={Utils.getFirstAvailablePath() || '/admin'}
                      className={`tab ${tab === 'admin' ? 'active' : ''}`}
                    >
                      ADMIN
                    </Link>
                    {/* <Link to="/analytics" className={`tab ${tab === 'analytics' ? 'active' : ''}`}>ANALYTICS</Link> */}
                  </div>
                </div>
                <UserSettings />
              </TopAppBarRow>
              <OfflineIndicator />
            </TopAppBar>
            <TopAppBarFixedAdjust />
            {tab === 'admin' || tab === 'analytics' || tab === 'profile' ? (
              <div className="container">
                <CSSTransition in={leftMenuState} timeout={300} classNames="menuTransition">
                  {leftMenuState ? (
                    <div className="left">
                      <LeftMenu
                        tab={tab}
                        menus={adminTabMenus.filter((f) => HasPermission.check(f.permissions))}
                      />
                    </div>
                  ) : <div />}
                </CSSTransition>
                <div className={`right ${!leftMenuState ? 'open' : ''}`}>
                  <Breadcrumb />
                  {children}
                </div>
              </div>
            ) : null}
            {tab === 'fleet' ? (
              <div className="container fleet">
                {children}
              </div>
            ) : null}
          </div>
        </>
      );
    }
}

const mapStateToProps = (state) => ({
  leftMenuState: state.app.leftMenuState,
  groupAllRights: state.groups.groupAllRights,
  userPermissions: state.app.userPermissions,
  userPermissionsStatus: state.app.userPermissionsStatus,
});

const mapDispatchToProps = {
  setLeftMenuState,
  getGroupAllRightsRequest,
  getGroupByIdRequest,
  setUserPermissionsRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Wrapper);

export default withRouter(Container);
