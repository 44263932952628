import React, { Component } from 'react';
import { CircularProgress } from 'rmwc';
import memoizeOne from 'memoize-one';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Wrapper from '../../components/Wrapper';
import HeadersMeta from '../../components/HeadersMeta';
import Button from '../../components/form/Button';
import DataTable from '../../components/DataTable';
import { deleteRCRequest, getRCsRequest } from '../../store/actions/rcs';
import Utils from '../../helpers/Utils';
import Confirmation from '../../components/modals/Confirmation';
import Pagination from '../../components/Pagination';
import HasPermission from '../../components/HasPermission';

class RCS extends Component {
  getData = memoizeOne(async (query) => {
    this.setState({ currentLoading: true });
    const {
      // eslint-disable-next-line camelcase
      name, merchant, zone, address, contact_person, contact_person_phone,
      predicate, reverse,
    } = query;
    const predicateObject = {
      name,
      address,
      contact_person,
      contact_person_phone,
      org_name: merchant,
      zone_name: zone,
    };
    const search = { predicateObject };
    const sort = { predicate: predicate || 'name', reverse: reverse || false };
    await this.props.getRCsRequest(sort, search);
    this.setState({ currentLoading: false });
  }, _.isEqual);

  static propTypes = {
    rcs: PropTypes.array.isRequired,
    getRCsRequest: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    deleteRCRequest: PropTypes.func.isRequired,
    totalPages: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.columns = [
      { column: 'name', type: 'searchable' },
      // { column: 'merchant', type: 'searchable' },
      { column: 'zone', type: 'searchable' },
      { column: 'address', type: 'searchable' },
      { column: 'contact_person', type: 'searchable' },
      { column: 'contact_person_phone', type: 'searchable' },
    ];
    this.keysToChange = { zone_name: 'zone', org_name: 'merchant', store_id: 'id' };

    this.state = {
      currentLoading: false,
      confirmationModal: false,
      rcId: null,
      loading: false,
    };
  }

  handleAdd = () => {
    this.props.history.push('/admin/stations/add');
  }

  handleEdit = (id) => `/admin/stations/edit/${id}`

  toggleConfirmationModal = (rcId) => {
    const { confirmationModal } = this.state;
    this.setState({ confirmationModal: !confirmationModal, rcId });
  }

  handleDelete = async () => {
    const { rcId } = this.state;
    this.setState({ loading: true });
    await this.props.deleteRCRequest(rcId);
    toast.success('Successfully Deleted');
    const query = Utils.queryParse(window.location.search, this.keysToChange);
    query.deleted = rcId;
    await this.getData(query);
    this.setState({ loading: false, confirmationModal: false });
  }

  render() {
    const { currentLoading, confirmationModal, loading } = this.state;
    const { rcs, totalPages } = this.props;
    const query = Utils.queryParse(window.location.search, this.keysToChange);
    this.getData(query);
    const data = Utils.renameKeys(this.keysToChange, rcs);

    const actions = HasPermission.check('rc_edit') ? ['edit', 'delete'] : undefined;

    return (
      <Wrapper>
        <HasPermission edit="r_cs" redirect>
          <HeadersMeta page="Stations" />

          <div className="zones">
            <div className="top">
              <div className="loading">
                {currentLoading ? <CircularProgress size="small" /> : null}
              </div>
              <HasPermission edit="rc_add">
                <Button title="Add Station" onClick={this.handleAdd} />
              </HasPermission>
            </div>
            <div>
              <DataTable
                columns={this.columns}
                data={data}
                actions={actions}
                onEdit={this.handleEdit}
                onDelete={this.toggleConfirmationModal}
                onSearch={this.handleSearch}
                onSort={this.handleSort}
              />
            </div>
            <Pagination currentPage={query.page} totalPages={totalPages} />
          </div>
          <Confirmation
            isOpen={confirmationModal}
            title="Are you sure, you want to delete this station?"
            onCloseModal={() => this.toggleConfirmationModal(null)}
            onConfirm={this.handleDelete}
            loading={loading}
          />
        </HasPermission>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  rcs: state.rcs.rcs,
  totalPages: state.rcs.totalPages,
});

const mapDispatchToProps = {
  getRCsRequest,
  deleteRCRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RCS);

export default Container;
