import { define } from '../../helpers/redux-request';
import Api from '../../Api';

export const GET_STOREFRONTS = define('GET_STOREFRONTS');

export function getStorefrontsRequest(sort, search, page, number) {
  return GET_STOREFRONTS.request(() => Api.getStorefronts(sort, search, page, number)).takeLatest();
}

export const CREATE_STOREFRONT = define('CREATE_STOREFRONT');

export function createStorefrontRequest(obj) {
  return CREATE_STOREFRONT.request(() => Api.createStorefront(obj));
}

export const UPDATE_STOREFRONT = define('UPDATE_STOREFRONT');

export function updateStorefrontRequest(obj) {
  return UPDATE_STOREFRONT.request(() => Api.updateStorefront(obj));
}

export const GET_STOREFRONT_BY_ID = define('GET_STOREFRONT_BY_ID');

export function getStorefrontByIdRequest(id) {
  return GET_STOREFRONT_BY_ID.request(() => Api.getStorefrontById(id));
}

export const DELETE_STOREFRONT_BY_ID = define('DELETE_STOREFRONT_BY_ID');

export function deleteStorefrontByIdRequest(id, data) {
  return DELETE_STOREFRONT_BY_ID.request(() => Api.deleteStorefrontById(id, data));
}
