import { define } from '../../helpers/redux-request';
import Api from '../../Api';

export const GET_CONSUMERS = define('GET_CONSUMERS');

export function getConsumersRequest(sort, search, page, number) {
  return GET_CONSUMERS.request(() => Api.getConsumers(sort, search, page, number)).takeLatest();
}

export const GET_SINGLE_CONSUMER = define('GET_SINGLE_CONSUMER');

export function getSingleCostumerRequest(id) {
  return GET_SINGLE_CONSUMER.request(() => Api.getSingleCostumer(id));
}

export const DELETE_CONSUMER = define('DELETE_CONSUMER');

export function deleteConsumerRequest(id) {
  return DELETE_CONSUMER.request(() => Api.deleteConsumer(id));
}
export const GET_STATE_DYNAMIC_LINK = define('GET_STATE_DYNAMIC_LINK');

export function getStatesDynamicLink(id) {
  return GET_STATE_DYNAMIC_LINK.request(() => Api.getDynamicLink(id));
}
