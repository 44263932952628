import React, { Component } from 'react';
import { CircularProgress } from 'rmwc';
import { connect } from 'react-redux';
import memoizeOne from 'memoize-one';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Wrapper from '../../components/Wrapper';
import HeadersMeta from '../../components/HeadersMeta';
import Button from '../../components/form/Button';
import DataTable from '../../components/DataTable';
import { getProductsRequest } from '../../store/actions/product';
import Utils from '../../helpers/Utils';
import Pagination from '../../components/Pagination';
import HasPermission from '../../components/HasPermission';

class Products extends Component {
    getData = memoizeOne(async (query) => {
      this.setState({ currentLoading: true });
      const {
        // eslint-disable-next-line camelcase
        product_code, product_name, category, description,
        predicate, reverse, price, qty,
      } = query;
      const predicateObject = {
        code: product_code,
        name: product_name,
        category_name: category,
        desc: description,
        price,
        qty,
      };
      const search = { predicateObject };
      const sort = { predicate: predicate || 'code', reverse: reverse || false };
      await this.props.getProductsRequest(sort, search, (query.page - 1) || 0);
      this.setState({ currentLoading: false });
    }, _.isEqual);

    static propTypes = {
      products: PropTypes.array.isRequired,
      getProductsRequest: PropTypes.func.isRequired,
      history: PropTypes.object.isRequired,
      totalPages: PropTypes.number.isRequired,
    };

    constructor(props) {
      super(props);
      this.state = {
        currentLoading: false,
      };
      this.columns = [
        { column: 'product_code', type: 'searchable' },
        { column: 'product_name', type: 'searchable' },
        { column: 'category', type: 'searchable' },
        { column: 'description', type: 'searchable' },
        { column: 'price', type: 'searchable' },
        { column: 'qty', type: 'searchable', isNumber: true },
      ];
    }

    handleRedirectToTags = (id) => {
      const query = Utils.queryParse();
      return `/admin/products/tags/${id}?${Utils.queryStringify(query)}`;
    }

    handleAdd = () => {
      this.props.history.push('/admin/products/add');
    }

    handleEdit = (id) => `/admin/products/edit/${id}`

    render() {
      const { currentLoading } = this.state;
      const { products, totalPages } = this.props;
      const keysToChange = {
        code: 'product_code',
        name: 'product_name',
        category_name: 'category',
        desc: 'description',
        product_id: 'id',
      };

      const data = Utils.renameKeys(keysToChange, products);

      const query = Utils.queryParse(window.location.search, keysToChange);
      this.getData(query);
      const actions = [];
      if (HasPermission.check('product_tags')) {
        actions.push('tags');
      }
      if (HasPermission.check('product_edit')) {
        actions.push('edit');
      }
      return (
        <Wrapper>
          <HasPermission edit="products" redirect>
            <HeadersMeta page="Products" />
            <div className="zones">
              <div className="top">
                <div className="loading">
                  {currentLoading ? <CircularProgress size="small" /> : null}
                </div>
                <HasPermission edit="product_add">
                  <Button title="Add Product" onClick={this.handleAdd} />
                </HasPermission>
              </div>
              <div>
                <DataTable
                  columns={this.columns}
                  data={data}
                  onTag={this.handleRedirectToTags}
                  nameKey="product_name"
                  actions={actions}
                  onEdit={this.handleEdit}
                />
              </div>
              <Pagination currentPage={query.page} totalPages={totalPages} />
            </div>
          </HasPermission>
        </Wrapper>
      );
    }
}

const mapStateToProps = (state) => ({
  products: state.product.products,
  totalPages: state.product.totalPages,
});

const mapDispatchToProps = {
  getProductsRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Products);

export default Container;
