import React, { Component } from 'react';
import {
  DataTable, DataTableBody, DataTableCell, DataTableContent, DataTableHead, DataTableRow,
  Checkbox,
} from 'rmwc';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import classNames from 'classnames';
import _ from 'lodash';
import Wrapper from '../../components/Wrapper';
import { getInventoryRequest, getRobomartByIdRequest, updateBaselineInventoryRequest } from '../../store/actions/robomarts';
import Button from '../../components/form/Button';
import ViewTag from '../../components/modals/ViewTag';
import Utils from '../../helpers/Utils';
import HeadersMeta from '../../components/HeadersMeta';
import firebase from 'firebase';
import { setCurrentItemName } from "../../store/actions/app";
import HasPermission from "../../components/HasPermission";
import Select from "../../components/form/Select";
import { Link } from "react-router-dom";

class Inventory extends Component {
  getInventory = memoizeOne((getInventory) => {
    const { match: { params: { robomartId } } } = this.props;
    const path = `/Vehicles/${robomartId}/current_inventory_requested`
    if (getInventory) {
      firebase.database().ref().child(path).set(true);
      this.interval = setInterval(() => {
        this.props.getInventoryRequest(robomartId);
      }, 1000);
    } else {
      firebase.database().ref().child(path).set(false);
      clearInterval(this.interval);
    }
  })

  static propTypes = {
    getInventoryRequest: PropTypes.func.isRequired,
    updateBaselineInventoryRequest: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    inventory: PropTypes.object.isRequired,
    setCurrentItemName: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.columns = [
      { key: 'name', label: 'Product' },
      { key: 'price', label: 'Price' },
      { key: 'baseline_qty', label: 'Qty' },
      { key: 'current_qty', label: 'Last Qty' },
      { key: 'union_qty', label: 'Union Qty' },
    ];
    this.options = [
      { label: 'All', value: 'all' },
      { label: 'Last qty differs', value: 'last' },
      { label: 'Union qty differs', value: 'union' },
    ];
    this.state = {
      productId: null,
      getInventory: false,
      loading: false,
      filter: 'all'
    };
  }

  async componentDidMount() {
    const { match: { params: { robomartId } } } = this.props;
    this.props.setCurrentItemName('');
    this.props.getInventoryRequest(robomartId);
    const { payload: { data: { data = {} } = {} } } = await this.props.getRobomartByIdRequest(robomartId);
    this.props.setCurrentItemName(data.name);
  }

  toggleViewModal = (id) => {
    const { productId } = this.state;
    if (productId) {
      this.setState({ productId: null });
    } else {
      this.setState({ productId: id });
    }
  }

  handleUpdateBaselineInventory = async () => {
    this.setState({ loading: true });
    const { match: { params: { robomartId } } } = this.props;
    await this.props.updateBaselineInventoryRequest(robomartId);
    this.props.getInventoryRequest(robomartId);
    this.setState({ loading: false });
  }

  handleGetInventory = () => {
    const { getInventory } = this.state;
    this.setState({ getInventory: !getInventory });
  }

  handleRedirect = (url) => {
    const query = Utils.queryParse();
    this.props.history.push(`${url}?${Utils.queryStringify(query)}`);
  }
  handleFilter = (val) => {
    this.setState({ filter: val.value })
  }

  render() {
    const { inventory: { products, updated_on: updatedOn }, match: { params: { robomartId } } } = this.props;
    const { productId, getInventory, loading, filter } = this.state;
    this.getInventory(getInventory);
    let filteredProducts = products;
    if (filter === 'last') {
      filteredProducts = filteredProducts.filter(product =>
        (product.baseline_qty === product.union_qty && product.current_qty !== product.union_qty) || (product.baseline_qty !== product.union_qty)
      )
    }
    if (filter === 'union') {
      filteredProducts = filteredProducts.filter(product => product.baseline_qty !== product.union_qty)
    }
    return (
      <Wrapper>
        <HasPermission edit="robomart_inventory" redirect>
          <HeadersMeta page="Inventory" />
          <div className="inventory">
            <div className="top">
              <div className="lastUpdate">
                <p>Last Inventory Update:</p>
                <p className="bold">{`${updatedOn?.updated_on || ''} second (s)`}</p>
              </div>
              <div className="getInventory">
                <div className="baseline">
                  <Checkbox
                    label="Get Inventory"
                    checked={getInventory}
                    onChange={this.handleGetInventory}
                  />
                  <Button
                    title="Update baseline inventory"
                    type="button"
                    onClick={this.handleUpdateBaselineInventory}
                    loading={loading}
                  />
                </div>
                <div>
                  <Select
                    options={this.options}
                    value={this.options.find(o => o.value === filter)}
                    onChange={(val) => this.handleFilter(val)}
                    isSearchable={false}
                  />
                </div>
              </div>
            </div>
            <div>
              <DataTable
                stickyRows="1"
              >
                <DataTableContent>
                  <DataTableHead>
                    <DataTableRow>
                      <DataTableCell>S.No</DataTableCell>
                      {this.columns.map((c) => (
                        <DataTableCell key={c.key}>{c.label}</DataTableCell>
                      ))}
                      <DataTableCell>Tags</DataTableCell>

                    </DataTableRow>
                  </DataTableHead>
                  <DataTableBody>
                    {filteredProducts?.map((product, i) => (
                      <DataTableRow key={product.product_id}>
                        <DataTableCell>{i + 1}</DataTableCell>
                        {this.columns.map((c) => {
                          let lightGreen;
                          let lightBlue;
                          let lightRed;
                          if (['baseline_qty', 'current_qty', 'union_qty'].includes(c.key)) {
                            lightGreen = product.baseline_qty === product.union_qty;
                            lightRed = product.baseline_qty !== product.union_qty;
                            lightBlue = product.baseline_qty === product.union_qty && product.current_qty !== product.union_qty;
                          }
                          return (
                            <DataTableCell
                              key={c.key}
                              className={classNames({
                                blue: c.key === 'name',
                                clickable: c.key === 'name',
                                lightGreen, lightRed, lightBlue
                              })}
                            >
                              {c.key === 'name'
                                ? (<Link to={`/admin/products/edit/${product.product_id}`}>
                                  {product[c.key]}
                                </Link>)
                                : product[c.key]}
                            </DataTableCell>
                          );
                        })}
                        <DataTableCell
                          className="blue clickable"
                          onClick={() => this.toggleViewModal(product.product_id)}
                        >
                          View
                        </DataTableCell>
                      </DataTableRow>
                    ))}
                    <DataTableRow className="total">
                      <DataTableCell />
                      <DataTableCell />
                      <DataTableCell />
                      <DataTableCell>{_.sum(products?.map((p) => p?.baseline_qty))}</DataTableCell>
                      <DataTableCell>{_.sum(products?.map((p) => p?.current_qty))}</DataTableCell>
                      <DataTableCell>{_.sum(products?.map((p) => p?.union_qty))}</DataTableCell>
                    </DataTableRow>
                  </DataTableBody>
                </DataTableContent>
              </DataTable>
            </div>
            <div className="actions">
              <Button title="Back" onClick={() => this.handleRedirect('/admin/shops')} />
              <Button title="Go to edit" onClick={() => this.handleRedirect(`/admin/shops/edit/${robomartId}`)} />
            </div>
          </div>
          <ViewTag isOpen={productId} productId={productId} onCloseModal={this.toggleViewModal} />
        </HasPermission>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  inventory: state.robomarts.inventory,
});

const mapDispatchToProps = {
  getInventoryRequest,
  updateBaselineInventoryRequest,
  setCurrentItemName,
  getRobomartByIdRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Inventory);

export default Container;
