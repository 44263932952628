import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import memoizeOne from 'memoize-one';
import _ from 'lodash';
import Input from '../../components/form/Input';
import Wrapper from '../../components/Wrapper';
import Button from '../../components/form/Button';
import { createMerchantRequest, getMerchantByIdRequest, updateMerchantRequest } from '../../store/actions/merchants';
import HeadersMeta from '../../components/HeadersMeta';
import Validator from '../../helpers/Validator';
import Form from '../../components/form/Form';
import { setCurrentItemName } from '../../store/actions/app';
import HasPermission from '../../components/HasPermission';

class MerchantForm extends Component {
  initMerchantData = memoizeOne(async (id) => {
    if (!id) {
      return;
    }
    this.props.setCurrentItemName('');
    const { payload: { data: { data } } } = await this.props.getMerchantByIdRequest(id);
    if (data) {
      this.props.setCurrentItemName(data.name);
      this.setState({ formData: data });
    }
  })

  static propTypes = {
    history: PropTypes.object.isRequired,
    createMerchantRequest: PropTypes.func.isRequired,
    updateMerchantRequest: PropTypes.func.isRequired,
    getMerchantByIdRequest: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    merchantStatus: PropTypes.string.isRequired,
    setCurrentItemName: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        name: '',
        owner_name: '',
        admin_name: '',
        phone: '',
        address: '',
        owner_email: '',
        admin_email: '',
        stripe_account: '',
        www_address: '',
      },
      loading: false,
      errors: [],
      changed: false,
      emptyFields: [],
    };
  }

  handleAddMerchant = async (ev) => {
    ev.preventDefault();
    const { formData } = this.state;
    const { match: { params: { merchantId } } } = this.props;
    const emptyFields = Validator.getEmptyFields(formData, ['name', 'stripe_account', 'address']);
    this.setState({ loading: true });
    if (!_.isEmpty(emptyFields)) {
      this.setState({ emptyFields, loading: false });
      return;
    }
    let data;
    if (merchantId) {
      formData.org_id = merchantId;
      const { payload } = await this.props.updateMerchantRequest(formData);
      data = payload.data;
    } else {
      const { payload } = await this.props.createMerchantRequest(formData);
      data = payload.data;
    }
    if (data.success) {
      toast.success(`Successfully ${merchantId ? 'updated' : 'created'}.`);
      this.props.history.push('/admin/merchants');
    }
    if (data?.error) {
      toast.error('Something went wrong');
    }
    this.setState({ loading: false });
  }

  handleChange = (key, value, type) => {
    const { formData, errors } = this.state;
    let { emptyFields } = this.state;

    formData[key] = value;
    if (errors.includes(key)) {
      this.handleOnBlur(key, type);
    }
    if (emptyFields.includes(key)) {
      emptyFields = emptyFields.filter((f) => f !== key);
    }
    this.setState({ formData, emptyFields });
  }

  cancel = () => {
    this.props.history.push('/admin/merchants');
  }

  handleOnBlur = (key, type) => {
    const { formData } = this.state;
    let { errors } = this.state;
    if (!_.isEmpty(formData[key]) && !Validator.validate(type, formData[key])) {
      errors = [...errors, key];
      this.setState({ errors });
    } else {
      this.setState({ errors: errors.filter((e) => e !== key) });
    }
  }

  handleFormChange = () => {
    const { changed } = this.state;
    if (!changed) {
      this.setState({ changed: true });
    }
  }

  render() {
    const {
      formData, loading, errors, changed, emptyFields,
    } = this.state;
    const { match: { params: { merchantId } }, merchantStatus } = this.props;
    this.initMerchantData(merchantId);

    const blur = merchantStatus !== 'ok' && merchantId ? 'blur' : '';
    return (
      <Wrapper>
        <HasPermission edit={['merchant_add', 'merchant_edit']} redirect>
          <HeadersMeta page={` ${merchantId ? 'Edit' : 'Add'} Merchant`} />
          <Form
            onSubmit={this.handleAddMerchant}
            className="addBlockForm"
            ref={(ref) => this.form = ref}
            id={merchantId}
            onChange={this.handleFormChange}
          >
            <div className="addBlock">
              <div className="col">
                <Input
                  label="Name *"
                  value={formData.name || ''}
                  onChange={(ev) => this.handleChange('name', ev.target.value)}
                  className={blur}
                  invalid={emptyFields.includes('name')}
                />
                <Input
                  label="Owner Name"
                  value={formData.owner_name || ''}
                  onChange={(ev) => this.handleChange('owner_name', ev.target.value)}
                  className={blur}
                />
                <Input
                  label="Admin Name"
                  value={formData.admin_name || ''}
                  onChange={(ev) => this.handleChange('admin_name', ev.target.value)}
                  className={blur}
                />
                <Input
                  label="Phone"
                  value={formData.phone || ''}
                  onChange={(ev) => this.handleChange('phone', ev.target.value, 'phone')}
                  className={blur}
                  onBlur={() => this.handleOnBlur('phone', 'phone')}
                  invalid={errors.includes('phone') ? 'Not valid phone.' : undefined}
                />
                <Input
                  label="Address *"
                  value={formData.address || ''}
                  onChange={(ev) => this.handleChange('address', ev.target.value)}
                  className={blur}
                  invalid={emptyFields.includes('address')}
                />
              </div>
              <div className="col">
                <Input
                  label="Stripe Account *"
                  value={formData.stripe_account || ''}
                  onChange={(ev) => this.handleChange('stripe_account', ev.target.value)}
                  className={blur}
                  invalid={emptyFields.includes('stripe_account')}
                />

                <Input
                  label="Owner Email"
                  value={formData.owner_email || ''}
                  onChange={(ev) => this.handleChange('owner_email', ev.target.value, 'email')}
                  className={blur}
                  onBlur={() => this.handleOnBlur('owner_email', 'email')}
                  invalid={errors.includes('owner_email') ? 'Not valid email address.' : undefined}
                />
                <Input
                  label="Admin Email"
                  value={formData.admin_email || ''}
                  onChange={(ev) => this.handleChange('admin_email', ev.target.value, 'email')}
                  className={blur}
                  onBlur={() => this.handleOnBlur('admin_email', 'email')}
                  invalid={errors.includes('admin_email') ? 'Not valid email address.' : undefined}
                />
                <Input
                  label="Web Address"
                  value={formData.www_address || ''}
                  onChange={(ev) => this.handleChange('www_address', ev.target.value, 'url')}
                  className={blur}
                  onBlur={() => this.handleOnBlur('www_address', 'url')}
                  invalid={errors.includes('www_address') ? 'Not valid web address.' : undefined}
                />
              </div>
            </div>
            <div className="actions">
              <Button title="Cancel" onClick={this.cancel} type="button" />
              <Button
                type="submit"
                title={merchantId ? 'Save' : 'Create'}
                loading={loading}
                disabled={(merchantStatus !== 'ok' && merchantId)
                  || !_.isEmpty(errors)
                  || (merchantId && !changed)}
              />
            </div>
          </Form>
        </HasPermission>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  merchantStatus: state.merchants.merchantStatus,
});

const mapDispatchToProps = {
  createMerchantRequest,
  getMerchantByIdRequest,
  updateMerchantRequest,
  setCurrentItemName,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MerchantForm);

export default Container;
