import React, { Component } from 'react';
import {
  DataTable, DataTableBody, DataTableCell, DataTableContent, DataTableHead, DataTableRow,
} from 'rmwc';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import noImg from '../../assets/images/noImg.png';

class TagGroup extends Component {
  render() {
    const { tags } = this.props;
    return (
      <DataTable>
        <DataTableContent>
          <DataTableHead>
            <DataTableRow>
              <DataTableCell alignStart>Tag Id</DataTableCell>
              <DataTableCell alignStart>Tag group Id</DataTableCell>
            </DataTableRow>
          </DataTableHead>
          <DataTableBody>
            {tags.map((item) => (
              <DataTableRow>
                <DataTableCell>
                  {item.tag}
                </DataTableCell>
                <DataTableCell>
                  {item.group_id}
                </DataTableCell>
              </DataTableRow>
            ))}
          </DataTableBody>
        </DataTableContent>
      </DataTable>
    );
  }
}

export default TagGroup;
