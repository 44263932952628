import { define } from '../../helpers/redux-request';
import Api from '../../Api';

export const GET_INVITE_CODES = define('GET_INVITE_CODES');

export function getInviteCodesRequest(sort, search, page, number) {
  return GET_INVITE_CODES.request(() => Api.getInviteCodes(sort, search, page, number)).takeLatest();
}

export const GET_CODE_FOR_CONSUMERS = define('GET_CODE_FOR_CONSUMERS');

export function genCodeForConsumersRequest(obj) {
  return GET_CODE_FOR_CONSUMERS.request(() => Api.genCodeForConsumers(obj));
}

export const UPDATE_CODE_FOR_CONSUMERS = define('UPDATE_CODE_FOR_CONSUMERS');

export function updateCodeForConsumersRequest(obj) {
  return UPDATE_CODE_FOR_CONSUMERS.request(() => Api.updateCodeForConsumers(obj));
}
