import { combineReducers } from 'redux';
import zones from './zones';
import merchants from './merchants';
import rcs from './rcs';
import app from './app';
import robomarts from './robomarts';
import types from './types';
import product from './product';
import groups from './groups';
import users from './users';
import consumers from './consumers';
import orders from './orders';
import robomartTypes from './robomartTypes';
import applicants from './applicants';
import inviteCodes from './inviteCodes';
import settings from './settings';
import storefronts from './storefronts';
import coupons from './coupons';
import categories from './categories';
import credits from './credits';

export default combineReducers({
  zones,
  merchants,
  rcs,
  app,
  robomarts,
  types,
  product,
  groups,
  users,
  consumers,
  orders,
  robomartTypes,
  applicants,
  inviteCodes,
  settings,
  storefronts,
  coupons,
  categories,
  credits,
});
