import { GET_COUPON_TAGS } from '../actions/coupons';

const initialState = {
  couponTags: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_COUPON_TAGS: {
      return {
        ...state,
        couponTags: action.payload.data,
      };
    }
    default: {
      return state;
    }
  }
}
