import { GET_ZONES, GET_ZONE_BY_ID } from '../actions/zones';

const initialState = {
  zones: [],
  zonesStatus: '',
  zone: {},
  zoneStatus: '',
  totalPages: 1,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ZONES.REQUEST: {
      return {
        ...state,
        zonesStatus: 'request',
      };
    }
    case GET_ZONES.SUCCESS: {
      const { data: { data: { Records, RecordsCount } } } = action.payload;
      return {
        ...state,
        zones: Records,
        zonesStatus: 'ok',
        totalPages: Math.ceil(RecordsCount / 10),
      };
    }
    case GET_ZONES.FAIL: {
      return {
        ...state,
        zonesStatus: 'fail',
        zones: [],
      };
    }
    case GET_ZONE_BY_ID.REQUEST: {
      return {
        ...state,
        zoneStatus: 'request',
      };
    }
    case GET_ZONE_BY_ID.SUCCESS: {
      return {
        ...state,
        zoneStatus: 'ok',
      };
    }
    case GET_ZONE_BY_ID.FAIL: {
      return {
        ...state,
        zoneStatus: 'fail',
        zone: {},
      };
    }
    default: {
      return state;
    }
  }
}
