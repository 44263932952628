import { define } from '../../helpers/redux-request';
import Api from '../../Api';

export const GET_ROBOMARTS = define('GET_ROBOMARTS');

export function getRobomartsRequest(sort, search, page, number) {
  return GET_ROBOMARTS.request(() => Api.getRobomarts(sort, search, page, number)).takeLatest();
}

export const CREATE_ROBOMART = define('CREATE_ROBOMART');

export function createRobomartRequest(obj) {
  return CREATE_ROBOMART.request(() => Api.createRobomart(obj));
}

export const GET_ROBOMART_BY_ID = define('GET_ROBOMART_BY_ID');

export function getRobomartByIdRequest(id) {
  return GET_ROBOMART_BY_ID.request(() => Api.getRobomartById(id));
}

export const UPDATE_ROBOMART = define('UPDATE_ROBOMART');

export function updateRobomartRequest(obj) {
  return UPDATE_ROBOMART.request(() => Api.updateRobomart(obj));
}

export const GET_INVENTORY = define('GET_INVENTORY');

export function getInventoryRequest(id) {
  return GET_INVENTORY.request(() => Api.getInventory(id));
}

export const UPDATE_BASELINE_INVENTORY = define('UPDATE_BASELINE_INVENTORY');

export function updateBaselineInventoryRequest(id) {
  return UPDATE_BASELINE_INVENTORY.request(() => Api.updateBaselineInventory(id));
}

export const GET_INVENTORY_STOCK_LIST = define('GET_INVENTORY_STOCK_LIST');

export function getInventoryStockListRequest(id) {
  return GET_INVENTORY_STOCK_LIST.request(() => Api.getInventoryStockList(id));
}
