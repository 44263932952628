class QueryStorage {
  static setZoomData(data) {
    sessionStorage.setItem('zoomData', JSON.stringify(data));
  }

  static setPaths(key, path) {
    const paths = this.getPaths();
    paths[key] = path;
    sessionStorage.setItem('paths', JSON.stringify(paths));
  }

  static getZoomData() {
    try {
      return JSON.parse(sessionStorage.getItem('zoomData')) || {};
    } catch (e) {
      return '';
    }
  }

  static getPaths() {
    try {
      return JSON.parse(sessionStorage.getItem('paths')) || {};
    } catch (e) {
      return '';
    }
  }
}

export default QueryStorage;
