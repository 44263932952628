import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import HasPermission from '../../components/HasPermission';
import HeadersMeta from '../../components/HeadersMeta';
import Wrapper from '../../components/Wrapper';
import { getSingleCostumerRequest, getStatesDynamicLink } from '../../store/actions/consumers';
import Input from '../../components/form/Input';
import Loader from '../../components/Loader';
import DataTable from '../../components/DataTable';

const queryString = require('query-string');

class ConsumerLink extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    stateDynamicLink: PropTypes.object.isRequired,
    consumerStatus: PropTypes.string.isRequired,
    getSingleCostumerRequest: PropTypes.func.isRequired,
    getStatesDynamicLink: PropTypes.func.isRequired,
    consumer: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.columns = [
      { column: 'platform', type: 'searchable' },
      { column: 'event', type: 'searchable' },
      { column: 'count', type: 'searchable' },
    ];
  }

  componentDidMount() {
    const { match: { params: { consumerId } } } = this.props;

    const parsed = queryString.parse(this.props.history?.location?.search);
    this.props.getSingleCostumerRequest(consumerId);
    this.props.getStatesDynamicLink(parsed.dynamicLink);
  }

  handleSearch = (value) => {
  // todo if necessary
  }

  render() {
    const {
      match: { params: { consumerId } }, consumer, consumerStatus, stateDynamicLink,
    } = this.props;

    return (
      <Wrapper>
        <HasPermission edit="consumers" redirect>
          <HeadersMeta page="Consumer Link" />
          {consumerId && consumerStatus !== 'request' ? (
            <div>
              <Input value={consumer.fullname} readOnly label="Name" />
              <div className="consumerData">
                <Input value={consumer.email} readOnly label="Email" />
                <Input value={consumer.phone} readOnly label="Phone" />
              </div>
            </div>
          ) : null}
          {consumerId ? (
            <DataTable
              columns={this.columns}
              data={stateDynamicLink}
              className="consumer-links"
              nameKey="receipt_no"
              onSearch={this.handleSearch}
            />
          ) : null}
          <div>
            {consumerId && consumerStatus === 'request' ? <Loader /> : null}
          </div>
        </HasPermission>
      </Wrapper>
    );
  }
}
const mapStateToProps = (state) => ({
  orders: state.orders.orders,
  totalPages: state.orders.totalPages,
  consumer: state.consumers.consumer,
  consumerStatus: state.consumers.consumerStatus,
  stateDynamicLink: state.consumers.stateDynamicLink || [],
});

const mapDispatchToProps = {
  getSingleCostumerRequest,
  getStatesDynamicLink,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConsumerLink);

export default Container;
