import { GET_INVITE_CODES } from '../actions/inviteCodes';

const initialState = {
  inviteCodes: [],
  inviteCodesStatus: '',
  totalPages: 1,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_INVITE_CODES.REQUEST: {
      return {
        ...state,
        inviteCodesStatus: 'request',
      };
    }
    case GET_INVITE_CODES.SUCCESS: {
      const { data: { data: { Records, RecordsCount } } } = action.payload;
      return {
        ...state,
        inviteCodes: Records,
        inviteCodesStatus: 'ok',
        totalPages: Math.ceil(RecordsCount / 10),
      };
    }
    case GET_INVITE_CODES.FAIL: {
      return {
        ...state,
        inviteCodesStatus: 'fail',
      };
    }

    default: {
      return state;
    }
  }
}
