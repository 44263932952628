import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from '../form/Input';

class SettingItem extends Component {
  static propTypes = {
    label: PropTypes.string,
    unit: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
    info: PropTypes.string,
    value: PropTypes.any,
    invalid: PropTypes.bool,
  };

  static defaultProps = {
    label: '',
    unit: '',
    info: '',
    className: '',
    type: 'text',
    value: undefined,
    invalid: false,
  }

  render() {
    const {
      label, unit, value, type, className, info, invalid, ...props
    } = this.props;
    return (
      <>
        <div className="item">
          {label ? (
            <div className="labels">
              <span className={invalid ? 'invalid' : ''}>{label}</span>
            </div>
          ) : null}
          <div className={`inputs ${className}`}>
            <Input value={value} type={type} invalid={invalid} {...props} />
          </div>
          {unit ? (
            <div className="units">
              <span>{unit}</span>
            </div>
          ) : null}

        </div>
        {info ? <span className="info">{info}</span> : null}
      </>
    );
  }
}

export default SettingItem;
