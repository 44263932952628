import _ from 'lodash';
import gLibPhone from 'google-libphonenumber';

// validations form form fields and forms
class Validator {
  static disableFormSubmit = (formData, requiredFields) => requiredFields.some((d) => _.isEmpty(String(formData[d] || '')))

  static getEmptyFields = (formData, fields) => _.compact(fields.map((d) => (_.isEmpty(String(formData[d] || '')) ? d : false)))

  static isValidPhone = (value) => {
    try {
      const phoneUtil = gLibPhone.PhoneNumberUtil.getInstance();
      return phoneUtil.isValidNumberForRegion(
        phoneUtil.parse(value, 'US'), 'US',
      );
    } catch (e) {
      return false;
    }
  }

  static isValidEmail(val) {
    // eslint-disable-next-line max-len
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val || '');
  }

  static isValidUrl(url) {
    const regExp = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
    return regExp.test((url || ''));
  }

  static is2DecimalFloat = (number) => (+number || 0).toFixed(2) === number

  static validate = (key, val) => ({
    phone: this.isValidPhone(val),
    email: this.isValidEmail(val),
    url: this.isValidUrl(val),
    float: this.is2DecimalFloat(val),
  })[key]
}

export default Validator;
