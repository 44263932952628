import _ from 'lodash';
import {
  GET_PRODUCT_TAGS, GET_PRODUCTS, GET_PRODUCT_ALL_TAGS, GET_PRODUCT_CATEGORIES,
  GET_PRODUCT_BY_ID, GET_PRODUCTS_BY_STOREFRONT,
} from '../actions/product';
import Utils from '../../helpers/Utils';

const initialState = {
  productTags: {},
  productTagsStatus: '',
  products: [],
  productsStatus: '',
  totalPages: 1,
  productAllTags: [],
  productAllTagsStatus: '',
  productCategories: [],
  productCategoriesStatus: '',
  product: {},
  productStatus: '',
  productsByType: [],
  productsByTypeStatus: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCT_TAGS.REQUEST: {
      return {
        ...state,
        productTags: {},
        productTagsStatus: 'request',
      };
    }
    case GET_PRODUCT_TAGS.SUCCESS: {
      return {
        ...state,
        productTags: action.payload.data.data,
        productTagsStatus: 'ok',
      };
    }
    case GET_PRODUCT_TAGS.FAIL: {
      return {
        ...state,
        productTagsStatus: 'fail',
      };
    }
    case GET_PRODUCTS.REQUEST: {
      return {
        ...state,
        productsStatus: 'request',
      };
    }
    case GET_PRODUCTS.SUCCESS: {
      const { data: { data: { Records, RecordsCount } }, cacheData } = action.payload;
      let records = Records;

      const products = [...state.products];
      if (cacheData) {
        records = [...products, ...Records];
      }
      records = records.map((r) => {
        r.price = Utils.formatPriceAmounts(r.price);
        return r;
      });
      return {
        ...state,
        products: _.uniqBy(records, 'product_id'),
        productsStatus: 'ok',
        totalPages: Math.ceil(RecordsCount / 10),
      };
    }
    case GET_PRODUCTS.FAIL: {
      return {
        ...state,
        productsStatus: 'fail',
      };
    }
    case GET_PRODUCT_ALL_TAGS.REQUEST: {
      return {
        ...state,
        productAllTagsStatus: 'request',
        // productAllTags: [],
      };
    }
    case GET_PRODUCT_ALL_TAGS.SUCCESS: {
      const { data: { data } } = action.payload;
      return {
        ...state,
        productAllTags: data,
        productAllTagsStatus: 'ok',
      };
    }
    case GET_PRODUCT_ALL_TAGS.FAIL: {
      return {
        ...state,
        productAllTagsStatus: 'fail',
      };
    }
    case GET_PRODUCT_CATEGORIES.REQUEST: {
      return {
        ...state,
        productCategoriesStatus: 'request',
      };
    }
    case GET_PRODUCT_CATEGORIES.SUCCESS: {
      const { data: { data: { Records } } } = action.payload;
      return {
        ...state,
        productCategories: Records,
        productCategoriesStatus: 'ok',
      };
    }
    case GET_PRODUCT_CATEGORIES.FAIL: {
      return {
        ...state,
        productCategoriesStatus: 'fail',
      };
    }
    case GET_PRODUCT_BY_ID.REQUEST: {
      return {
        ...state,
        productStatus: 'request',
      };
    }
    case GET_PRODUCT_BY_ID.SUCCESS: {
      const { data: { data } } = action.payload;
      return {
        ...state,
        product: data,
        productStatus: 'ok',
      };
    }
    case GET_PRODUCT_BY_ID.FAIL: {
      return {
        ...state,
        productStatus: 'fail',
      };
    }
    case GET_PRODUCTS_BY_STOREFRONT.REQUEST: {
      return {
        ...state,
        productsByTypeStatus: 'request',
      };
    }
    case GET_PRODUCTS_BY_STOREFRONT.SUCCESS: {
      const { data: { data } } = action.payload;
      return {
        ...state,
        productsByType: data,
        productsByTypeStatus: 'ok',
      };
    }
    case GET_PRODUCTS_BY_STOREFRONT.FAIL: {
      return {
        ...state,
        productsByTypeStatus: 'fail',
      };
    }

    default: {
      return state;
    }
  }
}
