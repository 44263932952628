import React, { Component } from 'react';
import { CircularProgress } from 'rmwc';
import memoizeOne from 'memoize-one';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Wrapper from '../../components/Wrapper';
import HeadersMeta from '../../components/HeadersMeta';
import DataTable from '../../components/DataTable';
import { deleteConsumerRequest, getConsumersRequest } from '../../store/actions/consumers';
import Utils from '../../helpers/Utils';
import Pagination from '../../components/Pagination';
import Confirmation from '../../components/modals/Confirmation';
import HasPermission from '../../components/HasPermission';

class Consumers extends Component {
  getData = memoizeOne(async (query) => {
    this.setState({ currentLoading: true });
    const {
      // eslint-disable-next-line camelcase
      name, email, phone, signup_on, active, predicate, reverse, invite_link, credit, balance,
    } = query;
    const predicateObject = {
      fullname: name,
      email,
      phone,
      created_on: signup_on,
      is_active: active,
      invite_url: invite_link,
      credit,
      balance,
    };
    const search = { predicateObject };
    const sort = { predicate: predicate || 'fullname', reverse: reverse || false };
    await this.props.getConsumersRequest(sort, search, (query.page - 1) || 0);
    this.setState({ currentLoading: false });
  }, _.isEqual);

  static propTypes = {
    consumers: PropTypes.array.isRequired,
    getConsumersRequest: PropTypes.func.isRequired,
    deleteConsumerRequest: PropTypes.func.isRequired,
    totalPages: PropTypes.number,
  };

  static defaultProps = {
    totalPages: 1,
  }

  constructor(props) {
    super(props);
    this.state = {
      currentLoading: false,
      confirmationModal: false,
      loading: false,
      consumerId: null,
    };
    this.columns = [
      { column: 'name', type: 'searchable' },
      { column: 'email', type: 'searchable' },
      { column: 'phone', type: 'searchable' },
      { column: 'signup_on', type: 'searchable', isDate: true },
      { column: 'credit', type: 'searchable' },
      { column: 'balance', type: 'searchable' },
      { column: 'invite_link', type: 'searchable', isInviteLink: true },
      { column: 'active', type: '' },
      { column: 'sms', type: '' },
      { column: 'push', type: '' },
    ];
    this.keysToChange = {
      fullname: 'name',
      is_active: 'active',
      invite_url: 'invite_link',
      delivery_sms: 'sms',
      delivery_notification: 'push',
      created_on: 'signup_on',
    };
  }

  toggleConfirmationModal = (consumerId) => {
    const { confirmationModal } = this.state;
    this.setState({ confirmationModal: !confirmationModal, consumerId });
  }

  handleDelete = async () => {
    const { consumerId } = this.state;
    this.setState({ loading: true });
    await this.props.deleteConsumerRequest(consumerId);
    toast.success('Successfully Deleted');
    const query = Utils.queryParse(window.location.search, this.keysToChange);
    query.deleted = consumerId;
    this.getData(query);
    this.setState({ loading: false, confirmationModal: false });
  }

  handleRedirect = (id) => `/admin/consumers/orders/${id}`

  render() {
    const { currentLoading, confirmationModal, loading } = this.state;
    const { consumers, totalPages } = this.props;
    const data = Utils.renameKeys(this.keysToChange, consumers);

    const query = Utils.queryParse(window.location.search, this.keysToChange);
    this.getData(query);
    const actions = ['info'];
    if (HasPermission.check('consumers_delete')) {
      actions.push('delete');
    }
    return (
      <Wrapper>
        <HasPermission edit="consumers" redirect>
          <HeadersMeta page="Consumers" />
          <div className="zones">
            <div className="top">
              <div className="loading">
                {currentLoading ? <CircularProgress size="small" /> : null}
              </div>
            </div>
            <div>
              <DataTable
                columns={this.columns}
                data={data}
                actions={actions}
                onDelete={this.toggleConfirmationModal}
                onInfo={this.handleRedirect}
              />
            </div>
            <Pagination currentPage={query.page} totalPages={totalPages} />
          </div>
          <Confirmation
            isOpen={confirmationModal}
            title="Are you sure, you want to delete this consumer?"
            onCloseModal={() => this.toggleConfirmationModal(null)}
            onConfirm={this.handleDelete}
            loading={loading}
          />
        </HasPermission>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  consumers: state.consumers.consumers,
  totalPages: state.consumers.totalPages,
});

const mapDispatchToProps = {
  getConsumersRequest,
  deleteConsumerRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Consumers);

export default Container;
