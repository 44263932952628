import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import memoizeOne from 'memoize-one';
import _ from 'lodash';
import Wrapper from '../../components/Wrapper';
import HeadersMeta from '../../components/HeadersMeta';
import Input from '../../components/form/Input';
import Button from '../../components/form/Button';
import { getZonesRequest } from '../../store/actions/zones';
import Select from '../../components/form/Select';
import { getMerchantsRequest } from '../../store/actions/merchants';
import Autocomplete from '../../components/form/Autocomplete';
import Address from '../../helpers/Address';
import { createRCRequest, getRCByIdRequest, updateRCRequest } from '../../store/actions/rcs';
import Validator from '../../helpers/Validator';
import Form from '../../components/form/Form';
import GoogleMapWrapper from '../../components/GoogleMapWrapper';
import { setCurrentItemName } from '../../store/actions/app';
import HasPermission from '../../components/HasPermission';

class RCSForm extends Component {
  initRCData = memoizeOne(async (id) => {
    if (!id) {
      return;
    }
    this.props.setCurrentItemName('');
    const { payload: { data: { data } = {} } } = await this.props.getRCByIdRequest(id);
    if (data) {
      this.props.setCurrentItemName(data.name);
      this.setState({ formData: data || {} });
    }
  })

  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    getZonesRequest: PropTypes.func.isRequired,
    getMerchantsRequest: PropTypes.func.isRequired,
    zones: PropTypes.array.isRequired,
    merchants: PropTypes.array.isRequired,
    google: PropTypes.object.isRequired,
    createRCRequest: PropTypes.func.isRequired,
    getRCByIdRequest: PropTypes.func.isRequired,
    updateRCRequest: PropTypes.func.isRequired,
    rcStatus: PropTypes.string.isRequired,
    setCurrentItemName: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        name: '',
        org_id: null,
      },
      placeId: null,
      loading: false,
      changed: false,
      errors: [],
      emptyFields: [],
    };
  }

  componentDidMount() {
    this.props.getZonesRequest({ predicate: 'name', reverse: false }, {}, 0, 100);
    this.props.getMerchantsRequest({ predicate: 'name', reverse: false }, {});
  }

  handleChange = async (key, value, type) => {
    const { formData, errors } = this.state;
    let { emptyFields } = this.state;
    formData[key] = value;
    if (emptyFields.includes(key)) {
      emptyFields = emptyFields.filter((f) => f !== key);
    }
    formData[key] = value;
    this.setState({ formData, emptyFields });
    this.form.forceChange();
    this.handleFormChange();
    if (errors.includes(key)) {
      this.handleOnBlur(key, type);
    }
    if (key === 'coords') {
      const [lat, lng] = value.split(',');
      const pos = { lat: +lat, lng: +lng };
      let data = [];
      if (lat && lng) {
        data = await Address.getAddress(pos);
      }
      const [city, address] = data;
      let cityAddress;
      if (!address && city) {
        cityAddress = `${city[2].value}, ${city[1].value}, ${city[0].value} `;
      }
      if (!address && !city) {
        // toast.error('Invalid coordinates.');
      }
      formData.address = address || cityAddress;
      this.setState({ formData });
    }
  }

  handleAddRC = async (ev) => {
    ev.preventDefault();
    const { formData } = this.state;
    const { match: { params: { rcId } } } = this.props;
    const emptyFields = Validator.getEmptyFields(formData, ['name', 'zone_id', 'coords', 'address']);
    this.setState({ loading: true });
    if (!_.isEmpty(emptyFields)) {
      this.setState({ emptyFields, loading: false });
      return;
    }
    let data;
    if (rcId) {
      formData.store_id = rcId;
      const { payload } = await this.props.updateRCRequest(formData);
      data = payload.data;
    } else {
      const { payload } = await this.props.createRCRequest(formData);
      data = payload.data;
    }
    if (data.success) {
      toast.success(`Successfully ${rcId ? 'updated' : 'created'}.`);
      this.props.history.push('/admin/stations');
    }
    if (data?.error) {
      toast.error('Something went wrong');
    }
    this.setState({ loading: false });
  }

  cancel = () => {
    this.props.history.push('/admin/stations');
  }

  handleSelect = (data) => {
    if (!data || !data.location) {
      return;
    }
    const { formData } = this.state;
    formData.address = data.address;
    formData.coords = `${data.location.lat}, ${data.location.lng}`;
    this.setState({ formData, placeId: data.placeId });
  }

  removeFieldValue = () => {
    const { formData } = this.state;
    formData.address = '';
    formData.coords = '';
    this.setState({ formData });
  }

  handleOnBlur = (key, type) => {
    const { formData } = this.state;
    let { errors } = this.state;
    if (!_.isEmpty(formData[key]) && !Validator.validate(type, formData[key])) {
      errors = [...errors, key];
      this.setState({ errors });
    } else {
      this.setState({ errors: errors.filter((e) => e !== key) });
    }
  }

  handleFormChange = () => {
    const { changed } = this.state;
    if (!changed) {
      this.setState({ changed: true });
    }
  }

  render() {
    const {
      loading, formData, placeId, errors, changed, emptyFields,
    } = this.state;
    const {
      zones, merchants, google, match: { params: { rcId } }, rcStatus,
    } = this.props;
    let coords = {};
    if (formData.coords) {
      const [lat, lng] = formData.coords.split(',');
      coords = { lat: +lat, lng: +lng };
    }
    this.initRCData(rcId);
    const blur = rcStatus !== 'ok' && rcId ? 'blur' : '';
    return (
      <Wrapper>
        <HasPermission edit={['rc_add', 'rc_edit']} redirect>

          <HeadersMeta page={` ${rcId ? 'Edit' : 'Add'} Station`} />
          <Form
            onSubmit={this.handleAddRC}
            className="addBlockForm"
            ref={(ref) => this.form = ref}
            id={rcId}
            onChange={this.handleFormChange}
          >
            <div className="addBlock">
              <div className="col">
                <Input
                  label="Name *"
                  value={formData.name || ''}
                  onChange={(ev) => this.handleChange('name', ev.target.value)}
                  className={blur}
                  invalid={emptyFields.includes('name')}
                />
                <Select
                  options={zones}
                  label="Zone *"
                  getOptionLabel={(o) => o.name}
                  getOptionValue={(o) => o.id}
                  value={zones.find((z) => z.id === formData.zone_id)}
                  onChange={(val) => this.handleChange('zone_id', val.id)}
                  className={blur}
                  invalid={emptyFields.includes('zone_id')}
                />
              </div>
              <div className="col">
                {/* <Select */}
                {/*  options={merchants} */}
                {/*  label="Merchant *" */}
                {/*  getOptionLabel={(o) => o.name} */}
                {/*  getOptionValue={(o) => o.org_id} */}
                {/*  value={merchants.find((z) => z.org_id === formData.org_id)} */}
                {/*  onChange={(val) => this.handleChange('org_id', val.org_id)} */}
                {/*  className={blur} */}
                {/*  invalid={emptyFields.includes('org_id')} */}
                {/* /> */}
                <Input
                  label="Location *"
                  value={formData.coords || ''}
                  placeholder="Lat, Lng"
                  onChange={(ev) => this.handleChange('coords', ev.target.value)}
                  className={blur}
                  invalid={emptyFields.includes('coords')}
                />
              </div>
            </div>
            <div className="addBlock">
              {google
                ? (
                  <Autocomplete
                    google={google}
                    onSelect={this.handleSelect}
                    value={formData.address}
                    onChange={this.removeFieldValue}
                    invalid={emptyFields.includes('address')}
                  />
                )
                : null}
            </div>
            <div className="addBlock">
              <div className="col">
                <Input
                  label="Contact Person"
                  value={formData.contact_person || ''}
                  onChange={(ev) => this.handleChange('contact_person', ev.target.value)}
                  className={blur}
                />

              </div>
              <div className="col">

                <Input
                  label="Contact Person Phone"
                  value={formData.contact_person_phone || ''}
                  onChange={(ev) => this.handleChange('contact_person_phone', ev.target.value, 'phone')}
                  className={blur}
                  onBlur={() => this.handleOnBlur('contact_person_phone', 'phone')}
                  invalid={errors.includes('contact_person_phone') ? 'Not valid phone.' : undefined}
                />
              </div>
            </div>
            <GoogleMapWrapper
              markerCoords={coords}
              onMarkerCoorsChange={this.handleChange}
              placeId={placeId}
              createView={!rcId}
            />
            <div className="actions">
              <Button title="Cancel" onClick={this.cancel} type="button" />
              <Button
                type="submit"
                title={rcId ? 'Save' : 'Create'}
                loading={loading}
                disabled={(rcId && !changed)}
              />
            </div>
          </Form>
        </HasPermission>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  zones: state.zones.zones,
  merchants: state.merchants.merchants,
  google: state.app.google,
  rcStatus: state.rcs.rcStatus,
});

const mapDispatchToProps = {
  getZonesRequest,
  getMerchantsRequest,
  createRCRequest,
  getRCByIdRequest,
  updateRCRequest,
  setCurrentItemName,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RCSForm);

export default Container;
