import { GET_STOREFRONTS, GET_STOREFRONT_BY_ID } from '../actions/storefronts';

const initialState = {
  storefronts: [],
  storefrontsStatus: '',
  storefront: {},
  storefrontStatus: '',
  totalPages: 0,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_STOREFRONTS.REQUEST: {
      return {
        ...state,
        storefrontsStatus: 'request',
      };
    }
    case GET_STOREFRONTS.SUCCESS: {
      const { data: { data: { Records, RecordsCount } } } = action.payload;
      return {
        ...state,
        storefronts: Records,
        storefrontsStatus: 'ok',
        totalPages: Math.ceil(RecordsCount / 10),
      };
    }
    case GET_STOREFRONTS.FAIL: {
      return {
        ...state,
        storefrontsStatus: 'fail',
      };
    }
    case GET_STOREFRONT_BY_ID.REQUEST: {
      return {
        ...state,
        storefrontStatus: 'request',
      };
    }
    case GET_STOREFRONT_BY_ID.SUCCESS: {
      const { data: { data } } = action.payload;
      return {
        ...state,
        storefront: data,
        storefrontStatus: 'ok',
      };
    }
    case GET_STOREFRONT_BY_ID.FAIL: {
      return {
        ...state,
        storefrontStatus: 'fail',
      };
    }

    default: {
      return state;
    }
  }
}
