import {
  GET_ROBOMARTS, GET_ROBOMART_BY_ID, GET_INVENTORY, GET_INVENTORY_STOCK_LIST,
} from '../actions/robomarts';

const initialState = {
  robomarts: [],
  robomartsStatus: '',
  robomart: {},
  robomartStatus: '',
  inventory: {},
  inventoryStatus: '',
  inventoryStock: [],
  inventoryStockStatus: '',
  totalPages: 1,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ROBOMARTS.REQUEST: {
      return {
        ...state,
        robomartsStatus: 'request',
      };
    }
    case GET_ROBOMARTS.SUCCESS: {
      const { data: { data: { Records, RecordsCount } } } = action.payload;
      return {
        ...state,
        robomartsStatus: 'ok',
        robomarts: Records,
        totalPages: Math.ceil(RecordsCount / 10),
      };
    }
    case GET_ROBOMARTS.FAIL: {
      return {
        ...state,
        robomartsStatus: 'fail',
      };
    }
    case GET_ROBOMART_BY_ID.REQUEST: {
      return {
        ...state,
        robomartStatus: 'request',
      };
    }
    case GET_ROBOMART_BY_ID.SUCCESS: {
      return {
        ...state,
        robomartStatus: 'ok',
      };
    }
    case GET_ROBOMART_BY_ID.FAIL: {
      return {
        ...state,
        robomartStatus: 'fail',
      };
    }
    case GET_INVENTORY.REQUEST: {
      return {
        ...state,
        inventoryStatus: 'request',
      };
    }
    case GET_INVENTORY.SUCCESS: {
      return {
        ...state,
        inventoryStatus: 'ok',
        inventory: action.payload.data.data,
      };
    }
    case GET_INVENTORY.FAIL: {
      return {
        ...state,
        inventoryStatus: 'fail',
      };
    }
    case GET_INVENTORY_STOCK_LIST.REQUEST: {
      return {
        ...state,
        inventoryStockStatus: 'request',
        inventoryStock: [],
      };
    }
    case GET_INVENTORY_STOCK_LIST.SUCCESS: {
      return {
        ...state,
        inventoryStockStatus: 'ok',
        inventoryStock: action.payload.data.data.products,
      };
    }
    case GET_INVENTORY_STOCK_LIST.FAIL: {
      return {
        ...state,
        inventoryStockStatus: 'fail',
      };
    }
    default: {
      return state;
    }
  }
}
