import React, { Component } from 'react';
import { CircularProgress } from 'rmwc';

class Loader extends Component {
  render() {
    return (
      <div className="loader">
        <CircularProgress size="large" />
      </div>
    );
  }
}

export default Loader;
