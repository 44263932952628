import React, { Component } from 'react';
import {
  DataTable as Table,
  DataTableContent,
  DataTableHead,
  DataTableRow,
  DataTableBody,
  DataTableCell,
  Icon,
} from 'rmwc';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import moment from 'moment';
import Tooltip from 'rc-tooltip';
import Input from './form/Input';
import Utils from '../helpers/Utils';
import Datepicker from './form/Datepicker';
import Select from './form/Select';

class DataTable extends Component {
  static propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    onEdit: PropTypes.func,
    onCustomFieldClick: PropTypes.func,
    onTag: PropTypes.func,
    onInfo: PropTypes.func,
    onMenu: PropTypes.func,
    onDelete: PropTypes.func,
    onInventory: PropTypes.func,
    actions: PropTypes.array,
    loading: PropTypes.bool,
    idKey: PropTypes.string,
    customField: PropTypes.string,
    nameKey: PropTypes.string,
    filters: PropTypes.bool,
    stateType: PropTypes.string,
    state: PropTypes.string,
    options: PropTypes.array,
    className: PropTypes.any,
    disabled: PropTypes.any,
    modal: PropTypes.bool,
  };

  static defaultProps = {
    actions: [],
    options: [],
    onEdit: undefined,
    onDelete: undefined,
    onInventory: undefined,
    onCustomFieldClick: undefined,
    onMenu: undefined,
    onTag: undefined,
    onInfo: undefined,
    loading: false,
    filters: true,
    idKey: '',
    customField: '',
    nameKey: '',
    stateType: '',
    state: '',
    className: '',
    disabled: undefined,
    modal: false,
  }

  handleSearch = (key, val) => {
    const query = Utils.queryParse();
    if (key === 'created' && val === 'Invalid date') {
      query[key] = '';
    } else {
      query[key] = val;
    }
    delete query.page;
    this.props.history.replace(`?${Utils.queryStringify(query)}`);
  }

  handleSort = (predicate) => {
    const { filters } = this.props;
    if (!filters) {
      return;
    }
    const query = Utils.queryParse();
    query.predicate = predicate;
    query.reverse = query.reverse !== 'true';
    delete query.page;
    this.props.history.replace(`?${Utils.queryStringify(query)}`);
  }

  getActionIcon = (icon, id, name, disabled, modal) => ({
    edit: <Link to={!modal && this.props.onEdit && this.props.onEdit(id, name)}>
      <Icon
        icon={{ icon: 'border_color', size: 'xsmall' }}
        className="primary"
        key={`edit_${id}`}
        onClick={() => (modal ? this.props.onEdit(id, name) : undefined)}
      />
    </Link>,
    delete: <Icon
      icon={{ icon: 'delete', size: 'xsmall' }}
      onClick={() => this.props.onDelete(id)}
      className={`primary ${disabled === id ? 'disabled' : ''}`}
      key={`delete_${id}`}
      disabled={disabled}
    />,
    info: <Link to={this.props.onInfo && this.props.onInfo(id, name)}>
      <Icon
        icon={{ icon: 'info', size: 'xsmall' }}
        className="primary"
        key={`info_${id}`}
      />
    </Link>,
    inventory: <Link to={this.props.onInventory && this.props.onInventory(id, name)}>
      <Icon
        icon={{ icon: 'inventory', size: 'xsmall' }}
        className="primary"
        key={`info_${id}`}
      />
    </Link>,
    tags: <Link to={this.props.onTag && this.props.onTag(id, name)}>
      {' '}
      <Icon
        icon={{ icon: 'local_offer', size: 'xsmall' }}
        className="primary"
        key={`info_${id}`}
      />
    </Link>,
    menu: <Link to={this.props.onMenu && this.props.onMenu(id, name)}>
      <Icon
        icon={{ icon: 'list', size: 'medium' }}
        className="primary"
        key={`info_${id}`}
      />
    </Link>,
  })[icon]

  getValue = (c, val, i) => {
    if (val.invite_link !== '') {
    }
    const {
      stateType, data, options, state,
    } = this.props;
    if (c.isProductLink) {
      return (
        <DataTableCell key={c.column}>
          <Link to={`/admin/products/edit/${val.product_id}`}>
            {val.name}
          </Link>
        </DataTableCell>
      );
    }
    if (c.isCreditLink) {
      return (
        <DataTableCell key={c.column}>
          <Link to={`/admin/consumers/orders/${val.consumer_id}`}>
            {val.receiver}
          </Link>
        </DataTableCell>
      );
    }
    if (c.isInviteLink) {
      return (
        <DataTableCell key={c.column}>
          <Link to={`/admin/consumers/invite-link/${val.id}?dynamicLink=${val.invite_link}`}>
            {val.invite_link}
          </Link>
        </DataTableCell>
      );
    }
    if (['active', 'mode'].includes(c.column)) {
      return (
        <DataTableCell key={c.column}>
          {val[c.column]
            ? <Icon icon="circle" className="active" />
            : <Icon icon="radio_button_unchecked" />}
        </DataTableCell>
      );
    }
    if (c.column === 'vehicle_state') {
      return (
        <DataTableCell key={c.column}>
          {+val[c.column] === 1
            ? <Icon icon="circle" className="active" />
            : <Icon icon="radio_button_unchecked" />}
        </DataTableCell>
      );
    }
    if (['driver', 'scanner', 'sms', 'push'].includes(c.column)) {
      return (
        <DataTableCell key={c.column}>
          {val[c.column]
            ? <Icon icon="check_box" className="active" />
            : <Icon icon="check_box_outline_blank" />}
        </DataTableCell>
      );
    }
    if (c.column === 'state' && stateType !== 'list' && state !== 'administrative') {
      return (
        <DataTableCell key={c.column}>
          {val[c.column] === 1 || val[c.column] === true
            ? <Icon icon="circle" className="active" />
            : <Icon icon="radio_button_unchecked" />}
        </DataTableCell>
      );
    }
    if (c.column === 'state' && stateType === 'list' && state !== 'administrative') {
      return <DataTableCell key={c.column}>{options.find((o) => o.value === data[i].state)?.label}</DataTableCell>;
    }

    if (c.isNumber) {
      return <DataTableCell key={c.column}>{val[c.column] || 0}</DataTableCell>;
    }
    if (c.isDate) {
      return (
        <DataTableCell key={c.column}>
          {val[c.column]
            ? moment(+val[c.column]).format('DD-MMM-YYYY HH:mm:ss') : ''}
        </DataTableCell>
      );
    }
    return <DataTableCell key={c.column}>{val[c.column] || '-'}</DataTableCell>;
  }

  getTitle = (column) => {
    if (column === 'sms') {
      return 'SMS';
    }
    if (column === 'applicant_seeder') {
      return 'Applicant/Seeder';
    }
    return _.startCase(column);
  }

  render() {
    const {
      columns, data, actions, loading, idKey, customField, nameKey, filters, options, disabled, className, page, modal,
    } = this.props;
    const query = Utils.queryParse();
    return (
      <div className={`dataTable ${className}`}>
        <Table
          stickyRows="1"
          // stickyColumns="1"
          ref={(ref) => {
            this.table = ref;
          }}
        >
          <DataTableContent>
            <DataTableHead>
              <DataTableRow>
                {columns.map((v) => (
                  <DataTableCell
                    key={v.column}
                    className={`${v.column} ${(page === 'applicants' || page === 'inviteCodes') && v.column === 'state' ? 'selectable' : ''}`}
                  >
                    <p onClick={() => this.handleSort(v.column)}>
                      {this.getTitle(v.column)}
                      {query.predicate === v.column && query.reverse === 'true'
                        ? <Icon icon="arrow_drop_up" /> : null}
                      {query.predicate === v.column && query.reverse === 'false'
                        ? <Icon icon="arrow_drop_down" /> : null}
                    </p>
                  </DataTableCell>
                ))}
                {customField ? <DataTableCell>{customField}</DataTableCell> : null}
                {!_.isEmpty(actions) ? <DataTableCell>Actions</DataTableCell> : null}
              </DataTableRow>
            </DataTableHead>
            {!loading ? (
              <DataTableBody>
                <DataTableRow>
                  {filters ? columns.map((c) => {
                    if (c.type === 'selectable') {
                      return (
                        <DataTableCell key={c.column}>
                          <Select
                            placeholder="New"
                            options={options}
                            value={options.find((o) => o.value === +query[c.column] || !query[c.column])}
                            onChange={(val) => this.handleSearch(c.column, val.value)}
                            isSearchable={false}
                            className="tableSelect"
                            menuPortalTarget={this.table}
                          />
                        </DataTableCell>
                      );
                    }
                    if (c.type === 'searchable') {
                      return (
                        <DataTableCell key={c.column}>
                          <Input
                            placeholder="Search"
                            onChange={(ev) => this.handleSearch(c.column, ev.target.value)}
                            value={query[c.column] || ''}
                          />
                        </DataTableCell>
                      );
                    }
                    if (c.type === 'date') {
                      return (
                        <DataTableCell key={c.column} className="datepicker">
                          <Datepicker
                            onChange={(ev) => this.handleSearch(c.column, moment(ev).format('YYYY-MM-DD'))}
                            selected={query.created ? moment(query.created, 'YYYY-MM-DD').toDate() : undefined}
                            placeholderText="YYYY-MM-DD"
                          />
                        </DataTableCell>
                      );
                    }
                    return <DataTableCell key={c.column} />;
                  }) : null}
                  {customField ? <DataTableCell /> : null}
                </DataTableRow>
                {data.map((v, i) => (
                  <DataTableRow key={i}>
                    {columns.map((c) => (
                      this.getValue(c, v, i)
                    ))}
                    {customField
                      ? (
                        <DataTableCell
                          className="customField"
                          onClick={() => this.props.onCustomFieldClick(v.id || v[idKey], v.name || v[nameKey])}
                        >
                          {customField}
                        </DataTableCell>
                      ) : null}
                    {!_.isEmpty(actions) ? (
                      <DataTableCell>
                        {actions.map((action) => (
                          <Tooltip
                            overlay={_.upperFirst(action)}
                            placement="top"
                            key={action}
                          >
                            {this.getActionIcon(action, v.id || v[idKey], v[nameKey] || v.name, disabled, modal)}
                          </Tooltip>
                        ))}
                      </DataTableCell>
                    ) : null}
                  </DataTableRow>
                ))}
              </DataTableBody>
            ) : null}
          </DataTableContent>
        </Table>

      </div>
    );
  }
}

export default withRouter(DataTable);
