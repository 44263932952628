import React, { Component } from 'react';
import { CircularProgress } from 'rmwc';
import memoizeOne from 'memoize-one';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import HeadersMeta from '../../components/HeadersMeta';
import DataTable from '../../components/DataTable';
import Wrapper from '../../components/Wrapper';
import { getInviteCodesRequest } from '../../store/actions/inviteCodes';
import Utils from '../../helpers/Utils';
import Pagination from '../../components/Pagination';
import Button from '../../components/form/Button';
import GenCode from '../../components/modals/GenCode';
import EditInviteCode from '../../components/modals/EditInviteCode';

class InviteCodes extends Component {
  getData = memoizeOne(async (query) => {
    this.setState({ currentLoading: true });
    const {
      // eslint-disable-next-line camelcase
      created, email, code, notes, state, applicant_seeder, owner,
      predicate, reverse,
    } = query;
    const predicateObject = {
      created_at: created,
      email,
      code,
      notes,
      state,
      owner,
      applicant_email: applicant_seeder,
    };
    const search = { predicateObject };
    const sort = { predicate: predicate || 'created_at', reverse: reverse || true };
    await this.props.getInviteCodesRequest(sort, search, (query.page - 1) || 0);
    this.setState({ currentLoading: false });
  }, _.isEqual);

  static propTypes = {
    getInviteCodesRequest: PropTypes.func.isRequired,
    inviteCodes: PropTypes.array.isRequired,
    totalPages: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentLoading: false,
      genCodeModal: false,
      editModal: false,
      codeId: null,
    };
    this.columns = [
      { column: 'created', type: 'date', isDate: true },
      { column: 'code', type: 'searchable' },
      { column: 'state', type: 'selectable' },
      { column: 'applicant_seeder', type: 'searchable' },
      { column: 'owner', type: 'searchable' },
      { column: 'notes', type: 'searchable' },
    ];
    this.keysToChange = {
      created_at: 'created',
      applicant_email: 'applicant_seeder',
    };
    this.states = [
      { value: '', label: 'All' },
      { value: 0, label: 'New' },
      { value: 1, label: 'Used' },
      { value: 2, label: 'Expired' },
    ];
  }

  toggleGenCodeModal = () => {
    const { genCodeModal } = this.state;
    this.setState({ genCodeModal: !genCodeModal });
  }

  toggleEditModal = (codeId) => {
    const { editModal } = this.state;
    this.setState({ editModal: !editModal, codeId });
  }

  render() {
    const {
      currentLoading, genCodeModal, editModal, codeId,
    } = this.state;
    const { inviteCodes, totalPages } = this.props;
    const query = Utils.queryParse(window.location.search, this.keysToChange);
    const data = Utils.renameKeys(this.keysToChange, inviteCodes);
    this.getData(query);
    return (
      <Wrapper>
        <HeadersMeta page="Invite Codes" />

        <div className="zones">
          <div className="top">
            <div className="loading">
              {currentLoading ? <CircularProgress size="small" /> : null}
            </div>
            <Button title="Gen. Code" onClick={this.toggleGenCodeModal} />
          </div>
          <div>
            <DataTable
              columns={this.columns}
              data={data}
              actions={['edit']}
              onEdit={this.toggleEditModal}
              modal
              stateType="list"
              options={this.states}
              page="inviteCodes"
            />
          </div>
          <Pagination currentPage={query.page} totalPages={totalPages} />
        </div>
        <GenCode isOpen={genCodeModal} onCloseModal={this.toggleGenCodeModal} />
        <EditInviteCode
          isOpen={editModal}
          onCloseModal={this.toggleEditModal}
          code={inviteCodes.find((c) => c.id === codeId)}
        />
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  inviteCodes: state.inviteCodes.inviteCodes,
  totalPages: state.inviteCodes.totalPages,
});

const mapDispatchToProps = {
  getInviteCodesRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(InviteCodes);

export default Container;
