import { GET_ORDERS, GET_ORDER_OF_CONSUMER, GET_ORDER_RECEIPT } from '../actions/orders';
import Utils from '../../helpers/Utils';

const initialState = {
  orders: [],
  ordersStatus: '',
  totalPages: 1,
  orderReceipt: {},
  orderReceiptStatus: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ORDERS.REQUEST:
    case GET_ORDER_OF_CONSUMER.REQUEST: {
      return {
        ...state,
        ordersStatus: 'request',
      };
    }
    case GET_ORDERS.SUCCESS:
    case GET_ORDER_OF_CONSUMER.SUCCESS: {
      const { data: { data: { Records, RecordsCount } } } = action.payload;
      const orders = Records.map((o) => {
        o.total = Utils.formatPriceAmounts(o.total);
        o.credit_applied = Utils.formatPriceAmounts(o.credit_applied);
        o.stripe_charge_amount = Utils.formatPriceAmounts(o.stripe_charge_amount);
        return o;
      });
      return {
        ...state,
        orders,
        ordersStatus: 'ok',
        totalPages: Math.ceil(RecordsCount / 10),
      };
    }
    case GET_ORDERS.FAIL:
    case GET_ORDER_OF_CONSUMER.FAIL: {
      return {
        ...state,
        ordersStatus: 'fail',
      };
    }
    case GET_ORDER_RECEIPT.REQUEST: {
      return {
        ...state,
        orderReceiptStatus: 'request',
      };
    }
    case GET_ORDER_RECEIPT.SUCCESS: {
      const { data: { data } } = action.payload;
      return {
        ...state,
        orderReceipt: data,
        orderReceiptStatus: 'ok',
      };
    }
    case GET_ORDER_RECEIPT.FAIL: {
      return {
        ...state,
        orderReceiptStatus: 'fail',
      };
    }
    default: {
      return state;
    }
  }
}
