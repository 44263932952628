import axios from 'axios';
import Utils from './Utils';

class Address {
  static getAddress = async (pos) => {
    const reqData = {
      key: 'AIzaSyBYNmmGa09aANc-8HgPgOlgwHmJVQsyBEc',
      language: 'en',
      latlng: [pos.lat, pos.lng],
    };
    const { data: { results } } = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?${Utils.queryStringify(reqData)}`,
    );
    const address = results?.find((r) => r.types.includes('street_address') || r.types.includes('administrative_area_level_2'));
    const address2 = results[0];
    const data = address?.address_components?.map((a) => this.formatAddress(a));
    return [data, address2?.formatted_address];
  }

  // format address according to project
  static formatAddress = (address) => {
    if (address.types.includes('country')) {
      return {
        key: 'country',
        value: address.long_name,
      };
    }
    if (address.types.includes('administrative_area_level_1')) {
      return {
        key: 'state',
        value: address.long_name,
      };
    }
    if (address.types.includes('locality') || address.types.includes('administrative_area_level_2')) {
      return {
        key: 'city',
        value: address.long_name,
      };
    }
    return {};
  }
}

export default Address;
