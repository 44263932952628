const adminTabMenus = [
  {
    title: 'Zones',
    path: '/admin/zones',
    permissions: ['zone'],
  },
  {
    title: 'Stations',
    path: '/admin/stations',
    permissions: ['r_cs'],
  },
  {
    title: 'Merchants',
    path: '/admin/merchants',
    permissions: ['merchants'],
  },
  {
    title: 'Types',
    path: '/admin/types',
    permissions: ['robomarts'], // todo
  },
  {
    title: 'Storefronts',
    path: '/admin/storefronts',
    permissions: ['robomarts'], // todo
  },
  {
    title: 'Categories',
    path: '/admin/categories',
    permissions: ['products'],
  },
  {
    title: 'Products',
    path: '/admin/products',
    permissions: ['products'],
  },
  {
    title: 'Shops',
    path: '/admin/shops',
    permissions: ['robomarts'],
  },
  {
    title: 'Orders',
    path: '/admin/orders',
    permissions: ['robomarts'], // todo
  },
  {
    title: 'Consumers',
    path: '/admin/consumers',
    permissions: ['consumers'],
  },
  {
    title: 'Credits',
    path: '/admin/credits',
    permissions: ['consumers'], // todo
  },
  // {
  //   title: 'Coupons',
  //   path: '/admin/coupons',
  // },
  // {
  //   title: 'Applicants',
  //   path: '/admin/applicants',
  //   permissions: ['robomarts'], // todo
  // },
  // {
  //   title: 'Invite Codes',
  //   path: '/admin/invite-codes',
  //   permissions: ['robomarts'], // todo
  // },
  {
    title: 'Users',
    path: '/admin/users',
    permissions: ['users'],
  },
  {
    title: 'Groups',
    path: '/admin/groups',
    permissions: ['groups'],
  },
  {
    title: 'Drivers',
    path: '/admin/drivers',
    permissions: ['drivers'],
  },
  {
    title: 'Settings',
    path: '/admin/settings',
    permissions: ['settings'],
  },
];
export default adminTabMenus;
