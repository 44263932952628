import React, { Component } from 'react';
import Tooltip from 'rc-tooltip';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Checkbox } from 'rmwc';
import { toast } from 'react-toastify';
import memoizeOne from 'memoize-one';
import Wrapper from '../../components/Wrapper';
import HeadersMeta from '../../components/HeadersMeta';
import Input from '../../components/form/Input';
import Select from '../../components/form/Select';
import Button from '../../components/form/Button';
import { getMerchantsRequest } from '../../store/actions/merchants';
import { getRCsRequest } from '../../store/actions/rcs';
import { getGroupsRequest } from '../../store/actions/groups';
import { createUserRequest, getUserByIdRequest, updateUserRequest } from '../../store/actions/users';
import Validator from '../../helpers/Validator';
import Form from '../../components/form/Form';
import { setCurrentItemName } from '../../store/actions/app';
import HasPermission from '../../components/HasPermission';

class UserForm extends Component {
  initUserData = memoizeOne(async (id) => {
    if (!id) {
      return;
    }
    this.props.setCurrentItemName('');
    const { payload: { data: { data } } } = await this.props.getUserByIdRequest(id);
    if (data) {
      this.props.setCurrentItemName(data.display_name);
      data.groups = data.groups?.split(',').map((g) => +g);
      if (data.org_id === '00000000-0000-0000-0000-000000000000') {
        data.org_id = '';
      }
      if (data.rc_id === '00000000-0000-0000-0000-000000000000') {
        data.rc_id = '';
      }
      const predicateObject = { org_id: data.org_id };
      await this.props.getRCsRequest({ predicate: 'name', reverse: false }, { predicateObject }, 0, 100);
      this.setState({ formData: data });
    }
  })

  static propTypes = {
    getMerchantsRequest: PropTypes.func.isRequired,
    getGroupsRequest: PropTypes.func.isRequired,
    createUserRequest: PropTypes.func.isRequired,
    updateUserRequest: PropTypes.func.isRequired,
    merchants: PropTypes.array.isRequired,
    getRCsRequest: PropTypes.func.isRequired,
    rcs: PropTypes.array.isRequired,
    groups: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    getUserByIdRequest: PropTypes.func.isRequired,
    setCurrentItemName: PropTypes.func.isRequired,
    userStatus: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.mode = [
      { value: true, label: 'Online' },
      { value: false, label: 'Offline' },
    ];
    this.state = {
      formData: {
        display_name: '',
        email: '',
        phone: '',
        org_id: undefined,
        is_driver: false,
        title: '',
        password: '',
        is_active: false,
        rc_id: undefined,
        allow_scanner_app: false,
        groups: [],
      },
      loading: false,
      changed: false,
      errors: [],
      emptyFields: [],
    };
  }

  componentDidMount() {
    this.props.getMerchantsRequest({ predicate: 'name', reverse: false }, {}, 0, 100);
    this.props.getGroupsRequest({ predicate: 'name', reverse: false }, {}, 0, 100);
  }

  handleChange = (key, value, type) => {
    const { formData, errors } = this.state;
    let { emptyFields } = this.state;
    formData[key] = value;
    if (emptyFields.includes(key)) {
      emptyFields = emptyFields.filter((f) => f !== key);
    }
    if (errors.includes(key)) {
      this.handleOnBlur(key, type);
    }
    this.setState({ formData, emptyFields });
  }

  handleGroupChange = (id) => {
    const { formData } = this.state;
    let { formData: { groups }, emptyFields } = this.state;
    if (groups.includes(id)) {
      groups = groups.filter((g) => g !== id);
    } else {
      groups = [...groups, id];
    }
    if (emptyFields.includes('groups')) {
      emptyFields = emptyFields.filter((f) => f !== 'groups');
    }
    formData.groups = groups;
    this.setState({ formData, emptyFields });
  }

  handleGetRC = (orgId) => {
    const predicateObject = { org_id: orgId };
    this.props.getRCsRequest({ predicate: 'name', reverse: false }, { predicateObject }, 0, 100);
  }

  cancel = () => {
    this.props.history.push('/admin/users');
  }

  handleAddUser = async (ev) => {
    ev.preventDefault();
    const { formData } = this.state;
    const { match: { params: { userId } } } = this.props;
    let fields = ['display_name', 'email', 'groups'];
    if (!userId) {
      fields = [...fields, 'password'];
    }
    const emptyFields = Validator.getEmptyFields(formData, fields);
    this.setState({ loading: true });
    if (!_.isEmpty(emptyFields)) {
      this.setState({ emptyFields, loading: false });
      return;
    }
    const groups = formData.groups.join(',');
    if (!formData.org_id) {
      formData.allow_scanner_app = false;
      formData.is_driver = false;
      formData.rc_id = false;
    }

    let data;
    if (userId) {
      formData.user_id = userId;
      const { payload } = await this.props.updateUserRequest({ ...formData, groups });
      data = payload.data;
    } else {
      const { payload } = await this.props.createUserRequest({ ...formData, groups });
      data = payload.data;
    }
    if (data.success) {
      toast.success(`Successfully ${userId ? 'updated' : 'created'}.`);
      this.props.history.push('/admin/users');
    }
    if (data?.error?.includes('INVALID_PHONE_NUMBER')) {
      toast.error('Invalid phone number.');
    }
    if (data?.error?.includes('User already exists')) {
      toast.error('User already exists.');
    }
    if (data?.error) {
      toast.error('Something went wrong.');
    }
    this.setState({ loading: false });
  }

  handleOnBlur = (key, type) => {
    const { formData } = this.state;
    let { errors } = this.state;
    if (!_.isEmpty(formData[key]) && !Validator.validate(type, formData[key])) {
      errors = [...errors, key];
      this.setState({ errors });
    } else {
      this.setState({ errors: errors.filter((e) => e !== key) });
    }
  }

  handleFormChange = () => {
    const { changed } = this.state;
    if (!changed) {
      this.setState({ changed: true });
    }
  }

  render() {
    const {
      formData, loading, errors, changed, emptyFields,
    } = this.state;
    const {
      merchants, rcs, groups, match: { params: { userId } }, userStatus,
    } = this.props;
    this.initUserData(userId);
    const blur = userStatus !== 'ok' && userId ? 'blur' : '';
    return (
      <Wrapper>
        <HasPermission edit={['user_add', 'user_edit']} redirect>
          <HeadersMeta page={` ${userId ? 'Edit' : 'Add'} User`} />
          <Form
            onSubmit={this.handleAddUser}
            className="addBlockForm userForm"
            ref={(ref) => this.form = ref}
            id={userId}
            onChange={this.handleFormChange}
          >
            <div className="addBlock">
              <div className="col">
                <Input
                  label="Name *"
                  value={formData.display_name || ''}
                  onChange={(ev) => this.handleChange('display_name', ev.target.value)}
                  className={blur}
                  invalid={emptyFields.includes('display_name')}
                />
                <Input
                  label="Title"

                  value={formData.title || ''}
                  name="title"
                  onChange={(ev) => this.handleChange('title', ev.target.value)}
                  className={blur}
                />
                <Input
                  label="Email *"
                  value={formData.email || ''}
                  name="email"
                  onChange={(ev) => this.handleChange('email', ev.target.value, 'email')}
                  className={blur}
                  disabled={userId}
                  onBlur={() => this.handleOnBlur('email', 'email')}
                  invalid={(errors.includes('email') ? 'Not valid email address.' : undefined) || emptyFields.includes('email')}
                />
                {!userId ? (
                  <Input
                    label="Password *"
                    type="password"
                    name={`p_${new Date().getTime()}`}
                    value={formData.password || ''}
                    onChange={(ev) => this.handleChange('password', ev.target.value)}
                    className={blur}
                    invalid={emptyFields.includes('password')}
                  />
                ) : null}
                <Input
                  label="Phone"
                  value={formData.phone || ''}
                  onChange={(ev) => this.handleChange('phone', ev.target.value, 'phone')}
                  className={blur}
                  type="numeric"
                  min="1"
                  onBlur={() => this.handleOnBlur('phone', 'phone')}
                  invalid={errors.includes('phone') ? 'Not valid phone.' : undefined}
                />

              </div>
              <div className="col">
                <Select
                  options={this.mode}
                  label="Mode *"
                  value={this.mode.find((m) => m.value === formData.is_active)}
                  onChange={(val) => this.handleChange('is_active', val.value)}
                  className={blur}
                />
                <Select
                  options={[{ name: '(empty)', org_id: '' }, ...merchants]}
                  label="Merchant"
                  getOptionValue={(o) => o.org_id}
                  getOptionLabel={(o) => o.name}
                  value={merchants.find((s) => formData.org_id === s.org_id)}
                  onChange={(val) => {
                    this.handleChange('org_id', val.org_id);
                    this.handleGetRC(val.org_id);
                  }}
                  className={blur}
                />

                <Tooltip
                  overlay={!formData.org_id ? 'Select the merchant' : 'This merchant does not have any station.'}
                  placement="top"
                  overlayClassName={!_.isEmpty(rcs) && formData.org_id ? 'hide' : ''}
                >
                  <span>
                    <Select
                      options={[{ store_id: '', name: '(empty)' }, ...rcs]}
                      label="Station"
                      getOptionValue={(o) => o.store_id}
                      getOptionLabel={(o) => o.name}
                      value={rcs.find((s) => formData.rc_id === s.store_id)}
                      onChange={(val) => this.handleChange('rc_id', val.store_id)}
                      isDisabled={_.isEmpty(rcs) || !formData.org_id}
                      className={blur}
                    />
                  </span>
                </Tooltip>
                <div className="checkboxes">
                  <Checkbox
                    label="Allow Driver App"
                    checked={formData.is_driver}
                    disabled={!(formData.org_id && formData.rc_id)}
                    onChange={() => {
                      if (formData.is_driver) {
                        this.handleChange('is_driver', false);
                      } else {
                        this.handleChange('is_driver', true);
                      }
                    }}
                    className={blur}
                  />
                  <Checkbox
                    label="Allow Scanner App"
                    checked={formData.allow_scanner_app}
                    onChange={() => {
                      if (formData.allow_scanner_app) {
                        this.handleChange('allow_scanner_app', false);
                      } else {
                        this.handleChange('allow_scanner_app', true);
                      }
                    }}
                    className={blur}
                    disabled={!(formData.org_id && formData.rc_id)}
                  />
                </div>
              </div>
            </div>
            <div className={`groups  ${emptyFields.includes('groups') ? 'invalid' : ''}`}>
              <p className="title">Groups *</p>
              <ul>
                {groups.map((group) => (
                  <li key={group.id}>
                    <Checkbox
                      label={group.name}
                      checked={formData.groups.includes(group.id)}
                      onChange={() => this.handleGroupChange(group.id)}
                      className={blur}
                    />
                  </li>
                ))}

              </ul>
            </div>
            <div className="actions">
              <Button title="Cancel" onClick={this.cancel} type="button" />
              <Button
                type="submit"
                title={userId ? 'Save' : 'Create'}
                loading={loading}
                disabled={(userStatus !== 'ok' && userId)
                  || (userId && !changed)}
              />
            </div>
          </Form>
        </HasPermission>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  merchants: state.merchants.merchants,
  rcs: state.rcs.rcs,
  groups: state.groups.groups,
  userStatus: state.users.userStatus,
});

const mapDispatchToProps = {
  getMerchantsRequest,
  getRCsRequest,
  getGroupsRequest,
  createUserRequest,
  updateUserRequest,
  getUserByIdRequest,
  setCurrentItemName,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserForm);

export default Container;
