import { define } from '../../helpers/redux-request';
import Api from '../../Api';

export const GET_CATEGORIES = define('GET_CATEGORIES');

export function getCategoriesRequest(sort, search, page, number) {
  return GET_CATEGORIES.request(() => Api.getCategories(sort, search, page, number)).takeLatest();
}

export const GET_ACTIVE_CATEGORIES = define('GET_ACTIVE_CATEGORIES');

export function getActiveCategoriesRequest(sort, search, page, number) {
  return GET_ACTIVE_CATEGORIES.request(() => Api.getActiveCategories(sort, search, page, number)).takeLatest();
}

export const CREATE_CATEGORY = define('CREATE_CATEGORY');

export function createCategoryRequest(obj) {
  return CREATE_CATEGORY.request(() => Api.createCategory(obj));
}

export const UPDATE_CATEGORY = define('UPDATE_CATEGORY');

export function updateCategoryRequest(obj) {
  return UPDATE_CATEGORY.request(() => Api.updateCategory(obj));
}

export const GET_CATEGORY_BY_ID = define('GET_CATEGORY_BY_ID');

export function getCategoryByIdRequest(id) {
  return GET_CATEGORY_BY_ID.request(() => Api.getCategoryById(id));
}

export const DELETE_CATEGORY = define('DELETE_CATEGORY');

export function deleteCategoryRequest(id) {
  return DELETE_CATEGORY.request(() => Api.deleteCategory(id));
}
