import { define } from '../../helpers/redux-request';
import Api from '../../Api';

export const GET_ZONES = define('GET_ZONES');

export function getZonesRequest(sort, search, page, number) {
  return GET_ZONES.request(() => Api.getZones(sort, search, page, number)).takeLatest();
}

export const CREATE_ZONE = define('CREATE_ZONE');

export function createZoneRequest(obj) {
  return CREATE_ZONE.request(() => Api.createZone(obj));
}

export const GET_ZONE_BY_ID = define('GET_ZONE_BY_ID');

export function getZoneByIdRequest(id) {
  return GET_ZONE_BY_ID.request(() => Api.getZoneById(id));
}

export const UPDATE_ZONE = define('UPDATE_ZONE');

export function updateZoneRequest(obj) {
  return UPDATE_ZONE.request(() => Api.updateZone(obj));
}
