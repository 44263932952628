import { GET_SETTINGS } from '../actions/settings';

const initialState = {
  settings: {},
  settingsStatus: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_SETTINGS.REQUEST: {
      return {
        ...state,
        settingsStatus: 'request',
      };
    }
    case GET_SETTINGS.SUCCESS: {
      const { data: { data } } = action.payload;
      return {
        ...state,
        settings: data,
        settingsStatus: 'ok',
      };
    }
    case GET_SETTINGS.FAIL: {
      return {
        ...state,
        settingsStatus: 'fail',
      };
    }
    default: {
      return state;
    }
  }
}
