import React, { Component } from 'react';
import { CircularProgress } from 'rmwc';
import { connect } from 'react-redux';
import memoizeOne from 'memoize-one';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Wrapper from '../../components/Wrapper';
import HeadersMeta from '../../components/HeadersMeta';
import DataTable from '../../components/DataTable';
import { assignVehicleRequest, getDriversRequest, getUnassignedVehiclesRequest } from '../../store/actions/users';
import Utils from '../../helpers/Utils';
import AssignModal from '../../components/modals/AssignModal';
import Pagination from '../../components/Pagination';
import HasPermission from '../../components/HasPermission';

class Drivers extends Component {
  getData = memoizeOne(async (query) => {
    this.setState({ currentLoading: true });
    const {
      // eslint-disable-next-line camelcase
      name, title, email, phone, vehicle, registration_no, predicate, reverse,
    } = query;
    const predicateObject = {
      display_name: name,
      title,
      email,
      phone,
      vehicle_name: vehicle,
      registration_no,
    };
    const search = { predicateObject };
    const sort = { predicate: predicate || 'display_name', reverse: reverse || false };
    await this.props.getDriversRequest(sort, search, (query.page - 1) || 0);
    this.setState({ currentLoading: false });
  }, _.isEqual);

  static propTypes = {
    drivers: PropTypes.array.isRequired,
    getDriversRequest: PropTypes.func.isRequired,
    getUnassignedVehiclesRequest: PropTypes.func.isRequired,
    assignVehicleRequest: PropTypes.func.isRequired,
    unassignedVehicles: PropTypes.array.isRequired,
    totalPagesDrivers: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentLoading: false,
      assignModal: '',
      driverId: '',
      loading: '',
    };
    this.columns = [
      { column: 'name', type: 'searchable' },
      { column: 'title', type: 'searchable' },
      { column: 'email', type: 'searchable' },
      { column: 'phone', type: 'searchable' },
      { column: 'driver', type: '' },
      { column: 'mode', type: '' },
      { column: 'vehicle', type: 'searchable' },
      { column: 'registration_no', type: 'searchable' },
    ];
    this.keysToChange = {
      display_name: 'name',
      is_active: 'mode',
      user_id: 'id',
      is_driver: 'driver',
      vehicle_name: 'vehicle',
    };
  }

  toggleAssignModal = (id, name) => {
    const { assignModal } = this.state;
    if (id) {
      this.props.getUnassignedVehiclesRequest(id);
    }
    if (!assignModal) {
      this.setState({ assignModal: name, driverId: id });
    } else {
      this.setState({ assignModal: '', driverId: '' });
    }
  }

  handleAssign = async (id) => {
    const { driverId } = this.state;
    this.setState({ loading: id ? 'assign' : 'unassign' });
    const data = { user_id: driverId, vehicle_id: id };
    await this.props.assignVehicleRequest(data);
    toast.success(`Successfully ${id ? 'assigned' : 'unassigned'}.`);
    const query = Utils.queryParse(window.location.search, this.keysToChange);
    query.assigned = driverId;
    this.getData(query);
    this.setState({ loading: '' });
  }

  render() {
    const { currentLoading, assignModal, loading } = this.state;
    const { drivers, unassignedVehicles, totalPagesDrivers } = this.props;
    const query = Utils.queryParse(window.location.search, this.keysToChange);
    this.getData(query);

    const data = Utils.renameKeys(this.keysToChange, drivers);
    return (
      <Wrapper>
        <HasPermission edit="drivers" redirect>
          <HeadersMeta page="Drivers" />
          <div className="zones">
            <div className="top">
              <div className="loading">
                {currentLoading ? <CircularProgress size="small" /> : null}
              </div>
            </div>
            <div>
              <DataTable
                columns={this.columns}
                data={data}
                customField="(Un)assign"
                onCustomFieldClick={this.toggleAssignModal}
              />
            </div>
            <Pagination currentPage={query.page} totalPages={totalPagesDrivers} />
          </div>
          <AssignModal
            isOpen={assignModal}
            title={`Assign Vehicle to ${assignModal}`}
            onCloseModal={this.toggleAssignModal}
            vehicles={unassignedVehicles}
            loading={loading}
            onAssign={this.handleAssign}
          />
        </HasPermission>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  drivers: state.users.drivers,
  unassignedVehicles: state.users.unassignedVehicles,
  totalPagesDrivers: state.users.totalPagesDrivers,
});

const mapDispatchToProps = {
  getDriversRequest,
  getUnassignedVehiclesRequest,
  assignVehicleRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Drivers);

export default Container;
