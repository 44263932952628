import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { getProductsByStorefrontRequest } from '../../store/actions/product';
import DataTable from '../DataTable';
import Confirmation from '../modals/Confirmation';
import { deleteProductRequest } from '../../store/actions/types';
import { getRobomartTypeByIdRequest } from '../../store/actions/robomartTypes';
import { setCurrentItemName } from '../../store/actions/app';
import Utils from '../../helpers/Utils';

class ProductsList extends Component {
  static propTypes = {
    getProductsByStorefrontRequest: PropTypes.func.isRequired,
    deleteProductRequest: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    productsByType: PropTypes.array.isRequired,
    getRobomartTypeByIdRequest: PropTypes.func.isRequired,
    setCurrentItemName: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.columns = [
      { column: 'code', type: 'searchable' },
      { column: 'name', type: 'searchable', isProductLink: true },
      { column: 'price', type: '' },
    ];
    this.state = {
      confirmationModal: false,
      productId: null,
      loading: null,
    };
  }

  async componentDidMount() {
    const { match: { params, params: { storefrontId } } } = this.props;
    this.props.setCurrentItemName('');
    this.props.getProductsByStorefrontRequest(storefrontId);
    const { payload: { data: { data } = {} } } = await this.props.getRobomartTypeByIdRequest(storefrontId);
    this.props.setCurrentItemName(data?.name);
  }

  toggleConfirmationModal = (productId) => {
    const { confirmationModal } = this.state;
    this.setState({ confirmationModal: !confirmationModal, productId });
  }

  handleDelete = async () => {
    const { productId } = this.state;
    const { match: { params: { storefrontId } }, productsByType } = this.props;
    this.setState({ loading: true });
    const product = productsByType?.find((p) => p.id === productId);
    await this.props.deleteProductRequest(product);
    toast.success('Successfully Deleted');
    this.props.getProductsByStorefrontRequest(storefrontId);
    this.setState({ loading: false, confirmationModal: false });
  }

  render() {
    let { productsByType } = this.props;
    const { confirmationModal, loading } = this.state;
    const query = Utils.queryParse();
    if (query.predicate === 'name') {
      productsByType = _.orderBy(productsByType, 'name', query.reverse === 'true' ? 'desc' : 'asc');
    }
    if (query.predicate === 'code') {
      productsByType = _.orderBy(productsByType, 'code', query.reverse === 'true' ? 'desc' : 'asc');
    }
    if (query.predicate === 'price') {
      productsByType = _.orderBy(productsByType, 'price', query.reverse === 'true' ? 'desc' : 'asc');
    }
    if (query.code) {
      productsByType = productsByType?.filter((p) => p.code.toLowerCase().includes(query.code.toLowerCase()));
    }
    if (query.name) {
      productsByType = productsByType?.filter((p) => p.name.toLowerCase().includes(query.name.toLowerCase()));
    }
    return (
      <div className="allTagsList">
        <DataTable
          columns={this.columns}
          data={productsByType}
          actions={['delete']}
          // filters={false}
          onDelete={this.toggleConfirmationModal}
        />
        <Confirmation
          isOpen={confirmationModal}
          title="Are you sure, you want to delete this product?"
          onCloseModal={() => this.toggleConfirmationModal(null)}
          onConfirm={this.handleDelete}
          loading={loading}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  productsByType: state.product.productsByType,
  productsByTypeStatus: state.product.productsByTypeStatus,
});

const mapDispatchToProps = {
  getProductsByStorefrontRequest,
  deleteProductRequest,
  getRobomartTypeByIdRequest,
  setCurrentItemName,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductsList);

export default withRouter(Container);
