import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { Provider } from 'react-redux';
import store from './store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './helpers/styles';
import Theme from './components/Theme';

Modal.setAppElement(document.getElementById('root'));

ReactDOM.render((
  <Provider store={store}>
    <React.StrictMode>
      <Theme>
        <App />
      </Theme>
    </React.StrictMode>
  </Provider>
), document.getElementById('root'));

reportWebVitals();
