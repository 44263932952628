import { GET_CREDITS, GET_CREDITS_ALL_TAGS, GET_CONTACTS_BY_TAG } from '../actions/credits';
import Utils from '../../helpers/Utils';

const initialState = {
  credits: [],
  creditsStatus: '',
  totalPages: 1,
  creditsAllTags: [],
  creditsAllTagsStatus: '',
  contactsByTag: [],
  contactsByTagStatus: '',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_CREDITS.REQUEST: {
      return {
        ...state,
        creditsStatus: 'request',
      };
    }
    case GET_CREDITS.SUCCESS: {
      const { data: { data: { Records, RecordsCount } } } = action.payload;
      const credits = Records.map((r) => {
        r._credit_type = r.type === 1 ? 'Manual' : 'Invite';
        r.amount = Utils.formatPriceAmounts(r.amount);
        r.consumer_name = r.consumer_name || '(none)';
        return r;
      });
      return {
        ...state,
        credits,
        creditsStatus: 'ok',
        totalPages: Math.ceil(RecordsCount / 10),
      };
    }
    case GET_CREDITS.FAIL: {
      return {
        ...state,
        creditsStatus: 'fail',
      };
    }
    case GET_CREDITS_ALL_TAGS.REQUEST: {
      return {
        ...state,
        creditsAllTagsStatus: 'request',
      };
    }
    case GET_CREDITS_ALL_TAGS.SUCCESS: {
      const { data: { data } } = action.payload;
      return {
        ...state,
        creditsAllTags: data,
        creditsAllTagsStatus: 'ok',
      };
    }
    case GET_CREDITS_ALL_TAGS.FAIL: {
      return {
        ...state,
        creditsAllTagsStatus: 'fail',
        creditsAllTags: [],
      };
    }
    case GET_CONTACTS_BY_TAG.REQUEST: {
      return {
        ...state,
        contactsByTagStatus: 'request',
        contactsByTag: [],
      };
    }
    case GET_CONTACTS_BY_TAG.SUCCESS: {
      const { data: { data } } = action.payload;
      return {
        ...state,
        contactsByTag: data,
        contactsByTagStatus: 'ok',
      };
    }
    case GET_CONTACTS_BY_TAG.FAIL: {
      return {
        ...state,
        contactsByTagStatus: 'fail',
      };
    }
    default: {
      return state;
    }
  }
}
