// group rights

export default [
  { label: 'Fleet', accessKey: 'fleet' },
  {
    label: 'Zone', accessKey: 'zone', addKey: 'zone_add', editKey: 'zone_edit',
  },
  {
    label: 'Merchant', accessKey: 'merchants', addKey: 'merchant_add', editKey: 'merchant_edit',
  },
  {
    label: 'Station', accessKey: 'r_cs', addKey: 'rc_add', editKey: 'rc_edit',
  },
  {
    label: 'Robomart', accessKey: 'robomarts', addKey: 'robomart_add', editKey: 'robomart_edit',
  },
  {
    label: 'Product',
    accessKey: 'products',
    addKey: 'product_add',
    editKey: 'product_edit',
    tagsViewKey: 'product_tags',
    tagsUpdateKey: 'product_tags_add',
  },
  {
    label: 'Group',
    accessKey: 'groups',
    addKey: 'group_add',
    editKey: 'group_edit',
  },
  {
    label: 'User',
    accessKey: 'users',
    addKey: 'user_add',
    editKey: 'user_edit',
    deleteKey: 'user_delete',
  },
  {
    label: 'Driver',
    accessKey: 'driver_app',
  },
  {
    label: 'Consumer',
    accessKey: 'consumers',
    deleteKey: 'consumers_delete',
  }, {
    label: 'Settings',
    accessKey: 'settings',
    editKey: 'settings_edit',
  },
];
