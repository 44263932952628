import React, { Component } from 'react';
import DatePicker from 'react-datepicker';

class Datepicker extends Component {
  render() {
    const { ...props } = this.props;
    return (
      <DatePicker
        portalId="root"
        {...props}
      />
    );
  }
}

export default Datepicker;
