import React, { Component } from 'react';
import AddTag from '../../components/tags/AddTag';
import Wrapper from '../../components/Wrapper';
import AllTagsList from '../../components/tags/AllTagsList';

class ProductsTags extends Component {
  render() {
    return (
      <Wrapper>
        <AddTag />
        <AllTagsList />
      </Wrapper>
    );
  }
}

export default ProductsTags;
