import React, { Component } from 'react';
import { CircularProgress } from 'rmwc';
import { connect } from 'react-redux';
import memoizeOne from 'memoize-one';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Wrapper from '../../components/Wrapper';
import HeadersMeta from '../../components/HeadersMeta';
import Button from '../../components/form/Button';
import DataTable from '../../components/DataTable';
import Utils from '../../helpers/Utils';
import Pagination from '../../components/Pagination';
import HasPermission from '../../components/HasPermission';
import { deleteCategoryRequest, getCategoriesRequest } from '../../store/actions/categories';
import Confirmation from '../../components/modals/Confirmation';

class Categories extends Component {
  getData = memoizeOne(async (query) => {
    this.setState({ currentLoading: true });
    const {
      description, predicate, reverse, name, storefront,
    } = query;
    const predicateObject = {
      desc: description,
      name,
      storefront_name: storefront,
    };
    const search = { predicateObject };
    const sort = { predicate: predicate || 'name', reverse: reverse || false };
    await this.props.getCategoriesRequest(sort, search, (query.page - 1) || 0);
    this.setState({ currentLoading: false });
  }, _.isEqual);

  static propTypes = {
    categories: PropTypes.array.isRequired,
    deleteCategoryRequest: PropTypes.func.isRequired,
    getCategoriesRequest: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    totalPages: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentLoading: false,
      categoryId: null,
      confirmationModal: false,
    };
    this.columns = [
      { column: 'name', type: 'searchable' },
      { column: 'description', type: 'searchable' },
      { column: 'active', type: '' },
      // { column: 'storefront', type: 'searchable' },
    ];
    this.keysToChange = {
      desc: 'description',
      is_active: 'active',
      storefront_name: 'storefront',
    };
  }

  toggleConfirmationModal = (categoryId) => {
    const { confirmationModal } = this.state;
    this.setState({ confirmationModal: !confirmationModal, categoryId });
  }

  handleAdd = () => {
    this.props.history.push('/admin/categories/add');
  }

  handleEdit = (id) => `/admin/categories/edit/${id}`

  handleDelete = async () => {
    const { categoryId } = this.state;
    const { categories } = this.props;
    this.setState({ loading: true });

    const category = categories.find((c) => c.product_category_id === categoryId);
    await this.props.deleteCategoryRequest(category);
    toast.success('Successfully Deleted');
    const query = Utils.queryParse(window.location.search, this.keysToChange);
    this.getData(query);
    this.setState({ loading: false, confirmationModal: false });
  }

  render() {
    const { currentLoading, confirmationModal, loading } = this.state;
    const { categories, totalPages } = this.props;

    const data = Utils.renameKeys(this.keysToChange, categories);

    const query = Utils.queryParse(window.location.search, this.keysToChange);
    this.getData(query);

    return (
      <Wrapper>
        <HasPermission edit="products" redirect>
          <HeadersMeta page="Categories" />
          <div className="zones">
            <div className="top">
              <div className="loading">
                {currentLoading ? <CircularProgress size="small" /> : null}
              </div>
              <HasPermission edit="product_add">
                <Button title="Add Category" onClick={this.handleAdd} />
              </HasPermission>
            </div>
            <div>
              <DataTable
                columns={this.columns}
                data={data}
                actions={['edit', 'delete']}
                onEdit={this.handleEdit}
                idKey="product_category_id"
                onDelete={this.toggleConfirmationModal}
              />
            </div>
            <Pagination currentPage={query.page} totalPages={totalPages} />
          </div>
          <Confirmation
            isOpen={confirmationModal}
            title="Are you sure, you want to delete this category?"
            onCloseModal={() => this.toggleConfirmationModal(null)}
            onConfirm={this.handleDelete}
            loading={loading}
          />
        </HasPermission>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: state.categories.categories,
  totalPages: state.categories.totalPages,
});

const mapDispatchToProps = {
  getCategoriesRequest,
  deleteCategoryRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Categories);

export default Container;
