import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import Confirmation from '../modals/Confirmation';

class Form extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    id: PropTypes.string,
  };

  static defaultProps = {
    id: undefined,
    onChange: undefined,
  }

  constructor(props) {
    super(props);
    this.state = {
      changed: false,
      confirm: false,
      loading: false,
    };
  }

  componentDidMount() {
    [...this.ref.elements].forEach((d) => d.addEventListener('change', this.handleChange));
    window.addEventListener('beforeunload', this.handleUnload);
  }

  componentWillUnmount() {
    [...this.ref.elements].forEach((d) => d.removeEventListener('change', this.handleChange));
    window.removeEventListener('beforeunload', this.handleUnload);
  }

  handleChange = () => {
    [...this.ref.elements].forEach((d) => d.removeEventListener('change', this.handleChange));
    this.setState({ changed: true });
    const { id } = this.props;
    if (id && this.props.onChange) {
      this.props.onChange();
    }
  }

  handleUnload = (ev) => {
    const { changed } = this.state;
    if (changed) {
      ev.preventDefault();
      ev.returnValue = 'Are you sure you want to leave?';
    }
  }

  handleSubmit = async (ev) => {
    ev.preventDefault();
    const { id } = this.props;
    if (!id) {
      await this.setState({ changed: false });
      this.props.onSubmit(ev);
    } else {
      this.setState({ confirm: !!id });
    }
  }

  forceChange = () => {
    const { changed } = this.state;
    if (!changed) {
      this.setState({ changed: true });
    }
  }

  handleUpdate = async (ev) => {
    await this.setState({ loading: true, changed: false });
    await this.props.onSubmit(ev);
    this.setState({ loading: false, confirm: false });
  }

  closeModal = () => {
    this.setState({ confirm: false });
  }

  render() {
    const { children, ...props } = this.props;
    const { changed, confirm, loading } = this.state;
    return (
      <form
        {...props}
        onSubmit={this.handleSubmit}
        ref={(ref) => {
          this.ref = ref;
        }}
      >
        <Prompt
          when={changed}
          message="Are you sure you want to leave?"
        />
        {children}
        <Confirmation
          isOpen={confirm}
          title="Are you sure, you want to update?"
          onCloseModal={this.closeModal}
          onConfirm={this.handleUpdate}
          loading={loading}
        />
      </form>
    );
  }
}

export default Form;
