import React, { Component } from 'react';
import {
  Checkbox, DataTable, DataTableBody, DataTableCell, DataTableContent, DataTableHead, DataTableRow,
} from 'rmwc';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import rights from '../../helpers/rights';

class Rights extends Component {
  static propTypes = {
    onCheck: PropTypes.func.isRequired,
    groupAllRights: PropTypes.array.isRequired,
    checked: PropTypes.array.isRequired,
    blur: PropTypes.string.isRequired,
    invalid: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.columns = ['Access', 'Add', 'Edit', 'Delete', 'Tags View', 'Tags Update'];
  }

  render() {
    const {
      groupAllRights, checked, blur, invalid,
    } = this.props;

    return (
      <div className={`rights ${invalid ? 'invalid' : ''}`}>
        <p className="title">Rights *</p>
        <DataTable stickyRows="1" className={blur}>
          <DataTableContent>
            <DataTableHead>
              <DataTableRow>
                <DataTableCell />
                {this.columns.map((c) => (
                  <DataTableCell key={c}>{c}</DataTableCell>
                ))}

              </DataTableRow>
            </DataTableHead>
            <DataTableBody>
              {rights.map((r) => (
                <DataTableRow key={r.label}>
                  <DataTableCell>{r.label}</DataTableCell>
                  {this.columns.map((c) => {
                    const right = groupAllRights.find((g) => _.snakeCase(g.name) === r[`${_.camelCase(c)}Key`]);
                    return (
                      <DataTableCell key={c}>
                        {Object.keys(r).some((k) => k === `${_.camelCase(c)}Key`)
                          ? <Checkbox checked={checked.includes(+right?.id)} onChange={() => this.props.onCheck(right.id)} />
                          : ''}
                      </DataTableCell>
                    );
                  })}
                </DataTableRow>
              ))}
            </DataTableBody>
          </DataTableContent>
        </DataTable>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  groupAllRights: state.groups.groupAllRights,
});

const mapDispatchToProps = {};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Rights);

export default Container;
