import {
  GET_USERS, GET_USER_BY_ID, GET_DRIVERS, GET_UNASSIGNED_VEHICLES,
} from '../actions/users';

const initialState = {
  users: [],
  usersStatus: '',
  user: {},
  userStatus: '',
  drivers: [],
  driversStatus: '',
  unassignedVehicles: [],
  unassignedVehiclesStatus: '',
  totalPages: 1,
  totalPagesDrivers: 1,

};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_USERS.REQUEST: {
      return {
        ...state,
        usersStatus: 'request',
      };
    }
    case GET_USERS.SUCCESS: {
      const { data: { data: { Records, RecordsCount } } } = action.payload;
      return {
        ...state,
        usersStatus: 'ok',
        users: Records,
        totalPages: Math.ceil(RecordsCount / 10),
      };
    }
    case GET_USERS.FAIL: {
      return {
        ...state,
        usersStatus: 'fail',
      };
    }
    case GET_USER_BY_ID.REQUEST: {
      return {
        ...state,
        userStatus: 'request',
      };
    }
    case GET_USER_BY_ID.SUCCESS: {
      return {
        ...state,
        userStatus: 'ok',
      };
    }
    case GET_USER_BY_ID.FAIL: {
      return {
        ...state,
        userStatus: 'fail',
      };
    }
    case GET_DRIVERS.REQUEST: {
      return {
        ...state,
        driversStatus: 'request',
      };
    }
    case GET_DRIVERS.SUCCESS: {
      const { data: { data: { Records, RecordsCount } } } = action.payload;

      return {
        ...state,
        driversStatus: 'ok',
        drivers: Records,
        totalPagesDrivers: Math.ceil(RecordsCount / 10),
      };
    }
    case GET_DRIVERS.FAIL: {
      return {
        ...state,
        driversStatus: 'fail',
      };
    }
    case GET_UNASSIGNED_VEHICLES.REQUEST: {
      return {
        ...state,
        unassignedVehiclesStatus: 'request',
      };
    }
    case GET_UNASSIGNED_VEHICLES.SUCCESS: {
      const { data: { data } } = action.payload;

      return {
        ...state,
        unassignedVehiclesStatus: 'ok',
        unassignedVehicles: data,
      };
    }
    case GET_UNASSIGNED_VEHICLES.FAIL: {
      return {
        ...state,
        unassignedVehiclesStatus: 'fail',
      };
    }
    default: {
      return state;
    }
  }
}
