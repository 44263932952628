import React, { Component } from 'react';
import Wrapper from '../../components/Wrapper';
import AddProducts from '../../components/menus/AddProducts';
import ProductsList from '../../components/menus/ProductsList';

class Menu extends Component {
  render() {
    return (
      <Wrapper>
        <AddProducts />
        <ProductsList />
      </Wrapper>
    );
  }
}

export default Menu;
