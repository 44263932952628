import React, { Component } from 'react';
import Modal from 'react-modal';
import { Icon } from 'rmwc';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import memoizeOne from 'memoize-one';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import Button from '../form/Button';
import Input from '../form/Input';
import {
  getApplicantTypeByIdRequest,
  updateApplicantRequest,
} from '../../store/actions/applicants';
import Utils from '../../helpers/Utils';

class EditApplicants extends Component {
  getApplicant = memoizeOne(async (applicantId) => {
    this.setState({ formData: {} });
    if (applicantId) {
      const { payload: { data: { data } } } = await this.props.getApplicantTypeByIdRequest(applicantId);
      this.setState({ formData: data });
    }
  })

  static propTypes = {
    isOpen: PropTypes.bool,
    onCloseModal: PropTypes.func,
    applicantId: PropTypes.string,
    getApplicantTypeByIdRequest: PropTypes.func.isRequired,
    updateApplicantRequest: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
  };

  static defaultProps = {
    isOpen: false,
    onCloseModal: undefined,
    applicantId: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      loading: '',
    };
  }

  handleChange = (key, val) => {
    const { formData } = this.state;
    formData[key] = val;
    this.setState({ formData });
  }

  handleUpdate = async (type) => {
    const { formData } = this.state;
    this.setState({ loading: type });
    if (type === 'archive') {
      formData.state = formData.state ? 0 : 1;
    }
    const { payload: { data } } = await this.props.updateApplicantRequest(formData);
    if (data.success) {
      toast.success('Successfully Updated.');
      const query = Utils.queryParse();
      query.updated = new Date();
      this.props.history.replace(`?${Utils.queryStringify(query)}`);
      this.props.onCloseModal();
    } else {
      toast.error('Something went wrong.');
    }
    this.setState({ loading: '' });
  }

  render() {
    const {
      isOpen, applicantId,
    } = this.props;
    const { formData, loading } = this.state;
    if (!isOpen) {
      return null;
    }
    this.getApplicant(applicantId);
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.props.onCloseModal}
        overlayClassName="modalOverlay"
        className="modalContent editApplicants"
        parentSelector={() => document.querySelector('#root > div')}
      >
        <div className="top">
          <span>Edit Applicants</span>
          <Icon icon="close" className="icon" onClick={this.props.onCloseModal} />
        </div>

        <div className="type">
          <span>Type :</span>
          <span className="applicantType">{formData.type === 0 ? 'Applicant' : 'Seeder'}</span>
        </div>

        <Input label="Email" readOnly value={formData.email} />
        <Input
          label="Name"
          value={formData.name}
          onChange={(ev) => this.handleChange('name', ev.target.value)}
        />
        <Input
          textarea
          label="Notes"
          value={formData.notes}
          fieldType="Textarea"
          onChange={(ev) => this.handleChange('notes', ev.target.value)}
        />

        <div className="actions">
          <Button title="Cancel" outlined raised={false} onClick={this.props.onCloseModal} />
          <Button
            title="Save"
            loading={loading === 'update'}
            onClick={() => this.handleUpdate('update')}
          />
          <Button
            title={`${formData.state ? 'Unarchive' : 'Archive'}`}
            loading={loading === 'archive'}
            onClick={() => this.handleUpdate('archive')}
          />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  applicants: state.applicants.applicants,
});

const mapDispatchToProps = {
  getApplicantTypeByIdRequest,
  updateApplicantRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditApplicants);

export default withRouter(Container);
