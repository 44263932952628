import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Icon } from 'rmwc';
import _ from 'lodash';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom';
import Outside from '../Outside';
import Utils from '../../helpers/Utils';

class FilterSelect extends Component {
  static propTypes = {
    label: PropTypes.string,
    className: PropTypes.string,
    dropdown: PropTypes.string,
    onToggle: PropTypes.func,
    history: PropTypes.object.isRequired,
    options: PropTypes.array,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    label: '',
    className: '',
    dropdown: '',
    onToggle: undefined,
    disabled: false,
    options: [],
  }

  constructor(props) {
    super(props);
    this.state = {
      search: '',
    };
  }

  handleSearch = (ev) => {
    this.setState({ search: ev.target.value.toLowerCase() });
  }

  handleChange = (value, key) => {
    const query = Utils.queryParse(window.location.search, {}, key, 'comma');

    if (key === 'country' && query.country.length === 1) {
      delete query.zone;
      delete query.state;
      delete query.city;
    }
    if (key === 'state' && query.state.length === 1) {
      delete query.zone;
      delete query.city;
    }
    if (key === 'city' && query.city.length === 1) {
      delete query.zone;
    }
    if (query[key].includes(value)) {
      query[key] = query[key].filter((q) => q !== value);
    } else {
      query[key] = [...query[key], value];
    }
    this.props.history.replace(`?${Utils.queryStringify(query)}`);
  }

  render() {
    const {
      label, className, options, dropdown, disabled,
    } = this.props;
    const { search } = this.state;
    const query = Utils.queryParse(window.location.search, {}, '', 'comma');
    return (
      <div className={`filterSelectContainer ${disabled ? 'disabled' : ''}`}>
        <div
          className={`filterSelect ${className}`}
          onClick={() => {
            if (dropdown !== label) {
              this.props.onToggle();
            }
          }}
          ref={(ref) => {
            this.label = ref;
          }}
        >
          <div className="label">
            {_.upperCase(label)}
            {' '}
            <Icon icon="arrow_drop_down" />
          </div>
        </div>
        {dropdown === label && this.label ? (
          ReactDOM.createPortal((
            <Outside
              onClick={() => {
                if (dropdown === label) {
                  this.props.onToggle();
                }
              }}
              component="div"
              exclude=".filterSelect"
            >
              <div className="dropdownFilter">
                <input onChange={this.handleSearch} value={search} />
                <ul>
                  {options.filter((o) => (label !== 'zone'
                    ? o[label].toLowerCase().includes(search) : o.name.toLowerCase().includes(search)))
                    .map((o) => (
                      <li key={o.id}>
                        <Checkbox
                          checked={query[label] && query[label]?.includes(label !== 'zone' ? o[label] || o.name : o.id)}
                          onChange={() => this.handleChange(label !== 'zone' ? o[label] || o.name : o.id, label)}
                          label={o[label] || o.name || '-'}
                        />
                      </li>
                    ))}

                </ul>
              </div>
            </Outside>), this.label)
        ) : null}
      </div>
    );
  }
}

export default withRouter(FilterSelect);
