import { define } from '../../helpers/redux-request';
import Api from '../../Api';

export const GET_RCS = define('GET_RCS');

export function getRCsRequest(sort, search, page, number) {
  return GET_RCS.request(() => Api.getRCs(sort, search, page, number)).takeLatest();
}

export const CREATE_RC = define('CREATE_RC');

export function createRCRequest(obj) {
  return CREATE_RC.request(() => Api.createRC(obj));
}

export const GET_RC_BY_ID = define('GET_RC_BY_ID');

export function getRCByIdRequest(id) {
  return GET_RC_BY_ID.request(() => Api.getRCById(id));
}

export const UPDATE_RC = define('UPDATE_RC');

export function updateRCRequest(obj) {
  return UPDATE_RC.request(() => Api.updateRC(obj));
}

export const DELETE_RC = define('DELETE_RC');

export function deleteRCRequest(id) {
  return DELETE_RC.request(() => Api.deleteRC(id));
}
