import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import _ from 'lodash';
import Button from '../form/Button';
import Select from '../form/Select';
import { getProductsByStorefrontRequest, getProductsRequest } from '../../store/actions/product';
import { addProductsRequest } from '../../store/actions/types';
import CreatedItems from '../modals/CreatedItems';

class AddProducts extends Component {
  static propTypes = {
    getProductsRequest: PropTypes.func.isRequired,
    products: PropTypes.array.isRequired,
    addProductsRequest: PropTypes.func.isRequired,
    getProductsByStorefrontRequest: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedProducts: [],
      loading: false,
      data: {},
      modal: false,
    };
  }

  handleLoadOptions = async (val) => {
    const { payload: { data: { data: { Records } } } } = await this.props.getProductsRequest(
      { predicate: 'name', reverse: false }, { predicateObject: { code: val }, cache: true }, 0, 20,
    );
    return Records;
  }

  handleChange = (val) => {
    this.setState({ selectedProducts: val.map((v) => v.code) });
  }

  handleAddProducts = async (ev) => {
    ev.preventDefault();
    const { match: { params: { storefrontId } } } = this.props;
    const { selectedProducts } = this.state;
    this.setState({ loading: true });

    const requestData = {
      product_codes: selectedProducts.join('\n'),
      storefront_id: storefrontId,
    };
    const { payload: { data: response, data: { data = {} } } } = await this.props.addProductsRequest(requestData);
    if (response.success) {
      await this.props.getProductsByStorefrontRequest(storefrontId);
      this.setState({ selectedProducts: [], data, modal: true });
    } else {
      toast.error('Something went wrong.');
    }
    this.setState({ loading: false });
  }

  handleRedirect = (url) => {
    this.props.history.push(url);
  }

  handleCloseModal = () => {
    this.setState({ modal: false, data: {} });
  }

  getValueForList = (code, name) => `${code} - ${name}`

  render() {
    const { products, match: { params: { storefrontId } } } = this.props;
    const {
      selectedProducts, loading, modal, data,
    } = this.state;
    return (
      <>
        <form onSubmit={this.handleAddProducts} className="addBlockForm addTag">
          <div className="addBlock">
            <Select
              getOptionValue={(o) => o.product_id}
              getOptionLabel={(o) => this.getValueForList(o.code, o.name)}
              label="Product Codes"
              isMulti
              isAsync
              cacheOptions
              defaultOptions
              value={products.filter((p) => selectedProducts.includes(p.code))}
              onChange={(val) => this.handleChange(val)}
              loadOptions={this.handleLoadOptions}
            />
          </div>
          <div className="actions">
            <Button title="Cancel" type="button" onClick={() => this.handleRedirect('/admin/storefronts')} />
            <Button
              title="Go to edit"
              type="button"
              onClick={() => this.handleRedirect(`/admin/storefronts/edit/${storefrontId}`)}
            />
            <Button
              title="Add Products"
              type="submit"
              disabled={_.isEmpty(selectedProducts)}
              loading={loading}
            />
          </div>
        </form>
        <CreatedItems
          isOpen={modal}
          onCloseModal={this.handleCloseModal}
          data={data}
          view="menu"
          addedText="Product Codes Added"
          notAddedText="Product Codes Not Added"
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  products: state.product.products,
});

const mapDispatchToProps = {
  getProductsRequest,
  addProductsRequest,
  getProductsByStorefrontRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddProducts);

export default withRouter(Container);
