import React, { Component } from 'react';
import Modal from 'react-modal';
import { Icon } from 'rmwc';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import Input from '../form/Input';
import Button from '../form/Button';
import { genCodeForConsumersRequest } from '../../store/actions/inviteCodes';
import Utils from '../../helpers/Utils';

class GenCode extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onCloseModal: PropTypes.func,
    genCodeForConsumersRequest: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
  };

  static defaultProps = {
    isOpen: false,
    onCloseModal: undefined,
  }

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      formData: { no_of_codes: '' },
    };
  }

  handleChange = (key, value) => {
    const { formData } = this.state;
    formData[key] = value;
    this.setState({ formData });
  }

  handleGenCode = async () => {
    const { formData } = this.state;
    this.setState({ loading: true });
    const { payload: { data } } = await this.props.genCodeForConsumersRequest(formData);
    if (data.success) {
      toast.success('Successfully generated.');
      const query = Utils.queryParse();
      query.updated = new Date();
      this.props.history.replace(`?${Utils.queryStringify(query)}`);
      this.props.onCloseModal();
    } else {
      toast.error('Something went wrong.');
    }
    this.setState({ loading: false });
  }

  render() {
    const {
      isOpen,
    } = this.props;
    const { loading, formData } = this.state;
    if (!isOpen) {
      return null;
    }
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.props.onCloseModal}
        overlayClassName="modalOverlay"
        className="modalContent genCode"
        parentSelector={() => document.querySelector('#root > div')}
      >
        <div className="top">
          <span>Gen. Code</span>
          <Icon icon="close" className="icon" onClick={this.props.onCloseModal} />
        </div>

        <Input
          label="No of Code"
          onChange={(ev) => this.handleChange('no_of_codes', ev.target.value)}
          type="numeric"
          value={formData.no_of_codes}
          min="1"
        />

        <Input
          textarea
          label="Notes"
          fieldType="Textarea"
          onChange={(ev) => this.handleChange('notes', ev.target.value)}
          value={formData.notes}
        />

        <div className="actions">
          <Button title="Cancel" outlined raised={false} onClick={this.props.onCloseModal} />
          <Button
            title="Generate"
            loading={loading}
            onClick={this.handleGenCode}
            disabled={!formData.no_of_codes || +formData.no_of_codes > 10}
          />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  genCodeForConsumersRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GenCode);

export default withRouter(Container);
