import React, { Component } from 'react';
import firebase from '../helpers/firebase';

class OfflineIndicator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onLine: navigator.onLine,
      connecting: true,
    };
  }

  componentDidMount() {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);

    setTimeout(() => {
      const connectedRef = firebase.database().ref().child('.info/connected');
      connectedRef.on('value', (data) => {
        this.updateConnectionStatus(data.val());
      });
    }, 5000);
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  }

  updateOnlineStatus = () => {
    const { onLine } = navigator;
    this.setState({ onLine });
  };

  updateConnectionStatus = (connecting) => {
    this.setState({ connecting });
  };

  render() {
    const { onLine, connecting } = this.state;
    if (onLine === true) {
      return null;
    }
    return (
      <div id="offline">
        {!onLine ? 'You\'re offline right now. Please check your connection.' : null}
        {onLine && !connecting ? 'Slow network. Can\'t connect to firebase' : null}
      </div>
    );
  }
}

export default OfflineIndicator;
