import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import _ from 'lodash';
import FilterSelect from '../form/FilterSelect';
import { getZonesRequest } from '../../store/actions/zones';
import Utils from '../../helpers/Utils';

class Filters extends Component {
  initCountriesData = memoizeOne((data) => {
    const countries = _.uniqBy(data.filter((d) => d.is_active), (d) => d.country);
    this.setState({ countries });
  }, _.isEqual)

  initStatesData = memoizeOne((countries, zones) => {
    if (_.isEmpty(countries)) {
      return;
    }
    const data = zones.filter((d) => d.is_active).filter((z) => countries.includes(z.country));
    const states = _.uniqBy(data, (s) => s.state);
    this.setState({ states });
  }, _.isEqual)

  initCitiesData = memoizeOne((states, zones) => {
    if (_.isEmpty(states)) {
      return;
    }
    const data = zones.filter((d) => d.is_active).filter((z) => states.includes(z.state));
    const cities = _.uniqBy(data, (s) => s.city);
    this.setState({ cities });
  }, _.isEqual)

  initZonesData = memoizeOne((cities, zones) => {
    if (_.isEmpty(cities)) {
      return;
    }
    const zonesArr = zones.filter((d) => d.is_active).filter((z) => cities.includes(z.city));
    this.setState({ zonesArr });
  }, _.isEqual)

  static propTypes = {
    getZonesRequest: PropTypes.func.isRequired,
    zones: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      dropdown: '',
      countries: [],
      states: [],
      cities: [],
      zonesArr: [],
    };
  }

  async componentDidMount() {
    const { payload: { data: { data } } = {} } = await this.props.getZonesRequest({
      predicate: 'name',
      reverse: false,
    }, {}, 0, 100);

    const query = Utils.queryParse(window.location.search, {}, '', 'comma');
    if (query.zone) {
      data?.Records?.forEach(d => {
        if (query.zone?.includes(d.id) && !d.is_active) {
          const z = _.isArray(query.zone) ? query.zone : [query.zone]
          query.zone = z.filter(a => a !== d.id)
          this.props.history.replace(`?${Utils.queryStringify(query)}`)
        }
      })
      if (!data?.Records?.some(d => d.is_active)) {
        delete query.city;
        delete query.country;
        delete query.state;
        this.props.history.replace(`?${Utils.queryStringify(query)}`)
      }
    }

  }

  toggleDropdown = (label, prev) => {
    const { dropdown } = this.state;
    const query = Utils.queryParse(window.location.search, 'comma');
    if (!query[prev]) {
      return;
    }
    if (dropdown === label) {
      this.setState({ dropdown: '' });
      return;
    }
    this.setState({ dropdown: label });
  }

  render() {
    const {
      dropdown, countries, states, cities, zonesArr,
    } = this.state;
    const { zones } = this.props;
    this.initCountriesData(zones);
    const query = Utils.queryParse(window.location.search, {}, '', 'comma');
    this.initStatesData(query.country, zones);
    this.initCitiesData(query.state, zones);
    this.initZonesData(query.city, zones);
    return (
      <div className="filters">
        <FilterSelect
          label="country"
          className="country"
          options={countries}
          onToggle={() => this.toggleDropdown('country')}
          dropdown={dropdown}
        />
        <FilterSelect
          label="state"
          options={states}
          onToggle={() => this.toggleDropdown('state', 'country')}
          dropdown={dropdown}
          disabled={!query.country}
        />
        <FilterSelect
          label="city"
          options={cities}
          onToggle={() => this.toggleDropdown('city', 'state')}
          dropdown={dropdown}
          disabled={!query.state || !query.country}
        />
        <FilterSelect
          label="zone"
          className="noBorder"
          options={zonesArr}
          onToggle={() => this.toggleDropdown('zone', 'city')}
          dropdown={dropdown}
          disabled={!query.city || !query.state || !query.country}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  zones: state.zones.zones,
});

const mapDispatchToProps = {
  getZonesRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Filters);

export default withRouter(Container);
