import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import memoizeOne from 'memoize-one';
import _ from 'lodash';
import Wrapper from '../../components/Wrapper';
import Input from '../../components/form/Input';
import Select from '../../components/form/Select';
import Button from '../../components/form/Button';
import HeadersMeta from '../../components/HeadersMeta';
import Validator from '../../helpers/Validator';
import Form from '../../components/form/Form';
import { setCurrentItemName } from '../../store/actions/app';
import HasPermission from '../../components/HasPermission';
import { createCategoryRequest, getCategoryByIdRequest, updateCategoryRequest } from '../../store/actions/categories';
import Api from '../../Api';
import { getStorefrontsRequest } from '../../store/actions/storefronts';

class CategoryForm extends Component {
  initCategoriesData = memoizeOne(async (id) => {
    if (!id) {
      return;
    }
    this.props.setCurrentItemName('');
    const { payload: { data: { data } = {} } } = await this.props.getCategoryByIdRequest(id);
    if (data) {
      this.props.setCurrentItemName(data.name);
      this.setState({ formData: data });
    }
  })

  static propTypes = {
    createCategoryRequest: PropTypes.func.isRequired,
    getCategoryByIdRequest: PropTypes.func.isRequired,
    updateCategoryRequest: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    categoryStatus: PropTypes.string.isRequired,
    setCurrentItemName: PropTypes.func.isRequired,
    storefronts: PropTypes.object.isRequired,
    getStorefrontsRequest: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        name: '',
        desc: '',
        is_active: true,
        storefront_id: '',
      },
      loading: false,
      changed: false,
      focused: null,
      errors: [],
      emptyFields: [],
    };
    this.status = [
      { value: true, label: 'Active' },
      { value: false, label: 'Inactive' }];
  }

  componentDidMount() {
    this.props.getStorefrontsRequest({ predicate: 'name', reverse: false }, {});
  }

  handleChange = async (key, value, type) => {
    const { formData, errors } = this.state;
    let { emptyFields } = this.state;
    formData[key] = value;
    if (emptyFields.includes(key)) {
      emptyFields = emptyFields.filter((f) => f !== key);
    }
    if (errors.includes(key)) {
      this.handleValidate(key, type);
    }
    if (key === 'img') {
      value.uri = URL.createObjectURL(value);
      const reader = new FileReader();
      reader.readAsDataURL(value);
      reader.onload = () => {
        value.base64 = reader.result;
        formData[key] = value;
        this.setState({ formData });
      };
    }
    this.setState({ formData, emptyFields });
  }

  handleRedirect = (url) => {
    this.props.history.push(url);
  }

  handleAddProduct = async (ev) => {
    ev.preventDefault();
    const { formData } = this.state;
    const { match: { params: { categoryId } } } = this.props;
    const emptyFields = Validator.getEmptyFields(formData, ['name']);
    this.setState({ loading: true });
    if (!_.isEmpty(emptyFields)) {
      this.setState({ emptyFields, loading: false });
      return;
    }
    const img = formData?.img?.base64 ? formData?.img?.base64 : null;
    if (img) {
      const payload = await Api.fileUpload(formData?.img) || {};
      formData.image_url = payload.data.data[0];
    }

    let data;

    if (categoryId) {
      formData.product_category_id = categoryId;
      const { payload } = await this.props.updateCategoryRequest({ ...formData });
      data = payload.data;
    } else {
      const { payload } = await this.props.createCategoryRequest({ ...formData });
      data = payload.data;
    }
    if (data.success) {
      toast.success(`Successfully ${categoryId ? 'updated' : 'created'}.`);
      this.props.history.push('/admin/categories');
    }
    if (data?.error.includes('duplicate key')) {
      toast.error('Category code must be unique.');
    }
    this.setState({ loading: false });
  }

  handleFocus = (focused) => {
    this.setState({ focused });
  }

  handleBlur = () => {
    this.setState({ focused: null });
  }

  handleValidate = (key, type) => {
    const { formData } = this.state;
    let { errors } = this.state;
    if (!_.isEmpty(formData[key]) && !Validator.validate(type, formData[key])) {
      errors = [...errors, key];
      this.setState({ errors });
    } else {
      this.setState({ errors: errors.filter((e) => e !== key) });
    }
  }

  handleFormChange = () => {
    const { changed } = this.state;
    if (!changed) {
      this.setState({ changed: true });
    }
  }

  render() {
    const {
      formData, loading, focused, emptyFields, changed,
    } = this.state;
    const {
      match: { params: { categoryId } }, categoryStatus, storefronts,
    } = this.props;
    this.initCategoriesData(categoryId);
    const blur = categoryStatus !== 'ok' && categoryId ? 'blur' : '';
    return (
      <Wrapper>
        <HasPermission edit={['product_add', 'product_edit']} redirect>
          <HeadersMeta page={` ${categoryId ? 'Edit' : 'Add'} Product`} />
          <Form
            onSubmit={this.handleAddProduct}
            className="addBlockForm"
            ref={(ref) => this.form = ref}
            id={categoryId}
            onChange={this.handleFormChange}
          >
            <div className="addBlock">
              <div className="col">
                <Input
                  label="Category name *"
                  value={formData.name || ''}
                  onChange={(ev) => this.handleChange('name', ev.target.value)}
                  className={blur}
                  invalid={emptyFields.includes('name')}
                />
                {/* <Select */}
                {/*  options={storefronts} */}
                {/*  label="Storefront" */}
                {/*  getOptionValue={(o) => o.id} */}
                {/*  getOptionLabel={(o) => o.name} */}
                {/*  value={storefronts.find((s) => formData.storefront_id === s.id)} */}
                {/*  onChange={(val) => this.handleChange('storefront_id', val.id)} */}
                {/*  className={blur} */}
                {/* /> */}
                <Input
                  textarea
                  label="Description"
                  value={formData.desc || ''}
                  onChange={(ev) => this.handleChange('desc', ev.target.value)}
                  className={blur}
                  fieldType="Textarea"
                />
              </div>
              <div className="col">
                <Select
                  options={this.status}
                  label="Status"
                  value={this.status.find((s) => s.value === formData.is_active)}
                  onChange={(val) => this.handleChange('is_active', val.value)}
                  className={blur}
                  invalid={emptyFields.includes('is_active')}
                />
                <div className="fileInput">
                  {focused === 'img' ? <span className="fileName">{formData?.img?.name}</span> : null}
                  <Input
                    label="Category Image"
                    onChange={(ev) => {
                      this.handleChange('img', ev.target.files[0]);
                      ev.target.value = '';
                    }}
                    type="file"
                    accept="image/png, image/jpeg, image/gif"
                    className={blur}
                    onFocus={() => this.handleFocus('img')}
                    onBlur={this.handleBlur}
                    invalid={emptyFields.includes('img')}
                  />
                </div>
                {formData?.img || formData?.image_url
                  ? <img src={formData?.img?.uri || formData?.image_url} alt="" className="productImg" /> : null}
              </div>

            </div>

            <div className="actions">
              <Button title="Cancel" onClick={() => this.handleRedirect('/admin/categories')} type="button" />
              <Button
                type="submit"
                title={categoryId ? 'Save' : 'Create'}
                loading={loading}
                disabled={categoryId && !changed}
              />
            </div>
          </Form>
        </HasPermission>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  categoryStatus: state.categories.categoryStatus,
  storefronts: state.storefronts.storefronts,

});

const mapDispatchToProps = {
  createCategoryRequest,
  setCurrentItemName,
  getCategoryByIdRequest,
  updateCategoryRequest,
  getStorefrontsRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CategoryForm);

export default Container;
