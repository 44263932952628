import { define } from '../../helpers/redux-request';
import Api from '../../Api';

export const GET_ROBOMART_TYPES = define('GET_ROBOMART_TYPES');

export function getRobomartTypesRequest(sort, search, page, number) {
  return GET_ROBOMART_TYPES.request(() => Api.getRobomartTypes(sort, search, page, number)).takeLatest();
}

export const CREATE_ROBOMART_TYPE = define('CREATE_ROBOMART_TYPE');

export function createRobomartTypeRequest(obj) {
  return CREATE_ROBOMART_TYPE.request(() => Api.createType(obj));
}

export const UPDATE_ROBOMART_TYPE = define('UPDATE_ROBOMART_TYPE');

export function updateRobomartTypeRequest(obj) {
  return UPDATE_ROBOMART_TYPE.request(() => Api.updateType(obj));
}

export const GET_ROBOMART_TYPE_BY_ID = define('GET_ROBOMART_TYPE_BY_ID');

export function getRobomartTypeByIdRequest(id) {
  return GET_ROBOMART_TYPE_BY_ID.request(() => Api.getRobomartTypeById(id));
}
