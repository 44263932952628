import fb from 'firebase';
import config from '../config';

const firebaseConfigs = config.FIREBASE_CONFIGS;

if (!fb.apps.length) {
  fb.initializeApp(firebaseConfigs);
} else {
  fb.app();
}

export default fb;
