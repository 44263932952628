import { define } from '../../helpers/redux-request';
import Api from '../../Api';

export const GET_APPLICANTS = define('GET_APPLICANTS');

export function getApplicantsRequest(sort, search, page, number) {
  return GET_APPLICANTS.request(() => Api.getApplicants(sort, search, page, number)).takeLatest();
}

export const GEN_CODE = define('GEN_CODE');

export function genCodeRequest(obj) {
  return GEN_CODE.request(() => Api.genCode(obj));
}

export const GET_APPLICANT_BY_ID = define('GET_APPLICANT_BY_ID');

export function getApplicantTypeByIdRequest(id) {
  return GET_APPLICANT_BY_ID.request(() => Api.getApplicantTypeById(id));
}

export const UPDATE_APPLICANT = define('UPDATE_APPLICANT');

export function updateApplicantRequest(obj) {
  return UPDATE_APPLICANT.request(() => Api.updateApplicant(obj));
}
