import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import memoizeOne from 'memoize-one';
import _ from 'lodash';
import Wrapper from '../../components/Wrapper';
import Input from '../../components/form/Input';
import Select from '../../components/form/Select';
import {
  createGroupRequest,
  getGroupAllRightsRequest,
  getGroupByIdRequest,
  updateGroupRequest,
} from '../../store/actions/groups';
import Rights from '../../components/groups/Rights';
import HeadersMeta from '../../components/HeadersMeta';
import Button from '../../components/form/Button';
import Validator from '../../helpers/Validator';
import Form from '../../components/form/Form';
import { setCurrentItemName } from '../../store/actions/app';
import HasPermission from '../../components/HasPermission';

class GroupForm extends Component {
  initGroupData = memoizeOne(async (id) => {
    if (!id) {
      return;
    }
    this.props.setCurrentItemName('');
    const { payload: { data: { data } } } = await this.props.getGroupByIdRequest(id);
    if (data) {
      this.props.setCurrentItemName(data.name);
      data.rights = data.rights?.split(',').map((r) => +r);
      this.setState({ formData: data });
    }
  })

  static propTypes = {
    getGroupAllRightsRequest: PropTypes.func.isRequired,
    setCurrentItemName: PropTypes.func.isRequired,
    createGroupRequest: PropTypes.func.isRequired,
    getGroupByIdRequest: PropTypes.func.isRequired,
    updateGroupRequest: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    groupStatus: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.mode = [
      { value: true, label: 'Online' },
      { value: false, label: 'Offline' },
    ];
    this.state = {
      formData: {
        name: '',
        is_active: false,
        mode: false,
        rights: [],
      },
      loading: false,
      changed: false,
      emptyFields: [],
    };
  }

  componentDidMount() {
    this.props.getGroupAllRightsRequest();
  }

  handleChange = (key, value) => {
    const { formData } = this.state;
    if (key === 'rights') {
      this.onRightChange();
      return;
    }
    let { emptyFields } = this.state;
    formData[key] = value;
    if (emptyFields.includes(key)) {
      emptyFields = emptyFields.filter((f) => f !== key);
    }
    this.setState({ formData, emptyFields });
  }

  onRightChange = (id) => {
    let { formData: { rights }, emptyFields } = this.state;
    const { formData } = this.state;
    if (emptyFields.includes('rights')) {
      emptyFields = emptyFields.filter((f) => f !== 'rights');
    }
    if (rights.includes(id)) {
      rights = rights.filter((r) => r !== id);
    } else {
      rights = [...rights, id];
    }
    formData.rights = rights;
    this.setState({ formData, emptyFields });
  }

  handleAddGroup = async (ev) => {
    ev.preventDefault();
    const { formData } = this.state;
    const { match: { params: { groupId } } } = this.props;

    const emptyFields = Validator.getEmptyFields(formData, ['name', 'rights']);
    this.setState({ loading: true });
    if (!_.isEmpty(emptyFields)) {
      this.setState({ emptyFields, loading: false });
      return;
    }
    const rights = formData.rights.join(',');
    let data;
    if (groupId) {
      formData.id = groupId;
      const { payload } = await this.props.updateGroupRequest({ ...formData, rights });
      data = payload.data;
    } else {
      const { payload } = await this.props.createGroupRequest({ ...formData, rights });
      data = payload.data;
    }
    if (data.success) {
      toast.success(`Successfully ${groupId ? 'updated' : 'created'}.`);
      this.props.history.push('/admin/groups');
    }
    if (data?.error) {
      toast.error('Something went wrong.');
    }
    this.setState({ loading: false });
  }

  cancel = () => {
    this.props.history.push('/admin/groups');
  }

  handleFormChange = () => {
    const { changed } = this.state;
    if (!changed) {
      this.setState({ changed: true });
    }
  }

  render() {
    const {
      formData, loading, changed, emptyFields,
    } = this.state;
    const { match: { params: { groupId } }, groupStatus } = this.props;
    this.initGroupData(groupId);

    const blur = groupStatus !== 'ok' && groupId ? 'blur' : '';

    return (
      <Wrapper>
        <HasPermission edit={['group_add', 'group_edit']} redirect>
          <HeadersMeta page={` ${groupId ? 'Edit' : 'Add'} Group`} />
          <Form
            onSubmit={this.handleAddGroup}
            className="addBlockForm"
            ref={(ref) => this.form = ref}
            id={groupId}
            onChange={this.handleFormChange}
          >
            <div className="addBlock">
              <div className="col">
                <Input
                  label="Name *"
                  value={formData.name || ''}
                  onChange={(ev) => this.handleChange('name', ev.target.value)}
                  className={blur}
                  invalid={emptyFields.includes('name')}
                />
                <Select
                  options={this.mode}
                  label="Mode *"
                  value={this.mode.find((m) => m.value === formData.is_active)}
                  onChange={(val) => {
                    this.handleChange('is_active', val.value);
                    this.handleChange('mode', `${val.value}`);
                  }}
                  className={blur}
                />
              </div>

            </div>
            <Rights
              checked={formData.rights}
              onCheck={this.onRightChange}
              blur={blur}
              invalid={emptyFields.includes('rights')}
            />
            <div className="actions">
              <Button title="Cancel" onClick={this.cancel} type="button" />
              <Button
                type="submit"
                title={groupId ? 'Save' : 'Create'}
                loading={loading}
                disabled={groupId && !changed}
              />
            </div>
          </Form>
        </HasPermission>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  groupAllRights: state.groups.groupAllRights,
  groupStatus: state.groups.groupStatus,
});

const mapDispatchToProps = {
  getGroupAllRightsRequest,
  createGroupRequest,
  getGroupByIdRequest,
  updateGroupRequest,
  setCurrentItemName,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupForm);

export default Container;
