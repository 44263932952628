import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import memoizeOne from 'memoize-one';
import {
  DataTable, DataTableBody, DataTableCell, DataTableContent, DataTableHead, DataTableRow, Icon,
} from 'rmwc';
import Button from '../form/Button';
import { getProductTagsRequest } from '../../store/actions/product';

class ViewTag extends Component {
  getProductTags = memoizeOne((id) => {
    if (!id) {
      return;
    }
    const { match: { params: { robomartId } } } = this.props;

    this.props.getProductTagsRequest(robomartId, id);
  })

  static propTypes = {
    isOpen: PropTypes.string,
    onCloseModal: PropTypes.func,
    getProductTagsRequest: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    productId: PropTypes.string,
    productTags: PropTypes.object.isRequired,
  };

  static defaultProps = {
    isOpen: null,
    onCloseModal: undefined,
    productId: null,
  }

  render() {
    const { isOpen, productId, productTags } = this.props;
    if (!isOpen) {
      return null;
    }
    this.getProductTags(productId);
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.props.onCloseModal}
        overlayClassName="modalOverlay"
        className="modalContent viewTagModal"
        parentSelector={() => document.querySelector('#root > div')}
      >
        <div className="top">
          <Icon icon="close" className="icon" onClick={this.props.onCloseModal} />
        </div>
        <div className="viewTag">
          <DataTable>
            <DataTableContent>
              <DataTableHead>
                <DataTableRow>
                  <DataTableCell alignStart>Baseline</DataTableCell>
                  <DataTableCell alignStart>Current</DataTableCell>
                </DataTableRow>
              </DataTableHead>
              <DataTableBody>
                <DataTableRow>
                  <DataTableCell className="tag">
                    {productTags?.baseline_tags?.baseline_tags || '-'}
                  </DataTableCell>
                  <DataTableCell className="tag">
                    {productTags?.current_tags?.current_tags || '-'}
                  </DataTableCell>
                </DataTableRow>
              </DataTableBody>
            </DataTableContent>
          </DataTable>
        </div>
        <div className="actions">
          <Button title="Ok" onClick={this.props.onCloseModal} />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  productTags: state.product.productTags,
});

const mapDispatchToProps = {
  getProductTagsRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ViewTag);

export default withRouter(Container);
