import React, { Component } from 'react';
import { CircularProgress } from 'rmwc';
import memoizeOne from 'memoize-one';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Wrapper from '../../components/Wrapper';
import HeadersMeta from '../../components/HeadersMeta';
import Button from '../../components/form/Button';
import DataTable from '../../components/DataTable';
import Utils from '../../helpers/Utils';
import { getRobomartsRequest } from '../../store/actions/robomarts';
import Pagination from '../../components/Pagination';
import HasPermission from '../../components/HasPermission';

class Robomarts extends Component {
  getData = memoizeOne(async (query) => {
    this.setState({ currentLoading: true });
    const {
      // eslint-disable-next-line camelcase
      vehicle_ID, name, type, gen, registration_no, VIN,
      predicate, reverse, merchant, station, storefront,
    } = query;
    const predicateObject = {
      name,
      gen,
      registration_no,
      engine_no: VIN,
      vid: vehicle_ID,
      type_name: type,
      org_name: merchant,
      store_name: station,
      storefront_name: storefront,
    };
    const search = { predicateObject };
    const sort = { predicate: predicate || 'name', reverse: reverse || false };
    await this.props.getRobomartsRequest(sort, search);
    this.setState({ currentLoading: false });
  }, _.isEqual);

  static propTypes = {
    robomarts: PropTypes.array.isRequired,
    getRobomartsRequest: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    totalPages: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.columns = [
      { column: 'vehicle_ID', type: 'searchable' },
      { column: 'name', type: 'searchable' },
      // { column: 'type', type: 'searchable' },
      { column: 'storefront', type: 'searchable' },
      { column: 'gen', type: 'searchable' },
      { column: 'registration_no', type: 'searchable' },
      { column: 'VIN', type: 'searchable' },
      // { column: 'merchant', type: 'searchable' },
      { column: 'station', type: 'searchable' },
      { column: 'vehicle_state', type: '' },
    ];
    this.state = {
      currentLoading: false,
    };
  }

  handleAdd = () => {
    this.props.history.push('/admin/shops/add');
  }

  handleEdit = (id) => `/admin/shops/edit/${id}`

  handleRedirectToInventory = (id) => `/admin/shops/inventory/${id}`

  render() {
    const { currentLoading } = this.state;
    const { robomarts, totalPages } = this.props;
    const keysToChange = {
      vid: 'vehicle_ID',
      type_name: 'type',
      engine_no: 'VIN',
      org_name: 'merchant',
      store_name: 'station',
      mode: 'vehicle_state',
      storefront_name: 'storefront',
    };
    const data = Utils.renameKeys(keysToChange, robomarts);

    const query = Utils.queryParse(window.location.search, keysToChange);
    this.getData(query);
    const actions = [];
    if (HasPermission.check('robomart_inventory')) {
      actions.push('inventory');
    }
    if (HasPermission.check('robomart_edit')) {
      actions.push('edit');
    }
    return (
      <Wrapper>
        <HasPermission edit="robomarts" redirect>
          <HeadersMeta page="Shops" />
          <div className="zones">
            <div className="top">
              <div className="loading">
                {currentLoading ? <CircularProgress size="small" /> : null}
              </div>
              <HasPermission edit="robomart_add">
                <Button title="Add Shop" onClick={this.handleAdd} />
              </HasPermission>
            </div>
            <div>
              <DataTable
                columns={this.columns}
                data={data}
                actions={actions}
                onEdit={this.handleEdit}
                onSearch={this.handleSearch}
                onSort={this.handleSort}
                idKey="vehicle_ID"
                onInventory={this.handleRedirectToInventory}
              />
            </div>
            <Pagination currentPage={query.page} totalPages={totalPages} />
          </div>
        </HasPermission>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  robomarts: state.robomarts.robomarts,
  totalPages: state.robomarts.totalPages,
});

const mapDispatchToProps = {
  getRobomartsRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Robomarts);

export default Container;
