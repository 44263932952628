import React, { Component } from 'react';
import ReactPaginate from 'react-paginate';
import { withRouter } from 'react-router-dom';
import { Icon } from 'rmwc';
import PropTypes from 'prop-types';
import Utils from '../helpers/Utils';

class Pagination extends Component {
  static propTypes = {
    currentPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    totalPages: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    history: PropTypes.object.isRequired,
  };

  static defaultProps = {
    currentPage: 1,
    totalPages: 1,
  }

  handleChange = (val) => {
    const query = Utils.queryParse();
    query.page = val.selected + 1;
    this.props.history.replace(`?${Utils.queryStringify(query)}`);
  }

  render() {
    const { currentPage, totalPages, ...props } = this.props;
    if (totalPages < 2) {
      return null;
    }
    return (
      <ReactPaginate
        containerClassName="pagination"
        forcePage={(currentPage || 1) - 1}
        pageCount={totalPages}
        activeClassName="active"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextLinkClassName="page-link"
        nextClassName="page-item"
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        breakLinkClassName="page-link"
        previousLabel={<Icon icon="arrow_back_ios" />}
        nextLabel={<Icon icon="arrow_forward_ios" />}
        disabledClassName="disabled"
        onPageChange={this.handleChange}
        previousAriaDisabled={currentPage === 1}
        nextAriaDisabled={currentPage === totalPages}
        {...props}
      />
    );
  }
}

export default withRouter(Pagination);
