import React, { Component } from 'react';
import {
  Avatar, Icon,
} from 'rmwc';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Account from '../../helpers/Account';
import Outside from '../Outside';

class UserSettings extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  toggleDropdown = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  }

  handleRedirect = (url) => {
    this.props.history.push(url);
  }

  render() {
    const account = Account.get();
    const { open } = this.state;
    return (
      <div className="userSettings">
        <Avatar
          src={account.photo_url}
          size="xlarge"
          name={account.display_name}
          onClick={this.toggleDropdown}
          className="avatar"
        />

        {open ? (
          <Outside component="div" className="dropdown" onClick={this.toggleDropdown} exclude=".avatar">
            <div className="profileInfo">
              <Avatar
                src={account.photo_url}
                size="xlarge"
                name={account.display_name}
              />
              <div className="titles">
                <p className="title">{account.display_name}</p>
                <p>{account.title}</p>
              </div>
            </div>
            <ul>
              <li onClick={() => this.handleRedirect('/profile')}>
                <Icon icon="person" />
                Profile
              </li>
              <li onClick={() => this.handleRedirect('/logout')}>
                <Icon icon="logout" />
                Logout
              </li>
            </ul>
          </Outside>
        ) : null}

      </div>
    );
  }
}

export default withRouter(UserSettings);
