import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { Icon } from 'rmwc';
import Button from '../form/Button';

class Confirmation extends Component {
  static propTypes = {
    title: PropTypes.string,
    isOpen: PropTypes.bool,
    onCloseModal: PropTypes.func,
    onConfirm: PropTypes.func,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    title: '',
    isOpen: false,
    onCloseModal: undefined,
    onConfirm: undefined,
    loading: false,
  }

  render() {
    const { title, isOpen, loading } = this.props;
    if (!isOpen) {
      return null;
    }
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.props.onCloseModal}
        overlayClassName="modalOverlay"
        className="modalContent"
        parentSelector={() => document.querySelector('#root > div')}
      >
        <div className="top">
          <Icon icon="close" className="icon" onClick={this.props.onCloseModal} />
        </div>
        <p>{title}</p>
        <div className="actions">
          <Button title="Cancel" outlined raised={false} onClick={this.props.onCloseModal} />
          <Button title="Confirm" loading={loading} onClick={this.props.onConfirm} />
        </div>
      </Modal>
    );
  }
}

export default Confirmation;
