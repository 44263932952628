import React, { Component } from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import HeadersMeta from '../components/HeadersMeta';
import logo from '../assets/images/logo.svg';
import Input from '../components/formNew/Input';
import Button from '../components/formNew/Button';
import firebase from '../helpers/firebase';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: '',
    };
  }

  handleChange = (ev) => {
    this.setState({ email: ev.target.value });
  }

  handleSubmit = async (ev) => {
    ev.preventDefault();
    const { email } = this.state;
    this.setState({ loading: true });
    if (_.isEmpty(email)) {
      toast.error('Email is required.');
    } else {
      try {
        await firebase.auth().sendPasswordResetEmail(email);
        toast.success('Password reset email sent!');
      } catch (e) {
        if (['auth/invalid-email', 'auth/user-not-found'].includes(e.code)) {
          toast.error(e.message);
        } else {
          toast.error(e);
        }
      }
    }
    this.setState({ loading: false });
  }

  render() {
    const { loading, email } = this.state;
    return (
      <div>
        <HeadersMeta page="Forgot Password" />
        <div className="loginContainer">
          <form action="" onSubmit={this.handleSubmit} className="loginForm" autoComplete="off">
            <div className="logo">
              <img src={logo} alt="" />
            </div>
            <p className="title">Forgot Your Password?</p>
            <span className="desc">Please enter the email address you'd like your password reset information sent to.</span>
            <div className="formFields">
              <Input
                placeholder="Email"
                value={email}
                onChange={this.handleChange}
                autoComplete="off"
                name="email"
              />
              <Button title="Send" loading={loading} disabled={loading} type="submit" />
              <Link to="/login" className="forgotPassword">Back to sign in</Link>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
