import { GET_ROBOMART_TYPES, GET_ROBOMART_TYPE_BY_ID } from '../actions/robomartTypes';

const initialState = {
  robomartTypes: [],
  robomartTypesStatus: '',
  robomartType: {},
  robomartTypeStatus: '',
  totalPages: 1,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ROBOMART_TYPES.REQUEST: {
      return {
        ...state,
        robomartTypesStatus: 'request',
      };
    }
    case GET_ROBOMART_TYPES.SUCCESS: {
      const { data: { data: { Records, RecordsCount } } } = action.payload;
      return {
        ...state,
        robomartTypesStatus: 'ok',
        robomartTypes: Records,
        totalPages: Math.ceil(RecordsCount / 10),
      };
    }
    case GET_ROBOMART_TYPES.FAIL: {
      return {
        ...state,
        robomartTypesStatus: 'fail',
      };
    }
    case GET_ROBOMART_TYPE_BY_ID.REQUEST: {
      return {
        ...state,
        robomartTypeStatus: 'request',
      };
    }
    case GET_ROBOMART_TYPE_BY_ID.SUCCESS: {
      return {
        ...state,
        robomartTypeStatus: 'ok',
      };
    }
    case GET_ROBOMART_TYPE_BY_ID.FAIL: {
      return {
        ...state,
        robomartTypeStatus: 'fail',
      };
    }
    default: {
      return state;
    }
  }
}
