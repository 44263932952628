import { define } from '../../helpers/redux-request';
import Api from '../../Api';

export const GET_PRODUCT_TAGS = define('GET_PRODUCT_TAGS');

export function getProductTagsRequest(robomartId, id) {
  return GET_PRODUCT_TAGS.request(() => Api.getProductTags(robomartId, id));
}

export const GET_PRODUCTS = define('GET_PRODUCTS');

export function getProductsRequest(sort, search, page, number) {
  return GET_PRODUCTS.request(() => Api.getProducts(sort, search, page, number)).onSuccess((p) => {
    p.cacheData = search.cache;
    return p;
  });
}

export const UPDATE_PRODUCT_TAGS = define('UPDATE_PRODUCT_TAGS');

export function updateProductTagsRequest(data) {
  return UPDATE_PRODUCT_TAGS.request(() => Api.updateProductTags(data));
}

export const GET_PRODUCT_ALL_TAGS = define('GET_PRODUCT_ALL_TAGS');

export function getProductAllTagsRequest(pid, search) {
  return GET_PRODUCT_ALL_TAGS.request(() => Api.getProductAllTags(pid, search)).takeLatest();
}

export const DELETE_PRODUCT_TAG = define('DELETE_PRODUCT_TAG');

export function deleteProductTagRequest(obj) {
  return DELETE_PRODUCT_TAG.request(() => Api.deleteProductTag(obj));
}

export const GET_PRODUCT_CATEGORIES = define('GET_PRODUCT_CATEGORIES');

export function getProductCategoriesRequest(sort, search, page, number) {
  return GET_PRODUCT_CATEGORIES.request(() => Api.getProductCategories(sort, search, page, number));
}

export const CREATE_PRODUCT = define('CREATE_PRODUCT');

export function createProductRequest(obj) {
  return CREATE_PRODUCT.request(() => Api.createProduct(obj));
}

export const UPDATE_PRODUCT = define('UPDATE_PRODUCT');

export function updateProductRequest(obj) {
  return UPDATE_PRODUCT.request(() => Api.updateProduct(obj));
}

export const GET_PRODUCT_BY_ID = define('GET_PRODUCT_BY_ID');

export function getProductByIdRequest(id) {
  return GET_PRODUCT_BY_ID.request(() => Api.getProductById(id));
}

export const GET_PRODUCTS_BY_STOREFRONT = define('GET_PRODUCTS_BY_STOREFRONT');

export function getProductsByStorefrontRequest(id) {
  return GET_PRODUCTS_BY_STOREFRONT.request(() => Api.getProductsByStorefront(id));
}
