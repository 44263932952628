import React, { Component } from 'react';
import { connect } from 'react-redux';
import memoizeOne from 'memoize-one';
import _ from 'lodash';
import { CircularProgress } from 'rmwc';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Wrapper from '../../components/Wrapper';
import { deleteStorefrontByIdRequest, getStorefrontsRequest } from '../../store/actions/storefronts';
import Utils from '../../helpers/Utils';
import HeadersMeta from '../../components/HeadersMeta';
import Button from '../../components/form/Button';
import DataTable from '../../components/DataTable';
import Pagination from '../../components/Pagination';
import Confirmation from '../../components/modals/Confirmation';

class Storefronts extends Component {
  getData = memoizeOne(async (query) => {
    this.setState({ currentLoading: true });
    const {
      // eslint-disable-next-line camelcase
      type, merchant, is_active,
      predicate, reverse, name,
    } = query;
    const predicateObject = {
      org_name: merchant,
      type_name: type,
      name,
      state: is_active,
    };
    const search = { predicateObject };
    const sort = { predicate: predicate || 'name', reverse: reverse || false };
    await this.props.getStorefrontsRequest(sort, search, (query.page - 1) || 0);
    this.setState({ currentLoading: false });
  }, _.isEqual);

  static propTypes = {
    getStorefrontsRequest: PropTypes.func.isRequired,
    storefronts: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    deleteStorefrontByIdRequest: PropTypes.func.isRequired,
    totalPages: PropTypes.number.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      currentLoading: false,
      storefrontId: null,
      confirmationModal: false,
      loading: false,
    };
    this.columns = [
      { column: 'name', type: 'searchable' },
      { column: 'merchant', type: 'searchable' },
      { column: 'type', type: 'searchable' },
      { column: 'state', type: '' },
    ];
    this.keysToChange = {
      is_active: 'state',
      type_name: 'type',
      org_name: 'merchant',
    };
  }

  handleAdd = () => {
    this.props.history.push('/admin/storefronts/add');
  }

  handleEdit = (id) => `/admin/storefronts/edit/${id}`

  handleMenu = (id) => `/admin/storefronts/menu/${id}`

  toggleConfirmationModal = (storefrontId) => {
    const { confirmationModal } = this.state;
    this.setState({ confirmationModal: !confirmationModal, storefrontId });
  }

  handleDelete = async () => {
    const { storefrontId } = this.state;
    const { storefronts } = this.props;
    this.setState({ loading: true });
    await this.props.deleteStorefrontByIdRequest(storefrontId, storefronts.find((s) => s.id === storefrontId));
    toast.success('Successfully Deleted');
    const query = Utils.queryParse(window.location.search, this.keysToChange);
    query.deleted = storefrontId;
    await this.getData(query);
    this.setState({ loading: false, confirmationModal: false });
  }

  render() {
    const { storefronts, totalPages } = this.props;
    const { currentLoading, confirmationModal, loading } = this.state;

    const data = Utils.renameKeys(this.keysToChange, storefronts);
    const query = Utils.queryParse(window.location.search, this.keysToChange);

    this.getData(query);
    return (
      <Wrapper>
        <HeadersMeta page="Storefronts" />
        <div className="zones">
          <div className="top">
            <div className="loading">
              {currentLoading ? <CircularProgress size="small" /> : null}
            </div>
            <Button title="Add Storefront" onClick={this.handleAdd} />
          </div>
          <div>
            <DataTable
              columns={this.columns}
              data={data}
              onTag={this.handleRedirectToTags}
              nameKey="product_name"
              actions={['menu', 'edit', 'delete']}
              onEdit={this.handleEdit}
              onMenu={this.handleMenu}
              onDelete={this.toggleConfirmationModal}
            />
          </div>
          <Pagination currentPage={query.page} totalPages={totalPages} />
        </div>
        <Confirmation
          isOpen={confirmationModal}
          title="Are you sure, you want to delete this storefront?"
          onCloseModal={() => this.toggleConfirmationModal(null)}
          onConfirm={this.handleDelete}
          loading={loading}
        />
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  storefronts: state.storefronts.storefronts,
  totalPages: state.storefronts.totalPages,
});

const mapDispatchToProps = {
  getStorefrontsRequest,
  deleteStorefrontByIdRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Storefronts);

export default Container;
