import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  DataTable, DataTableBody, DataTableCell, DataTableContent, DataTableHead, DataTableRow, Icon,
  MenuSurfaceAnchor, Menu, MenuItem,
} from 'rmwc';
import Utils from '../../helpers/Utils';
import InventoryStockList from '../modals/InventoryStockList';

class RobomartsList extends Component {
  static propTypes = {
    vehicles: PropTypes.array,
    history: PropTypes.object.isRequired,
    mapRef: PropTypes.object,
    rcs: PropTypes.array,
  };

  static defaultProps = {
    vehicles: [],
    rcs: [],
    mapRef: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      robomartName: '',
      open: false,
      robomartId: null,
      dropdown: null,
    };
  }

  componentDidMount() {
    window.addEventListener('click', this.closeDropdown, true);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.closeDropdown, true);
  }

  closeDropdown = (ev) => {
    const { dropdown } = this.state;
    if (ev.target !== this.menuItem && dropdown) {
      this.setState({ dropdown: null });
    }
  }

  toggleDropdown = (id) => {
    const { dropdown } = this.state;
    if (dropdown) {
      this.setState({ dropdown: null });
    } else {
      this.setState({ dropdown: id });
    }
  }

  getRequestState = (state) => ({
    1: '',
    2: 'BEING ASSIGNED',
    3: 'EN ROUTE',
    4: 'ARRIVED',
    5: 'DOORS OPENING',
    6: 'SHOPPING',
    7: 'END SHOPPING',
    8: 'DOORS CLOSING',
    9: 'CHECK BASKET',
    0: 'END',
  })[state]

  getVehicleData = (ev, vehicle) => {
    this.props.mapRef.handleHighlightMarker(ev, vehicle, false);
    const query = Utils.queryParse();
    query.vehicle_id = vehicle.id;
    query.vehicle_name = vehicle.name;
    this.props.history.replace(`?${Utils.queryStringify(query)}`);
  }

  handleCheck = (robomartName, robomartId) => {
    this.setState({ robomartName, open: true, robomartId });
  }

  handleCloseModal = () => {
    this.setState({ robomartName: '', open: false, robomartId: null });
  }

  getStoreData = (store) => {
    this.props.mapRef.handleHighlightStoreMarker({ store_id: store.store_id, coords: store.coords });
    this.props.mapRef.openInfoWindow(store.store_id);
  }

  handleRedirect = (id) => {
    this.props.history.push(`/admin/shops/edit/${id}`);
  }

  render() {
    const { vehicles, rcs } = this.props;
    const {
      robomartName, open, robomartId, dropdown,
    } = this.state;
    const query = Utils.queryParse();
    return (
      <div className="robomartsList">
        <DataTable className="vehicles" ref={(ref) => this.list = ref}>
          <DataTableContent>
            <DataTableHead>
              <DataTableRow>
                <DataTableCell>ROBOMART</DataTableCell>
                <DataTableCell>STATE</DataTableCell>
                <DataTableCell>STATION</DataTableCell>
                <DataTableCell>STOCK</DataTableCell>
                <DataTableCell />
                {/* <DataTableCell>CAM</DataTableCell> */}
              </DataTableRow>
            </DataTableHead>
            <DataTableBody>
              {vehicles.map((v) => (
                <DataTableRow
                  className={`
                  ${query.v_id === v.id ? 'robomartListItem' : ''} 
                  ${query.vehicle_id === v.id ? 'selected' : ''}
                  ${v.customer_id !== undefined && query?.customer_id === v?.customer_id ? 'highlighted' : ''}`}
                  key={v.id}
                >
                  <DataTableCell
                    className="vehicleName"
                    onClick={(ev) => this.getVehicleData(ev, v)}
                  >
                    {v.name}
                  </DataTableCell>
                  <DataTableCell>{this.getRequestState(v.request_state)}</DataTableCell>
                  <DataTableCell
                    className="vehicleName"
                    onClick={() => this.getStoreData(rcs.find((r) => r.store_id === v.rc_id))}
                  >
                    {rcs.find((r) => r.store_id === v.rc_id)?.name}
                  </DataTableCell>
                  <DataTableCell className="check" onClick={() => this.handleCheck(v.name, v.id)}>CHECK</DataTableCell>
                  <DataTableCell className="dots" onClick={() => this.toggleDropdown(v.id)}>
                    <Icon icon="more_vert" />
                    {dropdown === v.id ? (
                      <MenuSurfaceAnchor className="list">
                        <Menu open renderToPortal={this.list}>
                          {['Edit Robomart'].map((o) => (
                            <MenuItem
                              ref={(ref) => this.menuItem = ref}
                              key={o}
                              onClick={() => this.handleRedirect(v.id, v.name)}
                            >
                              {o}
                            </MenuItem>
                          ))}
                        </Menu>
                      </MenuSurfaceAnchor>
                    ) : null}
                  </DataTableCell>
                  {/* <DataTableCell className="videoCam"><img src={cameraPng} alt="" /></DataTableCell> */}
                </DataTableRow>
              ))}
            </DataTableBody>
          </DataTableContent>
        </DataTable>
        <InventoryStockList
          isOpen={open}
          robomartName={robomartName}
          onCloseModal={this.handleCloseModal}
          robomartId={robomartId}
        />
      </div>
    );
  }
}

export default withRouter(RobomartsList);
