import axios from 'axios';
import fileDownload from 'js-file-download';
import { serialize } from 'object-to-formdata';
import Account from './helpers/Account';
import config from './config';
import { getStatesDynamicLink } from './store/actions/consumers';

const apiUrl = config.API_URL;
const localServerUrl = 'http://localhost:5000';

const api = axios.create({
  baseURL: apiUrl,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(async (c) => {
  const token = 'fTJbtcdaD0MGfTy4RF2OQNi/ZN8MPYcUuzzk+E80G2rUeDLeC0qg/ftucUqfgfYO';
  const fbToken = await Account.getFBToken();

  if (token && fbToken) {
    c.headers.token = token;
    c.headers.fbtoken = fbToken;
  }
  return c;
}, (error) => Promise.reject(error));
const apiTokens = {};

function toFormData(data) {
  return serialize({ ...data }, { indices: false });
}

class Api {
  static cancel(methodName) {
    if (apiTokens[methodName]) {
      apiTokens[methodName]();
    }
  }

  static cancelToken(methodName) {
    return new axios.CancelToken((token) => {
      apiTokens[methodName] = token;
    });
  }

  static getZones(sort = {}, search = {}, page = 0, number = 10) {
    return api.get('/v3/api/Zone/GetAll', {
      params: {
        tableState: {
          sort,
          search,
          pagination: {
            start: page * 10, totalItemCount: 0, number,
          },
        },
      },
    });
  }

  static createZone(obj) {
    return api.post('/v3/api/Zone/Create', obj);
  }

  static deleteZone() {
    return api.delete('/v3/api/Zone/Delete', { data: { id: '13a6a7f5-e6ec-4272-a5df-79e0b43c243f' } });
  }

  static getZoneById(id) {
    return api.get('/v3/api/Zone/GetById', {
      params: { id },
    });
  }

  static updateZone(obj) {
    return api.post('/v3/api/Zone/Update', obj);
  }

  static getMerchants(sort = {}, search = {}, page = 0, number = 10) {
    return api.get('/v3/api/organization/GetAll', {
      params: {
        tableState: {
          sort,
          search,
          pagination: {
            start: page, totalItemCount: 0, number,
          },
        },
      },
    });
  }

  static getMerchantById(id) {
    return api.get('/v3/api/organization/GetById', {
      params: { id },
    });
  }

  static createMerchant(obj) {
    return api.post('/v3/api/organization/Create', obj);
  }

  static updateMerchant(obj) {
    return api.post('/v3/api/organization/Update', obj);
  }

  static getRCs(sort = {}, search = {}, page = 0, number = 10) {
    return api.get('/v3/api/Store/GetAll', {
      params: {
        tableState: {
          sort,
          search,
          pagination: {
            start: page, totalItemCount: 0, number,
          },
        },
      },
    });
  }

  static createRC(obj) {
    return api.post('/v3/api/Store/Create', obj);
  }

  static getRCById(id) {
    return api.get('/v3/api/Store/GetById', {
      params: { id },
    });
  }

  static updateRC(obj) {
    return api.post('/v3/api/Store/Update', obj);
  }

  static deleteRC(storeId) {
    return api.delete(`/v3/api/Store/Delete?store_id=${storeId}`);
  }

  static getRobomarts(sort = {}, search = {}, page = 0, number = 10) {
    return api.get('/v3/api/vehicle/GetAllPagging', {
      params: {
        tableState: {
          sort,
          search,
          pagination: {
            start: page, totalItemCount: 0, number,
          },
        },
      },
    });
  }

  static createRobomart(obj) {
    return api.post('/v3/api/vehicle/Create', obj);
  }

  static getRobomartById(id) {
    return api.get('/v3/api/vehicle/GetById', {
      params: { id },
    });
  }

  static updateRobomart(obj) {
    return api.post('/v3/api/vehicle/Update', obj);
  }

  static getInventory(id) {
    return api.get('/v3/api/Robomart/GetInventoryCounts', { headers: { vehicle_id: id } });
  }

  static updateBaselineInventory(id) {
    return api.post('/v3/api/Robomart/UpdateBaselineInventory', {}, { headers: { vehicle_id: id } });
  }

  static getProductTags(robomartId, id) {
    return api.get(`/v3/api/product/GetProductTags/${robomartId}/${id}`);
  }

  static updateProductTags(data) {
    return api.post('/v3/api/Product/UpdateTags', data);
  }

  static getProductAllTags(pid, search) {
    return api.get('/v3/api/Product/GetAllProductTags', { params: { pid, search } });
  }

  static deleteProductTag(obj) {
    return api.delete('/v3/api/Product/DeleteTag', { data: { ...obj } });
  }

  static createProduct(obj) {
    return api.post('/v3/api/Product/Create', obj);
  }

  static updateProduct(obj) {
    return api.post('/v3/api/Product/Update', obj);
  }

  static getProductById(id) {
    return api.get('/v3/api/Product/GetProduct', { params: { id } });
  }

  static getProductCategories(sort = {}, search = {}, page = 0, number = 10) {
    return api.get('/v3/api/Product/GetAllProductCategories', {
      params: {
        tableState: {
          sort,
          search,
          pagination: {
            start: page, totalItemCount: 0, number,
          },
        },
      },
    });
  }

  static getType(sort = {}, search = {}, page = 0) {
    return api.get('/v3/api/type/GetAll', {
      params: {
        tableState: {
          sort,
          search,
          pagination: {
            start: page, totalItemCount: 0, number: 10, numberOfPages: 1,
          },
        },
      },
    });
  }

  static getProducts(sort = {}, search = {}, page = 0, number = 10) {
    return api.get('/v3/api/Product/GetAllPagging', {
      params: {
        tableState: {
          sort,
          search,
          pagination: {
            start: page * 10, totalItemCount: 0, number,
          },
        },
      },
    });
  }

  static getGroups(sort = {}, search = {}, page = 0, number = 10) {
    return api.get('/v3/api/group/GetAllPagging', {
      params: {
        tableState: {
          sort,
          search,
          pagination: {
            start: page * 10, totalItemCount: 0, number,
          },
        },
      },
    });
  }

  static getGroupAllRights() {
    return api.get('/v3/api/group/GetAllRights', {
      params: {
        tableState: {
          sort: {},
          search: {},
          pagination: {
            start: 0, totalItemCount: 0, number: 100,
          },
        },
      },
    });
  }

  static createGroup(obj) {
    return api.post('/v3/api/group/Create', obj);
  }

  static updateGroup(obj) {
    return api.post('/v3/api/group/Update', obj);
  }

  static getGroupById(id) {
    return api.get('/v3/api/group/GetById', {
      params: { id },
    });
  }

  static getUsers(sort = {}, search = {}, page = 0, number = 10) {
    return api.get('/v3/api/user/GetAllPagging', {
      params: {
        tableState: {
          sort,
          search,
          pagination: {
            start: page * 10, totalItemCount: 0, number,
          },
        },
      },
    });
  }

  static createUser(obj) {
    return api.post('/v3/api/user/Create', obj);
  }

  static updateUser(obj) {
    return api.post('/v3/api/user/Update', obj);
  }

  static getUserById(id) {
    return api.get('/v3/api/user/GetById', {
      params: { id },
    });
  }

  static deleteUser(id) {
    return api.delete(`/v3/api/user/Delete?user_id=${id}`);
  }

  static getDrivers(sort = {}, search = {}, page = 0, number = 10) {
    return api.get('/v3/api/user/GetAllDriversPagging', {
      params: {
        tableState: {
          sort,
          search,
          pagination: {
            start: page * 10, totalItemCount: 0, number,
          },
        },
      },
    });
  }

  static getUnassignedVehicles(id) {
    return api.get('/v3/api/Vehicle/GetAllUnAssignVehicle', {
      params: { user_id: id },
    });
  }

  static assignVehicle(obj) {
    return api.post('/v3/api/User/AssignVehicle', obj);
  }

  static getConsumers(sort = {}, search = {}, page = 0, number = 10) {
    return api.get('/v3/api/Consumer/GetAll', {
      params: {
        tableState: {
          sort,
          search,
          pagination: {
            start: page * 10, totalItemCount: 0, number,
          },
        },
      },
    });
  }

  static deleteConsumer(id) {
    return api.delete(`/v3/api/Consumer/Delete?user_id=${id}`);
  }

  static getOrders(sort = {}, search = {}, page = 0, number = 10) {
    this.cancel('getOrders');
    return api.get('/v3/api/order/GetAllOrder', {
      cancelToken: this.cancelToken('getOrders'),
      params: {
        tableState: {
          sort,
          search,
          pagination: {
            start: page * 10, totalItemCount: 0, number,
          },
        },
      },
    });
  }

  static getOrderOfConsumer(id, sort = {}, search = {}, page = 0, number = 10) {
    return api.get('/v3/api/order/GetAllByConsumer', {
      params: {
        consumer_id: id,
        tableState: {
          sort,
          search,
          pagination: {
            start: page * 10, totalItemCount: 0, number,
          },
        },
      },
    });
  }

  static getSingleCostumer(id) {
    return api.get('/v3/api/Consumer/GetProfile', { params: { user_id: id } });
  }

  static getRobomartTypes(sort = {}, search = {}, page = 0, number = 10) {
    this.cancel('getRobomartTypes');
    return api.get('/v3/api/type/GetAll', {
      cancelToken: this.cancelToken('getRobomartTypes'),
      params: {
        tableState: {
          sort,
          search,
          pagination: {
            start: page * 10, totalItemCount: 0, number,
          },
        },
      },
    });
  }

  static createType(obj) {
    return api.post('/v3/api/type/Create', obj);
  }

  static updateType(obj) {
    return api.post('/v3/api/type/Update', obj);
  }

  static getRobomartTypeById(id) {
    return api.get('/v3/api/Storefront/GetById', {
      params: { id },
    });
  }

  static getProductsByStorefront(id) {
    return api.get(`/v3/api/Storefront/GetProducts/${id}`);
  }

  static addProducts(obj) {
    return api.post('/v3/api/storefront/AddProducts', obj);
  }

  static deleteProduct(obj) {
    return api.delete('/v3/api/Storefront/DeleteProduct', { data: { ...obj } });
  }

  static getApplicants(sort = {}, search = {}, page = 0, number = 10) {
    this.cancel('getApplicants');
    return api.get('/v3/api/Invite/GetAll', {
      cancelToken: this.cancelToken('getApplicants'),
      params: {
        tableState: {
          sort,
          search,
          pagination: {
            start: page * 10, totalItemCount: 0, number,
          },
        },
      },
    });
  }

  static genCode(obj) {
    return api.post('/v3/api/Invite/GenCode', obj);
  }

  static getApplicantTypeById(id) {
    return api.get('/v3/api/Invite/GetById', {
      params: { id },
    });
  }

  static updateApplicant(obj) {
    return api.post('/v3/api/Invite/Update', obj);
  }

  static getInviteCodes(sort = {}, search = {}, page = 0, number = 10) {
    this.cancel('getInviteCodes');
    return api.get('/v3/api/Invite/GetAllInviteCodes', {
      cancelToken: this.cancelToken('getInviteCodes'),
      params: {
        tableState: {
          sort,
          search,
          pagination: {
            start: page * 10, totalItemCount: 0, number,
          },
        },
      },
    });
  }

  static genCodeForConsumers(obj) {
    return api.post('/v3/api/Invite/GenerateCodeForConsumer', obj);
  }

  static updateCodeForConsumers(obj) {
    return api.post('/v3/api/Invite/UpdateInviteCode', obj);
  }

  static getSettings() {
    return api.get('/v3/api/GlobalSettings/Get');
  }

  static updateSettings(obj) {
    return api.post('/v3/api/GlobalSettings/Update', obj);
  }

  static getCustomToken() {
    return api.post('/v3/api/User/GetCustomToken?requestedRight=1');
  }

  static getInventoryStockList(vid) {
    return api.get('/v3/api/robomart/GetInventory', { params: { vid } });
  }

  static cancelOrder(id, orderId) {
    return api.delete(`/v3/api/order/cancel/${id}/${orderId}`);
  }

  static updateRobomartLocation(lat, lng, vehicleId) {
    return api.post('/v3/api/Robomart/UpdateLocationForTest', { lat, lng, vehicle_id: vehicleId });
  }

  static async exportIntercomCsv(period) {
    const response = await axios.get(`${localServerUrl}/get_intercom_data`, { params: { period }, responseType: 'arraybuffer' });
    fileDownload(response.data, 'intercom.csv');
    return response;
  }

  static getOrderReceipt(orderId) {
    return api.get('/v3/api/Order/GetOrderWithTags', { params: { order_id: orderId } });
  }

  static getCouponTags() {
    return api.get('/v3/api/Coupon/getTags');
  }

  static fileUpload(file) {
    return api.post('/v3/api/Upload', toFormData({ img: file }));
  }

  static getStorefronts(sort = {}, search = {}, page = 0, number = 10) {
    this.cancel('getStorefronts');
    return api.get('/v3/api/storefronts/GetAllPagging', {
      cancelToken: this.cancelToken('getStorefronts'),
      params: {
        tableState: {
          sort,
          search,
          pagination: {
            start: page * 10, totalItemCount: 0, number,
          },
        },
      },
    });
  }

  static createStorefront(obj) {
    return api.post('/v3/api/storefronts', obj);
  }

  static updateStorefront(obj) {
    return api.put('/v3/api/storefronts', obj);
  }

  static getStorefrontById(id) {
    return api.get(`/v3/api/storefront/${id}`);
  }

  static deleteStorefrontById(id, data) {
    return api.delete(`/v3/api/storefront/${id}`, { data });
  }

  static getCategories(sort = {}, search = {}, page = 0, number = 10) {
    this.cancel('getCategories');
    return api.get('/v3/api/Category/GetAllPagging', {
      cancelToken: this.cancelToken('getCategories'),
      params: {
        tableState: {
          sort,
          search,
          pagination: {
            start: page * 10, totalItemCount: 0, number,
          },
        },
      },
    });
  }

  static getActiveCategories() {
    return api.get('/v3/api/category/GetAllActive');
  }

  static createCategory(obj) {
    return api.post('/v3/api/Category/Create', obj);
  }

  static getCategoryById(id) {
    return api.get('/v3/api/Category/GetById', { params: { id } });
  }

  static updateCategory(obj) {
    return api.post('/v3/api/Category/Update', obj);
  }

  static deleteCategory(obj) {
    return api.delete('/v3/api/Category/Delete', { data: { ...obj } });
  }

  static getCredits(sort = {}, search = {}, page = 0, number = 10) {
    this.cancel('getCredits');
    return api.get('/v3/api/credits/GetAllPagging', {
      cancelToken: this.cancelToken('getCredits'),
      params: {
        tableState: {
          sort,
          search,
          pagination: {
            start: page * 10, totalItemCount: 0, number,
          },
        },
      },
    });
  }

  static getCreditsAllTags() {
    return api.get('/v3/api/credits/GetTags');
  }

  static getContactsByTagId(TagId) {
    return api.get('/v3/api/credits/GetContactsByTagID', { params: { TagId } });
  }

  static createCredit(data) {
    return api.post('/v3/api/credits', data);
  }

  static deleteCredit(obj) {
    return api.delete('/v3/api/credits', { data: { ...obj } });
  }

  static getDynamicLink(id) {
    return api.get('/v3/api/Consumer/GetStatsDynamicLink', { params: { dynamicLink: id } });
  }
}

export default Api;
