const firebaseConfigsProd = {
  apiKey: "AIzaSyAt9gbR0BK621MmgV9yxY4aq6u4E2bFzoY",
  authDomain: "robomart-ai.firebaseapp.com",
  databaseURL: "https://robomart-ai-default-rtdb.firebaseio.com",
  projectId: "robomart-ai",
  storageBucket: "robomart-ai.appspot.com",
  messagingSenderId: "760466381060",
  appId: "1:760466381060:web:b5c79e0f79a4c69e439851",
  measurementId: "G-DPJKGSMQ73"
};

export default firebaseConfigsProd;
