/* global google */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import { connect } from 'react-redux';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import mapStyle from '../assets/styles/mapStyle.json';
import Polygon from '../helpers/Polygon';
import Utils from '../helpers/Utils';
import { setGoogle } from '../store/actions/app';
import Address from '../helpers/Address';
import storeMarker from '../assets/images/markers/store.png';
import storeMarkerHighlighted from '../assets/images/markers/storeHilighted.png';
import robomartMarker from '../assets/images/markers/robomart-marker.png';
import customerMarker from '../assets/images/markers/location-pin.png';
import Filters from './fleet/Filters';
import QueryStorage from '../helpers/QueryStorage';
import Api from '../Api';

const robo = '/icons/robo.png';
const roboHigh = '/icons/roboHigh.png';
const customerMarkerPulsing = '/icons/pulse.png?customerMarkerId=customerMarkerPulsing';

class GoogleMaps extends Component {
  createMarker = memoizeOne((markerCoords, placeId) => {
    const { markerPlaceId } = this.state;
    if (this.marker) {
      this.marker.setPosition(markerCoords);
      this.handleBoundsOnMarker(markerCoords.lat, markerCoords.lng, false);
    }
    if ((_.isEmpty(markerCoords) || this.marker) && markerPlaceId === placeId) {
      return;
    }
    if (markerCoords) {
      this.handleMarkerCreate();
      this.setState({ markerPlaceId: placeId });
    }
  })

  updateVehiclesMarkers = memoizeOne((data) => {
    if (this.robomartMarkers) {
      this.robomartMarkers.forEach(async (marker) => {
        const newMarker = data.find((d) => d.id === marker.metadata.id);
        const latlng = new google.maps.LatLng(newMarker?.lat, newMarker?.lng);
        const icon = marker.getIcon();
        icon.url = this.highlightedMarker?.id === marker.metadata.id ? `${roboHigh}?markerId=${marker.metadata.id}` : `${robo}?markerId=${marker.metadata.id}`,
        marker.setIcon(icon);

        const rotation = Utils.normalizeRotation(newMarker.rotation);
        marker.metadata.oldRotation = marker.metadata.rotation || 0;
        marker.metadata.rotation = Utils.closestEquivalentAngle(marker.metadata.oldRotation, rotation);

        this.setState({ mapClick: false });
        await Utils.sleep(500);
        await Utils.animateMarkerTo(marker, latlng);
        this.setState({ mapClick: true });
        if (newMarker?.request_state === '3' && newMarker?.polyline) {
          const { map } = this.state;
          const path = google.maps.geometry.encoding?.decodePath(newMarker?.polyline);
          if (this.polylines.some((s) => s.metadata.id === newMarker?.id)) {
            this.polylines.forEach((p) => {
              if (p.metadata?.id === newMarker?.id) {
                p.setPath(path);
              }
            });
          } else {
            const polyline = new google.maps.Polyline({
              strokeColor: '#000000',
              strokeWeight: 2,
              path,
              clickable: false,
              zIndex: 8,
              metadata: {
                id: newMarker?.id,
              },
            });
            polyline.setMap(map);
            this.polylines = this.polylines ? [...this.polylines, polyline] : [polyline];
          }
        } else if (newMarker?.request_state !== '3') {
          this.polylines?.forEach((p) => {
            if (p.metadata?.id === newMarker?.id) {
              p.setMap(null);
              this.polylines = this.polylines.filter((m) => m.metadata.id !== p.metadata.id);
            }
          });
        }
      });
      const newV = data.map((d) => d.id);
      const oldV = this.robomartMarkers.map((d) => d.metadata.id);
      this.robomartMarkers.forEach((c) => {
        if (!newV.includes(c.metadata.id)) {
          c.setMap(null);
          this.robomartMarkers = this.robomartMarkers.filter((m) => m.metadata.id !== c.metadata.id);
        }
      });
      data.forEach((d) => {
        if (!oldV.includes(d.id)) {
          this.renderVehiclesMarkers([d], this.state.map);
        }
      });
    }
  }, _.isEqual)

  updateCustomerMarkers = memoizeOne((data) => {
    if (this.customerMarkes) {
      const newOrders = data.map((d) => d.id);
      const oldOrders = this.customerMarkes.map((d) => d.metadata.id);
      this.customerMarkes.forEach((c) => {
        if (!newOrders.includes(c.metadata.id)) {
          c.setMap(null);
          this.customerMarkes = this.customerMarkes.filter((m) => m.metadata.id !== c.metadata.id);
        }
      });
      this.pulsingMarkers.forEach((c) => {
        if (!newOrders.includes(c.metadata.id)) {
          c.setMap(null);
          this.pulsingMarkers = this.pulsingMarkers.filter((m) => m.metadata.id !== c.metadata.id);
        }
      });
      data.forEach((d) => {
        if (!oldOrders.includes(d.id)) {
          this.renderCustomerMarkers([d], this.state.map);
        }
        if (oldOrders.includes(d.id) && d.status === '2' && !this.pulsingMarkers.some((s) => s.metadata.id === d.id)) {
          const pulsingMarker = new google.maps.Marker({
            position: { lat: +d.lat, lng: +d.lng },
            map: this.state.map,
            icon: {
              url: customerMarkerPulsing,
              scaledSize: new google.maps.Size(90, 90),
              anchor: new google.maps.Point(40, 45),
            },
            title: d.title,
            zIndex: 8,
            metadata: {
              id: d.id,
            },
          });
          this.pulsingMarkers = [...this.pulsingMarkers, pulsingMarker];
        } else if (oldOrders.includes(d.id) && d.status !== '2') {
          this.pulsingMarkers.forEach((c) => {
            if (c.metadata.id === d.id) {
              c.setMap(null);
              this.pulsingMarkers = this.pulsingMarkers.filter((m) => m.metadata.id !== c.metadata.id);
            }
          });
        }
      });
    }
  }, _.isEqual)

  updatePolygon = memoizeOne((coords) => {
    const coordsArr = Polygon.parse(coords) || [];
    if (this.polygon) {
      this.polygon.setPaths(coordsArr[0]);
      this.handlePolygonChange(this.polygon);
    }
  })

  renderCustomerMarkers = memoizeOne(async (markers, map) => {
    const infoWindow = new google.maps.InfoWindow();
    const query = Utils.queryParse(window.location.search, {}, '', 'comma');
    let id = '';

    const markers1 = markers.map((m) => {
      const marker = new google.maps.Marker({
        position: { lat: +m.lat, lng: +m.lng },
        map,
        icon: {
          url: customerMarker,
          scaledSize: new google.maps.Size(30, 30),
          anchor: new google.maps.Point(15, 15),
        },
        title: m.title,
        zIndex: 9,
        metadata: {
          id: m.id,
        },
      });
      if (m.status === '2') {
        const pulsingMarker = new google.maps.Marker({
          position: { lat: +m.lat, lng: +m.lng },
          map,
          icon: {
            url: customerMarkerPulsing,
            scaledSize: new google.maps.Size(90, 90),
            anchor: new google.maps.Point(40, 45),
          },
          title: m.title,
          zIndex: 8,
          metadata: {
            id: m.id,
          },
        });
        this.pulsingMarkers = [...this.pulsingMarkers, pulsingMarker];
      }
      const title = marker.getTitle();
      const contentString = '<span class="infoWindow"><span>(</span><span class="link"'
        + ' id="contentString">view</span><span>)</span></span>';
      if (marker.metadata.id === query.customer_id) {
        this.activeInfoWindow?.close();
        infoWindow.close();
        infoWindow.setContent(title === 'Restock' ? title : `${title} ${contentString}`);
        infoWindow.open(marker.getMap(), marker);
        this.activeInfoWindow = infoWindow;
        google.maps.event.addListener(infoWindow, 'domready', () => {
          const link = document.querySelector('#contentString');
          if (link) {
            link.addEventListener('click', () => {
              this.props.onRedirect('consumers/orders', query.customer_id);
            });
          }
        });
      }
      marker.addListener('click', () => {
        this.activeInfoWindow?.close();
        infoWindow.close();
        infoWindow.setContent(title === 'Restock' ? title : `${title} ${contentString}`);
        infoWindow.open(marker.getMap(), marker);
        this.activeInfoWindow = infoWindow;
        if (this.props.onReplaceUrl) {
          this.props.onReplaceUrl({ customer_id: m.id });
        }
        id = m.id;
      });

      return marker;
    });
    google.maps.event.addListener(infoWindow, 'domready', () => {
      const link = document.querySelector('#contentString');
      if (link) {
        link.addEventListener('click', () => {
          if (this.props.onRedirect) {
            this.props.onRedirect('consumers/orders', id);
          }
        });
      }
    });

    if (this.customerMarkes) {
      this.customerMarkes = [...this.customerMarkes, ...markers1];
    } else {
      this.customerMarkes = markers1;
    }
  })

  static propTypes = {
    onGetPolygon: PropTypes.func,
    onGetAddressData: PropTypes.func,
    onMarkerCoorsChange: PropTypes.func,
    coords: PropTypes.any,
    className: PropTypes.string,
    drawingControl: PropTypes.bool,
    setGoogle: PropTypes.func.isRequired,
    placeId: PropTypes.string,
    markerCoords: PropTypes.object,
    filters: PropTypes.bool,
    vehiclesMarkers: PropTypes.array,
    storeMarkers: PropTypes.array,
    customersMarkers: PropTypes.array,
    onRedirect: PropTypes.func,
    onReplaceUrl: PropTypes.func,
    vehicleId: PropTypes.string,
  };

  static defaultProps = {
    coords: '',
    className: '',
    drawingControl: false,
    onGetPolygon: undefined,
    onGetAddressData: undefined,
    markerCoords: undefined,
    onMarkerCoorsChange: undefined,
    onReplaceUrl: undefined,
    placeId: null,
    filters: false,
    vehicleId: undefined,
    vehiclesMarkers: [],
    storeMarkers: [],
    customersMarkers: [],
    onRedirect: () => {
    },
  }

  constructor(props) {
    super(props);
    this.renderCount = 0;
    this.state = {
      markerDraged: false,
      markerPlaceId: null,
      bounded: false,
      mapClick: true,
    };
    this.polygons = {};
    this.polylines = [];
    this.pulsingMarkers = [];
    this.duplicatedPolygons = [];
  }

  componentDidMount() {
    this.mapInit();
  }

  componentDidUpdate(prevProps) {
    const {
      vehiclesMarkers, storeMarkers, filters, coords, customersMarkers,
    } = this.props;
    const { map, bounded } = this.state;

    if (!filters && coords) {
      if (prevProps.coords.length) {
        this.updatePolygon(coords);
      } else if (!this.polygon) {
        this.handlePolygonCreate(coords);
      }
    }

    if (filters) {
      const query = Utils.queryParse(window.location.search, {}, '', 'comma');
      let z = [];
      if (query.zone) {
        z = _.isArray(query.zone) ? query.zone : [query.zone];
      }
      this.duplicatedPolygons.forEach((d) => {
        if (!z.includes(d.metadata?.id)) {
          d.setMap(null);
        }
      });
      this.duplicatedPolygons = this.duplicatedPolygons.filter((d) => z.includes(d.metadata?.id));
      if (coords) {
        coords.forEach((c) => {
          // const bounding = coords.length !== Object.keys(this.polygons)?.length;
          // console.log(bounding);
          this.handlePolygonCreate(c.coords, false, false, c.id, false);
        });

        Object.keys(this.polygons).forEach((p) => {
          if (!z.includes(p)) {
            delete this.polygons[p];
          }
        });

        if (_.isEmpty(coords) && this.polygon) {
          this.polygon.setMap(null);
        }
      }
      if (vehiclesMarkers.length) {
        if (!prevProps.vehiclesMarkers.length) {
          this.renderCount += 1;
          this.renderVehiclesMarkers(vehiclesMarkers, map);
        } else {
          this.updateVehiclesMarkers(vehiclesMarkers);
        }
      }
      if (storeMarkers.length) {
        this.renderStoreMarkers(storeMarkers, map);
        this.renderCount += 1;
      }
      if (!prevProps.customersMarkers.length) {
        this.renderCount += 1;
        this.renderCustomerMarkers(customersMarkers, map);
      } else {
        this.updateCustomerMarkers(customersMarkers);
      }

      const zoomData = QueryStorage.getZoomData();
      if (this.renderCount === 3 && _.isEmpty(zoomData)) {
        this.handleFitBounds([...vehiclesMarkers, ...storeMarkers]);
      }
      if (!_.isEmpty(zoomData) && !bounded) {
        this.setState({ bounded: true });
      }
    }
  }

  handleBoundsOnMarker = (lat, lng, marker = true) => {
    this.handleFitBounds([{ lat, lng }], marker);
  }

  handleHighlightMarker = (ev, marker, map = true) => {
    this.highlightedMarker = marker;
    if (this.robomartMarkers) {
      this.robomartMarkers.forEach(async (m) => {
        const icon = m.getIcon();
        const { metadata: { id } } = m;
        icon.url = id === marker.id ? `${roboHigh}?markerId=${id}` : `${robo}?markerId=${id}`;
        m.setIcon(icon);
        if (!map) {
          this.handleBoundsOnMarker(marker?.location?.lat, marker?.location?.lng);
        }
      });
    }
  }

  handleHighlightStoreMarker = (marker) => {
    this.highlightedMarker = marker;

    if (this.storeMarkers) {
      this.storeMarkers.forEach((m) => {
        const icon = m.getIcon();
        const { metadata: { id } } = m;
        if (id === marker.store_id) {
          icon.url = storeMarkerHighlighted;
        } else {
          icon.url = storeMarker;
        }
        m.setIcon(icon);
      });
      const [lat, lng] = marker.coords.split(',');
      this.handleBoundsOnMarker(lat, lng);
    }
  }

  renderVehiclesMarkers = async (markers, map) => {
    const infoWindow = new google.maps.InfoWindow();
    const query = Utils.queryParse();
    await Utils.prefetchImages(robomartMarker);
    this.robomartMarkers = await Promise.all(markers.map(async (m) => {
      const marker = new google.maps.Marker({
        position: { lat: +m.lat, lng: +m.lng },
        map,
        icon: {
          url: query.vehicle_id === m.id ? `${roboHigh}?markerId=${m.id}` : `${robo}?markerId=${m.id}`,
          scaledSize: new google.maps.Size(50, 50),
          anchor: new google.maps.Point(25, 25),
        },
        zIndex: 4,
        metadata: {
          id: m.id,
          rotation: Utils.normalizeRotation(m.rotation),
          oldRotation: 0,
        },
      });
      if (m.request_state === '3' && m.polyline) {
        const path = google.maps.geometry.encoding?.decodePath(m.polyline);
        const polyline = new google.maps.Polyline({
          strokeColor: '#000000',
          strokeWeight: 2,
          path,
          zIndex: 8,
          clickable: false,
          metadata: {
            id: m.id,
          },
        });
        polyline.setMap(map);
        this.polylines = this.polylines ? [...this.polylines, polyline] : [polyline];
      }

      marker.addListener('mouseover', () => {
        const lastUpdated = this.getLastUpdated(m.id);
        infoWindow.setContent(`${m.title} (${lastUpdated}s)`);
        infoWindow.open(marker.getMap(), marker);
      });
      marker.addListener('mouseout', () => {
        infoWindow.close();
      });
      marker.addListener('click', (ev) => {
        this.handleHighlightMarker(ev, { id: m.id, location: { lat: +m.lat, lng: +m.lng } });
        if (this.props.onReplaceUrl) {
          this.props.onReplaceUrl({ vehicle_name: m.title, vehicle_id: m.id });
        }
      });
      return marker;
    }));
  }

  getLastUpdated = (id) => {
    const { vehiclesMarkers } = this.props;
    const markerData = vehiclesMarkers.find((v) => v.id === id);
    return Math.round((new Date().getTime() - markerData.updatedAt) / 1000);
  }

  openInfoWindow = (id) => {
    this.storeMarkers.forEach((m) => {
      if (m.metadata.id === id) {
        const infoWindow = new google.maps.InfoWindow();
        const contentString = '<span class="infoWindow"><span>(</span><span class="link" id="contentString">Edit</span><span>)</span></span>';
        infoWindow.setContent(`${m.title}  ${contentString}`);
        this.activeInfoWindow?.close();
        infoWindow.open(m.getMap(), m);

        this.activeInfoWindow = infoWindow;
        google.maps.event.addListener(infoWindow, 'domready', () => {
          const link = document.querySelector('#contentString');
          if (link) {
            link.addEventListener('click', () => {
              if (this.props.onRedirect) {
                this.props.onRedirect('stations/edit', id);
              }
            });
          }
        });
      }
    });
  }

  openCustomerInfoWindow = (id) => {
    this.customerMarkes.forEach((m) => {
      if (m.metadata.id === id) {
        const infoWindow = new google.maps.InfoWindow();
        const contentString = '<span class="infoWindow"><span>(</span><span class="link"'
          + ' id="contentString">view</span><span>)</span></span>';
        this.activeInfoWindow?.close();
        infoWindow.close();
        infoWindow.setContent(m.title === 'Restock' ? m.title : `${m.title} ${contentString}`);
        infoWindow.open(m.getMap(), m);
        this.activeInfoWindow = infoWindow;
        google.maps.event.addListener(infoWindow, 'domready', () => {
          const link = document.querySelector('#contentString');
          if (link) {
            link.addEventListener('click', () => {
              if (this.props.onRedirect) {
                this.props.onRedirect('consumers/orders', m.metadata.id);
              }
            });
          }
        });
      }
    });
  }

  renderStoreMarkers = async (markers, map) => {
    if (this.storeMarkers) {
      return;
    }
    const infoWindow = new google.maps.InfoWindow();
    let id = '';
    this.storeMarkers = await Promise.all(markers.map(async (m) => {
      const marker = new google.maps.Marker({
        position: { lat: +m.lat, lng: +m.lng },
        map,
        icon: {
          url: storeMarker,
          scaledSize: new google.maps.Size(40, 40),
          anchor: new google.maps.Point(20, 20),
        },
        title: m.title,
        zIndex: 4,
        metadata: { id: m.id },
      });
      const title = marker.getTitle();
      const contentString = '<span class="infoWindow"><span>(</span><span class="link" id="contentString">Edit</span><span>)</span></span>';
      marker.addListener('click', () => {
        this.activeInfoWindow?.close();
        infoWindow.setContent(`${title} ${contentString}`);
        infoWindow.open(marker.getMap(), marker);
        this.activeInfoWindow = infoWindow;
        this.handleHighlightStoreMarker({ store_id: m.id, coords: `${+m.lat}, ${+m.lng}` });
        id = m.id;
      });
      return marker;
    }));

    google.maps.event.addListener(infoWindow, 'domready', () => {
      const link = document.querySelector('#contentString');
      if (link) {
        link.addEventListener('click', () => {
          if (this.props.onRedirect) {
            this.props.onRedirect('stations/edit', id);
          }
        });
      }
    });
  }

  getCenter = () => {
    const { filters } = this.props;
    const zoomData = QueryStorage.getZoomData();

    if (!filters) {
      return [4, 37.7550202, -118.243683];
    }
    return [zoomData.zoom || 4, +zoomData.lat || 37.7550202, +zoomData.lng || -118.243683];
  }

  mapInit = async () => {
    const { drawingControl } = this.props;
    const query = Utils.queryParse(window.location.search, {}, '', 'comma');
    this.props.setGoogle(google);
    const map = new google.maps.Map(this.el, {
      zoom: this.getCenter()[0],
      center: { lat: this.getCenter()[1], lng: this.getCenter()[2] },
      styles: mapStyle,
      mapTypeId: query.map_type || 'roadmap',
      rotateControl: false,
    });
    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingControl,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [
          google.maps.drawing.OverlayType.POLYGON,
        ],
      },
      polygonOptions: {
        fillColor: '#ff0000',
        fillOpacity: 0.35,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        clickable: true,
        editable: true,
        zIndex: 1,
      },
    });

    this.drawingManager.setMap(map);
    google.maps.event.addListener(this.drawingManager, 'polygoncomplete', this.handlePolygonChange);
    google.maps.event.addListener(this.drawingManager, 'drawingmode_changed', this.handleClearSelection);

    google.maps.event.addListener(map, 'zoom_changed', this.handlePositionChanged);
    google.maps.event.addListener(map, 'center_changed', this.handlePositionChanged);
    google.maps.event.addListener(map, 'click', this.handleMapClick);
    google.maps.event.addListener(map, 'contextmenu', this.handleMapClick);
    google.maps.event.addListener(map, 'maptypeid_changed', this.handleMapTypeChange);

    this.setState({ map });
  }

  handleMapTypeChange = () => {
    const { map } = this.state;
    if (this.props.onReplaceUrl) {
      this.props.onReplaceUrl({ map_type: map.getMapTypeId() });
    }
  }

  handleMapClick = (ev) => {
    const { vehicleId, filters, vehiclesMarkers } = this.props;
    const { mapClick } = this.state;
    if (!filters || !mapClick) {
      return;
    }
    const {
      metaKey, ctrlKey, buttons, type,
    } = ev.domEvent;
    if (ctrlKey || metaKey || (type === 'contextmenu' && buttons === 1)) {
      if (!vehicleId) {
        toast.error('Please select the robomart.');
        return;
      }
      Api.updateRobomartLocation(ev.latLng.lat(), ev.latLng.lng(), vehicleId);
      this.highlightedMarker = vehiclesMarkers.find((m) => m.id === vehicleId);
    }
  }

  handlePositionChanged = () => {
    const { map } = this.state;
    const { filters } = this.props;
    if (!filters) {
      return;
    }
    const zoom = map.getZoom();
    const zoomData = {
      zoom,
      lat: map.getCenter().lat(),
      lng: map.getCenter().lng(),
    };
    QueryStorage.setZoomData(zoomData);
  }

  handleClearSelection = () => {
    if (this.drawingManager.drawingMode === null) {
      return;
    }
    if (this.polygon) {
      this.polygon.setEditable(false);
      this.polygon.setMap(null);
    }
    if (this.props.onGetPolygon) {
      this.props.onGetPolygon('');
    }
  }

  handleGetAddress = async (pos) => {
    const data = await Address.getAddress(pos);
    if (this.props.onGetAddressData) {
      this.props.onGetAddressData(data[0]);
    }
  }

  getMarkerIcon = (type) => ({
    robomart: robomartMarker,
    store: storeMarker,
    customer: customerMarker,
  })[type]

  handlePolygonChange = async (polygon, change = false) => {
    if (!polygon) {
      return;
    }
    const poly = [];
    polygon.getPaths().forEach((el) => {
      const polyline = [];
      el.forEach((pos) => {
        polyline.push({
          lng: pos.lng(),
          lat: pos.lat(),
        });
      });
      poly.push(polyline);
      return el;
    });
    const path = polygon.getPath();
    if (_.isEmpty(poly[0])) {
      return;
    }
    const polygonSting = Polygon.stringify([poly[0]]);
    const center = Polygon.center(poly[0]);
    this.polygon = polygon;
    if (this.props.onGetPolygon) {
      this.props.onGetPolygon(polygonSting);
    }
    if (!change) {
      google.maps.event.addListener(path, 'set_at', () => {
        this.handlePolygonChange(polygon, true);
      });

      google.maps.event.addListener(path, 'insert_at', () => {
        this.handlePolygonChange(polygon, true);
      });
      this.drawingManager.setDrawingMode(null);
    }

    if (!_.isNaN(center.lat)) {
      await this.handleGetAddress({ lng: center.lng, lat: center.lat });
    }
  }

  handlePolygonCreate = (coords, editable = true, clickable = true, id, bounding = true) => {
    const { map, bounded } = this.state;
    const { filters } = this.props;
    const coordsArr = Polygon.parse(coords) || [];
    const overlayCoords = [
      { lat: 85.0511, lng: -180 }, { lat: 85.0511, lng: 0 },
      { lat: 85.0511, lng: 180 }, { lat: -85.0511, lng: 180 },
      { lat: -85.0511, lng: 0 }, { lat: -85.0511, lng: -180 },
    ];
    const query = Utils.queryParse(window.location.search, {}, '', 'comma');
    let z = [];
    if (query.zone) {
      z = _.isArray(query.zone) ? query.zone : [query.zone];
    }
    Object.keys(this.polygons).map((a) => {
      if (!z.includes(a)) {
        delete this.polygons[a];
      }
    });
    let paths = { overlayCoords, ...this.polygons };
    paths = { ...paths, [id]: coordsArr[0] };

    if (this.polygon) {
      this.polygon.setMap(null);
    }
    const polygon = new google.maps.Polygon({
      paths: Object.values(paths),
      strokeColor: filters ? 'transparent' : '#FF0000',
      strokeOpacity: 1,
      strokeWeight: 2,
      fillColor: filters ? '#e3e3e3' : '#FF0000',
      fillOpacity: 0.5,
      zIndex: 1,
      clickable,
      editable,
      labelInBackground: false,
      metadata: { id },
    });
    polygon.setMap(map);
    if (filters) {
      const polygon1 = new google.maps.Polygon({
        paths: coordsArr[0],
        strokeColor: '#e57d7a',
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor: 'transparent',
        fillOpacity: 0.5,
        zIndex: 1,
        clickable,
        editable,
        labelInBackground: false,
        metadata: { id },
      });
      polygon1.setMap(map);
      this.duplicatedPolygons = [...this.duplicatedPolygons, polygon1];
    }

    if (filters) {
      this.polygons = paths;
    }
    this.polygon = polygon;

    if (coordsArr[0] && bounding) {
      this.handleFitBounds(coordsArr[0]);
    }
  }

  handleFitBounds = (coordsArr, marker = false) => {
    const { map } = this.state;
    const bounds = new google.maps.LatLngBounds();
    coordsArr.forEach((c) => {
      bounds.extend(new google.maps.LatLng(c.lat, c.lng));
    });
    map.fitBounds(bounds);
    map.panToBounds(bounds);
    let zoom = map.getZoom();
    if (marker) {
      zoom -= 8;
      map.setZoom(zoom);
    }
    this.setState({ bounded: true });
  }

  handleMarkerCreate = () => {
    const { markerCoords } = this.props;
    const { markerDraged, map } = this.state;
    const lanLng = new google.maps.LatLng(markerCoords.lat, markerCoords.lng);
    this.marker = undefined;
    const marker = new google.maps.Marker({
      map,
      position: lanLng,
      type: 'store',
      draggable: true,
      icon: {
        url: storeMarker,
        scaledSize: new google.maps.Size(40, 40),
        anchor: new google.maps.Point(20, 20),
      },

    });
    const bounds = new google.maps.LatLngBounds();

    this.marker = marker;
    if (!markerDraged) {
      bounds.extend(lanLng);
      map.fitBounds(bounds);
      map.setZoom(map.getZoom() - 3);
      this.setState({ bounded: true });
    }
    google.maps.event.addListener(marker, 'dragend', (ev) => {
      this.props.onMarkerCoorsChange('coords', `${ev.latLng.lat()}, ${ev.latLng.lng()}`);
    });
    marker.setMap(map);
  }

  render() {
    const { map, bounded } = this.state;
    const {
      markerCoords, className, placeId, filters, createView, vehiclesMarkers,
    } = this.props;
    if (map) {
      this.createMarker(markerCoords, placeId);
    }
    return (
      <div className="map" ref={this.mapRef}>
        <Helmet>
          <style>
            {vehiclesMarkers.map((m) => {
              let r = Utils.normalizeRotation(m.rotation);
              if (this.robomartMarkers) {
                const { rotation } = this.robomartMarkers.find((r) => r.metadata?.id === m?.id)?.metadata || {};
                r = rotation;
              }

              return (`
              img[src$="?markerId=${m?.id}"]{
                transition: transform 500ms;
                transform: rotate(${r}deg);
              }
            `);
            }).join('')}
          </style>
        </Helmet>
        {filters ? <Filters /> : null}
        <div
          className={`googleMaps ${className} ${bounded || createView || filters ? 'bounded' : ''}`}
          ref={(ref) => {
            this.el = ref;
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  zones: state.zones.zones,
});

const mapDispatchToProps = {
  setGoogle,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {
    forwardRef: true,
  },
)(GoogleMaps);

export default Container;
