import React, { Component } from 'react';
import { CircularProgress } from 'rmwc';
import memoizeOne from 'memoize-one';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Wrapper from '../../components/Wrapper';
import HeadersMeta from '../../components/HeadersMeta';
import DataTable from '../../components/DataTable';
import {
  genCodeRequest,
  getApplicantsRequest,
  getApplicantTypeByIdRequest,
  updateApplicantRequest,
} from '../../store/actions/applicants';
import Utils from '../../helpers/Utils';
import EditApplicants from '../../components/modals/EditApplicants';
import Pagination from '../../components/Pagination';

class Applicants extends Component {
  getData = memoizeOne(async (query) => {
    this.setState({ currentLoading: true });
    const {
      name, created, email, code, notes, state,
      predicate, reverse,
    } = query;
    const predicateObject = {
      name,
      created_at: created,
      email,
      code,
      notes,
      state,
    };
    const search = { predicateObject };
    const sort = { predicate: predicate || 'created_at', reverse: reverse || true };
    await this.props.getApplicantsRequest(sort, search, (query.page - 1) || 0);
    this.setState({ currentLoading: false });
  }, _.isEqual);

  static propTypes = {
    getApplicantsRequest: PropTypes.func.isRequired,
    genCodeRequest: PropTypes.func.isRequired,
    totalPages: PropTypes.number.isRequired,
    applicants: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentLoading: false,
      editModal: false,
      applicantId: null,
    };
    this.columns = [
      { column: 'created', type: 'date', isDate: true },
      { column: 'email', type: 'searchable' },
      { column: 'name', type: 'searchable' },
      { column: 'code', type: 'searchable' },
      { column: 'state', type: 'selectable' },
      { column: 'notes', type: 'searchable' },
    ];
    this.keysToChange = {
      created_at: 'created',
    };
    this.states = [
      { value: '', label: 'All' },
      { value: 0, label: 'New' },
      { value: 1, label: 'Archived' },
    ];
  }

  handleGenCode = async (id) => {
    const { applicants } = this.props;
    this.setState({ currentLoading: true });
    const data = applicants.find((a) => a.id === id);
    await this.props.genCodeRequest(data);
    toast.success('Successfully generated.');
    const query = Utils.queryParse(window.location.search, this.keysToChange);
    query.gen = id;
    this.getData(query);
  }

  toggleEditModal = (applicantId) => {
    const { editModal } = this.state;
    this.setState({ editModal: !editModal, applicantId });
  }

  render() {
    const { currentLoading, editModal, applicantId } = this.state;
    const { applicants, totalPages } = this.props;
    const query = Utils.queryParse(window.location.search, this.keysToChange);
    const data = Utils.renameKeys(this.keysToChange, applicants);

    this.getData(query);
    return (
      <Wrapper>
        <HeadersMeta page="Applicants" />

        <div className="zones">
          <div className="top">
            <div className="loading">
              {currentLoading ? <CircularProgress size="small" /> : null}
            </div>
          </div>
          <div>
            <DataTable
              columns={this.columns}
              data={data}
              actions={['edit']}
              onEdit={this.toggleEditModal}
              modal
              customField="Gen. Code"
              stateType="list"
              options={this.states}
              onCustomFieldClick={this.handleGenCode}
              page="applicants"
            />
          </div>
          <Pagination currentPage={query.page} totalPages={totalPages} />
        </div>
        <EditApplicants
          isOpen={editModal}
          onCloseModal={() => this.toggleEditModal(null)}
          onUpdate={this.handleUpdate}
          applicantId={applicantId}
        />
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  applicants: state.applicants.applicants,
  totalPages: state.applicants.totalPages,
});

const mapDispatchToProps = {
  getApplicantsRequest,
  genCodeRequest,
  getApplicantTypeByIdRequest,
  updateApplicantRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Applicants);

export default Container;
