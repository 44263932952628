import React, { Component } from 'react';
import { TextField } from 'rmwc';
import _ from 'lodash';
import PropTypes from 'prop-types';

class Input extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
    fieldType: PropTypes.string,
    invalid: PropTypes.any,
    type: PropTypes.any,
    min: PropTypes.string,
    max: PropTypes.string,
    float: PropTypes.bool,
  };

  static defaultProps = {
    placeholder: '',
    label: '',
    className: '',
    fieldType: 'Input',
    type: 'text',
    invalid: undefined,
    min: undefined,
    max: undefined,
    float: false,
  }

  handleKeyPress = (ev) => {
    const {
      type, max, min, float,
    } = this.props;
    if (type === 'numeric') {
      const { value, selectionStart, selectionEnd } = ev.target;
      const val = value.substring(0, selectionStart) + ev.key + value.substring(selectionEnd, value.length);
      if (!float && ev.charCode === 46) {
        ev.preventDefault();
      }
      if (!/^\d*\.?\d{0,2}$/.test(val)) {
        ev.preventDefault();
      }
      if (_.isNumber(+max) && +val && +max < +val) {
        ev.preventDefault();
      }
      if (_.isNumber(+min) && val && +min > +val) {
        ev.preventDefault();
      }
    }
  }

  render() {
    const {
      placeholder, label, className, fieldType, invalid, type, ...props
    } = this.props;
    return (
      <div className={`rm${fieldType} ${className}`}>
        <TextField
          placeholder={placeholder}
          label={label}
          invalid={invalid}
          type={type !== 'numeric' ? type : 'text'}
          {...props}
          onKeyPress={this.handleKeyPress}
        />
        {invalid ? <span className="invalid">{invalid}</span> : null}
      </div>
    );
  }
}

export default Input;
