import React, { Component } from 'react';
import { CircularProgress } from 'rmwc';
import memoizeOne from 'memoize-one';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Wrapper from '../../components/Wrapper';
import HeadersMeta from '../../components/HeadersMeta';
import DataTable from '../../components/DataTable';
import { getRobomartTypesRequest } from '../../store/actions/robomartTypes';
import Utils from '../../helpers/Utils';
import Button from '../../components/form/Button';
import Pagination from '../../components/Pagination';

class RobomartTypes extends Component {
  getData = memoizeOne(async (query) => {
    this.setState({ currentLoading: true });
    const {
      // eslint-disable-next-line camelcase
      name, desc, predicate, reverse,
    } = query;
    const predicateObject = {
      name,
      desc,
    };
    const search = { predicateObject };
    const sort = { predicate: predicate || 'name', reverse: reverse || false };
    await this.props.getRobomartTypesRequest(sort, search, (query.page - 1) || 0);
    this.setState({ currentLoading: false });
  }, _.isEqual);

  static propTypes = {
    robomartTypes: PropTypes.array.isRequired,
    getRobomartTypesRequest: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    totalPages: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentLoading: false,
    };
    this.columns = [
      { column: 'name', type: 'searchable' },
      { column: 'desc', type: 'searchable' },
      { column: 'show_order', type: '' },
      { column: 'state', type: '' },
    ];
  }

  handleAdd = () => {
    this.props.history.push('/admin/types/add');
  }

  handleEdit = (id) => `/admin/types/edit/${id}`

  handleRedirect = (id) => `/admin/types/menu/${id}`

  render() {
    const { currentLoading } = this.state;
    const { robomartTypes, totalPages } = this.props;
    const query = Utils.queryParse();
    this.getData(query);
    return (
      <Wrapper>
        <HeadersMeta page="Types" />

        <div className="zones">
          <div className="top">
            <div className="loading">
              {currentLoading ? <CircularProgress size="small" /> : null}
            </div>
            <Button title="Add Type" onClick={this.handleAdd} />
          </div>
          <div>
            <DataTable
              columns={this.columns}
              data={robomartTypes}
              actions={['edit']}
              onEdit={this.handleEdit}
              stateType="list"
              options={[
                { value: 0, label: 'Offline' },
                { value: 1, label: 'Online' },
                { value: 2, label: 'Coming Soon' },
              ]}
            />
          </div>
          <Pagination currentPage={query.page} totalPages={totalPages} />
        </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  robomartTypes: state.robomartTypes.robomartTypes,
  totalPages: state.robomartTypes.totalPages,
});

const mapDispatchToProps = {
  getRobomartTypesRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RobomartTypes);

export default Container;
