import React, { Component } from 'react';
import {
  DataTable, DataTableBody, DataTableCell, DataTableContent, DataTableHead, DataTableRow, Icon,
} from 'rmwc';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import Button from '../form/Button';

class CreatedItems extends Component {
  static propTypes = {
    isOpen: PropTypes.string,
    onCloseModal: PropTypes.func,
    data: PropTypes.object,
    view: PropTypes.string,
    addedText: PropTypes.string,
    notAddedText: PropTypes.string,
  };

  static defaultProps = {
    isOpen: null,
    data: {},
    onCloseModal: undefined,
    view: '',
    notAddedText: '',
    addedText: '',
  }

  render() {
    const {
      isOpen, data, view, addedText, notAddedText,
    } = this.props;

    const codeTexts = !_.isEmpty(data?.tagsAdded) ? data.tagsAdded : data.tagsAlreadyExist;

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.props.onCloseModal}
        overlayClassName="modalOverlay"
        className="modalContent createdTags"
        parentSelector={() => document.querySelector('#root > div')}
      >
        <div className="top">
          <Icon icon="close" className="icon" onClick={this.props.onCloseModal} />
        </div>
        <div className="tags">
          <div className="added">
            <span>{addedText}</span>
            <div className="tagsAdded">{codeTexts?.join(', ') || '-'}</div>
          </div>
          <hr />
          {/* <div className="existing"> */}
          {/*  <span>{notAddedText}</span> */}
          {/*  {view === 'menu' ? ( */}
          {/*    <DataTable> */}
          {/*      <DataTableContent> */}
          {/*        <DataTableHead> */}
          {/*          <DataTableRow> */}
          {/*            <DataTableCell alignStart>Code</DataTableCell> */}
          {/*            <DataTableCell alignStart>Type</DataTableCell> */}
          {/*            <DataTableCell alignStart>Products</DataTableCell> */}
          {/*          </DataTableRow> */}
          {/*        </DataTableHead> */}
          {/*        <DataTableBody> */}
          {/*          {data?.tagsAlreadyExist?.map((tag) => { */}
          {/*            const products = _.groupBy(data?.tagsAlreadyExist, d => d.type_id) */}
          {/*            return ( */}
          {/*              <DataTableRow> */}
          {/*                <DataTableCell className="tag"> */}
          {/*                  {tag.code || '-'} */}
          {/*                </DataTableCell> */}
          {/*                <DataTableCell className="tag"> */}
          {/*                  <Link to={`/admin/types/edit/${tag.type_id}?n=${tag.type_name}`}> */}
          {/*                    {' '} */}
          {/*                    {tag.type_name || '-'} */}
          {/*                  </Link> */}
          {/*                </DataTableCell> */}
          {/*                <DataTableCell className="tag"> */}
          {/*                  {tag.name} */}
          {/*                </DataTableCell> */}
          {/*              </DataTableRow> */}
          {/*            ) */}
          {/*          })} */}
          {/*        </DataTableBody> */}
          {/*      </DataTableContent> */}
          {/*    </DataTable> */}
          {/*  ) : null} */}
          {/*  {view === 'tags' ? ( */}
          {/*    <DataTable> */}
          {/*      <DataTableContent> */}
          {/*        <DataTableHead> */}
          {/*          <DataTableRow> */}
          {/*            <DataTableCell alignStart>Code</DataTableCell> */}
          {/*            <DataTableCell alignStart>Name</DataTableCell> */}
          {/*            <DataTableCell alignStart>Tag</DataTableCell> */}
          {/*          </DataTableRow> */}
          {/*        </DataTableHead> */}
          {/*        <DataTableBody> */}
          {/*          {data?.tagsAlreadyExist?.map((tag) => ( */}
          {/*            <DataTableRow> */}
          {/*              <DataTableCell className="tag"> */}
          {/*                {tag.code || '-'} */}
          {/*              </DataTableCell> */}
          {/*              <DataTableCell className="tag"> */}
          {/*                <Link to={`/admin/products/edit/${tag.product_id}?n=${tag.name}`}> */}
          {/*                  {' '} */}
          {/*                  {tag.name || '-'} */}
          {/*                </Link> */}
          {/*              </DataTableCell> */}
          {/*              <DataTableCell className="tag"> */}
          {/*                {tag.tag || '-'} */}
          {/*              </DataTableCell> */}
          {/*            </DataTableRow> */}
          {/*          ))} */}
          {/*        </DataTableBody> */}
          {/*      </DataTableContent> */}
          {/*    </DataTable> */}
          {/*  ) : null} */}
          {/* </div> */}
        </div>
        <div className="actions">
          <Button title="Ok" onClick={this.props.onCloseModal} />
        </div>
      </Modal>
    );
  }
}

export default withRouter(CreatedItems);
