import React, { Component } from 'react';
import { Icon } from 'rmwc';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { toast } from 'react-toastify';
import Select from '../form/Select';
import {
  createCreditRequest,
  getContactsByTagIdRequest,
  getCreditsAllTagsRequest,
  getCreditsRequest,
} from '../../store/actions/credits';
import Input from '../form/Input';
import Button from '../form/Button';
import { getConsumersRequest } from '../../store/actions/consumers';
import consumers from '../../pages/consumers/Consumers';
import Api from '../../Api';

class CreateVoucherModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    getCreditsAllTagsRequest: PropTypes.func.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    getContactsByTagIdRequest: PropTypes.func.isRequired,
    createCreditRequest: PropTypes.func.isRequired,
    getCreditsRequest: PropTypes.func.isRequired,
    creditsAllTags: PropTypes.array.isRequired,
    contactsByTag: PropTypes.array.isRequired,
    consumers: PropTypes.array.isRequired,
    getConsumersRequest: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        tag: '',
        consumers: [],
        type: 1,
        amount: '',
        notes: '',
      },
      loading: false,
      contactsLoading: false,
      inputValue: false,
    };
    this.types = [
      { value: 1, label: 'Manual' },
      { value: 2, label: 'Invite' },
    ];
  }

  componentDidMount() {
    this.props.getCreditsAllTagsRequest();
    this.props.getConsumersRequest();
  }

  handleChange = (key, value) => {
    const { formData } = this.state;
    formData[key] = value;
    this.setState({ formData });
  }

  handleGetContacts = async (id) => {
    this.setState({ contactsLoading: true });
    await this.props.getContactsByTagIdRequest(id);
    this.setState({ contactsLoading: false });
  }

  handleSubmit = async (ev) => {
    ev.preventDefault();
    const { formData } = this.state;
    const { contactsByTag, consumers } = this.props;
    this.setState({ loading: true });
    const arr = formData.consumers.map((d) => ({
      consumer_id: d.id,
      consumer_name: consumers.find((c) => c.id === d.id)?.fullname,
      tag: formData.tag,
      type: formData.type,
      amount: formData.amount,
      notes: formData.notes,
      coupon_code: '',
    }));
    const { payload: { data } } = await this.props.createCreditRequest(arr);
    if (data.success) {
      toast.success('Successfully created.');
      const sort = { predicate: 'created_at', reverse: true };
      await this.props.getCreditsRequest(sort, { predicateObject: {} }, 0);
      this.props.onCloseModal();
    } else {
      toast.error('Something went wrong.');
    }
    this.setState({ loading: false });
  }

  handleInputChange = async (newValue) => {
    const inputValue = newValue.replace(/\W/g, '');
    const predicateObject = {
      fullname: inputValue,
    };
    const search = { predicateObject };
    const { data: { data: { Records } } } = await Api.getConsumers(undefined, search);
    return Records;
  };

  render() {
    const {
      isOpen, creditsAllTags, contactsByTag, consumers, consumersStatus,
    } = this.props;
    const {
      formData, loading, contactsLoading, inputValue,
    } = this.state;
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.props.onCloseModal}
        overlayClassName="modalOverlay"
        className="modalContent createVoucherModal"
        parentSelector={() => document.querySelector('#root > div')}
      >
        <div className="top">
          <span>Issue credits</span>
          <Icon icon="close" className="icon" onClick={this.props.onCloseModal} />
        </div>
        <form onSubmit={this.handleSubmit}>
          {/* <Select */}
          {/*  label="Tags *" */}
          {/*  options={creditsAllTags} */}
          {/*  getOptionLabel={(o) => o.name} */}
          {/*  getOptionValue={(o) => o.id} */}
          {/*  onChange={(val) => { */}
          {/*    this.handleChange('tag', val.id); */}
          {/*    this.handleChange('consumers', []); */}
          {/*    this.handleGetContacts(val.id); */}
          {/*  }} */}
          {/*  value={creditsAllTags.find((c) => c.id === formData.tag)} */}
          {/* /> */}
          <Select
            label="Consumers *"
            getOptionLabel={(o) => o.fullname || '-'}
            getOptionValue={(o) => o.id}
            loadOptions={this.handleInputChange}
            isMulti
            isAsync
            cacheOptions
            defaultOptions
            onChange={(val) => this.handleChange('consumers', val)}
            value={formData.consumers || []}
          />
          {/* <Select */}
          {/*  label="Type *" */}
          {/*  options={this.types} */}
          {/*  onChange={(val) => this.handleChange('type', val.value)} */}
          {/*  value={this.types.find((t) => t.value === formData.type)} */}
          {/*  isDisabled */}
          {/* /> */}
          <Input
            label="Amount *"
            value={formData.amount || ''}
            onChange={(ev) => this.handleChange('amount', ev.target.value)}
            type="numeric"
            min="0"
            float
          />
          <Input
            textarea
            label="Note *"
            value={formData.notes}
            onChange={(ev) => this.handleChange('notes', ev.target.value)}
            fieldType="Textarea"
          />
          <div className="actions">
            <Button
              title="Cancel"
              onClick={this.props.onCloseModal}
              type="button"
              outlined
              raised={false}
            />
            <Button
              title="Create"
              type="submit"
              loading={loading}
              disabled={_.isEmpty(formData.consumers) || !formData.amount || !formData.notes}
            />
          </div>
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  creditsAllTags: state.credits.creditsAllTags,
  contactsByTag: state.credits.contactsByTag,
  consumers: state.consumers.consumers,
  consumersStatus: state.consumers.consumersStatus,

});

const mapDispatchToProps = {
  getCreditsAllTagsRequest,
  getContactsByTagIdRequest,
  createCreditRequest,
  getCreditsRequest,
  getConsumersRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateVoucherModal);

export default withRouter(Container);
