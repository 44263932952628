/* global google */

import queryString from 'query-string';
import _ from 'lodash';
import adminTabMenus from '../adminTabMenus';
import HasPermission from '../components/HasPermission';

class Utils {
  static sleep = (time) => new Promise((resolve) => setTimeout(resolve, time));

  static queryParse = (search = window.location.search, keysToChange = {}, key, arrayFormat) => {
    const query = queryString.parse(search, { arrayFormat });
    if (!_.isEmpty(keysToChange)) {
      Object.values(keysToChange).map((k, i) => {
        if (k === query.predicate) {
          query.predicate = Object.keys(keysToChange)[i];
        }
      });
    }
    if (!query[key]) {
      query[key] = [];
    } else if (_.isString(query[key])) {
      query[key] = [query[key]];
    }
    return query;
  }

  static queryStringify = (obj) => queryString.stringify(obj, {
    arrayFormat: 'comma',
    skipNull: true,
    skipEmptyString: true,
  })

  // renaming backend keys to frontend labels
  static renameKeys = (keysMap, arr) => arr.map((obj) => Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...{ [keysMap[key] || key]: obj[key] },
    }),
    {},
  ));

  static objectToArray = (data) => {
    const datum = Object.entries(data);
    return datum.map((d) => ({ ...d[1], id: d[0] }));
  }

  static formatVehiclesMarkerData = (data = {}) => {
    const datum = this.objectToArray(data);
    return datum.filter((d) => d.vehicle_state === 1).map((d) => ({
      lat: d.location?.lat,
      lng: d.location?.lng,
      rotation: +d.location?.angle,
      updatedAt: +d.location?.updated_at,
      type: 'robomart',
      isRobomart: true,
      title: d.name,
      id: d.id,
      request_state: d.request_state,
      polyline: d?.route?.polyline,
    }))
      .filter((d) => d.lat);
  }

  static formatStoreMarkersData = (data = []) => data.map((d) => {
    const [lat, lng] = d.coords.split(',');
    return {
      lat, lng, type: 'store', isStore: true, title: d.name, id: d.store_id, rotation: 0,
    };
  })

  static formatCustomersMarkersData = (data = {}) => {
    const datum = this.objectToArray(data);
    return datum.map((d) => ({
      lat: d.location?.lat,
      lng: d.location?.lng,
      rotation: d.location?.angle,
      type: 'customer',
      isCustomer: true,
      title: d.name,
      status: d.status,
      id: d.id,
      date: d.date,
    })).filter((d) => d.lat);
  }

  static createMarker = (width, height, degress = 0, src, border) => new Promise((resolve) => {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    const context = canvas.getContext('2d');
    context.translate(canvas.width / 2, canvas.height / 2);

    // context.rotate(Polygon.toRadians(degress));

    context.clearRect(0, 0, canvas.width, canvas.height);

    context.beginPath();
    const img = document.createElement('img');
    img.width = canvas.width;
    img.height = canvas.height;
    img.src = src;

    if (border) {
      context.strokeStyle = '#FF0000';
      context.lineWidth = 2;
      context.arc(0, 0, width / 2 - 1, 0, 2 * Math.PI);
      context.stroke();
    }

    context.closePath();

    img.onload = () => {
      context.drawImage(img, -canvas.width / 2, -canvas.width / 2);
      resolve(canvas.toDataURL());
    };
  })

  static prefetchImages = (src) => {
    const arr = _.isArray(src) ? src : [src];
    return Promise.all(arr.map((s) => (
      new Promise((resolve, reject) => {
        const img = document.createElement('img');
        img.src = s;
        img.onload = resolve;
        img.onerror = reject;
      })
    )));
  }

  static animateMarkerTo(marker, newPosition, opt = {}) {
    const options = {
      duration: 1500,
      easing: (x, t, b, c, d) => -c * (t /= d) * (t - 2) + b,
      ...opt,
    };

    window.requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame
      || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;
    window.cancelAnimationFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame;

    // save current position. prefixed to avoid name collisions. separate for lat/lng to avoid calling lat()/lng() in every frame
    marker.AT_startPosition_lat = marker.getPosition().lat();
    marker.AT_startPosition_lng = marker.getPosition().lng();
    const newPositionLat = newPosition.lat();
    let newPositionLng = newPosition.lng();

    // crossing the 180° meridian and going the long way around the earth?
    if (Math.abs(newPositionLng - marker.AT_startPosition_lng) > 180) {
      if (newPositionLng > marker.AT_startPosition_lng) {
        newPositionLng -= 360;
      } else {
        newPositionLng += 360;
      }
    }

    const animateStep = (marker, startTime) => {
      const ellapsedTime = (new Date()).getTime() - startTime;
      const durationRatio = ellapsedTime / options.duration; // 0 - 1
      const easingDurationRatio = options.easing(durationRatio, ellapsedTime, 0, 1, options.duration);

      if (durationRatio < 1) {
        marker.setPosition({
          lat: (
            marker.AT_startPosition_lat + (newPositionLat - marker.AT_startPosition_lat) * easingDurationRatio
          ),
          lng: (
            marker.AT_startPosition_lng + (newPositionLng - marker.AT_startPosition_lng) * easingDurationRatio
          ),
        });

        // use requestAnimationFrame if it exists on this browser. If not, use setTimeout with ~60 fps
        if (window.requestAnimationFrame) {
          marker.AT_animationHandler = window.requestAnimationFrame(() => {
            animateStep(marker, startTime);
          });
        } else {
          marker.AT_animationHandler = setTimeout(() => {
            animateStep(marker, startTime);
          }, 17);
        }
      } else {
        marker.setPosition(newPosition);
      }
    };

    if (window.cancelAnimationFrame) {
      window.cancelAnimationFrame(marker.AT_animationHandler);
    } else {
      clearTimeout(marker.AT_animationHandler);
    }

    animateStep(marker, (new Date()).getTime());

    return Utils.sleep(options.duration);
  }

  static animatedMarkerMove(marker, moveto) {
    const deltalat = (moveto.lat() - marker.position.lat()) / 100;
    const deltalng = (moveto.lng() - marker.position.lng()) / 100;

    let lat = marker.position.lat();
    let lng = marker.position.lng();
    let i = 0;
    const setPosition = () => {
      lat += deltalat;
      lng += deltalng;
      const latlng = new google.maps.LatLng(lat, lng);
      marker.setPosition(latlng);
      if (i++ < 100) {
        setTimeout(setPosition, 10);
      } else {
        setTimeout(() => marker.setPosition(moveto), 10);
      }
    };
    setPosition();
  }

  static isMobile(mobileMaxWidth = 767) {
    return (window.innerWidth || 1024) <= mobileMaxWidth;
  }

  static toDecimals(number) {
    if (+number === 0) {
      return '0.00';
    }
    return (`${(+number).toFixed(2)}`);
  }

  static parseDecimals(string) {
    return string.replace(/[\D]/g, '').replace('.00');
  }

  static normalizeRotation(rotation) {
    return rotation < 0 ? 360 + rotation : rotation;
  }

  static closestEquivalentAngle(from, to) {
    const delta = ((((to - from) % 360) + 540) % 360) - 180;
    return from + delta;
  }

  static getFirstAvailablePath() {
    return adminTabMenus.find((m) => HasPermission.check(m.permissions))?.path;
  }

  static formatPriceAmounts(price) {
    return `$${(+price || 0).toFixed(2)}`;
  }
}

export default Utils;
