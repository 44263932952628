import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from './Input';

class Autocomplete extends Component {
  static propTypes = {
    onSelect: PropTypes.func,
    google: PropTypes.object.isRequired,
    value: PropTypes.string,
  }

  static defaultProps = {
    onSelect: () => {
    },
    value: undefined,
  }

  componentDidMount() {
    const { google } = this.props;
    const options = {
      componentRestrictions: { country: 'us' },
    };
    const input = document.getElementById('autocomplete');
    this.autocomplete = new google.maps.places.Autocomplete(input, options);
    this.placeChangeListener = google.maps.event.addListener(this.autocomplete, 'place_changed', this.handlePlaceChange);
  }

  componentWillUnmount() {
    const { google } = this.props;
    const pacContainer = document.querySelector('.pac-container');
    if (pacContainer) {
      pacContainer.remove();
    }
    google.maps.event.removeListener(this.placeChangeListener);
  }

  handlePlaceChange = () => {
    const place = this.autocomplete.getPlace();
    const {
      geometry, formatted_address: address, place_id: placeId,
    } = place;
    if (!geometry) {
      return;
    }
    const location = { lat: geometry.location.lat(), lng: geometry.location.lng() };
    const params = { location, address, placeId };
    this.props.onSelect(params);
  }

  render() {
    const { value, ...props } = this.props;
    return (
      <Input
        placeholder="Search ..."
        autoComplete="off"
        id="autocomplete"
        label="Address *"
        value={value || undefined}
        {...props}
        ref={(ref) => {
          this.input = ref;
        }}
      />
    );
  }
}

export default Autocomplete;
