import { define } from '../../helpers/redux-request';
import Api from '../../Api';

export const GET_TYPES = define('GET_TYPES');

export function getTypesRequest() {
  return GET_TYPES.request(() => Api.getType());
}

export const ADD_PRODUCTS = define('ADD_PRODUCTS');

export function addProductsRequest(obj) {
  return ADD_PRODUCTS.request(() => Api.addProducts(obj));
}

export const DELETE_PRODUCT = define('DELETE_PRODUCT');

export function deleteProductRequest(obj) {
  return DELETE_PRODUCT.request(() => Api.deleteProduct(obj));
}
