import React, { Component } from 'react';
import { Checkbox } from 'rmwc';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import _ from 'lodash';
import Wrapper from '../../components/Wrapper';
import Button from '../../components/form/Button';
import { getSettingsRequest, updateSettingsRequest } from '../../store/actions/settings';
import SettingItem from '../../components/settings/SettingItem';
import HeadersMeta from '../../components/HeadersMeta';
import Loader from '../../components/Loader';
import Validator from '../../helpers/Validator';
import Form from '../../components/form/Form';
import HasPermission from '../../components/HasPermission';

class Settings extends Component {
  static propTypes = {
    getSettingsRequest: PropTypes.func.isRequired,
    updateSettingsRequest: PropTypes.func.isRequired,
    settingsStatus: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.fields = ['availability_max_eta', 'end_time', 'hailing_fee', 'message_already_booked', 'message_already_booked_desc',
      'message_availability_time', 'message_availability_time_desc', 'message_booked', 'message_coming_soon', 'message_coming_soon_desc',
      'message_hailing_fee', 'message_not_available', 'message_availability_time', 'message_not_available_desc', 'message_out_of_zone',
      'message_out_of_zone_desc', 'message_too_far', 'message_too_far_desc', 'order_average_shopping_time', 'order_queue_size',
      'ios_min_version', 'android_min_version',
      'queue_max_items', 'receipt_conformation_delay', 'restock_time', 'start_time', 'vehile_inventory_requeste_delay'];
    this.bypassFields = ['enroute_delay', 'arrive_delay', 'receipt_conformation_delay'];
    this.state = {
      formData: {},
      disabled: false,
      loading: false,
      changed: false,
      errors: [],
    };
  }

  async componentDidMount() {
    const { payload: { data: { data } } } = await this.props.getSettingsRequest();
    this.setState({ formData: data });
  }

  handleChange = (key, value) => {
    const { formData, errors } = this.state;
    if (['start_time', 'end_time'].includes(key)) {
      this.setState({ disabled: false });
      if (!value.match(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)) {
        this.setState({ disabled: true, errors: _.uniq([...errors, key]) });
      } else {
        this.setState({ errors: errors.filter((e) => e !== key) });
      }
    }
    formData[key] = value;
    this.setState({ formData });
  }

  handleUpdateSettings = async (ev) => {
    ev.preventDefault();
    const { formData } = this.state;
    this.setState({ loading: true });
    const { payload: { data } } = await this.props.updateSettingsRequest(formData);
    if (data.success) {
      toast.success('Successfully updated.');
    } else {
      toast.error('Something went wrong.');
    }
    this.setState({ loading: false });
  }

  handleFormChange = () => {
    const { changed } = this.state;
    if (!changed) {
      this.setState({ changed: true });
    }
  }

  render() {
    const {
      formData, disabled, loading, errors, changed,
    } = this.state;
    let requiredFields = this.fields;
    if (formData.enable_bypass_driver_app) {
      requiredFields = [...requiredFields, ...this.bypassFields];
    }
    if (formData.enable_doors_simulation) {
      requiredFields = [...requiredFields, 'doors_simulation_delay'];
    }
    if (formData.enable_default_bucket) {
      requiredFields = [...requiredFields, 'default_bucket'];
    }
    const { settingsStatus } = this.props;

    return (
      <Wrapper>
        <HasPermission edit="settings" redirect>
          <HeadersMeta page="Settings" />
          {settingsStatus === 'ok' ? (
            <Form
              onSubmit={this.handleUpdateSettings}
              className="settings"
              ref={(ref) => this.form = ref}
              onChange={this.handleFormChange}
            >
              <div className="row">
                <p className="title">Shop Availability Max ETA</p>
                <SettingItem
                  label="Availability Max ETA :"
                  unit="minutes"
                  value={formData.availability_max_eta}
                  type="numeric"
                  min="1"
                  onChange={(ev) => this.handleChange('availability_max_eta', ev.target.value)}
                />
              </div>
              <div className="row">
                <p className="title">
                  Bypass Driver App :
                  <Checkbox
                    checked={formData.enable_bypass_driver_app}
                    onChange={() => {
                      if (formData.enable_bypass_driver_app) {
                        this.handleChange('enable_bypass_driver_app', false);
                      } else {
                        this.handleChange('enable_bypass_driver_app', true);
                      }
                    }}
                  />
                </p>

                <SettingItem
                  label="EnRoute Delay :"
                  unit="milliseconds"
                  value={formData.enroute_delay}
                  type="numeric"
                  min="1"
                  onChange={(ev) => this.handleChange('enroute_delay', ev.target.value)}
                  readOnly={!formData.enable_bypass_driver_app}
                />
                <SettingItem
                  label="Arrived Delay :"
                  unit="milliseconds"
                  value={formData.arrive_delay}
                  type="numeric"
                  min="1"
                  onChange={(ev) => this.handleChange('arrive_delay', ev.target.value)}
                  readOnly={!formData.enable_bypass_driver_app}
                />
                <SettingItem
                  label="Receipt Confirmation Process Delay :"
                  unit="milliseconds"
                  value={formData.receipt_conformation_delay}
                  type="numeric"
                  min="1"
                  onChange={(ev) => this.handleChange('receipt_conformation_delay', ev.target.value)}
                  readOnly={!formData.enable_bypass_driver_app}
                />
              </div>
              <div className="row">
                <p className="title">
                  Doors Simulation :
                  <Checkbox
                    checked={formData.enable_doors_simulation}
                    onChange={() => {
                      if (formData.enable_doors_simulation) {
                        this.handleChange('enable_doors_simulation', false);
                      } else {
                        this.handleChange('enable_doors_simulation', true);
                      }
                    }}
                  />
                </p>
                <SettingItem
                  label="Door Simulation Delay :"
                  unit="milliseconds"
                  value={formData.doors_simulation_delay}
                  type="numeric"
                  min="1"
                  onChange={(ev) => this.handleChange('doors_simulation_delay', ev.target.value)}
                  readOnly={!formData.enable_doors_simulation}
                />
              </div>
              <div className="row">
                <p className="title">
                  Enable Default Basket :
                  <Checkbox
                    checked={formData.enable_default_bucket}
                    onChange={() => {
                      if (formData.enable_default_bucket) {
                        this.handleChange('enable_default_bucket', false);
                      } else {
                        this.handleChange('enable_default_bucket', true);
                      }
                    }}
                  />
                </p>
                <SettingItem
                  value={formData.default_bucket}
                  info="Comma separated product codes"
                  className="textarea"
                  textarea
                  fieldType="Textarea"
                  onChange={(ev) => this.handleChange('default_bucket', ev.target.value)}
                  readOnly={!formData.enable_default_bucket}
                />
              </div>
              <div className="row">
                <SettingItem
                  label="Inventory Request Delay :"
                  unit="milliseconds"
                  value={formData.vehile_inventory_requeste_delay}
                  type="numeric"
                  min="1"
                  onChange={(ev) => this.handleChange('vehile_inventory_requeste_delay', ev.target.value)}
                />
                <div className="item">
                  <div className="labels">
                    <span>
                      Update Baseline Inventory after Order Completion
                      <Checkbox
                        checked={formData.enable_update_baseline_inventory}
                        onChange={() => {
                          if (formData.enable_update_baseline_inventory) {
                            this.handleChange('enable_update_baseline_inventory', false);
                          } else {
                            this.handleChange('enable_update_baseline_inventory', true);
                          }
                        }}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <p className="title">Hours of Operation</p>
                <SettingItem
                  label="Start Time :"
                  unit="(24 Hours) (PST)"
                  invalid={errors.includes('start_time') ? 'Invalid time' : ''}
                  value={formData.start_time}
                  onChange={(ev) => this.handleChange('start_time', ev.target.value)}
                />
                <SettingItem
                  label="End Time :"
                  unit="(24 Hours) (PST)"
                  value={formData.end_time}
                  invalid={errors.includes('end_time') ? 'Invalid time' : ''}
                  onChange={(ev) => this.handleChange('end_time', ev.target.value)}
                />
              </div>
              <div className="row">
                <p className="title">Hailing</p>
                <SettingItem
                  label="Hailing Fee : $"
                  value={formData.hailing_fee}
                  onChange={(ev) => this.handleChange('hailing_fee', ev.target.value)}
                  type="numeric"
                  min="1"
                  float
                />
                <SettingItem
                  label="Message :"
                  value={formData.message_hailing_fee}
                  onChange={(ev) => this.handleChange('message_hailing_fee', ev.target.value)}
                />
              </div>
              <div className="row">
                <p className="title">Application Minimum Versions</p>
                <SettingItem
                  label="IOS minimum version"
                  value={formData.ios_min_version}
                  onChange={(ev) => this.handleChange('ios_min_version', ev.target.value)}
                />
                <SettingItem
                  label="Android minimum version"
                  value={formData.android_min_version}
                  onChange={(ev) => this.handleChange('android_min_version', ev.target.value)}
                />
              </div>
              <div className="row">
                <p className="title">Invites</p>
                <SettingItem
                  label="Invite base url"
                  value={formData.invite_base_url}
                  onChange={(ev) => this.handleChange('invite_base_url', ev.target.value)}
                />
                <SettingItem
                  label="Invite referee amount"
                  value={formData.invite_referee_amount}
                  onChange={(ev) => this.handleChange('invite_referee_amount', ev.target.value)}
                  type="numeric"
                />
                <SettingItem
                  label="Invite referrer amount"
                  value={formData.invite_referrer_amount}
                  onChange={(ev) => this.handleChange('invite_referrer_amount', ev.target.value)}
                  type="numeric"
                />
              </div>
              <div className="row">
                <p className="title">Messages</p>
                <div className="section">
                  <div className="leftCol">
                    <SettingItem
                      label="Coming soon :"
                      value={formData.message_coming_soon}
                      onChange={(ev) => this.handleChange('message_coming_soon', ev.target.value)}
                    />
                    <SettingItem
                      label="Out of Zone :"
                      value={formData.message_out_of_zone}
                      onChange={(ev) => this.handleChange('message_out_of_zone', ev.target.value)}
                    />
                    <SettingItem
                      label="Too Far :"
                      value={formData.message_too_far}
                      onChange={(ev) => this.handleChange('message_too_far', ev.target.value)}
                    />
                    <SettingItem
                      label="Availability Time :"
                      value={formData.message_availability_time}
                      onChange={(ev) => this.handleChange('message_availability_time', ev.target.value)}
                    />
                    <SettingItem
                      label="Not Available :"
                      value={formData.message_not_available}
                      onChange={(ev) => this.handleChange('message_not_available', ev.target.value)}
                    />
                  </div>
                  <div className="rightCol">
                    <SettingItem
                      label="Desc:"
                      value={formData.message_coming_soon_desc}
                      onChange={(ev) => this.handleChange('message_coming_soon_desc', ev.target.value)}
                    />
                    <SettingItem
                      label="Desc:"
                      value={formData.message_out_of_zone_desc}
                      onChange={(ev) => this.handleChange('message_out_of_zone_desc', ev.target.value)}
                    />
                    <SettingItem
                      label="Desc:"
                      value={formData.message_too_far_desc}
                      onChange={(ev) => this.handleChange('message_too_far_desc', ev.target.value)}
                    />
                    <SettingItem
                      label="Desc:"
                      value={formData.message_availability_time_desc}
                      onChange={(ev) => this.handleChange('message_availability_time_desc', ev.target.value)}
                    />
                    <SettingItem
                      label="Desc:"
                      value={formData.message_not_available_desc}
                      onChange={(ev) => this.handleChange('message_not_available_desc', ev.target.value)}
                    />
                  </div>
                </div>
                <SettingItem
                  label="Overbooked Prompt :"
                  value={formData.message_already_booked_desc}
                  className="full"
                  onChange={(ev) => this.handleChange('message_already_booked_desc', ev.target.value)}
                />
              </div>
              <div className="row">
                <p className="title">Tags Union</p>
                <SettingItem
                  label="Queue size :"
                  value={formData.queue_max_items}
                  onChange={(ev) => this.handleChange('queue_max_items', ev.target.value)}
                  type="numeric"
                />
              </div>
              <div className="row">
                <p className="title">Shop Order Queue</p>
                <SettingItem
                  label="Queue size :"
                  value={formData.order_queue_size}
                  onChange={(ev) => this.handleChange('order_queue_size', ev.target.value)}
                  type="numeric"
                />
                <SettingItem
                  label="Average Shopping Time :"
                  value={formData.order_average_shopping_time}
                  unit="minutes"
                  type="numeric"
                  onChange={(ev) => this.handleChange('order_average_shopping_time', ev.target.value)}
                />
              </div>
              <div className="row">
                <p className="title">Restock</p>
                <SettingItem
                  label="Restock Time :"
                  value={formData.restock_time}
                  unit="minutes"
                  type="numeric"
                  onChange={(ev) => this.handleChange('restock_time', ev.target.value)}
                />
              </div>
              <HasPermission edit="settings_edit">
                <div className="actions">
                  <Button
                    title="Save"
                    disabled={disabled || Validator.disableFormSubmit(formData, requiredFields) || !changed}
                    loading={loading}
                  />
                </div>
              </HasPermission>
            </Form>
          ) : <Loader />}
        </HasPermission>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  settingsStatus: state.settings.settingsStatus,
});

const mapDispatchToProps = {
  getSettingsRequest,
  updateSettingsRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Settings);

export default Container;
