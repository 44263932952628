import React, { Component } from 'react';
import memoizeOne from 'memoize-one';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CircularProgress } from 'rmwc';
import { toast } from 'react-toastify';
import HeadersMeta from '../../components/HeadersMeta';
import Wrapper from '../../components/Wrapper';
import HasPermission from '../../components/HasPermission';
import Utils from '../../helpers/Utils';
import { deleteCreditRequest, getCreditsRequest } from '../../store/actions/credits';
import Button from '../../components/form/Button';
import DataTable from '../../components/DataTable';
import Pagination from '../../components/Pagination';
import Confirmation from '../../components/modals/Confirmation';
import CreateVoucherModal from '../../components/modals/CreateVoucherModal';

class Credits extends Component {
  getData = memoizeOne(async (query) => {
    this.setState({ currentLoading: true });
    const {
      // eslint-disable-next-line camelcase
      created, type, receiver, note, predicate, reverse, amount,
    } = query;
    const predicateObject = {
      created_at: created,
      type,
      consumer_name: receiver,
      notes: note,
      amount,
    };
    const search = { predicateObject };
    const predicateVal = predicate === '_credit_type' ? 'type' : predicate;
    const sort = { predicate: predicateVal || 'created_at', reverse: reverse || true };
    await this.props.getCreditsRequest(sort, search, (query.page - 1) || 0);
    this.setState({ currentLoading: false });
  }, _.isEqual);

  static propTypes = {
    credits: PropTypes.array.isRequired,
    getCreditsRequest: PropTypes.func.isRequired,
    deleteCreditRequest: PropTypes.func.isRequired,
    totalPages: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentLoading: false,
      createVoucherModal: false,
      creditId: null,
      loading: false,
    };
    this.columns = [
      { column: 'created', type: 'date', isDate: true },
      { column: 'type', type: '' },
      { column: 'receiver', type: 'searchable', isCreditLink: true },
      { column: 'amount', type: 'searchable' },
      { column: 'note', type: 'searchable' },
    ];
    this.types = [
      { value: '', label: 'All' },
      { value: 1, label: 'Manual' },
      { value: 2, label: 'Invite' },
    ];
    this.keysToChange = {
      created_at: 'created',
      _credit_type: 'type',
      consumer_name: 'receiver',
      notes: 'note',
    };
  }

  toggleCreateVouchersModal = () => {
    const { createVoucherModal } = this.state;
    this.setState({ createVoucherModal: !createVoucherModal });
  }

  toggleConfirmationModal = (creditId) => {
    if (creditId) {
      this.setState({ creditId });
    } else {
      this.setState({ creditId: null });
    }
  }

  handleDelete = async () => {
    const { creditId } = this.state;
    const { credits } = this.props;
    this.setState({ loading: true });
    await this.props.deleteCreditRequest(credits.find((c) => c.credit_id === creditId));
    const query = Utils.queryParse(window.location.search, this.keysToChange);
    query.deleted = creditId;
    await this.getData(query);
    toast.success('Successfully deleted.');
    this.setState({ loading: false, creditId: null });
  }

  render() {
    const { credits, totalPages } = this.props;
    const {
      currentLoading, creditId, loading, createVoucherModal,
    } = this.state;

    const data = Utils.renameKeys(this.keysToChange, credits);

    const query = Utils.queryParse(window.location.search, this.keysToChange);
    this.getData(query);

    return (
      <Wrapper>
        <HasPermission edit="consumers" redirect>
          <HeadersMeta page="Consumers" />
        </HasPermission>
        <div className="zones">
          <div className="top">
            <div className="loading">
              {currentLoading ? <CircularProgress size="small" /> : null}
            </div>
            <HasPermission edit="product_add">
              <Button title="Issue credits" onClick={this.toggleCreateVouchersModal} />
            </HasPermission>
          </div>
          <div>
            <DataTable
              columns={this.columns}
              data={data}
              // actions={['delete']}
              // onDelete={this.toggleConfirmationModal}
              options={this.types}
              disabled={false}
              idKey="credit_id"
            />
          </div>
          <Pagination currentPage={query.page} totalPages={totalPages} />
        </div>
        <Confirmation
          isOpen={!!creditId}
          title="Are you sure, you want to delete this credit?"
          onCloseModal={() => this.toggleConfirmationModal(null)}
          onConfirm={this.handleDelete}
          loading={loading}
        />
        {createVoucherModal ? (
          <CreateVoucherModal
            isOpen={createVoucherModal}
            onCloseModal={this.toggleCreateVouchersModal}
          />
        ) : null}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  credits: state.credits.credits,
  totalPages: state.credits.totalPages,
});

const mapDispatchToProps = {
  getCreditsRequest,
  deleteCreditRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Credits);

export default Container;
