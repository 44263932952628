import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import memoizeOne from 'memoize-one';
import _ from 'lodash';
import Wrapper from '../../components/Wrapper';
import HeadersMeta from '../../components/HeadersMeta';
import Input from '../../components/form/Input';
import Select from '../../components/form/Select';
import Button from '../../components/form/Button';
import {
  createRobomartTypeRequest,
  getRobomartTypeByIdRequest,
  updateRobomartTypeRequest,
} from '../../store/actions/robomartTypes';
import Validator from '../../helpers/Validator';
import Form from '../../components/form/Form';
import { setCurrentItemName } from '../../store/actions/app';

class RobomartTypeForm extends Component {
  initRobomartTypeData = memoizeOne(async (id) => {
    if (!id) {
      return;
    }
    this.props.setCurrentItemName('');
    const { payload: { data: { data } = {} } } = await this.props.getRobomartTypeByIdRequest(id);
    if (data) {
      this.props.setCurrentItemName(data.name);
      this.setState({ formData: data });
    }
  })

  static propTypes = {
    history: PropTypes.object.isRequired,
    createRobomartTypeRequest: PropTypes.func.isRequired,
    getRobomartTypeByIdRequest: PropTypes.func.isRequired,
    updateRobomartTypeRequest: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    robomartTypeStatus: PropTypes.string.isRequired,
    setCurrentItemName: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      formData: {
        name: '',
        state: '0',
        desc: '',
        show_order: '',
        focused: false,
      },
      changed: false,
      loading: false,
      emptyFields: [],
    };
    this.states = [
      { value: '0', label: 'Offline' },
      { value: '1', label: 'Online' },
      { value: '2', label: 'Coming Soon' },
    ];
  }

  handleChange = (key, value) => {
    const { formData } = this.state;
    let { emptyFields } = this.state;
    formData[key] = value;
    if (emptyFields.includes(key)) {
      emptyFields = emptyFields.filter((f) => f !== key);
    }
    if (key === 'img') {
      value.uri = URL.createObjectURL(value);
      const reader = new FileReader();
      reader.readAsDataURL(value);
      reader.onload = () => {
        value.base64 = reader.result;
      };
    }
    this.setState({ formData, emptyFields });
  }

  handleRedirect = (url) => {
    this.props.history.push(url);
  }

  handleFocus = () => {
    this.setState({ focused: true });
  }

  handleBlur = () => {
    this.setState({ focused: false });
  }

  handleAddRobomartType = async (ev) => {
    ev.preventDefault();

    const { formData } = this.state;
    const { match: { params: { typeId } } } = this.props;
    const emptyFields = Validator.getEmptyFields(formData, ['name', 'show_order', 'img', 'desc']);
    this.setState({ loading: true });
    if (!_.isEmpty(emptyFields)) {
      this.setState({ emptyFields, loading: false });
      return;
    }
    let data;
    const img = formData?.img?.base64 ? formData?.img?.base64 : formData?.img;
    if (typeId) {
      formData.id = typeId;
      const { payload } = await this.props.updateRobomartTypeRequest({ ...formData, img });
      data = payload.data;
    } else {
      const { payload } = await this.props.createRobomartTypeRequest({ ...formData, img });
      data = payload.data;
    }
    if (data.success) {
      toast.success(`Successfully ${typeId ? 'updated' : 'created'}.`);
      this.props.history.push('/admin/types');
    }
    if (data?.error) {
      toast.error('Something went wrong.');
    }
    this.setState({ loading: false });
  }

  handleFormChange = () => {
    const { changed } = this.state;
    if (!changed) {
      this.setState({ changed: true });
    }
  }

  render() {
    const {
      formData, focused, loading, changed, emptyFields,
    } = this.state;
    const { match: { params: { typeId } }, robomartTypeStatus } = this.props;

    this.initRobomartTypeData(typeId);

    const blur = robomartTypeStatus !== 'ok' && typeId ? 'blur' : '';

    return (
      <Wrapper>
        <HeadersMeta page={` ${typeId ? 'Edit' : 'Add'} Type`} />
        <Form
          onSubmit={this.handleAddRobomartType}
          className="addBlockForm addRobomartType"
          ref={(ref) => this.form = ref}
          id={typeId}
          onChange={this.handleFormChange}
        >
          <div className="addBlock">
            <div className="col">
              <Input
                label="Name *"
                value={formData.name || ''}
                onChange={(ev) => this.handleChange('name', ev.target.value)}
                className={blur}
                invalid={emptyFields.includes('name')}
              />
              <Select
                options={this.states}
                label="State *"
                value={this.states.find((s) => +s.value === +formData.state)}
                onChange={(val) => this.handleChange('state', val.value)}
                className={blur}
                invalid={emptyFields.includes('state')}
              />
              <Input
                textarea
                label="Description *"
                value={formData.desc || ''}
                onChange={(ev) => this.handleChange('desc', ev.target.value)}
                className={blur}
                fieldType="Textarea"
                invalid={emptyFields.includes('desc')}
              />
            </div>
            <div className="col">
              <Input
                label="Order *"
                value={formData.show_order || ''}
                onChange={(ev) => this.handleChange('show_order', ev.target.value)}
                className={blur}
                type="numeric"
                invalid={emptyFields.includes('show_order')}
                min="1"

              />
              <div className="fileInput">
                {focused ? <span className="fileName">{formData?.img?.name}</span> : null}
                <Input
                  label="Image *"
                  onChange={(ev) => {
                    this.handleChange('img', ev.target.files[0]);
                    ev.target.value = '';
                  }}
                  type="file"
                  accept="image/png, image/jpeg"
                  className={blur}
                  onFocus={this.handleFocus}
                  onBlur={this.handleBlur}
                  invalid={emptyFields.includes('img')}
                />
              </div>
              {formData?.img ? <img src={formData?.img?.uri || formData?.img} alt="" className="productImg" /> : null}
            </div>
          </div>

          <div className="actions">
            <Button title="Cancel" onClick={() => this.handleRedirect('/admin/types')} type="button" />
            <Button
              type="submit"
              title={typeId ? 'Save' : 'Create'}
              loading={loading}
              disabled={typeId && !changed}
            />
          </div>
        </Form>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  robomartTypeStatus: state.robomartTypes.robomartTypeStatus,
});

const mapDispatchToProps = {
  createRobomartTypeRequest,
  updateRobomartTypeRequest,
  getRobomartTypeByIdRequest,
  setCurrentItemName,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RobomartTypeForm);

export default Container;
