import _ from 'lodash';

class Polygon {
  static toRadians(angleInDegrees) {
    return (angleInDegrees * Math.PI) / 180;
  }

  static toDegrees(angleInRadians) {
    return (angleInRadians * 180) / Math.PI;
  }

  static stringify(polygon) {
    const dataArr = [];
    polygon.forEach((polyline) => {
      const positions = [];
      polyline.forEach((pos) => {
        positions.push(`${pos.lng} ${pos.lat}`);
      });
      dataArr.push(`(${positions.join(',')})`);
    });
    return `POLYGON(${dataArr.join(',')})`;
  }

  static idValidPolygon(str) {
    try {
      return !this.parse(str).some((pos) => _.isNaN(pos.lat) || _.isNaN(pos.lng));
    } catch (e) {
      return false;
    }
  }

  static parse(polygon) {
    if (!polygon || _.isEmpty(polygon)) {
      return null;
    }
    let d;
    try {
      d = JSON.parse(`[${polygon
        .replace(/^POLYGON/, '')
        .replace(/,/g, '],[')
        .replace(/\(/g, '[')
        .replace(/\)/g, ']')
        .replace(/(\d)\s([\d-])/g, '$1,$2')}]`);
    } catch (e) {
      console.warn(e);
      d = [];
    }

    return d.map((polyline) => (
      polyline.map((pos) => ({
        lat: +pos[1],
        lng: +pos[0],
      }))
    ));
  }

  static circleToPolygon(center, radius, options = {}) {
    const n = options.numberOfEdges || 32;
    const bearing = options.bearing || 0;
    const direction = !options.rightHandRule ? 1 : -1;
    const earthRadius = 6378137;

    const start = this.toRadians(bearing);
    const coordinates = [];

    const offset = (c1, distance, _bearing) => {
      const lng1 = this.toRadians(c1.lng);
      const lat1 = this.toRadians(c1.lat);
      const dByR = distance / earthRadius;
      const lat = Math.asin(
        Math.sin(lat1) * Math.cos(dByR) + Math.cos(lat1) * Math.sin(dByR) * Math.cos(_bearing),
      );
      const lng = lng1
        + Math.atan2(
          Math.sin(_bearing) * Math.sin(dByR) * Math.cos(lat1),
          Math.cos(dByR) - Math.sin(lat1) * Math.sin(lat),
        );
      return { lng: this.toDegrees(lng), lat: this.toDegrees(lat) };
    };

    for (let i = 0; i < n; ++i) {
      coordinates.push(
        offset(
          center, radius, start + (direction * 2 * Math.PI * -i) / n,
        ),
      );
    }
    coordinates.push(coordinates[0]);
    return [coordinates];
  }

  static center(arr) {
    const x = arr.map((xy) => xy.lng);
    const y = arr.map((xy) => xy.lat);
    const lng = (Math.min(...x) + Math.max(...x)) / 2;
    const lat = (Math.min(...y) + Math.max(...y)) / 2;
    return { lng, lat };
  }

  static polygonToCircle(polygon) {
    const center = this.center(polygon[0]);
    const [pos1] = polygon[0];
    const distances = polygon[0].map((pos2) => this.calcDistance(pos1, pos2));
    const radius = Math.max.apply(null, distances) / 2;
    return {
      center,
      radius,
    };
  }

  // calculate distance of coordinates
  static calcDistance(pos1, pos2) {
    const { lng: lng1, lat: lat1 } = pos1;
    const { lng: lon2, lat: lat2 } = pos2;
    const R = 6378137;
    const dLat = this.toRadians(lat2 - lat1);
    const dLon = this.toRadians(lon2 - lng1);
    const lat1Rad = this.toRadians(lat1);
    const lat2Rad = this.toRadians(lat2);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
      + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1Rad) * Math.cos(lat2Rad);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d;
  }

  static getPolygonPaths(polygon) {
    const poly = [];
    polygon.getPaths().forEach((el) => {
      const polyline = [];
      el.forEach((pos) => {
        polyline.push({
          lng: pos.lng(),
          lat: pos.lat(),
        });
      });
      poly.push(polyline);
    });
    return poly;
  }
}

export default Polygon;
