import React, { Component } from 'react';
import memoizeOne from 'memoize-one';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CircularProgress } from 'rmwc';
import { getZonesRequest } from '../../store/actions/zones';
import Wrapper from '../../components/Wrapper';
import HeadersMeta from '../../components/HeadersMeta';
import Button from '../../components/form/Button';
import DataTable from '../../components/DataTable';
import Utils from '../../helpers/Utils';
import Pagination from '../../components/Pagination';
import HasPermission from '../../components/HasPermission';

class Zones extends Component {
  getData = memoizeOne(async (query) => {
    this.setState({ currentLoading: true });
    const {
      code, name, city, state, country, active,
      predicate, reverse,
    } = query;
    const predicateObject = {
      code,
      name,
      city,
      state,
      country,
      is_active: active,
    };
    const search = { predicateObject };
    const predicateVal = predicate === 'active' ? 'is_active' : predicate;
    const sort = { predicate: predicateVal || 'name', reverse: reverse || false };
    await this.props.getZonesRequest(sort, search, (query.page - 1) || 0);
    this.setState({ currentLoading: false });
  }, _.isEqual);

  static propTypes = {
    zones: PropTypes.array.isRequired,
    getZonesRequest: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    totalPages: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentLoading: false,
    };
    this.columns = [
      { column: 'code', type: 'searchable' },
      { column: 'name', type: 'searchable' },
      { column: 'city', type: 'searchable' },
      { column: 'state', type: 'searchable' },
      { column: 'country', type: 'searchable' },
      { column: 'active', type: '' },
    ];
  }

  handleAdd = () => {
    this.props.history.push('/admin/zones/add');
  }

  handleEdit = (id) => `/admin/zones/edit/${id}`

  render() {
    const { currentLoading } = this.state;
    const { zones, totalPages } = this.props;

    const query = Utils.queryParse();
    this.getData(query);

    const data = Utils.renameKeys({ is_active: 'active' }, zones);
    const actions = HasPermission.check('zone_edit') ? ['edit'] : undefined;
    return (
      <Wrapper>
        <HasPermission edit="zone" redirect>
          <HeadersMeta page="Zones" />

          <div className="zones">
            <div className="top">
              <div className="loading">
                {currentLoading ? <CircularProgress size="small" /> : null}
              </div>
              <HasPermission edit="zone_add">
                <Button title="Add Zone" onClick={this.handleAdd} />
              </HasPermission>
            </div>
            <div>
              <DataTable
                columns={this.columns}
                data={data}
                actions={actions}
                onEdit={this.handleEdit}
                onSearch={this.handleSearch}
                onSort={this.handleSort}
                state="administrative"
              />
            </div>
            <Pagination currentPage={query.page} totalPages={totalPages} />
          </div>
        </HasPermission>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  zones: state.zones.zones,
  totalPages: state.zones.totalPages,
});

const mapDispatchToProps = {
  getZonesRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Zones);

export default Container;
