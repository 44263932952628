import React, { Component } from 'react';
import Tooltip from 'rc-tooltip';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import HeadersMeta from '../../components/HeadersMeta';
import Form from '../../components/form/Form';
import Input from '../../components/form/Input';
import Select from '../../components/form/Select';
import Button from '../../components/form/Button';
import Wrapper from '../../components/Wrapper';
import Validator from '../../helpers/Validator';
import { getTypesRequest } from '../../store/actions/types';
import { setCurrentItemName } from '../../store/actions/app';
import { createStorefrontRequest, getStorefrontByIdRequest, updateStorefrontRequest } from '../../store/actions/storefronts';
import { getMerchantsRequest } from '../../store/actions/merchants';
import Api from '../../Api';
import Loader from '../../components/Loader';

class StorefrontForm extends Component {
  initProductData = memoizeOne(async (id) => {
    if (!id) {
      return;
    }
    this.props.setCurrentItemName('');
    const { payload: { data: { data } = {} } } = await this.props.getStorefrontByIdRequest(id);
    if (data) {
      this.props.setCurrentItemName(data.name);
      this.setState({ formData: data });
    }
  })

  static propTypes = {
    match: PropTypes.object.isRequired,
    merchants: PropTypes.array.isRequired,
    types: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    storefrontStatus: PropTypes.string.isRequired,
    createStorefrontRequest: PropTypes.func.isRequired,
    updateStorefrontRequest: PropTypes.func.isRequired,
    getMerchantsRequest: PropTypes.func.isRequired,
    getTypesRequest: PropTypes.func.isRequired,
    setCurrentItemName: PropTypes.func.isRequired,
    getStorefrontByIdRequest: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.status = [
      { value: true, label: 'Active' },
      { value: false, label: 'Inactive' }];
    this.state = {
      formData: {
        name: '',
        img: '',
        org_id: '',
        type_id: '',
        is_active: false,
      },
      loading: false,
      changed: false,
      emptyFields: [],
      imgLoading: false,
      focused: false,
    };
  }

  componentDidMount() {
    this.props.getTypesRequest({}, {}, 0, 100);
    this.props.getMerchantsRequest({}, {}, 0, 100);
  }

  handleAddStorefront = async (ev) => {
    ev.preventDefault();
    const { formData } = this.state;
    const { match: { params: { storefrontId } } } = this.props;
    const emptyFields = Validator.getEmptyFields(formData,
      ['name', 'img', 'type_id', 'org_id']);
    this.setState({ loading: true });
    if (!_.isEmpty(emptyFields)) {
      this.setState({ emptyFields, loading: false });
      return;
    }

    let data;
    if (storefrontId) {
      formData.vid = storefrontId;

      const { payload } = await this.props.updateStorefrontRequest(formData);
      data = payload.data;
    } else {
      const { payload } = await this.props.createStorefrontRequest(formData);
      data = payload.data;
    }
    if (data.success) {
      toast.success(`Successfully ${storefrontId ? 'updated' : 'created'}.`);
      this.props.history.push('/admin/storefronts');
    }
    if (data?.error) {
      toast.error('Something went wrong');
    }

    this.setState({ loading: false });
  }

  handleChange = async (key, value) => {
    const { formData } = this.state;
    let { emptyFields } = this.state;
    this.handleFormChange();
    formData[key] = value;

    if (key === 'img') {
      this.setState({ imgLoading: true });
      const payload = await Api.fileUpload(value) || {};
      formData[key] = payload.data.data[0];
      formData.imgName = value.name;
      this.setState({ imgLoading: false });
    }

    if (emptyFields.includes(key)) {
      emptyFields = emptyFields.filter((f) => f !== key);
    }
    this.setState({ formData, emptyFields });
  }

  cancel = () => {
    this.props.history.push('/admin/storefronts');
  }

  handleFormChange = () => {
    const { changed } = this.state;
    if (!changed) {
      this.setState({ changed: true });
    }
  }

  handleFocus = (focused) => {
    this.setState({ focused });
  }

  handleBlur = () => {
    this.setState({ focused: null });
  }

  handleRedirect = (url) => {
    this.props.history.push(url);
  }

  render() {
    const {
      match: { params: { storefrontId } }, storefrontStatus, merchants, types,
    } = this.props;
    const {
      loading, formData, emptyFields, changed, focused, imgLoading,
    } = this.state;
    this.initProductData(storefrontId);
    const blur = storefrontStatus !== 'ok' && storefrontId ? 'blur' : '';
    return (
      <Wrapper>
        <HeadersMeta page={` ${storefrontId ? 'Edit' : 'Add'} Storefront`} />
        <Form
          onSubmit={this.handleAddStorefront}
          className="addBlockForm addStorefront"
          ref={(ref) => this.form = ref}
          id={storefrontId}
          onChange={this.handleFormChange}
        >
          <div className="addBlock">
            <div className="col">
              <Input
                label="Name *"
                value={formData.name || ''}
                onChange={(ev) => this.handleChange('name', ev.target.value)}
                className={blur}
                invalid={emptyFields.includes('name')}
              />
              <Select
                options={this.status}
                label="Storefront State *"
                value={this.status.find((s) => formData.is_active === s.value)}
                onChange={(val) => this.handleChange('is_active', val.value)}
                className={blur}
              />
              <Select
                options={merchants}
                label="Merchant *"
                getOptionValue={(o) => o.org_id}
                getOptionLabel={(o) => o.name}
                value={merchants.find((s) => formData.org_id === s.org_id)}
                onChange={(val) => {
                  this.handleChange('org_id', val.org_id);
                  this.handleChange('org_name', val.name);
                }}
                className={blur}
                invalid={emptyFields.includes('org_id')}
              />
            </div>
            <div className="col">
              <Select
                options={types}
                label="Type *"
                getOptionValue={(o) => o.id}
                getOptionLabel={(o) => o.name}
                value={types.find((s) => formData.type_id === s.id)}
                onChange={(val) => {
                  this.handleChange('type_id', val.id);
                  this.handleChange('type_name', val.name);
                }}
                className={blur}
                invalid={emptyFields.includes('type_id')}
              />
              <div className="fileInput">
                {focused === 'img' ? <span className="fileName">{formData?.imgName}</span> : null}
                <Input
                  label="Storefront Image *"
                  onChange={(ev) => {
                    this.handleChange('img', ev.target.files[0]);
                    ev.target.value = '';
                  }}
                  type="file"
                  accept="image/png, image/jpeg, image/gif"
                  className={blur}
                  onFocus={() => this.handleFocus('img')}
                  onBlur={this.handleBlur}
                  invalid={emptyFields.includes('img')}
                />
                {formData?.img && !imgLoading
                  ? <img src={formData?.img} alt="" className="productImg" /> : null}
                {imgLoading ? <Loader /> : null}
              </div>
            </div>
          </div>
          <div className="actions">
            <Button title="Cancel" onClick={this.cancel} type="button" />
            <Button
              type="submit"
              title={storefrontId ? 'Save' : 'Create'}
              loading={loading}
              disabled={(storefrontStatus !== 'ok' && storefrontId)
                || (storefrontId && !changed)}
            />
            {storefrontId ? (
              <Button
                title="Go to menu"
                onClick={() => this.handleRedirect(`/admin/storefronts/menu/${storefrontId}`)}
                type="button"
              />
            ) : null}
          </div>
        </Form>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  types: state.types.types,
  merchants: state.merchants.merchants,
  storefrontStatus: state.storefronts.storefrontStatus,
});

const mapDispatchToProps = {
  getTypesRequest,
  getMerchantsRequest,
  setCurrentItemName,
  createStorefrontRequest,
  updateStorefrontRequest,
  getStorefrontByIdRequest,
};

const Container = connect(
  mapStateToProps,
  mapDispatchToProps,
)(StorefrontForm);

export default Container;
