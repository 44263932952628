import React, { Component } from 'react';
import { Button as B, CircularProgress } from 'rmwc';
import PropTypes from 'prop-types';

class Button extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    disabled: PropTypes.any,
    title: PropTypes.string,
    raised: PropTypes.bool,
    outlined: PropTypes.bool,
  };

  static defaultProps = {
    loading: false,
    title: '',
    disabled: false,
    raised: true,
    outlined: false,
  }

  render() {
    const {
      loading, disabled, title, raised, outlined, ...props
    } = this.props;
    return (
      <div className="rmButton">
        <B raised={raised} outlined={outlined} disabled={loading || disabled} {...props}>
          {loading ? <CircularProgress /> : title}
        </B>
      </div>
    );
  }
}

export default Button;
