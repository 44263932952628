import { GET_GROUPS, GET_GROUP_ALL_RIGHTS, GET_GROUP_BY_ID } from '../actions/groups';

const initialState = {
  groups: [],
  groupsStatus: '',
  groupAllRights: [],
  groupAllRightsStatus: '',
  group: [],
  groupStatus: '',
  totalPages: 1,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_GROUPS.REQUEST: {
      return {
        ...state,
        groupsStatus: 'request',
      };
    }
    case GET_GROUPS.SUCCESS: {
      const { data: { data: { Records, RecordsCount } } } = action.payload;
      return {
        ...state,
        groups: Records,
        groupsStatus: 'ok',
        totalPages: Math.ceil(RecordsCount / 10),
      };
    }
    case GET_GROUPS.FAIL: {
      return {
        ...state,
        groupsStatus: 'fail',
      };
    }
    case GET_GROUP_ALL_RIGHTS.REQUEST: {
      return {
        ...state,
        groupAllRightsStatus: 'request',
      };
    }
    case GET_GROUP_ALL_RIGHTS.SUCCESS: {
      const { data: { data: { Records } } } = action.payload;
      return {
        ...state,
        groupAllRights: Records,
        groupAllRightsStatus: 'ok',
      };
    }
    case GET_GROUP_ALL_RIGHTS.FAIL: {
      return {
        ...state,
        groupAllRightsStatus: 'fail',
      };
    }
    case GET_GROUP_BY_ID.REQUEST: {
      return {
        ...state,
        groupStatus: 'request',
      };
    }
    case GET_GROUP_BY_ID.SUCCESS: {
      return {
        ...state,
        groupStatus: 'ok',
      };
    }
    case GET_GROUP_BY_ID.FAIL: {
      return {
        ...state,
        groupStatus: 'fail',
      };
    }
    default: {
      return state;
    }
  }
}
