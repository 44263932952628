import React, { Component } from 'react';
import { Icon } from 'rmwc';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Button from '../form/Button';
import Select from '../form/Select';

class AssignModal extends Component {
  static propTypes = {
    title: PropTypes.string,
    isOpen: PropTypes.bool,
    onCloseModal: PropTypes.func,
    onAssign: PropTypes.func,
    loading: PropTypes.string,
    vehicles: PropTypes.array,
  };

  static defaultProps = {
    title: '',
    isOpen: false,
    onCloseModal: undefined,
    onAssign: undefined,
    loading: '',
    vehicles: [],
  }

  constructor(props) {
    super(props);
    this.state = {
      vehicleId: null,
    };
  }

  handleChange = (val) => {
    this.setState({ vehicleId: val.vid });
  }

  handleClose = () => {
    this.props.onCloseModal();
    this.setState({ vehicleId: null });
  }

  handleAssign = async () => {
    const { vehicleId } = this.state;
    await this.props.onAssign(vehicleId);
    this.handleClose();
  }

  render() {
    const {
      title, isOpen, loading, vehicles,
    } = this.props;
    const { vehicleId } = this.state;
    if (!isOpen) {
      return null;
    }
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.handleClose}
        overlayClassName="modalOverlay"
        className="modalContent assignModal"
        parentSelector={() => document.querySelector('#root > div')}
      >
        <div className="top">
          <Icon icon="close" className="icon" onClick={this.handleClose} />
        </div>
        <p>{title}</p>
        <Select
          options={vehicles}
          getOptionValue={(o) => o.vid}
          getOptionLabel={(o) => o.name}
          label="Vehicle"
          onChange={this.handleChange}
          value={vehicles.find((v) => v.vid === vehicleId)}
        />
        <div className="actions">
          <Button title="Cancel" outlined raised={false} onClick={this.handleClose} />
          <Button title="Unassign" loading={loading === 'unassign'} onClick={this.handleAssign} />
          <Button title="Assign" loading={loading === 'assign'} onClick={this.handleAssign} disabled={!vehicleId} />
        </div>
      </Modal>
    );
  }
}

export default AssignModal;
