import { define } from '../../helpers/redux-request';
import Api from '../../Api';

export const GET_GROUPS = define('GET_GROUPS');

export function getGroupsRequest(sort, search, page, number) {
  return GET_GROUPS.request(() => Api.getGroups(sort, search, page, number)).takeLatest();
}

export const GET_GROUP_ALL_RIGHTS = define('GET_GROUP_ALL_RIGHTS');

export function getGroupAllRightsRequest() {
  return GET_GROUP_ALL_RIGHTS.request(() => Api.getGroupAllRights());
}

export const CREATE_GROUP = define('CREATE_GROUP');

export function createGroupRequest(obj) {
  return CREATE_GROUP.request(() => Api.createGroup(obj));
}

export const UPDATE_GROUP = define('UPDATE_GROUP');

export function updateGroupRequest(obj) {
  return UPDATE_GROUP.request(() => Api.updateGroup(obj));
}

export const GET_GROUP_BY_ID = define('GET_GROUP_BY_ID');

export function getGroupByIdRequest(id) {
  return GET_GROUP_BY_ID.request(() => Api.getGroupById(id));
}
